<!---------------------------------------------------------------------------- 
 | 合同管理 - 开票记录 - 修改
 |----------------------------------------------------------------------------
 | 方法：请注意大小写,参数描述见具体方法
 | $refs[ref_name].Open(*id) 手动打开窗口,
 | $refs[ref_name].Close() 手动关闭窗口
 |----------------------------------------------------------------------------
 | 回调
 | @success = fn (Object) => {}
 |
 ---------------------------------------------------------------------------->

<template>
    <el-drawer class="w-el-drawer" :visible.sync="visible" :size="960" :with-header="false" direction="rtl" :before-close="Close" append-to-body>
        
        <!-- header -->
        <div class="w-el-drawer-header">
            <i class="el-icon-tickets"></i> 新增开票记录
        </div>

        <!-- body -->
        <div class="w-el-drawer-body"  v-loading="loading">
           
            <!-- 表单 -->
            <el-form :model="form" label-width="120px" size="mini">
                
                <!-- 关联合同 -->
                <el-form-item :rules="[{ required: true, message: '必填项' }]" label="关联合同" :error="formError.contract_id?.toString()" prop="contract_id">
                    <crm-contract-select v-model="form.contract_id" @onChange="ContractOnChange" />
                </el-form-item>

                <!-- 关联客户 -->
                <el-form-item :rules="[{ required: true, message: '必填项' }]" label="关联客户" :error="formError.customer_id?.toString()" prop="customer_id">
                    <crm-customer-select v-model="form.customer_id" @onChange="CustomerOnChange" clearable />
                </el-form-item>

                <el-divider content-position="left">票据信息</el-divider>

                <!-- 开票内容 & 开票日期 -->
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="开票内容" :rules="[{ required: true, message: '必填项' }]" :error="formError.invoice_content?.toString()" prop="invoice_content">
                            <el-input v-model="form.invoice_content" placeholder="请填写开票内容" clearable />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item :rules="[{ required: true, message: '必填项' }]" label="开票日期" :error="formError.invoice_date?.toString()" prop="invoice_date">
                            <el-date-picker v-model="form.invoice_date" value-format="yyyy-MM-dd" type="date" placeholder="请选择开票日期" clearable />
                        </el-form-item>
                    </el-col>
                </el-row>

                <!-- 票据类型 & 票据号码 -->
                <el-row>
                    <el-col :span="12">
                        <el-form-item :rules="[{ required: true, message: '必填项' }]" label="票据类型" :error="formError.invoice_type_id?.toString()" prop="invoice_type_id">
                            <config-invoice-type-select item-disabled v-model="form.invoice_type_id" @onChange="InvoiceTypeOnChange" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="票据号码">
                            <el-input v-model="form.invoice_number" placeholder="请填写票据号码" clearable />
                        </el-form-item>
                    </el-col>
                </el-row>

                <!-- 票据金额 & 经办人-->
                <el-row>
                    <el-col :span="12">
                        <el-form-item :rules="[{ required: true, message: '必填项' }]" label="票据金额" :error="formError.invoice_money?.toString()" prop="invoice_money">
                            <el-input-number v-model="form.invoice_money" label="请填写票据金额" :precision="2" class="w-width-auto"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item :rules="[{ required: true, message: '必选项' }]" label="经办人" :error="formError.user_id?.toString()" prop="user_id">
                            <users-select v-model="form.user_id" />
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-divider content-position="left">开票单位信息</el-divider>

                <!-- 开票单位名称 & 开票单位税号 -->
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="开票单位名称" :rules="[{ required: true, message: '必填项' }]" :error="formError.invoice_unit_name?.toString()" prop="invoice_unit_name">
                            <el-input v-model="form.invoice_unit_name" placeholder="请填写开票单位名称" clearable />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="开票单位税号">
                            <el-input v-model="form.invoice_unit_number" placeholder="请填写开票单位税号" clearable />
                        </el-form-item>
                    </el-col>
                </el-row>

                <!-- 开票单位地址 & 开票单位电话 -->
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="开票单位地址">
                            <el-input v-model="form.invoice_unit_address" placeholder="请填写开票单位名称" clearable />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="开票单位电话">
                            <el-input v-model="form.invoice_unit_phone" placeholder="开票单位电话" clearable />
                        </el-form-item>
                    </el-col>
                </el-row>

                <!-- 开户银行 & 银行账号 -->
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="开户银行">
                            <el-input v-model="form.invoice_unit_bank_name" placeholder="请填写开户银行" clearable />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="银行账号">
                            <el-input v-model="form.invoice_unit_bank_number" placeholder="请填写银行账号" clearable />
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-divider content-position="left">税务信息</el-divider>

                <!-- 是否含税 -->
                <el-form-item :rules="[{ required: true, message: '必填项' }]" label="是否含税" :error="formError.taxation_state?.toString()" prop="taxation_state">
                    <el-radio-group v-model="form.taxation_state">
                        <el-radio :label="0">不含税</el-radio>
                        <el-radio :label="1">含税</el-radio>
                    </el-radio-group>
                </el-form-item>

                <!-- 税率 & 税金 -->
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="税率 / %" :rules="[{ required: true, message: '必填项' }]" :error="formError.taxation_rate?.toString()" prop="taxation_rate">
                            <el-input-number v-model="form.taxation_rate" label="请填写税率" :min="0" :precision="2" class="w-width-auto"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="税金" :rules="[{ required: true, message: '必填项' }]" :error="formError.taxation_money?.toString()" prop="taxation_money">
                            <el-input-number v-model="form.taxation_money" label="请填写税金" :precision="2" class="w-width-auto" disabled></el-input-number>
                        </el-form-item>
                    </el-col>
                </el-row>

                <!-- 备注 -->
                <el-form-item label="备注">
                    <el-input v-model="form.describe" type="textarea" :rows="5" placeholder="备注信息"></el-input>
                </el-form-item>

                <el-divider content-position="left">审批流程</el-divider>
                
                <!-- 审批流程 -->
                <el-form-item label="审批流程">
                    <workflow-update ref="workflow" v-model="form.workflow_template" model="contract-pay-invoice" />
                </el-form-item>
            </el-form>
        </div>

        <!-- footer -->
        <div class="w-el-drawer-footer">
            <el-button size="mini" @click="Close()">取消 / 返回</el-button>
            <el-button size="mini" type="primary" @click="FormSubmit()" :loading="loading">更 新</el-button>
        </div>

    </el-drawer>
</template>
    
<script>
export default {
    data() {
        return {
            visible: false,
            loading: false,

            formError: {},
            form: {
                id: null,
                contract_id: null,
                customer_id: null,
                invoice_content: null,
                invoice_date: null,
                invoice_unit_name: null,
                invoice_unit_address: null,
                invoice_unit_number: null,
                invoice_unit_phone: null,
                invoice_unit_bank_name: null,
                invoice_unit_bank_number: null,
                invoice_type_id: null,
                invoice_number: null,
                invoice_money: null,
                taxation_state: 0,
                taxation_rate: 0,
                taxation_money: null,
                user_id: null,
                describe: null,
                workflow_id: null,
                workflow_template: null,
            }
        }
    },

    watch: {
        'form.invoice_money' () {
            this.CountTaxationRate()
        },

        'form.taxation_rate' () {
            this.CountTaxationRate()
        },

        'form.taxation_state' () {
            this.CountTaxationRate()
        }
    },
    
    methods: {
        /**
        |--------------------------------------------------
        | 窗口 - 打开
        |--------------------------------------------------
        |
        */

        Open(id) {
            // 窗口配置
            this.visible = true
            this.loading = true

            this.$http.post('/9api/contract-pay-invoice/first', { id: id, append: ['workflow_template'] }).then(rps => {
                this.form.id = rps.data.id
                this.form.contract_id = rps.data.contract_id
                this.form.customer_id = rps.data.customer_id
                this.form.invoice_content = rps.data.invoice_content
                this.form.invoice_date = rps.data.invoice_date
                this.form.invoice_unit_name = rps.data.invoice_unit_name
                this.form.invoice_unit_address = rps.data.invoice_unit_address
                this.form.invoice_unit_number = rps.data.invoice_unit_number
                this.form.invoice_unit_phone = rps.data.invoice_unit_phone
                this.form.invoice_unit_bank_name = rps.data.invoice_unit_bank_name
                this.form.invoice_unit_bank_number = rps.data.invoice_unit_bank_number
                this.form.invoice_type_id = rps.data.invoice_type_id
                this.form.invoice_number = rps.data.invoice_number
                this.form.invoice_money = rps.data.invoice_money
                this.form.taxation_state = rps.data.taxation_state
                this.form.taxation_rate = rps.data.taxation_rate
                this.form.taxation_money = rps.data.taxation_money
                this.form.user_id = rps.data.user_id
                this.form.describe = rps.data.describe
                this.form.workflow_id = rps.data.workflow_id
                this.form.workflow_template = rps.data.workflow_template
                this.loading = false
            })
        },

        
        /**
        |--------------------------------------------------
        | 窗口 - 关闭
        |--------------------------------------------------
        |
        */

        Close() {
            this.visible = false
            this.loading = false
        },

        /**
        |--------------------------------------------------
        | 合同信息 - 映射表单
        |--------------------------------------------------
        |
        */
        
        ContractOnChange(data) {
            this.form.customer_id = data?.customer_id
        },

        /**
        |--------------------------------------------------
        | 客户信息 - 映射表单
        |--------------------------------------------------
        |
        */

        CustomerOnChange(data) {
            this.form.invoice_unit_name = data?.invoice_unit_name
            this.form.invoice_unit_address = data?.invoice_unit_address
            this.form.invoice_unit_number = data?.invoice_unit_number
            this.form.invoice_unit_phone = data?.invoice_unit_phone
            this.form.invoice_unit_bank_name = data?.invoice_unit_bank_name
            this.form.invoice_unit_bank_number = data?.invoice_unit_bank_number
        },

        /**
        |--------------------------------------------------
        | 回款记录 - 映射表单
        |--------------------------------------------------
        |
        */

        ContractPayRecordOnChange(data) {
            if (data) {
                this.form.invoice_money = data?.pay_money
                this.form.invoice_date = data?.pay_date
                this.form.user_id = data?.user_id
            }
        },

        /**
        |--------------------------------------------------
        | 票据类型 - 映射表单
        |--------------------------------------------------
        |
        */

        InvoiceTypeOnChange (data) {
            console.log(data)
            this.form.taxation_rate = data?.taxation_rate
        },

        /**
        |--------------------------------------------------
        | 税金计算
        |--------------------------------------------------
        |
        */
        
        CountTaxationRate() {
            if (this.form.taxation_state == 1) { 
                this.form.taxation_money = this.form.invoice_money / (1 + this.form.taxation_rate * 0.01) * this.form.taxation_rate * 0.01 
            } else {
                this.form.taxation_money = this.form.invoice_money * this.form.taxation_rate * 0.01
            }
        },
        
        /**
        |--------------------------------------------------
        | 表单提交
        |--------------------------------------------------
        |
        */

        FormSubmit() {
            // 清空错误提示
            this.loading = true
            this.formError = {}

            this.$http.post('/9api/contract-pay-invoice/update', this.form).then(rps => {
                
                this.loading = false

                if (rps.data.state == 'error') {
                    this.formError = rps.data.body
                }

                if (rps.data.state == 'success') {
                    this.$notify.success({title: '提示', message: '操作成功', duration: 2000})
                    this.$emit('success', rps.data.body)
                    this.Close()
                }
            })
        }
    }
}
</script>