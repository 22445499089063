<!---------------------------------------------------------------------------- 
 | 附件管理 - 附件预览下载
 |----------------------------------------------------------------------------
 | :preview-type 窗口类型 drawer 或 dialog
 | :preview-direction 打开的方向
 | :preview-modal 是否需要遮罩层
 | :drawer-size Drawer 窗体的大小
 |
 ---------------------------------------------------------------------------->

<template>
    <div>
        <el-drawer
            :style="{ width: drawerSize + 'px' }"
            v-if="previewType == 'drawer'"
            class="w-el-drawer"
            :visible.sync="previewVisible"
            :modal="previewModal"
            size="100%"
            :wrapperClosable="false"
            :with-header="false"
            :direction="previewDirection"
            append-to-body>
            
            <!-- header -->
            <div class="w-el-drawer-header">
                <i class="el-icon-tickets"></i> {{ previewTitle }}
            </div>

            <!-- body -->
            <div class="w-el-drawer-body" :style="{ padding: '0px', overflow: 'hidden' }">
                <iframe :src="previewUrl" width="100%" height="100%" frameborder="no"></iframe>
            </div>

            <!-- footer -->
            <div class="w-el-drawer-footer">
                <el-button size="mini" type="danger" @click="previewVisible = false">关 闭 / ESC</el-button>
            </div>
        </el-drawer>

        <el-dialog
            v-if="previewType == 'dialog'"
            class="w-el-dialog"
            :visible.sync="previewVisible"
            :modal="previewModal"
            fullscreen
            :title="previewTitle"
            append-to-body
            :close-on-click-modal="false"
            :close-on-press-escape="false">

            <iframe :src="previewUrl" width="100%" height="100%" frameborder="no"></iframe>
        </el-dialog>

        <iframe :src="downUrl" width="0" height="0" v-show="false"></iframe>
    </div>
</template>

<script>
export default {
    props: {
        drawerSize: {
            type: [String, Number],
            default: '80%'
        },
        drawerSize: {
            type: [String, Number],
            default: 'dialog'
        },
        previewType: {
            type: String,
            default: 'dialog'
        },
        previewDirection: {
            type: String,
            default: 'rtl'
        },
        previewModal: {
            type: Boolean,
            default: true
        }
    },

    data () {
        return {
            previewVisible: false,
            previewUrl: null,
            previewTitle: null,

            downUrl: null,

            filesOffice: [
                // doc
                'application/msword',
                'application/octet-stream',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',

                // xls
                'application/vnd.ms-excel',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',

                // ppt
                'application/vnd.ms-powerpoint',
                'application/vnd.openxmlformats-officedocument.presentationml.presentation',
            ],

            filesPdf: [
                'application/pdf'
            ],

            // pdf
            // application/pdf
        }
    },

    methods: {
        Open(id, path, type) {

            // 查询文件信息
            this.$http.post('/9api/components-files/verify', {id: id, path: path}).then((rps) => {
                
                // 预览文件
                if (type == 'preview') {

                    this.previewTitle = rps.data.name + ' (' + rps.data.type + ')'
                    
                    // Office文件预览
                    if (this.filesOffice.includes(rps.data.type)) {
                        this.previewVisible = true
                        this.previewUrl = 'https://view.officeapps.live.com/op/view.aspx?src=' + encodeURIComponent('https://aqyy.waner.cn/9api/components-files/preview?id=' + id + '&path=' +  path)
                    }

                    else {
                        this.previewVisible = true
                        this.previewUrl = '/9api/components-files/preview?id=' + id + '&path=' +  path
                    }

                } 

                if (type == 'down') {
                    this.downUrl = '/9api/components-files/down?id=' + id + '&path=' +  path
                }
                
            })
        }
    }
}
</script>