<!---------------------------------------------------------------------------- 
 | CRM - 新的线索 
 |----------------------------------------------------------------------------
 | 方法：请注意大小写,参数描述见具体方法
 | $refs[ref_name].Open() 手动打开窗口,
 |----------------------------------------------------------------------------
 | 回调
 | @onChange = fn (Object) => {} 成功后返信息
 |
 ---------------------------------------------------------------------------->

 <template>
    <div v-loading="$store.state.loading">
        <div :style="{ marginBottom: '10px' }">
            <el-button size="mini" v-if="disabled"  @click="disabled = false">编辑工时</el-button>
            <el-button size="mini" v-if="!disabled" @click="disabled = true">取消</el-button>
            <el-button size="mini" v-if="!disabled" type="primary" @click="SubmitForm()" :loading="$store.state.loading">保存</el-button>
        </div>

        <!-- 基本信息 -->
        <div style="background-color: #e8eaf6; border-radius: 3px; padding: 15px; display: flex; border: 1px solid #0027ff; margin-bottom: 10px;">
            <el-statistic title="预计工时"><template slot="formatter"><span style="font-size: 14px;">{{ day_predict }} / P</span></template></el-statistic>
            <el-statistic title="消耗工时"><template slot="formatter"><span style="font-size: 16px;"><b>{{ day_consume }} / P</b></span></template></el-statistic>
            <el-statistic title="剩余可用工时"><template slot="formatter"><span style="font-size: 14px;">{{ day_surplus }} / P</span></template></el-statistic>
        </div>

        <vxe-table ref="xTable" :data="wo.day_log">

            <!-- 收单人 -->
            <vxe-column field="name" title="成员">
                <template #default="{ row }">
                    <div style="display: flex; align-items: center; justify-content: flex-start;">
                        <el-avatar style="margin-right: 5px;" :size="26" :src="row.avatar"> {{ row.name[0] }} </el-avatar>
                        {{  row.name }}
                    </div>
                </template>
            </vxe-column>

            <!-- 消耗工时/P -->
            <vxe-column field="day_consume" title="消耗工时/P">
                <template #default="{ row }">
                    <span v-if="disabled">{{ row.day_consume }} / P</span>
                    <el-input-number v-else v-model="row.day_consume" size="mini"></el-input-number>
                </template>
            </vxe-column>

        </vxe-table>
    </div>
</template>

<script>
export default {
    // 传递数据
    props: {

        // 工单ID
        wo_id: {
            type: Number,
            require: true
        }

    },

    // 存储数据
    data () {
        return {
            disabled: true,

            wo: {
                user_receive: {},
                user_assist: [],
                day_log: []
            },
        }
    },

    // 侦听器
    watch: {
        wo_id: {
            handler (val) {
                this.DataGet()
            },
            immediate: true,
        }
    },


    // 计算属性
    computed: {
        day_predict () {
            return this.wo.day_predict
        },
        day_consume () {
            return this.wo.day_log.reduce((sum, item) => sum + item.day_consume, 0)
        },
        day_surplus () {
            return this.day_predict - this.day_consume
        }
    },

    // 方法定义
    methods: {
        
        // 获取数据
        DataGet () {
            this.$http.post('/9api/wo/search-first', { id: this.wo_id, append: ['user_receive', 'user_assist'] }).then(rps => {
                this.wo = rps.data
            })
        },

        // 提交表单
        SubmitForm() {
            this.$http.post('/9api/pm/wo/update-day', { id: this.wo.id, day_log: this.wo.day_log }).then(rps => {
                this.$emit('onChange', rps.data)
            })
        }
    }  
}
</script>