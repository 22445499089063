<template>
    <el-drawer :visible.sync="visible" size="80%" direction="rtl" append-to-body destroy-on-close :show-close="false" :before-close="Close">

        <div slot="title">

            <!-- 合同状态 -->
            <div class="w-margin-b-10">
                <span>报废单 - </span>
                <el-tag effect="dark" size="mini" v-if="invalid.state">已调拨</el-tag>
                <el-tag effect="dark" size="mini" v-if="!invalid.state" type="danger">已作废</el-tag>
            </div>
            
            <div class="w-margin-b-10" style="font-size: 18px; color: #000; font-weight: bold;">{{ invalid.number }}</div>

            <!-- 功能按钮 -->
            <div style="display: flex;">
                <el-button size="mini" icon="el-icon-refresh" @click="SearchData()">更新信息</el-button>
                <el-button size="mini" icon="el-icon-delete" @click="DataDelete()" type="danger">作废</el-button>
            </div>

            <!-- 简要面板 -->
            <div class="w-margin-t-10" style="background-color: #f4f5f7; border-radius: 3px; padding: 15px; display: flex;">
                <el-statistic title="报废时间"><template slot="formatter"><span style="font-size: 16px;"><b>{{ invalid.date }}</b></span></template></el-statistic>
                <el-statistic title="负责人"><template slot="formatter"><span style="font-size: 14px;">{{ invalid.created_user.name }}</span></template></el-statistic>
                <el-statistic title="创建时间"><template slot="formatter"><span style="font-size: 14px;">{{ invalid.created_at }}</span></template></el-statistic>
            </div>

            <!-- 备注信息 -->
            <div :style="{ fontSize: '14px', marginTop: '10px' }">
                备注：{{ invalid.describe ?? '未填写' }}
            </div>

        </div>

        <div :style="{ padding: '0 20px 20px 20px' }">
            
            <!-- 报废记录 -->
            <el-divider content-position="left"><b>报废记录</b></el-divider>
            <vxe-table ref="xTable" :data="warehouseLog" :loading="loading" border>
                <!-- 产品名称 -->
                <vxe-column min-width="140" title="产品名称">
                    <template #default="{ row }">
                        <span class="el-span-primary" @click="$refs.productDrawer.Open(row.product.id)">{{ row.product.name }}</span>
                    </template>
                </vxe-column>
                <!-- 供应商 -->
                <vxe-column min-width="140" title="供应商">
                    <template #default="{ row }">
                        <span class="el-span-primary" @click="$refs.supplierDrawer.Open(row.product.supplier.id)">{{ row.product.supplier.name }}</span>
                    </template>
                </vxe-column>
                <!-- 其它信息 -->
                <vxe-column min-width="80" field="product.type.name" title="产品类型"></vxe-column>
                <vxe-column min-width="80" field="product.unit" title="产品单位"></vxe-column>
                <vxe-column min-width="80" field="product.brand" title="产品品牌"></vxe-column>
                <vxe-column min-width="80" field="warehouse.name" title="报废仓库"></vxe-column>
                <vxe-column min-width="80" field="num" title="报废数量"></vxe-column>
            </vxe-table>

        </div>

        <!-- 组件 -->
        <ims-product-drawer ref="productDrawer" @onChange="SearchData()" />
        <ims-supplier-drawer ref="supplierDrawer" @onChange="SearchData()" />

    </el-drawer>
</template>

<script>
export default {
    data() {
        return {
            visible: false,

            tabs: 'orderLog',

            invalid: {
                id: null,
                created_user: {},
                updated_user: {}
            },
            warehouseLog: []
        }
    },

    computed: {
        loading () {
            return !!this.$store.state.httpRequestCount
        }
    },

    methods: {

        // 打开窗口
        Open(id) {
            this.visible = true
            this.SearchData(id)
        },

        // 关闭窗口
        Close(done) {
            this.$emit('onChange')
            done()
        },

        // 检索数据
        SearchData(id = this.invalid.id) {

            // 报废单
            this.$http.post(
                '/9api/ims/product-invalid/search-first',
                { search: { id: id }, append: ['created_user', 'updated_user'] }
            ).then(rps => {
                this.invalid = rps.data
            })

            // 报废记录||出库记录
            this.$http.post(
                '/9api/ims/warehouse-log/search-all',
                { search: { model: 'ims_product_invalid', model_id: id }, append: ['product', 'warehouse'] }
            ).then(rps => {
                this.warehouseLog = rps.data
            })

        },

        /**
        |--------------------------------------------------
        | 删除
        |--------------------------------------------------
        |
        */

        DataDelete() {
            this.$confirm('作废后将无法恢复！', '警告', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'error'
            }).then(() => {
                this.$http.post('/9api/ims/product-invalid/update-delete', { id: this.invalid.id }).then((rps) => {
                    this.$emit('onChange')
                    this.visible = false
                })
            })
        },
        
    }
}

</script>6
