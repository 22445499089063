<template>
    <el-dialog :visible.sync="visible" width="900px" title="新建出库单" append-to-body destroy-on-close @close="Close()" :close-on-click-modal="false" :close-on-press-escape="false">

        <!-- 表单 -->
        <el-form :model="form" size="mini" label-position="top">
            
            <el-divider content-position="left"><b>基本信息</b></el-divider>
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="出库类型" :rules="[{ required: true, message: '必选项' }]" :error="formError.model?.toString()" prop="model">
                        <el-select v-model="form.model" :style="{ width: '100%' }" clearable @change="form.model_id = null; form.warehouse_log = []">
                            <el-option label="其它出库" value="other"></el-option>
                            <el-option label="退货订单" value="ims_product_back"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="出库时间" :rules="[{ required: true, message: '必填项' }]" :error="formError.date?.toString()" prop="date">
                        <el-date-picker v-model="form.date" type="date" value-format="yyyy-MM-dd" :style="{ width: '100%' }"></el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="12" v-if="['ims_product_back'].includes(form.model)">
                    <el-form-item label="关联单据" :rules="[{ required: true, message: '必填项' }]" :error="formError.model_id?.toString()" prop="model_id">
                        <ims-product-back-select @onChange="UpdateWarehoserLog" v-model="form.model_id" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="出库仓库" :rules="[{ required: true, message: '必选项' }]" :error="formError.warehouse_id?.toString()" prop="warehouse_id">
                        <ims-warehouse-select @onChange="UpdateWarehoserLog" v-model="form.warehouse_id" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="出库备注">
                        <el-input type="textarea" :rows="1" v-model="form.describe"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>

            <!-- 出库产品 -->
            <el-divider content-position="left"><b>出库产品</b></el-divider>

            <el-button type="primary" size="mini" @click="$refs.storageSearch.Open({ warehouse_id: form.warehouse_id })" v-if="['other', null].includes(this.form.model)">添加产品</el-button>

            <vxe-table border ref="xTable" :data="form.warehouse_log" :loading="loading" :style="{ marginTop: '10px' }">

                <!-- 产品信息 -->
                <vxe-column min-width="120" field="name" title="产品名称"></vxe-column>
                <vxe-column width="80" field="unit" title="计量单位"></vxe-column>
                
                <!-- 规格参数 -->
                <vxe-column min-width="100" title="规格参数">
                    <template #default="{ row }">
                        {{ row.parameter.toString() }}
                    </template>
                </vxe-column>

                <!-- 动态表单 -->
                <vxe-column width="70" field="num_max" title="库存数量" v-if="['ims_product_back'].includes(form.model)"></vxe-column>

                <!-- 固定表单 -->
                <vxe-column min-width="70" title="出库数量">
                    <template #default="{ row }">
                        <el-input-number size="mini" v-model="row.num" :min="0" :precision="2" :controls="false" :style="{ width: '100%' }"></el-input-number>
                    </template>
                </vxe-column>
                <vxe-column min-width="140" title="备注">
                    <template #default="{ row }">
                        <el-input size="mini" v-model="row.describe" placeholder="请输入备注" clearable></el-input>
                    </template>
                </vxe-column>

            </vxe-table>

        </el-form>

        <div slot="footer">
            <el-button size="small" @click="visible = false">取 消</el-button>
            <el-button size="small" type="primary" @click="FormSubmit()" :loading="loading">确 定</el-button>
        </div>

        <!-- 组件 -->
        <ims-warehouse-storage-search ref="storageSearch" @onChange="UpdateWarehoserLog" />

    </el-dialog>
</template>

<script>
export default {
    data () {
        return {
            visible: false,
            loading: false,

            formError: {},
            form: {
                model: null,
                model_id: null,
                warehouse_id: null,
                date: null,
                action: 1,
                warehouse_log: [],
                describe: null
            }
        }
    },
    
    methods: {

        // 打开窗口
        Open(config) {
            if (config) {
                this.form.model = config.model
                this.form.model_id = config.model_id
            }
            this.visible = true
            this.loading = false
        },

        // 关闭窗口
        Close() {
            this.form.model = null
            this.form.model_id = null
            this.form.warehouse_id = null
            this.form.date = null
            this.form.warehouse_log = []
            this.form.describe = null
        },

        // 更新出库产品
        UpdateWarehoserLog(data) {
            if (!data) {
                return false
            }

            if (['other', null].includes(this.form.model)) {
                this.form.warehouse_log = data.map(rps => {
                    return {
                        product_id: rps.product.id,
                        name: rps.product.name,
                        unit: rps.product.unit,
                        parameter: rps.product.parameter,
                        num: 0.00,
                        describe: rps.product.describe
                    }
                })
            }

            if (['ims_product_back'].includes(this.form.model)) {
                this.$http.post('/9api/ims/product-back-log/search-all', { search: { product_back_id: data.id }, append: ['product'] }).then(rps => {
                    this.$http.post(
                        '/9api/ims/warehouse-storage/search-all',
                        { 
                            search: {
                                warehouse_id: this.form.warehouse_id,
                                product_ids: rps.data.map(idx => idx.product_id)
                            },
                            append: ['product']
                        }
                    ).then(element => {
                        this.form.warehouse_log = element.data.map(pro => {
                            return {
                                product_id: pro.product.id,
                                name: pro.product.name,
                                unit: pro.product.unit,
                                parameter: pro.product.parameter,
                                num_max: pro.num,
                                num: pro.num,
                                describe: pro.product.describe,
                            }
                        })
                    })
                })
            }
        },

        // 提交表单
        FormSubmit() {
            // this.loading = true
            this.$http.post('/9api/ims/warehouse-document/create-back', this.form).then((rps) => {

                // 提交失败
                if (rps.data.state == 'error') {
                    this.formError = rps.data.body
                }

                // 提交成功
                if (rps.data.state == 'success') {
                    this.$emit('onChange', rps.data.body)
                    this.visible = false
                }

                this.loading = false
            })
        },
    }
}
</script>