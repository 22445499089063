<template>
    <div>

        <div :style="{ marginBottom: '10px' }">
            <el-button type="primary" size="mini" @click="UpdateStateBack()">一键出库</el-button>
            <el-button type="danger" size="mini" @click="UpdateStateEnter()">原仓归还</el-button>
        </div>

        <vxe-table ref="xTable" :data="table" :loading="loading" border>
            <!-- 复选框 -->
            <vxe-column type="checkbox" width="60"></vxe-column>
            <!-- 申领状态 -->
            <vxe-column min-width="80" title="申领状态">
                <template #default="{ row }">
                    <el-tag v-if="row.state == 0" size="mini" effect="dark" type="info">待出库</el-tag>
                    <el-tag v-if="row.state == 1" size="mini" effect="dark">已出库</el-tag>
                    <el-tag v-if="row.state == 2" size="mini" effect="dark" type="danger">已归还</el-tag>
                </template>
            </vxe-column>
            <!-- 产品名称 -->
            <vxe-column min-width="140" title="产品名称">
                <template #default="{ row }">
                    <span class="el-span-primary" @click="$refs.productDrawer.Open(row.product.id)">{{ row.product.name }}</span>
                </template>
            </vxe-column>
            <!-- 供应商 -->
            <vxe-column min-width="140" title="供应商">
                <template #default="{ row }">
                    <span class="el-span-primary" @click="$refs.supplierDrawer.Open(row.product.supplier.id)">{{ row.product.supplier.name }}</span>
                </template>
            </vxe-column>
            <!-- 其它信息 -->
            <vxe-column min-width="80" field="product.type.name" title="产品类型"></vxe-column>
            <vxe-column min-width="80" field="product.unit" title="产品单位"></vxe-column>
            <vxe-column min-width="80" field="product.brand" title="产品品牌"></vxe-column>
            <vxe-column min-width="80" field="warehouse.name" title="申领仓库"></vxe-column>
            <vxe-column min-width="80" field="num" title="申领数量"></vxe-column>
        </vxe-table>

        <!-- 组件 -->
        <ims-product-drawer ref="productDrawer" @onChange="TableSearch" />
        <ims-supplier-drawer ref="supplierDrawer" @onChange="TableSearch" />
    </div>
</template>

<script>
export default {
    props: {
        id: {
            type: Number,
            default: null
        },
    },

    data() {
        return {
            table: [],
        }
    },

    computed: {
        loading () {
            return !!this.$store.state.httpRequestCount
        }
    },

    watch: {
        id: {
            handler(val) {
                this.TableSearch()
            },
            immediate: true
        }
    },
    
    methods: {
        TableSearch() {
            this.$http.post(
                '/9api/ims/product-apply-log/search-all',
                { search: { product_apply_id: this.id }, append: ['product', 'warehouse'] }
            ).then(rps => {
                this.table = rps.data
            })
        },

        UpdateStateBack() {
            this.$http.post(
                '/9api/ims/product-apply-log/update-state-back',
                { ids: this.$refs.xTable.getCheckboxRecords().map(rps => rps.id) }
            ).then(rps => {
                this.TableSearch()
            })
        },

        UpdateStateEnter() {
            this.$http.post(
                '/9api/ims/product-apply-log/update-state-enter',
                { ids: this.$refs.xTable.getCheckboxRecords().map(rps => rps.id) }
            ).then(rps => {
                this.TableSearch()
            })
        },
    }
}
</script>