<template>
    <el-dialog :visible.sync="visible" width="600px" append-to-body title="新建员工" :close-on-click-modal="false" :close-on-press-escape="false" :before-close="Close">
            
        <!-- 表单 -->
        <el-form :model="form" size="mini" label-position="top" v-loading="loading">
            
            <!-- 基础信息 -->
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="姓名" :rules="[{ required: true, message: '必填项' }]" :error="formError.name?.toString()" prop="name">
                        <el-input v-model="form.name" placeholder="请填写员工姓名" clearable />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="手机号码" :rules="[{ required: true, message: '必填项' }]" :error="formError.phone?.toString()" prop="phone">
                        <el-input v-model="form.phone" placeholder="请填写员工手机号码" prefix-icon="el-icon-mobile" clearable />
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="性别" :rules="[{ required: true, message: '必选项' }]" :error="formError.sex?.toString()" prop="sex">
                        <el-select v-model="form.sex" :style="{ width: '100%' }">
                            <el-option :value="0" label="未知"></el-option>
                            <el-option :value="1" label="男"></el-option>
                            <el-option :value="2" label="女"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="凭证密码" :rules="[{ required: true, message: '必填项' }]" :error="formError.password?.toString()" prop="password">
                        <el-input type="password" v-model="form.password" placeholder="密码 A-Z/a-z/0-9/特殊字符" error />
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="工号" :rules="[{ required: true, message: '必填项' }]" :error="formError.number?.toString()" prop="number">
                        <el-input v-model="form.number" placeholder="请填写员工工号" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="所属组织" :rules="[{ required: true, message: '必选项' }]" :error="formError.organize_id?.toString()" prop="organize_id">
                        <hrm-organize-cascader v-model="form.organize_id" />
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="岗位">
                        <el-input v-model="form.appointment" placeholder="请填写用户岗位" clearable />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="角色权限" :rules="[{ required: true, message: '必选项' }]" :error="formError.role_id?.toString()" prop="role_id">
                        <config-role-select v-model="form.role_id" />
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="入职时间" :rules="[{ required: true, message: '必填项' }]" :error="formError.created_at?.toString()" prop="created_at">
                        <el-date-picker v-model="form.created_at" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="请选择用户入职时间" :style="{ width: '100%' }"></el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="聘用形式" :rules="[{ required: true, message: '必选项' }]" :error="formError.type?.toString()" prop="type">
                        <el-select v-model="form.type" :style="{ width: '100%' }">
                            <el-option value="试用" label="试用"></el-option>
                            <el-option value="全职" label="全职"></el-option>
                            <el-option value="实习" label="实习"></el-option>
                            <el-option value="兼职" label="兼职"></el-option>
                            <el-option value="劳务" label="劳务"></el-option>
                            <el-option value="顾问" label="顾问"></el-option>
                            <el-option value="返聘" label="返聘"></el-option>
                            <el-option value="外包" label="外包"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row :gutter="20">
                
            </el-row>

            <!-- 其它配置 -->
            <br><el-divider content-position="left">企业微信</el-divider>
            <el-checkbox v-model="form.wecom">主动绑定</el-checkbox><p></p>
            <el-checkbox v-model="form.wecom_sync">主动同步</el-checkbox><p></p>
            
        </el-form>

        <span slot="footer">
            <el-button size="mini" @click="Close()">取 消</el-button>
            <el-button size="mini" type="primary" @click="SubmitForm()" :loading="loading">确 定</el-button>
        </span>
        
    </el-dialog>
</template>

<script>
export default {
    data () {
        return {
            loading: false,
            visible: false,

            form: {
                organize_id: null,
                name: null,
                phone: null,
                role_id: [],
                country_id: null,
                number: null,
                email: null,
                password: null,
                type: null,
                status: 0,
                sex: 0,
                address: null,
                wecom: true,
                wecom_sync: true,
            },
            
            formError: { },
        }
    },
    methods: {
        
        /**
        |--------------------------------------------------
        | 窗口 - 打开
        |--------------------------------------------------
        |
        */

        async Open(config) {
            if (config) {
                this.form.organize_id = config.organize_id
            }
            this.visible = true
        },

        // 表单 - 提交
        async SubmitForm () {
            // 开启加载状态
            this.loading = true
            this.formError = {}

            // 提交表单
            this.$http.post('/9api/hrm/employee/create', this.form).then(rps => {
                // 关闭加载状态
                this.loading = false

                // 提交失败
                if (rps.data.state == 'error') {
                    this.formError = rps.data.body
                }
                
                // 提交成功
                if (rps.data.state == 'success') {
                    this.$emit('onChange', rps.data.body)
                    this.Close()
                }
            })
        },

        /**
        |--------------------------------------------------
        | 窗口 - 关闭
        |--------------------------------------------------
        |
        */

        Close() {
            this.form.organize_id = null
            this.form.name = null
            this.form.phone = null
            this.form.role_id = []
            this.form.country_id = null
            this.form.number = null
            this.form.email = null
            this.form.password = null
            this.form.type = null
            this.form.status = 0
            this.form.sex = 0
            this.form.address = null
            this.form.wecom = true
            this.form.wecom_sync = true

            this.loading = false
            this.visible = false
        },

    }
}
</script>