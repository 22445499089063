<template>
    <el-dialog :visible.sync="visible" width="800px" title="新的工作任务" append-to-body destroy-on-close class="w-el-dialog" @close="close()">

        <!-- 表单 -->
        <el-form :model="form" label-width="120px" size="mini" label-position="top">

            <!-- 任务名称 / 截止日期 -->
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="任务名称" :rules="[{ required: true, message: '必填项' }]" :error="formError.name?.toString()" prop="name">
                        <el-autocomplete clearable v-model="form.name" :style="{ width: '100%' }" :fetch-suggestions="(queryString, cb) => cb([
                            { 'value': '预算申报' },
                            { 'value': '编写方案' },
                            { 'value': '清单' },
                            { 'value': '需求书' },
                            { 'value': '投标方案' },
                            { 'value': '配合项目实施' }
                        ])"></el-autocomplete>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="起止日期" :rules="[{ required: true, message: '必选项' }]" :error="formError.datetime?.toString()" prop="datetime">
                        <el-date-picker v-model="form.datetime" type="daterange" value-format="yyyy-MM-dd" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
                    </el-form-item>
                </el-col>
            </el-row>

            <!-- 指派给 / 协助人 -->
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="指派给" :rules="[{ required: true, message: '必选项' }]" :error="formError.user_assign_id?.toString()" prop="user_assign_id">
                        <users-select v-model="form.user_assign_id" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="协助人">
                        <users-select v-model="form.user_helper_id" multiple />
                    </el-form-item>
                </el-col>
            </el-row>

            <!-- 负责人 -->
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="负责人" :rules="[{ required: true, message: '必选项' }]" :error="formError.user_admin_id?.toString()" prop="user_admin_id">
                        <users-select v-model="form.user_admin_id" />
                    </el-form-item>
                </el-col>
            </el-row>


            <!-- 任务附件 -->
            <el-row :gutter="20">
                <el-col :span="24">
                    <el-form-item label="附件">
                        <files-upload v-model="form.files_id" model="crm_business_progress" stage="任务创建" />
                    </el-form-item>
                </el-col>
            </el-row>

        </el-form>

        <div slot="footer">
            <el-button size="small" @click="visible = false">取 消</el-button>
            <el-button size="small" type="primary" @click="formSubmit()">确 定</el-button>
        </div>

    </el-dialog>
</template>

<script>
export default {
    data () {
        return {
            visible: false,
            loading: false,

            formError: {},
            form: {
                business_id: null,
                name: null,
                datetime: null,
                state: false,
                user_assign_id: null,
                user_helper_id: null,
                user_admin_id: this.$store.state.user.id,
                consume_days: 0,
                files_id: []
            }
        }
    },

    methods: {

        // 打开窗口
        open(form) {
            // 初始表单
            if (form.business_id) { this.form.business_id = form.business_id }
            
            // 初始窗口
            this.visible = true
            this.loading = false
        },

        // 关闭窗口
        close() {
            this.form.business_id = null
            this.form.name = null
            this.form.datetime = null
            this.form.state = 0
            this.form.user_assign_id = null
            this.form.user_helper_id = null
            this.form.user_admin_id = this.$store.state.user.id
            this.form.consume_days = 0
            this.form.files_id = []
        },

        // 提交表单
        formSubmit() {
            this.loading = true

            this.$http.post('/9api/crm/business-task/create', this.form).then(rps => {
                
                // 提交失败
                if (rps.data.state == 'error') {
                    this.formError = rps.data.body
                }

                // 提交成功
                if (rps.data.state == 'success') {
                    this.$emit('onChange', rps.data.body)
                    this.visible = false
                }

                this.loading = false
            })
        },
    }
}
</script>