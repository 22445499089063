<template>
    <div>
        <!-- 库存信息 -->
        <el-divider content-position="left"><b>库存信息</b></el-divider>
        <el-button type="primary" size="mini" @click="$refs.warehouseDocumentCreateBack.Open({ model: 'ims_product_back', model_id: id })" >新建出库记录</el-button>
        <vxe-table border ref="xTable" :data="warehouseStorage" :loading="loading" :style="{ marginTop: '10px' }">
            <vxe-column min-width="120" field="product.name" title="产品名称"></vxe-column>
            <vxe-column min-width="80" field="product.unit" title="计量单位"></vxe-column>
            <vxe-column min-width="100" title="规格参数">
                <template #default="{ row }">
                    {{ row.product.parameter?.toString() }}
                </template>
            </vxe-column>
            <vxe-column min-width="80" field="warehouse.name" title="仓库名称"></vxe-column>
            <vxe-column min-width="80" field="num" title="库存数量"></vxe-column>
        </vxe-table>

        <!-- 出库记录 -->
        <el-divider content-position="left"><b>出库记录</b></el-divider>
        <el-empty v-if="warehouseEnter.filter(rps => rps.state == 1) == 0" description="暂无记录"></el-empty>
        <div v-for="(item, idx) in warehouseEnter.filter(rps => rps.state == 1)" :key="'enter-' + idx" :style="{ marginBottom: '20px' }">
            <div :style="{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '14px' }">
                <div>
                    <span :style="{ marginRight: '10px' }">出库单号：<span class="el-span-primary">{{ item.number }}</span></span>
                    <span :style="{ marginRight: '10px' }">出库时间：{{ item.created_at }}</span>
                    <span :style="{ marginRight: '10px' }">负责人：{{ item.created_user.name }}</span>
                </div>
                <el-button type="danger" size="mini" @click="DeleteEnter(item.id)" >作废</el-button>
            </div>
            <vxe-table border ref="xTable" :data="item.warehouse_log" :loading="loading" :style="{ marginTop: '10px' }">
                <vxe-column min-width="120" field="product.name" title="产品名称"></vxe-column>
                <vxe-column min-width="80" field="product.unit" title="计量单位"></vxe-column>
                <vxe-column min-width="100" title="规格参数">
                    <template #default="{ row }">
                        {{ row.product.parameter?.toString() }}
                    </template>
                </vxe-column>
                <vxe-column min-width="80" field="num" title="本次出库"></vxe-column>
                <vxe-column min-width="80" field="warehouse.name" title="出库仓库"></vxe-column>
                <vxe-column min-width="90" field="describe" title="备注"></vxe-column>
            </vxe-table>
            <div :style="{ fontSize: '14px', marginTop: '5px' }" v-if="item.describe">备注：{{ item.describe }}</div>
        </div>

        <!-- 作废记录 -->
        <el-divider content-position="left"><b>作废记录</b></el-divider>
        <el-empty v-if="warehouseEnter.filter(rps => rps.state == 0).length == 0" description="暂无记录"></el-empty>
        <div v-for="(item, idx) in warehouseEnter.filter(rps => rps.state == 0)" :key="'delete-' + idx" :style="{ marginBottom: '20px' }">
            <div :style="{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '14px' }">
                <div>
                    <span :style="{ marginRight: '10px' }">出库单号：<span class="el-span-primary">{{ item.number }}</span></span>
                    <span :style="{ marginRight: '10px' }">出库时间：{{ item.created_at }}</span>
                    <span :style="{ marginRight: '10px' }">负责人：{{ item.created_user.name }}</span>
                </div>
            </div>
            <vxe-table border ref="xTable" :data="item.warehouse_log" :loading="loading" :style="{ marginTop: '10px' }">
                <vxe-column min-width="120" field="product.name" title="产品名称"></vxe-column>
                <vxe-column min-width="80" field="product.unit" title="计量单位"></vxe-column>
                <vxe-column min-width="100" title="规格参数">
                    <template #default="{ row }">
                        {{ row.product.parameter?.toString() }}
                    </template>
                </vxe-column>
                <vxe-column min-width="80" field="num" title="本次出库"></vxe-column>
                <vxe-column min-width="80" field="warehouse.name" title="出库仓库"></vxe-column>
                <vxe-column min-width="90" field="describe" title="备注"></vxe-column>
            </vxe-table>
            <div :style="{ fontSize: '14px', marginTop: '5px' }" v-if="item.describe">备注：{{ item.describe }}</div>
        </div>

        <!-- 组件 -->
        <ims-warehouse-document-create-back ref="warehouseDocumentCreateBack" @onChange="TableSearch" />
    </div>
</template>

<script>
export default {
    props: {
        id: {
            type: Number,
            default: null
        },
    },

    data() {
        return {
            loading: false,

            warehouseStorage: [],
            warehouseEnter: []
        }
    },

    watch: {
        id: {
            handler(val) {
                this.TableSearch()
            },
            immediate: true
        }
    },
    
    methods: {

        // 订单记录
        TableSearch() {
            this.$http.post( '/9api/ims/product-back-log/search-all', { search: { product_back_id: this.id } }).then(rps => {
                this.$http.post('/9api/ims/warehouse-storage/search-all', { search: { product_id: rps.data.map(rps => rps.product_id) }, append: ['product', 'warehouse'] }).then(element => {
                    this.warehouseStorage = element.data
                    this.loading = false
                })
            })
           
            this.$http.post('/9api/ims/warehouse-document/search-all', { search: { model_id: this.id, action: 0 }, append: ['warehouse_log', 'created_user'] }).then(rps => {
                this.warehouseEnter = rps.data
                this.loading = false
            })
        },

        // 作废出库单
        DeleteEnter(id) {
            this.$confirm('确认需要作废此出库单吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'error'
            }).then(() => {
                this.$http.post('/9api/ims/warehouse-document/update-delete', { id: id }).then(rps => {
                    this.TableSearch()
                })
            })

            
        }
    }
}
</script>