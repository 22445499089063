<template>
    <el-cascader
        ref="cascader"
        @change="SelectChange"
        v-model="selectValue"
        :options="select"
        :props="{ label: 'name', value: 'id', emitPath: false, checkStrictly: true }"
        :style="{ width: width, margin: margin }"
        placeholder="请选择任务类型"
        size="mini"
        clearable
        filterable>
    </el-cascader>
</template>

<script>
export default {
    props: {
        value: {
            type: [String, Array, Number],
            default: null
        },

        width: {
            type: String,
            default: '100%'
        },

        margin: {
            type: String,
            default: '0px'
        }
    },

    data () {
        return {
            select: [],
            selectValue: null,
        }
    },

    watch: {
        value () {
            this.selectValue = this.value
        }
    },

    mounted () {
        this.$http.post('/9api/ims/product-type/search-tree').then(rps => {
            this.select = rps.data.tree
            this.selectValue = this.value
        })
    },

    methods: {
        SelectChange(data) {
            this.$emit('input', data)
            this.$emit('onChange', this.$refs.cascader.getCheckedNodes()[0].data)
        }
    }
}
</script>