<!---------------------------------------------------------------------------- 
 | 销售 - 跟单管理 - 更新
 |----------------------------------------------------------------------------
 | 方法：请注意大小写,参数描述见具体方法
 | $refs[ref_name].Open(*id) 手动打开窗口,
 | $refs[ref_name].Close() 手动关闭窗口
 |----------------------------------------------------------------------------
 | 回调
 | @success = fn (Object) => {}
 |
 ---------------------------------------------------------------------------->

 <template>
    <el-drawer class="w-el-drawer" :visible.sync="visible" :size="960" :with-header="false" direction="rtl" :before-close="Close" append-to-body destroy-on-close>
        
        <!-- header -->
        <div class="w-el-drawer-header">
            <i class="el-icon-tickets"></i> 修改/跟进商机
        </div>

        <!-- body -->
        <div class="w-el-drawer-body"  v-loading="loading">
           
            <!-- 表单 -->
            <el-form :model="form" label-width="120px" size="mini">
                
                <!-- 跟单主题 -->
                <el-form-item label="商机主题" :rules="[{ required: true, message: '必填项' }]" :error="formError.name?.toString()" prop="name">
                    <el-input v-model="form.name" placeholder="客户名称+项目名称" clearable />
                </el-form-item>

                <!-- 关联客户 -->
                <el-form-item :rules="[{ required: true, message: '必填项' }]" label="关联客户" :error="formError.customer_id?.toString()" prop="customer_id">
                    <crm-customer-select v-model="form.customer_id" clearable />
                </el-form-item>

                <!-- 项目来源 & 工作进度 -->
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="项目来源" :rules="[{ required: true, message: '请选择项目来源,最多填入16个字符' }]" :error="formError.source_id?.toString()" prop="source_id">
                            <inputSource v-model="form.source_id"></inputSource>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-divider content-position="left">参与成员</el-divider>

                <!-- 商务 & 销售 -->
                <el-row>
                    <el-col :span="12">
                        <el-form-item :rules="[{ required: true, message: '必选项' }]" label="商务" :error="formError.user_affair_id?.toString()" prop="user_affair_id">
                            <users-select v-model="form.user_affair_id" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item :rules="[{ required: true, message: '必选项' }]" label="销售" :error="formError.user_sale_id?.toString()" prop="user_sale_id">
                            <users-select v-model="form.user_sale_id" />
                        </el-form-item>
                    </el-col>
                </el-row>

                <!-- 售前 & 管理员 -->
                <el-row>
                    <el-col :span="12">
                        <el-form-item :rules="[{ required: true, message: '必选项' }]" label="售前" :error="formError.user_presale_id?.toString()" prop="user_presale_id">
                            <users-select v-model="form.user_presale_id" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item :rules="[{ required: true, message: '必选项' }]" label="管理员" :error="formError.user_admin_id?.toString()" prop="user_admin_id">
                            <users-select v-model="form.user_admin_id" disabled />
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-divider content-position="left">成本预估</el-divider>

                <el-row>
                    <el-col :span="12">
                        <el-form-item :rules="[{ required: true, message: '必填项' }]" label="预估工时" :error="formError.estimate_work_day?.toString()" prop="estimate_work_day">
                            <el-input-number v-model="form.estimate_work_day" :min="0" :precision="2" class="w-width-auto"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item :rules="[{ required: true, message: '必填项' }]" label="预估成本" :error="formError.estimate_money?.toString()" prop="estimate_money">
                            <el-input-number v-model="form.estimate_money" :min="0" :precision="2" class="w-width-auto"></el-input-number>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-divider content-position="left">项目预期金额</el-divider>

                <el-row>
                    <el-col :span="12">
                        <el-form-item :rules="[{ required: true, message: '必填项' }]" label="预期总金额" :error="formError.sign_money?.toString()" prop="sign_money">
                            <el-input-number v-model="form.sign_money" :min="0" :precision="2" disabled class="w-width-auto"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item :rules="[{ required: true, message: '必填项' }]" label="开发金额" :error="formError.sign_money_kf?.toString()" prop="sign_money_kf">
                            <el-input-number v-model="form.sign_money_kf" :min="0" :precision="2" class="w-width-auto"></el-input-number>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="12">
                        <el-form-item :rules="[{ required: true, message: '必填项' }]" label="集成金额" :error="formError.sign_money_jc?.toString()" prop="sign_money_jc">
                            <el-input-number v-model="form.sign_money_jc" :min="0" :precision="2" class="w-width-auto"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item :rules="[{ required: true, message: '必填项' }]" label="运维金额" :error="formError.sign_money_yw?.toString()" prop="sign_money_yw">
                            <el-input-number v-model="form.sign_money_yw" :min="0" :precision="2" class="w-width-auto"></el-input-number>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="12">
                        <el-form-item :rules="[{ required: true, message: '必填项' }]" label="安服金额" :error="formError.sign_money_af?.toString()" prop="sign_money_af">
                            <el-input-number v-model="form.sign_money_af" :min="0" :precision="2" class="w-width-auto"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item :rules="[{ required: true, message: '必填项' }]" label="工程金额" :error="formError.sign_money_gc?.toString()" prop="sign_money_gc">
                            <el-input-number v-model="form.sign_money_gc" :min="0" :precision="2" class="w-width-auto"></el-input-number>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="12">
                        <el-form-item :rules="[{ required: true, message: '必填项' }]" label="设备金额" :error="formError.sign_money_sb?.toString()" prop="sign_money_sb">
                            <el-input-number v-model="form.sign_money_sb" :min="0" :precision="2" class="w-width-auto"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item :rules="[{ required: true, message: '必填项' }]" label="过单金额" :error="formError.sign_money_gd?.toString()" prop="sign_money_gd">
                            <el-input-number v-model="form.sign_money_gd" :min="0" :precision="2" class="w-width-auto"></el-input-number>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="12">
                        <el-form-item :rules="[{ required: true, message: '必填项' }]" label="其它金额" :error="formError.sign_money_qt?.toString()" prop="sign_money_qt">
                            <el-input-number v-model="form.sign_money_qt" :min="0" :precision="2" class="w-width-auto"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item :rules="[{ required: true, message: '必填项' }]" label="外采金额" :error="formError.sign_money_sw?.toString()" prop="sign_money_sw">
                            <el-input-number v-model="form.sign_money_sw" :min="0" :precision="2" class="w-width-auto"></el-input-number>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-divider content-position="left">其它信息</el-divider>

                <!-- 备注 -->
                <el-form-item label="备注">
                    <froala-editor v-model="form.describe" />
                </el-form-item>

                <el-form-item label="抄送对象">
                    <users-select v-model="form.notify_users_id" multiple />
                </el-form-item>

                <el-form-item label="附件">
                    <files-upload v-model="form.files_id" model="saleDocumentary" stage="跟进跟单" />
                </el-form-item>
            </el-form>
        </div>

        <!-- footer -->
        <div class="w-el-drawer-footer">
            <el-button size="medium" @click="Close()">取消 / 返回</el-button>
            <el-button size="medium" type="primary" @click="FormSubmit()" :loading="loading">确 定</el-button>
        </div>

    </el-drawer>
</template>
    
<script>
import inputSource from "@/views/config/contractSource/input"

export default {
    components: {
        inputSource,
    },
    
    data() {
        return {
            visible: false,
            loading: false,

            formError: {},
            form: {
                id: null,
                name: null,
                customer_id: null,
                source_id: null,
                progress_id: null,
                user_affair_id: null,
                user_presale_id: null,
                user_sale_id: null,
                user_admin_id: null,
                estimate_work_day: 0,
                estimate_money: 0,
                sign_money: 0,
                sign_money_jc: 0,
                sign_money_yw: 0,
                sign_money_af: 0,
                sign_money_gc: 0,
                sign_money_sb: 0,
                sign_money_kf: 0,
                sign_money_gd: 0,
                sign_money_qt: 0,
                sign_money_sw: 0,
                describe: null,
                notify_users_id: null,
                files_id: [],
            }
        }
    },

    watch: {
        'form.sign_money_jc' () { this.form.sign_money = this.form.sign_money_jc + this.form.sign_money_yw + this.form.sign_money_af + this.form.sign_money_gc + this.form.sign_money_sb + this.form.sign_money_kf + this.form.sign_money_gd + this.form.sign_money_qt },
        'form.sign_money_yw' () { this.form.sign_money = this.form.sign_money_jc + this.form.sign_money_yw + this.form.sign_money_af + this.form.sign_money_gc + this.form.sign_money_sb + this.form.sign_money_kf + this.form.sign_money_gd + this.form.sign_money_qt },
        'form.sign_money_af' () { this.form.sign_money = this.form.sign_money_jc + this.form.sign_money_yw + this.form.sign_money_af + this.form.sign_money_gc + this.form.sign_money_sb + this.form.sign_money_kf + this.form.sign_money_gd + this.form.sign_money_qt },
        'form.sign_money_gc' () { this.form.sign_money = this.form.sign_money_jc + this.form.sign_money_yw + this.form.sign_money_af + this.form.sign_money_gc + this.form.sign_money_sb + this.form.sign_money_kf + this.form.sign_money_gd + this.form.sign_money_qt },
        'form.sign_money_sb' () { this.form.sign_money = this.form.sign_money_jc + this.form.sign_money_yw + this.form.sign_money_af + this.form.sign_money_gc + this.form.sign_money_sb + this.form.sign_money_kf + this.form.sign_money_gd + this.form.sign_money_qt },
        'form.sign_money_kf' () { this.form.sign_money = this.form.sign_money_jc + this.form.sign_money_yw + this.form.sign_money_af + this.form.sign_money_gc + this.form.sign_money_sb + this.form.sign_money_kf + this.form.sign_money_gd + this.form.sign_money_qt },
        'form.sign_money_gd' () { this.form.sign_money = this.form.sign_money_jc + this.form.sign_money_yw + this.form.sign_money_af + this.form.sign_money_gc + this.form.sign_money_sb + this.form.sign_money_kf + this.form.sign_money_gd + this.form.sign_money_qt },
        'form.sign_money_qt' () { this.form.sign_money = this.form.sign_money_jc + this.form.sign_money_yw + this.form.sign_money_af + this.form.sign_money_gc + this.form.sign_money_sb + this.form.sign_money_kf + this.form.sign_money_gd + this.form.sign_money_qt },
    },
    
    methods: {
        /**
        |--------------------------------------------------
        | 窗口 - 打开
        |--------------------------------------------------
        |
        */

        Open(id) {
            // 窗口配置
            this.visible = true
            this.loading = true

            this.$http.post('/9api/crm/business/search-first', { id: id }).then(rps => {
                this.form.id = rps.data.id
                this.form.name = rps.data.name
                this.form.customer_id = rps.data.customer_id
                this.form.source_id = rps.data.source_id
                this.form.progress_id = rps.data.progress_id
                this.form.user_affair_id = rps.data.user_affair_id
                this.form.user_presale_id = rps.data.user_presale_id
                this.form.user_sale_id = rps.data.user_sale_id
                this.form.user_admin_id = rps.data.user_admin_id
                this.form.estimate_work_day = rps.data.estimate_work_day
                this.form.estimate_money = rps.data.estimate_money
                this.form.sign_money = rps.data.sign_money
                this.form.sign_money_jc = rps.data.sign_money_jc
                this.form.sign_money_yw = rps.data.sign_money_yw
                this.form.sign_money_af = rps.data.sign_money_af
                this.form.sign_money_gc = rps.data.sign_money_gc
                this.form.sign_money_sb = rps.data.sign_money_sb
                this.form.sign_money_kf = rps.data.sign_money_kf
                this.form.sign_money_gd = rps.data.sign_money_gd
                this.form.sign_money_qt = rps.data.sign_money_qt
                this.form.sign_money_sw = rps.data.sign_money_sw
                this.form.describe = rps.data.describe
                this.form.notify_users_id = rps.data.notify_users_id
                this.form.files_id = rps.data.files_id

                this.loading = false
            })
        },

        
        /**
        |--------------------------------------------------
        | 窗口 - 关闭
        |--------------------------------------------------
        |
        */

        Close() {
            this.visible = false
            this.loading = false
        },
        
        /**
        |--------------------------------------------------
        | 表单提交
        |--------------------------------------------------
        |
        */

        FormSubmit() {
            // 清空错误提示
            this.loading = true
            this.formError = {}

            this.$http.post('/9api/sale-documentary/update', this.form).then(rps => {
                
                this.loading = false

                if (rps.data.state == 'error') {
                    this.formError = rps.data.body
                }

                if (rps.data.state == 'success') {
                    this.$notify.success({title: '提示', message: '更新成功', duration: 2000})
                    this.$emit('success', rps.data.body)
                    this.Close()
                }
            })
        }
    }
}
</script>