<template>
    <el-drawer :visible.sync="visible" size="80%" direction="rtl" append-to-body destroy-on-close :show-close="false" :before-close="Close">
        
        <!-- Title -->
        <div slot="title">

            <!-- 考核信息 -->
            <div :style="{ background: 'linear-gradient(to top, #0027ff, #7e7eff', padding: '20px', borderRadius: '4px' }">
                <div :style="{ display: 'grid', gridTemplateColumns: '100px 1fr 1fr 1fr 1fr', gap: '20px' }">
                    <div :style="{ display: 'flex', flexDirection: 'column', alignItems: 'center', gridRowStart: '1', gridRowEnd: '3' }">
                        <el-avatar style="border: 2px solid white;" :size="56" :src="dossier.users?.avatar">{{ dossier.users?.name[0] }}</el-avatar>
                        <span :style="{ fontSize: '14px', fontWeight: 'bold', color: '#FFFFFF', margin: '5px 0px' }">{{  dossier.users?.name }}</span>
                        <el-tag effect="dark" v-if="dossier.state == 0" type="warning">未开始</el-tag>
                        <el-tag effect="dark" v-if="dossier.state == 1">进行中</el-tag>
                        <el-tag effect="dark" v-if="dossier.state == 2">评审完成</el-tag>
                        <el-tag effect="dark" v-if="dossier.state == 3">已归档</el-tag>
                        <el-tag effect="dark" v-if="dossier.state == 4" type="danger">有争议</el-tag>
                    </div>
                    <div :style="{ display: 'flex', flexDirection: 'column' }">
                        <span :style="{ color: '#FFFFFF', fontSize: '12px', marginBottom: '5px'}">考核计划</span>
                        <span :style="{ color: '#FFFFFF', fontSize: '16px', fontWeight: 'bold' }">{{ dossier.plan.name }}</span>
                    </div>
                    <div :style="{ display: 'flex', flexDirection: 'column' }">
                        <span :style="{ color: '#FFFFFF', fontSize: '12px', marginBottom: '5px'}">考核周期</span>
                        <span :style="{ color: '#FFFFFF', fontSize: '16px', fontWeight: 'bold' }">{{ dossier.plan.date?.join(' 至 ') }}</span>
                    </div>
                    <div :style="{ display: 'flex', flexDirection: 'column' }">
                        <span :style="{ color: '#FFFFFF', fontSize: '12px', marginBottom: '5px'}">最终评分</span>
                        <span :style="{ color: '#FFFFFF', fontSize: '16px', fontWeight: 'bold' }">{{ dossier.result_score ?? '-' }}</span>
                    </div>
                    <div :style="{ display: 'flex', flexDirection: 'column' }">
                        <span :style="{ color: '#FFFFFF', fontSize: '12px', marginBottom: '5px'}">最终评级</span>
                        <span :style="{ color: '#FFFFFF', fontSize: '16px', fontWeight: 'bold' }">{{ dossier.result_level ?? '-' }}</span>
                    </div>
                    <div :style="{ display: 'flex', flexDirection: 'column' }">
                        <span :style="{ color: '#FFFFFF', fontSize: '12px', marginBottom: '5px'}">岗位</span>
                        <span :style="{ color: '#FFFFFF', fontSize: '16px', fontWeight: 'bold' }">{{ dossier.users.appointment ?? '-' }}</span>
                    </div>
                    <div :style="{ display: 'flex', flexDirection: 'column' }">
                        <span :style="{ color: '#FFFFFF', fontSize: '12px', marginBottom: '5px'}">部门/组织</span>
                        <span :style="{ color: '#FFFFFF', fontSize: '16px', fontWeight: 'bold' }">{{ dossier.users.organize?.name }}</span>
                    </div>
                    <div :style="{ display: 'flex', flexDirection: 'column' }">
                        <span :style="{ color: '#FFFFFF', fontSize: '12px', marginBottom: '5px'}">在职状态</span>
                        <span :style="{ color: '#FFFFFF', fontSize: '16px', fontWeight: 'bold' }">{{ dossier.users.status ? '离职' : '在职' }}</span>
                    </div>
                    <div :style="{ display: 'flex', flexDirection: 'column' }">
                        <span :style="{ color: '#FFFFFF', fontSize: '12px', marginBottom: '5px'}">更新时间</span>
                        <span :style="{ color: '#FFFFFF', fontSize: '16px', fontWeight: 'bold' }">{{ dossier.created_at }}</span>
                    </div>
                </div>
            </div>

            <!-- 考核官 -->
            <uw-statistic :column="dossier.flow_all_users_id.length" gutter="10px" :style="{ marginTop: '10px' }">
                <uw-statistic-item
                    v-for="(e, i) in dossier.log"
                    :key="i"
                    :title="`${e.users?.name} 权重: ${e.t_flow.weight}%`"
                    :value="e.result_score ?? '-'" :unit="e.result_level ?? '-'"
                    :style="{ borderColor: i == logKey ? '#0027ff' : '' }"
                    @onClick="CatLog(i, false)">
                    <div :style="{ display: 'flex', flexDirection: 'column', alignItems: 'center' }">
                        <el-avatar :style="{ border: '2px solid white' }" :size="36" :src="e.users?.avatar">{{ e.users?.name[0] }}</el-avatar>
                        <div :style="{ marginTop: '5px' }">
                            <el-link :underline="false" type="primary" v-if="e.state == 2">已归档</el-link>
                            <el-link :underline="false" type="danger"  v-if="e.state == 3">有争议</el-link>
                            <template v-if="e.users.id != $store.state.user.id">
                                <el-link :underline="false" type="warning" v-if="e.state == 0">待评审</el-link>
                                <el-link :underline="false" type="primary" v-if="e.state == 1">已评审</el-link>
                            </template>
                            <template v-if="e.users.id == $store.state.user.id">
                                <el-link :underline="false" type="primary" v-if="e.state == 0" @click.stop="CatLog(i, true)">去评审</el-link>
                                <el-link :underline="false" type="primary" v-if="e.state == 1" @click.stop="CatLog(i, true)">去修改</el-link>
                            </template>
                            <template v-if="e.t_flow.reject_switch && dossier.users_id == $store.state.user.id">
                                <el-link :underline="false" type="danger" :style="{ marginLeft: '10px' }" v-if="e.state == 1" @click="UpdateReject(e.id, 'submit')">驳回</el-link>
                                <el-link :underline="false" type="danger" :style="{ marginLeft: '10px' }" v-if="e.state == 3" @click="UpdateReject(e.id, 'cancel')">撤回</el-link>
                            </template>
                        </div>
                    </div>
                </uw-statistic-item>
            </uw-statistic>

        </div>

        
        <div class="w-padding-l-20 w-padding-r-20 w-padding-b-20">
            <div :style="{ marginBottom: '10px',textAlign: 'right' }" v-if="logEdit">
                <el-button type="primary" @click="Update()">提交</el-button>
            </div>

            <!-- 争议项 -->
            <div :style="{ marginBottom: '10px' }" v-if="dossier.log[logKey]?.state == 3">
                <el-alert
                    type="error"
                    :closable="false"
                    :title="`争议项：${dossier.log[logKey]?.t_flow.reject[0].date}`"
                    :description="`${dossier.log[logKey]?.t_flow.reject[0].message}`">
                </el-alert>
            </div>

            <!-- 考核结果 -->
            <div v-for="(element, idx) in dossier.log[logKey]?.t_class" :key="idx" :style="{ marginBottom: '20px' }">
                <vxe-table :data="element.children">
                    <vxe-colgroup>

                        <!-- 维度信息 -->
                        <template #header>
                            <div :style="{ display: 'flex' }">
                                <hrm-kpi-template-class-type v-model="element.type" />
                                <el-link :style="{ marginLeft: '10px' }" :underline="false" type="primary">{{ element.name }}</el-link>
                                <el-link :style="{ marginLeft: '10px' }" :underline="false">维度权重：</el-link>
                                <el-link :style="{ marginLeft: '10px' }" :underline="false" type="warning">{{ element.weight }} %</el-link>
                            </div>
                            <div>{{ element.describe }}</div>
                        </template>

                        <!-- 维度配置 -->
                        <vxe-column min-width="80" title="指标项名称" >
                            <template #default="{ row }">
                                <span v-if="element.type == 'custom'">{{ row.name }}</span>
                                <hrm-kpi-goals-type v-model="row.goals" v-if="element.type == 'goals'" />
                            </template>
                        </vxe-column>

                        <!-- 指标信息 -->
                        <vxe-column min-width="200" title="指标说明" :show-overflow="false" field="describe"></vxe-column>
                        <vxe-column min-width="200" title="考核标准/目标" :show-overflow="false" field="target"></vxe-column>

                        <!-- 指标项权重 % -->
                        <vxe-column width="120" title="指标项权重 %" :resizable="false" field="weight">
                            <template #default="{ row }">
                                {{ row.weight || '按需评估' }}
                            </template>
                        </vxe-column>

                        <!-- 分值占比 -->
                        <vxe-column width="80" title="分值占比" :resizable="false"  >
                            <template #default="{ row }">
                                {{ (element.weight * row.weight * 0.01) || '自定义' }}
                            </template>
                        </vxe-column>

                        <!-- 考核评价 -->
                        <vxe-column
                            min-width="200"
                            title="考核评价"
                            :show-overflow="false"
                            :visible="dossier.log[logKey]?.t_flow.appraise_switch && (dossier.log[logKey]?.t_flow.appraise_show || dossier.log[logKey]?.users.id == $store.state.user.id)">
                            <template #default="{ row }">
                                <div v-if="!logEdit">{{ row.appraise ?? '待评价' }}</div>
                                <el-input v-if="logEdit" type="textarea" autosize v-model="row.appraise" />
                            </template>
                        </vxe-column>

                        <!-- 评分 / 分 -->
                        <vxe-column width="80" title="评分 / 分" :resizable="false">
                            <template #default="{ row }">
                                <div v-if="!logEdit">{{ row.score ?? '待评分' }}</div>
                                <el-input-number v-if="logEdit" v-model="row.score" :min="-100" :max="(element.weight * row.weight * 0.01) || 100" :controls="false" :style="{ width: '100%' }"></el-input-number>
                            </template>
                        </vxe-column>

                    </vxe-colgroup>
                </vxe-table>
            </div>
        </div>

    </el-drawer>
</template>

<script>
export default {
    data() {
        return {
            visible: false,
            tabs: 'base',

            logKey: 0,
            logEdit: false,

            dossier: {
                id: null,
                users: { name: '?' },
                plan: {},
                log: [],
                flow_all_users_id:[],
                created_user: {},
                updated_user: {}
            }
        }
    },

    computed: {
        loading () {
            return !!this.$store.state.httpRequestCount
        }
    },

    methods: {

        // 窗口：打开
        Open(id)
        {
            this.visible = true
            this.Search(id)
        },

        // 窗口：关闭
        Close(done)
        {
            this.$emit('onChange')
            done()
        },

        // 刷新
        Reset()
        {
            this.Search()
        },

        // 查询
        Search(id = this.dossier.id)
        {
            this.$http.post(
                '/9api/hrm/kpi-dossier/search-first',
                {
                    search: { id: id },
                    append: ['plan', 'users', 'flow_all_users', 'log' , 'created_users', 'updated_users']
                }
            ).then(rps => {
                this.dossier = rps.data
            })
        },

        // 查看：评审结果
        CatLog(key, edit)
        {
            const log = this.dossier.log[key]
            this.logKey = key
            this.logEdit = edit
        },

        // 修改：评审结果
        Update()
        {
            this.$http.post('/9api/hrm/kpi-dossier-log/update', this.dossier.log[this.logKey]).then(rps => {
                this.Search()
                this.CatLog(this.logKey, false)
            })
        },

        // 修改：驳回
        UpdateReject(id, type)
        {
            if (type == 'submit')
            {
                this.$prompt('请输入驳回描述或原因', '驳回', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    inputType: 'textarea'
                }).then(({ value }) => {
                    this.$http.post('/9api/hrm/kpi-dossier-log/update-reject', { id: id, type: type, message: value }).then(rps => {
                        this.Search()
                        this.CatLog(this.logKey, false)
                    })
                })
            }
            if (type == 'cancel')
            {
                this.$http.post('/9api/hrm/kpi-dossier-log/update-reject', { id: id, type: type }).then(rps => {
                    this.Search()
                    this.CatLog(this.logKey, false)
                })
            }
        }
    }
}

</script>6
