<template>
    <el-drawer :visible.sync="visible" size="80%" direction="rtl" append-to-body destroy-on-close :show-close="false" :before-close="Close">

        <div slot="title">

            <div class="w-margin-b-10">员工信息</div>
            <div class="w-margin-b-10" style="font-size: 18px; color: #000; font-weight: bold;">{{ employee.name }}</div>

            <!-- 功能按钮 -->
            <div style="display: flex;">
                <el-button size="mini" icon="el-icon-edit" @click="RePage()">更新信息</el-button>
                <el-button size="mini" icon="el-icon-edit" @click="UpdateWecom()">更新微信</el-button>
                <el-button style="margin-left: 5px;" size="mini" @click="DataDelete()" type="danger">移除员工</el-button>
            </div>

            <hrm-employee-drawer-day-consume :users_id="employee.id" />

        </div>

        <div class="w-padding-l-20 w-padding-r-20 w-padding-b-20">
            <el-tabs v-model="tabs">

                <!-- 详细资料 -->
                <el-tab-pane label="基本信息" name="base">
                    <hrm-employee-drawer-base :form="employee" @onChange="DataGet()" />
                </el-tab-pane>

                <!-- 详细资料 -->
                <el-tab-pane label="岗位信息" name="Appointment">
                    <hrm-employee-drawer-appointment :form="employee" @onChange="DataGet()" />
                </el-tab-pane>

            </el-tabs>
        </div>

    </el-drawer>
</template>

<script>
export default {
    data() {
        return {
            visible: false,
            loading: false,
            tabs: 'base',

            employee: {
                id: null,
                source: [],
                level: {},
                type: {},
                country: [],
                user_admin: {},
                created_user: {},
                updated_user: {}
            }
        }
    },

    methods: {

        /**
        |--------------------------------------------------
        | 打开窗口
        |--------------------------------------------------
        |
        */

        Open(id) {
            this.visible = true
            this.DataGet(id)
        },

        /**
        |--------------------------------------------------
        | 关闭窗口
        |--------------------------------------------------
        |
        */

        Close(done) {
            this.$emit('onChange')
            done()
        },

        /**
        |--------------------------------------------------
        | 刷新
        |--------------------------------------------------
        |
        */

        RePage() {
            this.DataGet()
        },

        /**
        |--------------------------------------------------
        | 查询
        |--------------------------------------------------
        |
        */

        DataGet(id = this.employee.id) {
            this.loading = true

            this.$http.post(
                '/9api/hrm/employee/search-first',
                {
                    search: { id: id },
                    append: []
                }
            ).then(rps => {
                this.employee = rps.data
                this.employee.password = null
                this.loading = false
            }) 
        },

        UpdateWecom(id = this.employee.id) {
            this.$http.post('/9api/hrm/employee/update-wecom', {id: id}).then(rps => {
                this.DataGet()
            })
        },

        /**
        |--------------------------------------------------
        | 删除
        |--------------------------------------------------
        |
        */

        DataDelete() {
            this.$http.post('/9api/hrm/employee/delete', { id: this.employee.id }).then(rps => {
                this.$emit('onChange')
                this.visible = false
            })
        },
        
    }
}

</script>6
