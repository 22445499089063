<!---------------------------------------------------------------------------- 
 | 合同管理 - 开票记录
 |----------------------------------------------------------------------------
 | 方法：请注意大小写,参数描述见具体方法
 | $refs[ref_name].Open(*id) 手动打开窗口,
 | $refs[ref_name].Close() 手动关闭窗口
 |----------------------------------------------------------------------------
 | 回调
 | @onChange = fn () => {}
 |
 ---------------------------------------------------------------------------->

 <template>
    <el-drawer class="w-el-drawer" :visible.sync="visible" :size="960" :with-header="false" direction="rtl" :before-close="Close" append-to-body>
        
        <!-- header -->
        <div class="w-el-drawer-header">
            <i class="el-icon-tickets"></i> {{ invoice.invoice_content }}
        </div>

        <!-- body -->
        <div class="w-el-drawer-body"  v-loading="loading">

            <el-alert show-icon :closable="false" v-if="invoice.workflow_state == 0" type="info"    title="未审核"></el-alert>
            <el-alert show-icon :closable="false" v-if="invoice.workflow_state == 1" type="warning" title="待审批"></el-alert>
            <el-alert show-icon :closable="false" v-if="invoice.workflow_state == 2" type="warning" title="审核中"></el-alert>
            <el-alert show-icon :closable="false" v-if="invoice.workflow_state == 3" type="error"   title="已拒绝"></el-alert>
            <el-alert show-icon :closable="false" v-if="invoice.workflow_state == 4" type="success" title="已通过"></el-alert>
            <el-alert show-icon :closable="false" v-if="invoice.workflow_state == 5" type="warning" title="待撤销"></el-alert>
            <el-alert show-icon :closable="false" v-if="invoice.workflow_state == 6" type="warning" title="撤销中"></el-alert>
            <el-alert show-icon :closable="false" v-if="invoice.workflow_state == 7" type="error"   title="拒绝撤销"></el-alert>
            <el-alert show-icon :closable="false" v-if="invoice.workflow_state == 8" type="error"   title="已撤销"></el-alert>

            <el-descriptions size="mini" border :column="1" class="w-margin-t-10">
                <el-descriptions-item label="关联合同">{{ invoice.contract.number }} - {{ invoice.contract.name }}</el-descriptions-item>
                <el-descriptions-item label="关联客户">{{ invoice.customer.number }} - {{ invoice.customer.name }}</el-descriptions-item>
            </el-descriptions>
            
            <el-divider content-position="left">票据信息</el-divider>
            <el-descriptions size="mini" border :column="2">
                <el-descriptions-item label="开票内容">{{ invoice.invoice_content }}</el-descriptions-item>
                <el-descriptions-item label="开票日期">{{ invoice.invoice_date }}</el-descriptions-item>
                <el-descriptions-item label="票据类型">{{ invoice.invoice_type.name }}</el-descriptions-item>
                <el-descriptions-item label="票据号码">{{ invoice.invoice_number }}</el-descriptions-item>
                <el-descriptions-item label="票据金额">￥{{ invoice.invoice_money }}</el-descriptions-item>
                <el-descriptions-item label="经办人">{{ invoice.user.name }}</el-descriptions-item>
                <el-descriptions-item label="是否含税">{{ invoice.taxation_state ? '含税' : '不含税' }} (税率: {{ invoice.taxation_rate }}%) </el-descriptions-item>
                <el-descriptions-item label="税金">￥{{ invoice.taxation_money }}</el-descriptions-item>
                <el-descriptions-item label="备注" :span="2">{{ invoice.describe }}</el-descriptions-item>
            </el-descriptions>

            <el-divider content-position="left">开票单位信息</el-divider>
            <el-descriptions size="mini" border :column="2">
                <el-descriptions-item label="开票单位名称">{{ invoice.invoice_unit_name }}</el-descriptions-item>
                <el-descriptions-item label="开票单位税号">{{ invoice.invoice_unit_number }}</el-descriptions-item>
                <el-descriptions-item label="开票单位地址">{{ invoice.invoice_unit_address }}</el-descriptions-item>
                <el-descriptions-item label="开票单位电话">{{ invoice.invoice_unit_phone }}</el-descriptions-item>
                <el-descriptions-item label="开户银行">{{ invoice.invoice_unit_bank_name }}</el-descriptions-item>
                <el-descriptions-item label="银行账号">{{ invoice.invoice_unit_bank_number }}</el-descriptions-item>
            </el-descriptions>

            <!-- 其它信息 -->
            <el-tabs value="log" class="w-margin-t-20">

                <!-- 操作日志 -->
                <el-tab-pane label="操作日志" name="log">
                    <vxe-table :data="invoice.log" :loading="loading" >
                        <vxe-column width="60" type="seq" title="序号"></vxe-column>
                        <vxe-column width="120" field="ip" title="IP"></vxe-column>
                        <vxe-column width="80" field="users.name" title="用户"></vxe-column>
                        <vxe-column width="150" field="created_at" title="时间"></vxe-column>
                        <vxe-column width="80" field="event" title="事件">
                            <template #default="{ row }">
                                <el-link :underline="false" type="success" v-if="row.event == 'create'">创建</el-link>
                                <el-link :underline="false" type="primary" v-if="row.event == 'update'">更新</el-link>
                            </template>
                        </vxe-column>
                        <vxe-column field="remarks" title="事件描述">
                            <template #default="{ row }">
                                {{ row.remarks?.join('，') }}
                            </template>
                        </vxe-column>
                    </vxe-table>
                </el-tab-pane>

                <!-- 待开发栏目 -->
                <el-tab-pane label="预留的列表" disabled>待开发栏目</el-tab-pane>
                <el-tab-pane label="加点什么好呢" disabled>待开发栏目</el-tab-pane>
                <el-tab-pane label="先放着吧" disabled>待开发栏目</el-tab-pane>
            </el-tabs>
        </div>

        <!-- footer -->
        <div class="w-el-drawer-footer">
            <el-button size="mini" @click="Close()">关 闭</el-button>
            <workflow-approve-button ref="dialogApprove" v-model="invoice.workflow_id" @onChange="InvoiceReset" class="w-margin-l-10" />
        </div>

    </el-drawer>
</template>
    
<script>
export default {
    data() {
        return {
            visible: false,
            loading: false,

            invoice: {
                contract: {},
                customer: {},
                invoice_type: {},
                user: {},
                log: []
            },
        }
    },
    
    methods: {
        /**
        |--------------------------------------------------
        | 窗口 - 打开
        |--------------------------------------------------
        |
        */

        Open(id) {
            // 窗口配置
            this.visible = true
            this.loading = true

            this.$http.post('/9api/contract-pay-invoice/first', { id: id, append: ['contract', 'customer', 'invoice_type', 'user', 'log'] }).then(rps => {
                this.invoice = rps.data
                this.loading = false
            })
        },

        
        /**
        |--------------------------------------------------
        | 窗口 - 关闭
        |--------------------------------------------------
        |
        */

        Close() {
            this.visible = false
            this.loading = false
        },

        /**
        |--------------------------------------------------
        | 获取数据
        |--------------------------------------------------
        |
        */

        InvoiceReset() {
            // 窗口配置
            this.loading = true

            this.$http.post('/9api/contract-pay-invoice/first', { id: this.invoice.id, append: ['contract', 'customer', 'invoice_type', 'user'] }).then(rps => {
                this.invoice = rps.data
                this.loading = false
            })

            this.$emit('onChange', null);
        },
    }
}
</script>