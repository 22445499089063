<!---------------------------------------------------------------------------- 
 | echats line mini
 |----------------------------------------------------------------------------
 | 参数说明
 | type => String => 类型 [primary]
 | title => String => 标题
 | value => String => 数值
 | name => String => 名称
 ---------------------------------------------------------------------------->

<template>
    <div :style="{ width: '100%', height: '100%' }" :id="uuid"></div>
</template>

<script>
import * as echarts from 'echarts';

export default {
    props: {
        type: {
            type: String,
            default: null,
        },

        title: {
            type: Array,
            default: () => { return ['Title A', 'Title B', 'Title C', 'Title D', 'Title E', 'Title F', 'Title G'] },
        },

        name: {
            type: String,
            default: 'Name Sum',
        },

        value: {
            type: Array,
            default: () => { return [1, 4, 1, 6, 1, 4, 1] },
        }
    },

    data () {
        return {
            uuid: crypto.randomUUID(),
            myChart: null
        }
    },

    computed: {
        color () {
            switch(this.type) {
                case 'primary':
                    return '#0027ff'
                default:
                    return '#FFFFFF'
            }       
        }
    },

    watch: {
        title () {
            this.EchartsCreate()
        },
        value () {
            this.EchartsCreate()
        },
        type () {
            this.EchartsCreate()
        },
        name () {
            this.EchartsCreate()
        }
    },

    mounted () {
        this.EchartsCreate()
    },

    methods: {
        EchartsCreate() {
            this.myChart = echarts.init(document.getElementById(this.uuid));
            this.myChart.setOption({
                tooltip: { trigger: 'axis' },
                grid: { left: '0', right: '0', top: '10', bottom: '10' },
                xAxis: {
                    show: false,
                    data: this.title
                },
                yAxis: { show: false, alignTicks: false, },
                series: [
                    {
                        name: this.name,
                        type: 'line',
                        data: this.value,
                        smooth: true,
                        lineStyle: { color: this.color, width: 2 },
                        itemStyle: { color: this.color },
                        areaStyle: {
                            color: {
                                type: 'linear',
                                x: 0, y: 0, x2: 0, y2: 1,
                                colorStops: [
                                    { offset: 0, color: this.color },
                                    { offset: 1, color: this.color + '00' }
                                ],
                                global: false
                            }
                        }
                    }
                ]
            })
        }  
    }
}
</script>