<!---------------------------------------------------------------------------- 
 | 工单模板 - 表单创建
 |----------------------------------------------------------------------------
 | 方法：请注意大小写,参数描述见具体方法
 | *v-model  默认配置
 | *id       模板ID 
 |----------------------------------------------------------------------------
 | 方法
 | Validate() 验证表单
 |
 ---------------------------------------------------------------------------->

<template>
    <div style="display: grid; grid-template-columns: repeat(2, 1fr);">

        <el-empty description="暂未配置表单" v-if="data_dict.length == 0" style="grid-column-start: span 2;"></el-empty>

        <el-form-item v-for="(item, idx) in data_dict" :key="idx" :rules="[{ required: item.required }]" :label="item.name" :error="(item.required && (Array.isArray(item.form.value) ? item.form.value.length == 0 : !item.form.value ) ) ? '此项不能为空' : null ">

            <!-- 单行文本 -->
            <el-input v-if="item.type == 'text'" v-model="item.form.value" :placeholder="item.placeholder" clearable></el-input>

            <!-- 多行文本 -->
            <el-input v-if="item.type == 'textarea'" v-model="item.form.value" :placeholder="item.placeholder" rows="1" :autosize="{ maxRows: 6 }" clearable type="textarea"></el-input>

            <!-- 整数 -->
            <el-input-number v-if="item.type == 'int'" v-model="item.form.value" :precision="0" class="w-width-auto"></el-input-number>

            <!-- 浮点数 -->
            <el-input-number v-if="item.type == 'float'" v-model="item.form.value" :precision="2" class="w-width-auto"></el-input-number>

            <!-- 日期 -->
            <el-date-picker v-if="item.type == 'date'" v-model="item.form.value" :placeholder="item.placeholder" clearable type="date" value-format="yyyy-MM-dd" style="width: 100%;"></el-date-picker>
            <el-date-picker v-if="item.type == 'date-range'" v-model="item.form.value" :placeholder="item.placeholder" clearable type="daterange" value-format="yyyy-MM-dd" style="width: 100%;"></el-date-picker>

            <!-- 时间 -->
            <el-time-picker v-if="item.type == 'time'" v-model="item.form.value" :placeholder="item.placeholder" clearable value-format="HH:mm:ss" style="width: 100%;"></el-time-picker>
            <el-time-picker v-if="item.type == 'time-range'" v-model="item.form.value" :placeholder="item.placeholder" clearable value-format="HH:mm:ss" is-range style="width: 100%;"></el-time-picker>

            <!-- 日期时间 -->
            <el-date-picker v-if="item.type == 'datetime'" v-model="item.form.value" :placeholder="item.placeholder" clearable type="datetime" value-format="yyyy-MM-dd HH:mm:ss" style="width: 100%;"></el-date-picker>
            <el-date-picker v-if="item.type == 'datetime-range'" v-model="item.form.value" :placeholder="item.placeholder" clearable type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" style="width: 100%;"></el-date-picker>

            <!-- 下拉菜单(单选) -->
            <el-select v-if="item.type == 'select'" v-model="item.form.value" :placeholder="item.placeholder" clearable class="w-width-auto">
                <el-option v-for="(i, k) in item.form.select" :key="k" :label="i" :value="i"></el-option>
            </el-select>

            <!-- 下拉菜单(多选) -->
            <el-select v-if="item.type == 'select-multiple'" v-model="item.form.value" :placeholder="item.placeholder" clearable multiple class="w-width-auto">
                <el-option v-for="(i, k) in item.form.select" :key="k" :label="i" :value="i"></el-option>
            </el-select>

            <!-- 单选框 -->
            <el-radio-group v-if="item.type == 'radio'"  v-model="item.form.value">
                <el-radio v-for="(i, k) in item.form.select" :key="k" :label="i"></el-radio>
            </el-radio-group>

            <!-- 多选框 -->
            <el-checkbox-group v-if="item.type == 'checkbox'" v-model="item.form.value">
                <el-checkbox v-for="(i, k) in item.form.select" :key="k" :label="i"></el-checkbox>
            </el-checkbox-group>
            
        </el-form-item>
    </div>
</template>

<script>
export default {
    props: {
        value: Array,
        id: Number,
    },

    data () {
        return {
            formError: {},
            data_dict: []
        }
    },

    watch: {
        'id': {
            handler (val) {
                if(val) {
                    // 查询模板配置
                    this.$http.post('/9api/wo-template/search-first', { search: { id: val }, append: ['data_dict'] }).then(rps => {

                        // 初始化数据字典配置
                        this.data_dict = rps.data.data_dict.map((item) => {
                            // 判断用户是否传入默认值
                            if (this.value) {
                                let setValue = this.value.find((element) => element.id == item.id)
                                item.form.value = setValue ? setValue.value : item.form.value
                                return item
                            } else {
                                return item
                            }
                        })
                    })
                } else {
                    this.data_dict = []
                }
            },
            immediate: true
        },

        'data_dict': {
            handler() {
                if (this.data_dict.length > 0) {
                    this.$emit('input', this.data_dict.map((item) => {
                        return { id: item.id, value: item.form.value  }
                    }))
                } else {
                    this.$emit('input', null)
                }
            },
            deep: true
        }
    },

    methods: {
        Validate() {
            for (let i = 0; i < this.data_dict.length; i++) {
                if (this.data_dict[i].required && (Array.isArray(this.data_dict[i].form.value) ? this.data_dict[i].form.value.length == 0 : !this.data_dict[i].form.value)) {
                    return false
                }
            }
            
            return true
        }
    }
}
</script>