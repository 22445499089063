<!---------------------------------------------------------------------------- 
 | 审批管理
 |----------------------------------------------------------------------------
 | 方法：请注意大小写,参数描述见具体方法
 | $refs[ref_name].Update() 更新数据
 |----------------------------------------------------------------------------
 | 回调
 | @onChange = fn () => {} 操作后回调
 |
 ---------------------------------------------------------------------------->

 <template>
    <div>
        <template v-if="workflow.progress">
            <el-button size="mini" type="primary" @click="visible = true">审批信息</el-button>
            <el-button size="mini" type="primary" :style="{ marginLeft: '5px' }" @click="visibleConsent = true" v-if="workflow.approve_auth">审批通过</el-button>
            <el-button size="mini" type="danger"  :style="{ marginLeft: '5px' }" @click="visibleRefuse = true"  v-if="workflow.approve_auth">审批拒绝</el-button>
            <el-button size="mini" type="danger"  :style="{ marginLeft: '5px' }" @click="visibleBack = true">撤销申请/重新提交</el-button>
        </template>

        <!-- 审批信息 -->
        <el-dialog class="w-el-dialog" :visible.sync="visible" width="800px" destroy-on-close append-to-body title="审批信息">
            <el-row :gutter="10">

                <!-- 审批进度 -->
                <el-col :span="10">
                    <el-steps :active="0" direction="vertical" finish-status="finish">
                        <el-step v-for="(item, idx) in workflow.progress" :key="idx" :status="!item.state ? 'wait' : (item.state == 1 ? 'finish' : (item.state == 2 ? 'error' : (item.state == 3 ? 'success' : '')))">
                            
                            <!-- 审批状态 -->
                            <template slot="title">
                                <el-link type="info"    :underline="false" v-if="!item.state">待审核</el-link>
                                <el-link type="primary" :underline="false" v-if="item.state == 1">审核中</el-link>
                                <el-link type="danger"  :underline="false" v-if="item.state == 2">已拒绝</el-link>
                                <el-link type="success" :underline="false" v-if="item.state == 3">已通过</el-link>
                                <el-link type="warning" :underline="false" class="w-margin-l-10">{{ item.multiple ? '会签 - 需要所有人同意' : '或签 - 需要其中一人同意' }}</el-link>
                            </template>

                            <!-- 审批流程 -->
                            <template slot="description">

                                <!-- 已审批人员 -->
                                <div v-if="item.users_verify.length > 0">
                                    <el-link :underline="false" type="info" class="w-margin-b-5 w-margin-r-5">已审批：</el-link>
                                    <el-link icon="el-icon-user" :underline="false" type="primary" class="w-margin-b-5 w-margin-r-5" v-for="e in item.users.filter(x => item.users_verify.indexOf(x) !== -1)" :key="e">
                                        {{ workflow.users.find((item) => item.id == e ).name }}
                                    </el-link>
                                </div>

                                <!-- 未审批人员 -->
                                <div v-if="item.users_verify.length != item.users.length">
                                    <el-link :underline="false" type="info" class="w-margin-b-5 w-margin-r-5">未审批：</el-link>
                                    <el-link icon="el-icon-user" :underline="false" type="info" class="w-margin-b-5 w-margin-r-5" v-for="e in item.users.filter(x => item.users_verify.indexOf(x) === -1)" :key="e">
                                        {{ workflow.users.find((item) => item.id == e ).name }}
                                    </el-link>
                                </div>

                                <!-- 抄送人员 -->
                                <div v-if="item.cc.length > 0">
                                    <el-link :underline="false" type="info" class="w-margin-b-5 w-margin-r-5">抄送给</el-link>
                                    <el-link icon="el-icon-user" :underline="false" type="info" class="w-margin-b-5 w-margin-r-5" v-for="e in item.cc" :key="e">
                                        {{ workflow.users.find((item) => item.id == e ).name }}
                                    </el-link>
                                </div>
                            </template>

                        </el-step>
                    </el-steps>
                </el-col>

                <!-- 审批记录 -->
                <el-col :span="14">
                    <!-- 审批记录 -->
                    <template v-if="workflow_log.length == 0">
                        <el-empty description="暂无审批记录"></el-empty>
                        <el-skeleton />
                    </template>
                    
                    <el-collapse :value="0">
                        <el-collapse-item v-for="(e, n) in workflow_log" :key="n" :name="n">
                            <template slot="title">
                                <el-link class="w-margin-r-5" type="warning" :underline="false" icon="el-icon-circle-check" v-if="e.event_state == 3">申请撤销</el-link>
                                <el-link class="w-margin-r-5" type="primary" :underline="false" icon="el-icon-circle-check" v-if="e.event_state == 2">提交合同</el-link>
                                <el-link class="w-margin-r-5" type="success" :underline="false" icon="el-icon-circle-check" v-if="e.event_state == 1">同意</el-link>
                                <el-link class="w-margin-r-5" type="danger"  :underline="false" icon="el-icon-circle-close" v-if="e.event_state == 0">拒绝</el-link>
                                <el-link class="w-margin-r-5" type="info" :underline="false" icon="el-icon-user">{{ e.users.name }}</el-link>
                                <el-link class="w-margin-r-5" type="info" :underline="false">{{ e.updated_at }}</el-link>
                            </template>
                            <div style="white-space: break-spaces;">
                                {{ e.event_describe }}
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                </el-col>
            </el-row>

            <span slot="footer" class="dialog-footer">
                <el-button size="mini" @click="visible = false">取 消</el-button>
                <el-button size="mini" @click="Update()">更 新</el-button>
                <el-button size="mini" type="primary" @click="visibleConsent = true" :loading="loading">审批通过</el-button>
                <el-button size="mini" type="danger"  @click="visibleRefuse = true"  :loading="loading">审批拒绝</el-button>
            </span>
        </el-dialog>

        <!-- 审片意见（通过） -->
        <el-dialog class="w-el-dialog" :visible.sync="visibleConsent" width="600px" append-to-body title="审片意见（通过）">
            <el-input type="textarea" :rows="6" placeholder="审批意见" v-model="event_describe"></el-input>
            <span slot="footer" class="dialog-footer">
                <el-button size="mini" @click="visibleConsent = false">取 消</el-button>
                <el-button size="mini" type="success" @click="WorkflowConsent()" :loading="loading">同 意</el-button>
            </span>
        </el-dialog>

        <!-- 审片意见（拒绝） -->
        <el-dialog class="w-el-dialog" :visible.sync="visibleRefuse" width="600px" append-to-body title="审片意见（拒绝）">
            <el-input type="textarea" :rows="6" placeholder="审批意见" v-model="event_describe"></el-input>
            <span slot="footer" class="dialog-footer">
                <el-button size="mini" @click="visibleRefuse = false">取 消</el-button>
                <el-button size="mini" type="danger" @click="WorkflowRefuse()" :loading="loading">拒 绝</el-button>
            </span>
        </el-dialog>

        <!-- 撤销申请/重新提交 -->
        <el-dialog class="w-el-dialog" :visible.sync="visibleBack" width="600px" append-to-body title="撤销申请/重新提交">
            <el-input type="textarea" :rows="6" placeholder="申请描述" v-model="event_describe"></el-input>
            <span slot="footer" class="dialog-footer">
                <el-button size="mini" @click="visibleBack = false">取 消</el-button>
                <el-button size="mini" type="danger" @click="WorkflowBack()" :loading="loading">提 交</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data () {
        return {
            // 窗口状态
            visible: false,
            visibleConsent: false,
            visibleRefuse: false,
            visibleBack: false,

            // 加载状态
            loading: false,

            workflow: {},
            workflow_log: [],

            event_describe: null,
        }
    },

    props: {
        value: {
            type: Number,
            default: null
        }
    },

    watch: {
        value: {
            handler() {
                this.WorkflowSearch()
            },
            immediate: true
        }
    },

    methods: {

        /**
        |--------------------------------------------------
        | 更新数据
        |--------------------------------------------------
        |
        */

        Update() {
            this.WorkflowSearch()
        },

        /**
        |--------------------------------------------------
        | 审批信息查询
        |--------------------------------------------------
        |
        */
        
        WorkflowSearch() {
            // 开启加载状态
            this.loading = true
            
            // 请求数据
            new Promise((reslove) => {

                // 用户有传递值
                if (this.value) {
                    this.$http.post('/9api/workflow/first', { id: this.value, append: ['users', 'approve_auth'] }).then(rps => {
                        this.workflow = rps.data
                    })
                    this.$http.post('/9api/workflow/log', { workflow_id: this.value, append: ['users'] }).then(rps => {
                        this.workflow_log = rps.data
                    })
                }

                // 用户没有传递值
                if (!this.value) {
                    this.workflow = {}
                    this.workflow_log = []
                }

                reslove()

            }).then((rps) => {

                // 关闭加载状态
                this.loading = false
            })
            
        },

        /**
        |--------------------------------------------------
        | 审批通过
        |--------------------------------------------------
        |
        */

        WorkflowConsent() {
            this.loading = true
            this.$http.post('/9api/workflow/approve', {id: this.workflow.id, event_state: 1, event_describe: this.event_describe}).then(rps => {
                this.WorkflowSearch()
                this.$emit('onChange')
                this.visible = false
                this.visibleConsent = false
                this.visibleRefuse = false
            })
        },

        /**
        |--------------------------------------------------
        | 审批拒绝
        |--------------------------------------------------
        |
        */

        WorkflowRefuse() {
            this.loading = true
            this.$http.post('/9api/workflow/approve', {id: this.workflow.id, event_state: 0, event_describe: this.event_describe}).then(rps => {
                this.WorkflowSearch()
                this.$emit('onChange')
                this.visible = false
                this.visibleConsent = false
                this.visibleRefuse = false
            })
        },

        /**
        |--------------------------------------------------
        | 撤销申请
        |--------------------------------------------------
        |
        */

        WorkflowBack() {
            this.loading = true
            this.$http.post('/9api/workflow/back', {id: this.workflow.id, event_describe: this.event_describe}).then(rps => {
                this.WorkflowSearch()
                this.$emit('onChange')
                this.visible = false
                this.visibleConsent = false
                this.visibleRefuse = false
            })
        },
        
    }  
}
</script>