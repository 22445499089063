<template>
    <el-dialog :visible.sync="visible" width="600px" title="产品采购信息修改" append-to-body>

        <uw-statistic :column="2" gutter="10px" :style="{ marginBottom: '20px' }">
            <uw-statistic-item type="primary" money title="合计" unit="元" :value="form.money_total"></uw-statistic-item>
            <uw-statistic-item type="primary" money title="整单折扣后合计" unit="元" :value="form.money_discount"></uw-statistic-item>
        </uw-statistic>

        <el-divider></el-divider>

        <!-- 表单 -->
        <el-form :model="form" label-width="120px" size="mini" label-position="top">
                    
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="采购数量">
                        <el-input-number size="mini" v-model="form.num" @change="Update()" :min="1" :precision="2" :controls="false" :style="{ width: '100%' }"></el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="采购单价">
                        <el-input-number size="mini" v-model="form.money" @change="Update()" :min="0" :precision="2" :controls="false" :style="{ width: '100%' }"></el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="折扣/%">
                        <el-input-number size="mini" v-model="form.discount" @change="Update()" :precision="2" :controls="false" :style="{ width: '100%' }"></el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="整单折扣/%">
                        <el-input-number size="mini" v-model="form.discount_document" @change="Update()" :precision="2" :controls="false" :style="{ width: '100%' }"></el-input-number>
                    </el-form-item>
                </el-col>
            </el-row>

        </el-form>

        <div slot="footer">
            <el-button size="small" @click="visible = false">取 消</el-button>
            <el-button size="small" type="primary" @click="FormSubmit()" :loading="loading">确 定</el-button>
        </div>

    </el-dialog>
</template>

<script>
export default {
    data () {
        return {
            visible: false,
            loading: false,

            formError: {},
            form: {
                id: null,
                num: 0,
                money: 0,
                discount: 0,
                discount_document: 0,
                money_total: 0,
                money_discount: 0,
                describe: null
            }
        }
    },

    methods: {

        /**
        |--------------------------------------------------
        | 打开窗口
        |--------------------------------------------------
        |
        */

        Open(id) {
            this.visible = true
            this.loading = true

            this.$http.post('/9api/ims/product-order-log/search-first', { search: { id: id } }).then(rps => {
                this.form = rps.data
                this.loading = false
            })
        },

        // 产品列表 - 添加
        Update(data) {
            this.form.money_total = (this.form.money * this.form.num * (1 - this.form.discount * 0.01)).toFixed(2)
            this.form.money_discount = (this.form.money_total * (1 - this.form.discount_document * 0.01)).toFixed(2)
        },

        /**
        |--------------------------------------------------
        | 提交表单
        |--------------------------------------------------
        |
        */

        FormSubmit() {

            this.loading = true

            this.$http.post('/9api/ims/pr/update-product', this.form).then((rps) => {

                // 提交失败
                if (rps.data.state == 'error') {
                    this.formError = rps.data.body
                }

                // 提交成功
                if (rps.data.state == 'success') {
                    this.$emit('onChange', rps.data.body)
                    this.visible = false
                }

                this.loading = false
            })
        },
    }
}
</script>