<!---------------------------------------------------------------------------- 
 | statistic-time 统计数值
 |----------------------------------------------------------------------------
 | 参数说明
 | type => String => 类型 [primary]
 | title => String => 标题
 | value => String => 数值
 | unit => String => 单位
 | tip => String => 提示
 | row => Array => [开始位置,结束位置]（行合并）
 | column => Array => [开始位置,结束位置]（列合并）
 ---------------------------------------------------------------------------->

<template>
    <div @click="$emit('onClick')" :class="['uw-statistic-item', type,]" :style="{ gridRowStart: row[0], gridRowEnd: row[1], gridColumnStart: column[0], gridColumnEnd: column[1] }">
        <div :style="{  width: $slots.default ? '50%' : '100%' }">
            <div class="uw-statistic-item-title">{{ title }}</div>
            <div class="uw-statistic-item-value">
                {{ money ? $lodash.toMoney(Number(value)) : value }}
                <span v-if="unit" class="uw-statistic-item-unit">/{{ unit }}</span>
            </div>
            <div class="uw-statistic-item-tip" v-if="tip">{{ tip }}</div>
        </div>
        <div class="uw-statistic-item-solt">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        type: {
            type: String,
            default: null,
        },

        title: {
            type: String,
            default: null,
        },

        value: {
            type: [String, Number],
            default: null,
        },

        unit: {
            type: String,
            default: null,
        },

        tip: {
            type: String,
            default: null,
        },

        row: {
            type: Array,
            default: () => [null, null],
        },

        column: {
            type: Array,
            default: () => [null, null],
        },

        money: {
            type: Boolean,
            default: false
        }
    },
}
</script>

<style lang="less" scoped>
    .uw-statistic-item {

        cursor: pointer;
        border-radius: 3px;
        padding: 10px;
        display: flex;
        justify-content: space-between;

        background-color: #FFFFFF;
        color: #001480;
        border: 1px solid #EEEEEE;

        &.primary {
            background-color: #0027ff;
            color: #FFFFFF;
            border: 1px solid #0027ff;
        }

        .uw-statistic-item-title { font-size: 14px; margin-bottom: 10px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }
        .uw-statistic-item-value { font-size: 22px; margin-bottom: 5px; font-weight: bold; white-space: nowrap; overflow: hidden; }
        .uw-statistic-item-tip { white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }
        .uw-statistic-item-unit { font-size: 14px; white-space: nowrap; }
        .uw-statistic-item-solt { flex: 1; margin-left: 20px; white-space: nowrap; }
        
    }
</style>