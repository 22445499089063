<template>
    <el-dialog :visible.sync="visible" width="600px" append-to-body destroy-on-close title="新增考核维度" :close-on-click-modal="false" :close-on-press-escape="false" :before-close="Close">
            
        <!-- 表单 -->
        <el-form :model="form" :rules="rules" ref="form" size="mini" label-position="top">
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="维度名称" prop="name">
                        <el-input v-model="form.name" clearable />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="指标类型" prop="type">
                        <hrm-kpi-template-class-type v-model="form.type" edit />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="维度权重 %" prop="weight">
                        <el-input-number v-model="form.weight" :min="0" :max="100" :controls="false" :style="{ width: '100%' }"></el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="备注">
                        <el-input type="textarea" v-model="form.describe" :rows="3" />
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>

        <span slot="footer">
            <el-button size="mini" @click="Close()">取 消</el-button>
            <el-button size="mini" type="primary" @click="SubmitForm()">确 定</el-button>
        </span>
        
    </el-dialog>
</template>

<script>
export default {
    data () {
        return {
            visible: false,

            rules: {
                name: [
                    { required: true, message: '请输入维度名称', trigger: 'change' },
                ],
                type: [
                    { required: true, message: '请选择指标类型', trigger: 'change' },
                ],
                weight: [
                    { required: true, type: 'number', min: 0, max: 100, message: '权重范围请控制在在0%~100%之间', trigger: 'change' },
                ]
            },

            form: {
                name: null,
                type: null,
                weight: 0,
                describe: null
            },
        }
    },

    methods: {

        // 窗口 - 打开
        async Open()
        {
            this.visible = true
        },

        // 窗口 - 关闭
        Close()
        {
            this.form.name = null
            this.form.type = null
            this.form.weight = 0
            this.form.describe = null
            this.visible = false
        },

        // 表单 - 提交
        SubmitForm ()
        {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.$emit('onChange', this.form)
                    this.Close()
                } else {
                    return false;
                }
            })
        },
    }
}
</script>