<template>
    <div>
        <span :style="{ fontSize: '12px' }" v-if="!edit">{{ select.find(rps => rps.value == selectValue).label }}</span>
        <el-select
            v-if="edit"
            @change="SelectChange"
            v-model="selectValue"
            placeholder="请选择指标类型"
            size="mini"
            clearable
            :style="{ width: width, margin: margin }">
            <el-option v-for="(item, idx) in select" :key="idx" :label="item.label" :value="item.value"></el-option>
        </el-select>
    </div>
</template>

<script>
export default {
    props: {
        edit: {
            type: Boolean,
            default: false
        },

        value: {
            type: String,
            default: null
        },

        width: {
            type: String,
            default: '100%'
        },

        margin: {
            type: String,
            default: '0px'
        },
    },
    
    watch: {
        value: {
            handler(val) {
                if (val) {
                    this.selectValue = val
                    this.selectName = this.select.find(rps => rps.value == val).label
                }
            },
            immediate: true
        },
    },

    data () {
        return {
            select: [
                { value: 'goals', label: '考核指标' },
                { value: 'custom', label: '自定义指标' }
            ],

            selectValue: null,
            selectName: null
        }
    },

    methods: {
        SelectChange(data) {
            this.$emit('input', data)
            this.$emit('onChange', data)
        }
    }
}
</script>