<template>
    <el-dialog
        :visible.sync="visible"
        width="500px"
        append-to-body
        title="异常任务"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :before-close="Close">
        
        <!-- 表单 -->
        <el-form :model="form" label-position="top">
            <el-select v-model="form.describe" multiple filterable allow-create default-first-option placeholder="异常描述" :style="{ width: '100%' }">
                <el-option v-for="(item, idx) in suggest" :key="idx" :label="item" :value="item" />
            </el-select>
        </el-form>
        

        <span slot="footer">
            <el-button size="mini" @click="Close()">取 消</el-button>
            <el-button size="mini" type="primary" @click="SubmitForm()" :loading="$store.state.loading">确 定</el-button>
        </span>
    </el-dialog>
</template>

<script>
export default {
    data () {
        return {
            // 加载状态
            visible: false,

            // 输入建议
            suggest: [
                '输入建议1',
                '输入建议2',
            ],

            formError: {},
            form: {
                task_id: null,
                describe: [],
            }
        }
    },

    methods: {

        // 窗口 - 打开
        Open (config)
        {
            if (config)
            {
                this.form.task_id = config.task_id
            }
            this.visible = true
        },

        // 窗口 - 关闭
        Close()
        {
            this.visible = false
        },


        /**
        |--------------------------------------------------
        | 提交表单
        |--------------------------------------------------
        |
        */

        SubmitForm() {
            this.formError = {}

            // 提交表单
            this.$http.post('/9api/pm/task-alarm/create', this.form).then(rps => {
                // 提交失败
                if (rps.data.state == 'error') {
                    this.formError = rps.data.body
                }
                
                // 提交成功
                if (rps.data.state == 'success') {
                    this.$emit('onChange', rps.data.body)
                    this.Close()
                }
            })
        }
    }  
}
</script>