<template>
    <el-dialog :visible.sync="visible" width="900px" title="新建报废单" append-to-body destroy-on-close @close="Close()" :close-on-click-modal="false" :close-on-press-escape="false">

        <!-- 表单 -->
        <el-form :model="form" size="mini" label-position="top">
            
            <!-- 基本信息 -->
            <el-divider content-position="left"><b>基本信息</b></el-divider>
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="报废日期" :rules="[{ required: true, message: '必填项' }]" :error="formError.date?.toString()" prop="date">
                        <el-date-picker v-model="form.date" type="date" value-format="yyyy-MM-dd" :style="{ width: '100%' }"></el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="报废备注">
                        <el-input type="textarea" :rows="1" v-model="form.describe"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>

            <!-- 报废产品 -->
            <el-divider content-position="left"><b>报废产品</b></el-divider>
            <el-button type="primary" size="mini" @click="$refs.storageSearch.Open()">添加产品</el-button>
            <vxe-table border ref="xTable" :data="form.warehouse_log" :loading="loading" :style="{ marginTop: '10px' }">
                <!-- 产品信息 -->
                <vxe-column min-width="120" field="product_name" title="产品名称"></vxe-column>
                <vxe-column min-width="80" field="product_type" title="产品类别"></vxe-column>
                <vxe-column min-width="80" field="warehouse_name" title="报废仓库"></vxe-column>
                <vxe-column min-width="70" field="warehouse_num" title="库存数量"></vxe-column>
                <!-- 报废数量 -->
                <vxe-column min-width="70" title="报废数量">
                    <template #default="{ row }">
                        <el-input-number size="mini" v-model="row.num" :min="0" :precision="2" :controls="false" :style="{ width: '100%' }"></el-input-number>
                    </template>
                </vxe-column>
            </vxe-table>

        </el-form>

        <div slot="footer">
            <el-button size="small" @click="visible = false">取 消</el-button>
            <el-button size="small" type="primary" @click="FormSubmit()" :loading="loading">确 定</el-button>
        </div>

        <!-- 组件 -->
        <ims-warehouse-storage-search ref="storageSearch" @onChange="UpdateWarehoserLog" />

    </el-dialog>
</template>

<script>
export default {
    data () {
        return {
            visible: false,

            formError: {},
            form: {
                date: null,
                describe: null,
                warehouse_log: [],
            }
        }
    },

    // 计算属性
    computed: {
        loading () {
            return !!this.$store.state.httpRequestCount
        }
    },
    
    methods: {

        // 打开窗口
        Open() {
            this.visible = true
        },

        // 关闭窗口
        Close() {
            this.form.date = null
            this.form.describe = null
            this.form.warehouse_log = []
        },

        // 更新产品
        UpdateWarehoserLog(data) {
            this.form.warehouse_log = data.map(rps => {
                return {
                    product_id: rps.product_id,
                    product_name: rps.product.name,
                    product_type: rps.product.type.name,
                    warehouse_id: rps.warehouse_id,
                    warehouse_name: rps.warehouse.name,
                    warehouse_num: rps.num,
                    num: 0.00,
                }
            })
        },

        // 提交表单
        FormSubmit() {
            this.$http.post( '/9api/ims/product-invalid/create', this.form ).then((rps) => {
                // 提交失败
                if (rps.data.state == 'error') {
                    this.formError = rps.data.body
                }
                // 提交成功
                if (rps.data.state == 'success') {
                    this.$emit('onChange', rps.data.body)
                    this.visible = false
                }
            })
        },
    }
}
</script>