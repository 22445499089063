<template>
    <div>
        <!-- 统计数据 -->
        <div :style="{ display: 'grid', gridTemplateColumns: 'calc(20% - 5px) calc(80% - 5px)', columnGap: '10px' }">
            <div style="background-color: #f4f5f7; border-radius: 3px; padding: 15px; display: flex;">
                <el-statistic :style="{ lineHeight: 2, cursor: 'pointer' }" @click.native="usersSearch.status = 0; RePage()">
                    <template slot="title"><span :style="{ color: usersSearch.status == 0 ? '#0027ff' : '#606266' }">在职</span></template>
                    <template slot="formatter"><span :style="{ color: usersSearch.status == 0 ? '#0027ff' : '#606266' }">{{ organize.users_count['在职'] }}</span></template>
                </el-statistic>
                <el-statistic :style="{ lineHeight: 2, cursor: 'pointer' }" @click.native="usersSearch.status = 1; RePage()">
                    <template slot="title"><span :style="{ color: usersSearch.status == 1 ? '#0027ff' : '#606266' }">离职</span></template>
                    <template slot="formatter"><span :style="{ color: usersSearch.status == 1 ? '#0027ff' : '#606266' }">{{ organize.users_count['离职'] }}</span></template>
                </el-statistic>
            </div>
            <div style="background-color: #f4f5f7; border-radius: 3px; padding: 15px; display: flex;">
                <el-statistic :style="{ lineHeight: 2, cursor: 'pointer' }" @click.native="usersSearch.type = '全职'; RePage()">
                    <template slot="title"><span :style="{ color: usersSearch.type == '全职' ? '#0027ff' : '#606266' }">全职</span></template>
                    <template slot="formatter"><span :style="{ color: usersSearch.type == '全职' ? '#0027ff' : '#606266' }">{{ organize.users_count['全职'] }}</span></template>
                </el-statistic>
                <el-statistic :style="{ lineHeight: 2, cursor: 'pointer' }" @click.native="usersSearch.type = '兼职'; RePage()">
                    <template slot="title"><span :style="{ color: usersSearch.type == '兼职' ? '#0027ff' : '#606266' }">兼职</span></template>
                    <template slot="formatter"><span :style="{ color: usersSearch.type == '兼职' ? '#0027ff' : '#606266' }">{{ organize.users_count['兼职'] }}</span></template>
                </el-statistic>
                <el-statistic :style="{ lineHeight: 2, cursor: 'pointer' }" @click.native="usersSearch.type = '试用'; RePage()">
                    <template slot="title"><span :style="{ color: usersSearch.type == '试用' ? '#0027ff' : '#606266' }">试用</span></template>
                    <template slot="formatter"><span :style="{ color: usersSearch.type == '试用' ? '#0027ff' : '#606266' }">{{ organize.users_count['试用'] }}</span></template>
                </el-statistic>
                <el-statistic :style="{ lineHeight: 2, cursor: 'pointer' }" @click.native="usersSearch.type = '实习'; RePage()">
                    <template slot="title"><span :style="{ color: usersSearch.type == '实习' ? '#0027ff' : '#606266' }">实习</span></template>
                    <template slot="formatter"><span :style="{ color: usersSearch.type == '实习' ? '#0027ff' : '#606266' }">{{ organize.users_count['实习'] }}</span></template>
                </el-statistic>
                <el-statistic :style="{ lineHeight: 2, cursor: 'pointer' }" @click.native="usersSearch.type = '劳务'; RePage()">
                    <template slot="title"><span :style="{ color: usersSearch.type == '劳务' ? '#0027ff' : '#606266' }">劳务</span></template>
                    <template slot="formatter"><span :style="{ color: usersSearch.type == '劳务' ? '#0027ff' : '#606266' }">{{ organize.users_count['劳务'] }}</span></template>
                </el-statistic>
                <el-statistic :style="{ lineHeight: 2, cursor: 'pointer' }" @click.native="usersSearch.type = '顾问'; RePage()">
                    <template slot="title"><span :style="{ color: usersSearch.type == '顾问' ? '#0027ff' : '#606266' }">顾问</span></template>
                    <template slot="formatter"><span :style="{ color: usersSearch.type == '顾问' ? '#0027ff' : '#606266' }">{{ organize.users_count['顾问'] }}</span></template>
                </el-statistic>
                <el-statistic :style="{ lineHeight: 2, cursor: 'pointer' }" @click.native="usersSearch.type = '返聘'; RePage()">
                    <template slot="title"><span :style="{ color: usersSearch.type == '返聘' ? '#0027ff' : '#606266' }">返聘</span></template>
                    <template slot="formatter"><span :style="{ color: usersSearch.type == '返聘' ? '#0027ff' : '#606266' }">{{ organize.users_count['返聘'] }}</span></template>
                </el-statistic>
                <el-statistic :style="{ lineHeight: 2, cursor: 'pointer' }" @click.native="usersSearch.type = '外包'; RePage()">
                    <template slot="title"><span :style="{ color: usersSearch.type == '外包' ? '#0027ff' : '#606266' }">外包</span></template>
                    <template slot="formatter"><span :style="{ color: usersSearch.type == '外包' ? '#0027ff' : '#606266' }">{{ organize.users_count['外包'] }}</span></template>
                </el-statistic>
            </div>
        </div>

        <el-button :style="{ margin: '10px 0' }" type="primary" size="mini" @click="$refs.employeeCreate.Open({ organize_id: organize_id })">新建员工</el-button>

        <!-- 表内容 -->
        <vxe-table
            ref="xTable"
            :data="users"
            :loading="loading"
            :export-config="{}"
            :print-config="{}"
            height="500px">

            <!-- 序号 -->
            <vxe-column width="60" type="seq" title="序号" fixed="left"></vxe-column>

            <!-- 员工名称 -->
            <vxe-column width="120" field="name" title="员工名称" fixed="left">
                <template #default="{ row }">
                    <div style="display: flex; align-items: center; justify-content: flex-start;">
                        <el-avatar style="margin-right: 5px;" :size="26" v-if="row.avatar" :src="row.avatar" />
                        <el-avatar style="margin-right: 5px;" :size="26" v-else> ? </el-avatar>
                        <el-link type="primary" @click="$refs.employeeDrawer.Open(row.id)">{{  row.name }}</el-link>
                    </div>
                </template>
            </vxe-column>

            <!-- 性别 -->
            <vxe-column show-overflow width="60" field="sex" title="性别">
                <template #default="{ row }">
                    <span v-if="row.sex == 0" style="color: #909399">未知</span>
                    <span v-if="row.sex == 1" style="color: #409EFF">男</span>
                    <span v-if="row.sex == 2" style="color: #F56C6C">女</span>
                </template>
            </vxe-column>

            <vxe-column width="80" field="status" title="在职状态">
                <template v-slot="{ row }">
                    <span v-if="row.status == 0" style="color: #409EFF">在职</span>
                    <span v-else style="color: #F56C6C">离职</span>
                </template>
            </vxe-column>
            
            <vxe-column width="120" field="type" title="聘用方式"></vxe-column>

            <!-- 组织/部门 -->
            <vxe-column width="160" field="organize.name" title="组织/部门">
                <template #default="{ row }">
                    <el-link :underline="false" type="primary" @click="$refs.organizeDrawer.Open(row.organize_id)">{{ row.organize?.name }}</el-link>
                    <el-tag :style="{ marginLeft: '5px' }" size="mini" effect="dark" type="danger" v-if="row.organize_admin">负责人</el-tag>
                </template>
            </vxe-column>

            <!-- 岗位 -->
            <vxe-column width="120" field="appointment" title="岗位"></vxe-column>

            <!-- 联系方式 -->
            <vxe-column width="120" field="phone" title="手机"></vxe-column>
            <vxe-column width="160" field="email" title="邮箱"></vxe-column>
            <vxe-column width="100" field="number" title="工号"></vxe-column>

            <!-- 企业微信 -->
            <vxe-column width="80" field="wecom_status" title="企业微信">
                <template #default="{ row }">
                    <span v-if="row.wecom_status" style="color: #409EFF">已绑定</span>
                    <span v-else style="color: #909399">未绑定</span>
                </template>
            </vxe-column>

            <!-- 用户权限/岗位 -->
            <vxe-column min-width="200" field="role" title="角色权限">
                <template #default="{ row }">
                    <span v-for="(item, idx) in row.role" style="color: #409EFF" :key="idx">{{ item.name }}&nbsp;&nbsp;&nbsp;</span>
                </template>
            </vxe-column>
        </vxe-table>

        <hrm-employee-create ref="employeeCreate" @onChange="RePage()" />
        <hrm-employee-drawer ref="employeeDrawer" @onChange="RePage()" />
        <hrm-organize-drawer ref="organizeDrawer" @onChange="RePage()" />
    </div>
</template>

<script>
export default {
    props: ['organize_id'],

    data() {
        return {
            loading: false,
            
            organize: {
                users_count: {}
            },

            users: [],
            usersSearch: {
                type: '全职',
                status: 0
            },
            usersOrder: {
                method: 'desc',
                column: 'id'
            },
        }
    },

    watch: {
        organize_id: {
            handler(val) {
                if(val) {
                    this.RePage()
                }
            },
            immediate: true
        }
    },

    methods: {
        RePage() {
            this.OrganizeSearch()
            this.UsersSearch()
        },

        OrganizeSearch() {
            this.loading = true

            this.$http.post(
                '/9api/hrm/organize/search-first',
                {
                    search: { id: this.organize_id },
                    append: ['users_count']
                }
            ).then(rps => {
                this.organize = rps.data
                this.loading = false
            }) 
        },

        UsersSearch() {
            this.$http.post(
                '/9api/hrm/employee/search-all',
                {
                    search: { ...this.usersSearch, organize_id: this.organize_id, children: true },
                    order: this.usersOrder,
                    hidden: ['wecom', 'password'],
                    append: ['role', 'organize', 'wecom_status']
                }
            ).then((rps) => {
                this.users = rps.data
                this.loading = false
            })
        },
    }
}
</script>