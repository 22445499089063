<!---------------------------------------------------------------------------- 
 | 参数：请注意大小写,参数描述见具体方法
 | Open(Object search) => 作用：打开窗口
 |----------------------------------------------------------------------------
 | 参数
 | :v-model     默认值------默认为 null
 | :width       默认值------默认为 100%
 | :margin      默认值------默认为 0px
 | :disabled    默认值------默认为 false
 | :showInput   默认值------默认为 true
 |----------------------------------------------------------------------------
 | 回调
 | @onChange = fn (data) => {} 返回表单内容
 ---------------------------------------------------------------------------->

 <template>
    <div>
        <el-input
            v-if="showInput"
            v-model="select.inputValue"
            @focus="Open"
            @clear="SelectClear"
            placeholder="请选择客户联系人"
            clearable
            :disabled="disabled"
            :style="{ width: width, margin: margin }"/>

        <el-dialog :visible.sync="dialogVisible" width="900px" append-to-body title="客户联系人" :close-on-click-modal="false" :close-on-press-escape="false">
            
            <!-- toolbar -->
            <uw-grid-search gutter="0 10px" :column="3" :style="{ marginBottom: '10px' }">
                <template slot="full">
                    <el-input @input="TableSearch" v-model="post.search.name" clearable  placeholder="联系人名称"></el-input>
                </template>
                <template slot="tools">
                    <el-button type="primary" @click="$refs.dialogCreate.Open()">新建联系人</el-button>
                </template>
                <el-form-item label="手机号码">
                    <el-input @input="TableSearch" v-model="post.search.phone" clearable  placeholder="手机号码"></el-input>
                </el-form-item>
                <el-form-item label="所属员工">
                    <users-select @onChange="TableSearch" v-model="post.search.user_admin_ids" multiple />
                </el-form-item>
                <el-form-item label="添加日期">
                    <el-date-picker @change="TableSearch" v-model="post.search.created_at" clearable type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :style="{ width: '100%' }"></el-date-picker>
                </el-form-item>
            </uw-grid-search>

            <!-- table -->
            <vxe-table ref="xTable" :data="table" :loading="$store.state.loading" @sort-change="TableSort" @cell-dblclick="TableCellDblclick" height="500px">

                <!-- 联系人 -->
                <vxe-column min-width="120" title="联系人" sortable>
                    <template #default="{ row }">
                        <el-link @click="$refs.dialogDrawer.Open(row.id)" :type="row.sex == '女' ? 'danger' : 'primary'" icon="el-icon-user">
                            {{ row.name }}
                        </el-link>
                    </template>
                </vxe-column>

                <vxe-column min-width="80" title="性别" sortable>
                    <template #default="{ row }">
                        <el-link :underline="false" :type="row.sex == '女' ? 'danger' : 'primary'"   v-if="row.sex">{{ row.sex }}</el-link>
                        <el-link :underline="false" type="info" v-else>{{ row.sex || '未记录' }}</el-link>
                    </template>
                </vxe-column>

                <!-- 客户单位 -->
                <vxe-column min-width="250" title="客户单位" sortable>
                    <template #default="{ row }">
                        <el-link @click="$refs.customerDrawer.Open(row.customer.id)" :type="row.sex == '女' ? 'danger' : 'primary'" icon="el-icon-school">{{ row.customer?.name }}</el-link>
                    </template>
                </vxe-column>

                <!-- 工作电话 -->
                <vxe-column min-width="160" title="手机">
                    <template #default="{ row }">
                        <el-link :underline="false" icon="el-icon-mobile-phone" v-if="row.phone || row.phone_t">{{ row.phone || row.phone_t }}</el-link>
                        <el-link :underline="false" icon="el-icon-mobile-phone" v-if="!row.phone && !row.phone_t" type="info">未记录</el-link>
                    </template>
                </vxe-column>

                <!-- 关键决策者 -->
                <vxe-column min-width="90" title="关键决策者">
                    <template #default="{ row }">
                        <el-link :underline="false" icon="el-icon-circle-check"     type="warning"   v-if="row.policymakers == '是'">{{ row.policymakers }}</el-link>
                        <el-link :underline="false" icon="el-icon-warning-outline"  type="info"      v-else >{{ row.policymakers || 未记录 }}</el-link>
                    </template>
                </vxe-column>
                
                <!-- 联系地址 -->
                <vxe-column min-width="90" title="联系地址">
                    <template #default="{ row }">
                        {{ ((row.country || '') + (row.country_province || '') + (row.country_province_city || '') + (row.address || '')) || '未登记' }}
                    </template>
                </vxe-column>

            </vxe-table>

            <!-- pager -->
            <vxe-pager 
                :style="{ marginTop: '10px' }"
                :total="post.page.total"
                :page-size.sync="post.page.size"
                :current-page.sync="post.page.current"
                @page-change="TableSearch()">
            </vxe-pager>

        </el-dialog>

        <!-- 组件 -->
        <crm-contacts-create ref="dialogCreate" @onChange="TableSearch()" />
        <crm-contacts-drawer ref="dialogDrawer" @onChange="TableSearch()" />
        <crm-customer-drawer ref="customerDrawer" @onChange="TableSearch()" />

    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: [String, Array, Number],
            default: null
        },

        width: {
            type: String,
            default: '100%'
        },

        margin: {
            type: String,
            default: '0px'
        },

        disabled: {
            type: Boolean,
            default: false
        },

        showInput: {
            type: Boolean,
            default: true
        }
    },

    data () {
        return {
            dialogVisible: false,

            // 查询结果
            table: [],

            // 查询链接
            url: {
                default: '/9api/crm/contacts/search',
                first: '/9api/crm/contacts/first'
            },

            // 查询条件
            post: {
                page: {
                    total: 0,
                    size: 50,
                    current: 1,
                },
                search: {
                    name: null,
                    phone: null,
                    user_admin_ids: null,
                    created_at: []
                },
                append: ['customer'],
                hidden: [],
                order: {
                    method: 'desc',
                    column: 'id'
                },
            },

            // 选择结果
            select: {
                data: {},
                inputValue: null
            }
        }
    },

    watch: {
        value: {
            handler(val) {
                val ? this.SelectSearch() : this.SelectClear()
            },
            immediate: true
        }
    },

    methods: {

        // 窗口：打开
        Open(search)
        {
            // 初始化检索条件
            if (search)
            {
                this.post.search = { ...this.post.search, ...search }
            }

            this.dialogVisible = true
            this.TableSearch()
        },

        // 数据：查询
        TableSearch()
        {
            this.$http.post(this.url.default, this.post).then((rps) => {
                // 更新数据
                this.post.page.total = rps.data.total
                this.table = rps.data.body

                // 页码溢出
                if (this.table.length == 0 && this.post.page.current != 1)
                {
                    this.post.page.current = 1
                    this.TableSearch()
                }
            })
        },

        // 表格：排序
        TableSort(column)
        {
            this.post.order.method = column.order ? column.order : this.post.order.method
            this.post.order.column = column.field
            this.TableSearch()
        },

        // 表格：双击事件
        TableCellDblclick({ row })
        {
            this.select.inputValue = row.name
            this.select.data = row
            this.$emit('input', row.id)
            this.$emit('onChange', row)
            this.dialogVisible = false
        },

        // 选择：查询
        SelectSearch()
        {
            this.$http.post(this.url.first, { id: this.value }).then(rps => {
                this.select.inputValue = rps.data.name
                this.select.data = rps.data
                this.$emit('input', rps.data.id)
                this.$emit('onChange', rps.data)
            })
        },

        // 选择：清除
        SelectClear()
        {
            this.select.inputValue = null
            this.select.data = {}
            this.$emit('input', null)
            this.$emit('onChange', null)
        },
    }
}
</script>