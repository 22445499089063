<!---------------------------------------------------------------------------- 
 | Input - 款项记录 - 未开票数据
 |----------------------------------------------------------------------------
 | 方法：请注意大小写,参数描述见具体方法
 | *v-model     默认值------默认为 null
 | multiple     是否多选----默认为 false
 | contractId   合同ID
 |----------------------------------------------------------------------------
 | 回调
 | @onChange = fn (data) => {} 返回表单内容
 |
 ---------------------------------------------------------------------------->

 <template>
    <div>
        <el-input
            v-model="selectName"
            @focus="DialogOpend"
            @clear="SelectClear"
            size="mini"
            placeholder="请选择记录"
            clearable
            :style="{ width: width, margin: margin }"/>

        <el-dialog :visible.sync="dialogVisible" class="w-el-dialog" width="900px" append-to-body title="选择记录（双击选择）" :close-on-click-modal="false" :close-on-press-escape="false">
            
            <!-- 表容器 -->
            <div class="w-vxe-fullscreen w-padding-none" style="height: 600px;">
                <div class="w-vxe-flex" style="border-radius: 0; box-shadow: none; padding: 0;">

                    <!-- toolbar -->
                    <div class="w-vxe-flex-toolbar">
                        <vxe-toolbar>
                            <template #buttons>
                                <!-- 开票信息 -->
                                <el-select v-model="search.invoice_state" @change="TableSearch()" placeholder="发票开具" size="mini" clearable filterable>
                                    <el-option label="未开具发票" :value="0"></el-option>
                                    <el-option label="已开具发票" :value="1"></el-option>
                                    <el-option label="无需开具发票" :value="2"></el-option>
                                </el-select>

                                <!-- 款项类型 -->
                                <el-select v-model="search.pay_type" @change="TableSearch()" placeholder="款项类型" size="mini" clearable filterable>
                                    <el-option label="退款记录" :value="0"></el-option>
                                    <el-option label="回款记录" :value="1"></el-option>
                                </el-select>

                                <!-- 款项日期 -->
                                <el-select v-model="search.pay_date_range" @change="TableSearch()" placeholder="款项日期" size="mini" clearable filterable>
                                    <el-option label="7天内" value="-7 day"></el-option>
                                    <el-option label="15天内" value="-15 day"></el-option>
                                    <el-option label="30天内" value="-30 day"></el-option>
                                    <el-option label="60天内" value="-60 day"></el-option>
                                    <el-option label="60天后" value="+60 day"></el-option>
                                </el-select>
                            </template>
                        </vxe-toolbar>
                    </div>

                    <!-- table -->
                    <div class="w-vxe-flex-table">
                        <vxe-table ref="xTable" :data="table" :loading="dialogLoading" @sort-change="TableSort" @cell-dblclick="TableCellDblclick" height="100%">

                            <!-- ID -->
                            <vxe-column width="60" field="id" title="ID" sortable></vxe-column>
                            
                            <!-- 开具发票 -->
                            <vxe-column width="100" field="invoice_state" title="开具发票">
                                <template #default="{ row }">
                                    <el-link v-if="row.invoice_state == 0" type="warning">未开具发票</el-link>
                                    <el-link v-if="row.invoice_state == 1" type="success">已开具发票</el-link>
                                    <el-link v-if="row.invoice_state == 2" type="info">无需开具发票</el-link>
                                </template>
                            </vxe-column>

                            <!-- 款项类型 -->
                            <vxe-column min-width="80" field="pay_money" title="款项类型">
                                <template #default="{ row }">
                                    <el-link type="danger" v-if="row.pay_type == 0">退款记录</el-link>
                                    <el-link type="primary" v-if="row.pay_type == 1">回款记录</el-link>
                                </template>
                            </vxe-column>

                            <!-- 回款金额 -->
                            <vxe-column min-width="100" field="pay_money" title="款项金额">
                                <template #default="{ row }">
                                    <el-link :type="row.pay_type ? 'primary' : 'danger'">
                                        ￥{{ row.pay_type ? row.pay_money : -row.pay_money }}
                                    </el-link>
                                </template>
                            </vxe-column>

                            <!-- 冲销金额 -->
                            <vxe-column min-width="100" field="pay_money_offset" title="冲销金额">
                                <template #default="{ row }">
                                    <el-link :type="row.pay_type ? 'primary' : 'danger'">
                                        ￥{{ row.pay_type ? row.pay_money_offset : -row.pay_money_offset }}
                                    </el-link>
                                </template>
                            </vxe-column>

                            <!-- 记业绩金额 -->
                            <vxe-column min-width="100" field="pay_money_performance" title="记业绩金额">
                                <template #default="{ row }">
                                    <el-link  :type="row.pay_type ? 'primary' : 'danger'">
                                        ￥{{ row.pay_type ? row.pay_money_performance : -row.pay_money_performance }}
                                    </el-link>
                                </template>
                            </vxe-column>

                            <!-- 款项负责人 -->
                            <vxe-column width="80" field="user.name" title="负责人"></vxe-column>

                            <!-- 回款日期 -->
                            <vxe-column width="100" field="pay_date" title="回款日期"></vxe-column>

                            <vxe-column width="70" title="操作" fixed="right" align="center">
                                <template #default="{ row }">
                                    <el-link @click="SelectChange(row)" type="primary" :underline="false">选择</el-link>
                                </template>
                            </vxe-column>
                        </vxe-table>
                    </div>

                    <!-- pager -->
                    <div class="w-vxe-flex-pager">
                        <vxe-pager 
                            :layouts="['Total', 'Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump',]"
                            :total="page.total"
                            :page-size.sync="page.size"
                            :current-page.sync="page.current"
                            @page-change="TableSearch()">
                        </vxe-pager>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: [String, Array, Number],
            default: null
        },

        contractId: {
            type: [String, Array, Number],
            default: null
        },

        multiple: {
            type: Boolean,
            default: false
        },

        width: {
            type: String,
            default: '100%'
        },

        margin: {
            type: String,
            default: '0px'
        },
    },

    data () {
        return {
            dialogVisible: false,
            dialogLoading: false,

            table: [],

            // 分页
            page: {
                total: 0,
                size: 50,
                current: 1,
            },

            // 检索
            search: {
                invoice_state: 0,
                pay_type: null,
                pay_date_range: null
            },

            // 排序
            order: {
                method: 'desc',
                column: 'id'
            },

            selectObject: {},
            selectName: null
        }
    },

    watch: {
        value: {
            handler(val) {
                if (val) {
                    this.$http.post('/9api/crm/contract-pay-record/search-first', { id: val }).then(rps => {
                        this.selectName =  (rps.data.pay_type == 0 ? '【退款记录】' : '【回款记录】') + 'ID：' + rps.data.id + '，款项日期：' + rps.data.pay_date + '，金额：￥' + (rps.data.pay_type == 0 ? '-' : '') + rps.data.pay_money
                    })
                }
            },
            immediate: true
        },

        contractId: {
            handler(val) {
                this.table = []
                this.selectName = null
                this.$emit('input', null)
                this.$emit('onChange', null)
            },
            immediate: true
        }
    },

    methods: {

        /**
        |--------------------------------------------------
        | 窗口 - 打开动画结束时
        |--------------------------------------------------
        |
        */

        DialogOpend() {
            this.dialogVisible = true
            this.TableSearch()
        },

        /**
        |--------------------------------------------------
        | 表格数据 - 查询
        |--------------------------------------------------
        |
        */

        TableSearch() {
            this.dialogLoading = true
            this.$http.post(
                '/9api/crm/contract-pay-record/search',
                {
                    page: this.page,
                    search: { ...this.search, ...{ contract_id: this.contractId } },
                    order: this.order,
                    append: ['user', 'created_user', 'updated_user']
                }
            ).then((rps) => {
                // 更新数据
                this.page.total = rps.data.total
                this.table = rps.data.body

                // 页码溢出
                if (this.table.length == 0 && this.page.current != 1) {
                    this.page.current = 1
                    this.TableSearch()
                }

                // 切换加载状态
                this.dialogLoading = false
            })
        },

        /**
        |--------------------------------------------------
        | 数据排序
        |--------------------------------------------------
        |
        */

        TableSort(column) {
            this.order.method = column.order ? column.order : this.order.method
            this.order.column = column.field
            this.TableSearch()
        },

        /**
        |--------------------------------------------------
        | 表格数据 - 行双击事件
        |--------------------------------------------------
        |
        */

        TableCellDblclick({ row }) {
            this.SelectChange(row)
        },

        /**
        |--------------------------------------------------
        | 清空选项
        |--------------------------------------------------
        |
        */

        SelectClear() {
            this.selectName = null
            this.selectObject = {}
            this.$emit('input', null)
            this.$emit('onChange', null)
        },

        /**
        |--------------------------------------------------
        | 选择事件
        |--------------------------------------------------
        |
        */

        SelectChange(item) {
            this.selectName =  (item.pay_type == 0 ? '【退款记录】' : '【回款记录】') + 'ID：' + item.id + '，款项日期：' + item.pay_date + '，金额：￥' + (item.pay_type == 0 ? '-' : '') + item.pay_money
            this.selectObject = item
            this.$emit('input', item.id)
            this.$emit('onChange', item)
            this.dialogVisible = false
        },
    }
}
</script>