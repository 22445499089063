<template>
    <el-dialog-menu :visible.sync="visible" :loading="loading" :title="projects.number" :tip="projects.name" fullscreen>

        <template slot="menu">

            <!-- 项目状态 -->
            <div :style="{ marginBottom: '10px' }">
                <el-alert center :closable="false" v-if="projects.status == 0" title="此项目暂未开始执行..." type="error" />
                <el-alert center :closable="false" v-if="projects.status == 1" title="此项目正在进行中..." type="success" />
                <el-alert center :closable="false" v-if="projects.status == 2" title="此项目已暂停..." type="warning" />
                <el-alert center :closable="false" v-if="projects.status == 4" title="此项目关闭归档..." type="info" />
                <el-alert center :closable="false" v-if="projects.status == 5" title="此项目正等待验收..." type="warning" />
                <el-alert center :closable="false" v-if="projects.is_overdue" title="项目已经延期..." type="error" style="margin-top: 5px;" />
            </div>

            <!-- 功能按钮 -->
            <div :style="{ marginBottom: '10px', display: 'grid', gap: '5px', gridTemplateColumns: 'repeat(2, 1fr)' }">
                <el-button :style="{ margin: '0px' }" icon="el-icon-video-play" type="primary" @click="$refs.projectsUpdateStatus.Open(projects.id, 1)" :disabled="!(projects.status == 0 || projects.status == 2)">开始项目</el-button>
                <el-button :style="{ margin: '0px' }" icon="el-icon-video-pause" type="primary" @click="$refs.projectsUpdateStatus.Open(projects.id, 2)" :disabled="projects.status == 2">暂停项目</el-button>
                <el-button :style="{ margin: '0px' }" icon="el-icon-coordinate" type="primary" @click="$refs.projectsUpdateStatus.Open(projects.id, 5)">转待验收</el-button>
                <el-button :style="{ margin: '0px' }" icon="el-icon-folder-checked" type="primary" @click="$refs.projectsUpdateStatus.Open(projects.id, 4)">关闭归档</el-button>
            </div>

            <!-- 项目菜单 -->
            <el-dialog-menu-group title="默认">

                <!-- 项目概况 -->
                <el-dialog-menu-item
                    title="项目概况"
                    icon="el-icon-data-analysis"
                    :is-active="currentComponent == 'pm-projects-drawer-base'"
                    @onClick="currentComponent = 'pm-projects-drawer-base'" />
                
                <!-- 项目描述 -->
                <el-dialog-menu-item
                    title="项目描述"
                    icon="el-icon-reading"
                    :is-active="currentComponent == 'pm-projects-drawer-describe'"
                    @onClick="currentComponent = 'pm-projects-drawer-describe'" />
                
                <!-- 任务管理 -->
                <el-dialog-menu-item
                    title="任务管理"
                    icon="el-icon-star-off"
                    :is-active="currentComponent == 'pm-projects-drawer-task'"
                    @onClick="currentComponent = 'pm-projects-drawer-task'" />
                
                <!-- 工单报障 -->
                <el-dialog-menu-item
                    title="工单报障"
                    icon="el-icon-phone-outline"
                    :is-active="currentComponent == 'pm-projects-drawer-wo'"
                    @onClick="currentComponent = 'pm-projects-drawer-wo'" />

                <!-- 团队管理 -->
                <el-dialog-menu-item
                    title="团队管理"
                    icon="el-icon-user"
                    :is-active="currentComponent == 'pm-projects-drawer-team'"
                    @onClick="currentComponent = 'pm-projects-drawer-team'" />

            </el-dialog-menu-group>

        </template>

        <template slot="footer">
            <el-dialog-menu-item icon="el-icon-edit" title=" 编辑" @onClick="$refs.projectsUpdate.Open(projects.id)" />
            <el-dialog-menu-item icon="el-icon-delete" title=" 删除" @onClick="DataDelete()" />
        </template>

        <!-- 动态组件 -->
        <component :is="currentComponent" :projects_id="projects.id" />

        <!-- 组件 -->
        <pm-projects-update ref='projectsUpdate' @success="RePage()" />
        <pm-projects-update-status ref="projectsUpdateStatus" @success="ProjectsGet()" />

    </el-dialog-menu>
</template>

<script>

export default {

    // 存储数据
    data() {
        return {
            visible: false,
            loading: false,

            currentComponent: 'pm-projects-drawer-base',

            projects: {
                id: null,
                name: null,
                number: null
            },
        }
    },

    // 方法定义
    methods: {

        // 代开窗口
        Open(id) {
            this.visible = true
            this.DataGet(id)
        },

        // 关闭窗口
        Close(done) {
            this.$emit('onChange')
            done()
        },

        // 查询数据
        DataGet(id = projects.id) {
            this.loading = true

            this.$http.post('/9api/projects/search-first', { id: id, hidden: ['describe'] }).then(rps => {
                this.projects = rps.data
                this.loading = false
            })
        },

        // 移除数据
        DataDelete() {
            this.$confirm('此操作将永久删除该项目信息，以及关联的文档、工时、工单、任务等一系列数据，请再三考虑！', '警告', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'error'
            }).then(() => {
                this.$http.post('/9api/pm/projects/delete', { id: this.projects.id }).then(rps => {
                    this.$emit('onChange')
                    this.visible = false
                })
            })
        },
        
    }
}

</script>

