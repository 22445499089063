<template>
    <el-dialog :visible.sync="visible" width="900px" title="新建调拨" append-to-body destroy-on-close @close="Close()" :close-on-click-modal="false" :close-on-press-escape="false">

        <!-- 表单 -->
        <el-form :model="form" size="mini" label-position="top">
            
            <!-- 基本信息 -->
            <el-divider content-position="left"><b>基本信息</b></el-divider>
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="调拨名称" :rules="[{ required: true, message: '必填项' }]" :error="formError.name?.toString()" prop="name">
                        <el-input v-model="form.name" clearable></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="调拨时间" :rules="[{ required: true, message: '必选项' }]" :error="formError.date?.toString()" prop="date">
                        <el-date-picker v-model="form.date" type="date" value-format="yyyy-MM-dd" :style="{ width: '100%' }"></el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="调出仓库" :rules="[{ required: true, message: '必选项' }]" :error="formError.back_warehouse_id?.toString()" prop="back_warehouse_id">
                        <ims-warehouse-select v-model="form.back_warehouse_id" @onChange="form.warehoser_log = []" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="调入仓库" :rules="[{ required: true, message: '必选项' }]" :error="formError.enter_warehouse_id?.toString()" prop="enter_warehouse_id">
                        <ims-warehouse-select v-model="form.enter_warehouse_id" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="备注">
                        <el-input type="textarea" :rows="3" v-model="form.describe"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>

            <!-- 调拨产品 -->
            <el-divider content-position="left"><b>调拨产品</b></el-divider>
            <el-button type="primary" size="mini" @click="$refs.storageSearch.Open({ warehouse_id: form.back_warehouse_id })">添加产品</el-button>
            <vxe-table border ref="xTable" :data="form.warehoser_log" :loading="loading" :style="{ marginTop: '10px' }">
                <vxe-column width="140" field="productName" title="产品名称"></vxe-column>
                <vxe-column width="140" field="supplierName" title="供应商"></vxe-column>
                <vxe-column width="140" field="productParameter" title="规格参数"></vxe-column>
                <vxe-column width="80" field="productUnit" title="计量单位"></vxe-column>
                <vxe-column width="80" field="productNum" title="仓库数量"></vxe-column>
                <vxe-column min-width="140" title="调拨数量" fixed="right">
                    <template #default="{ row }">
                        <el-input-number size="mini" v-model="row.num" :min="0" :precision="2" :controls="false" :style="{ width: '100%' }"></el-input-number>
                    </template>
                </vxe-column>
            </vxe-table>

        </el-form>

        <!-- 页脚 -->
        <div slot="footer">
            <el-button size="small" @click="visible = false">取 消</el-button>
            <el-button size="small" type="primary" @click="FormSubmit()" :loading="loading">确 定</el-button>
        </div>

        <!-- 组件 -->
        <ims-warehouse-storage-search ref="storageSearch" @onChange="UpdateWarehoserLog" />

    </el-dialog>
</template>

<script>
export default {
    data () {
        return {
            visible: false,
            loading: false,

            formError: {},
            form: {
                name: null,
                date: null,
                back_warehouse_id: null,
                enter_warehouse_id: null,
                describe: null,
                warehoser_log: []
            }
        }
    },
    
    methods: {

        // 打开窗口
        Open() {
            this.visible = true
            this.loading = false
        },

        // 关闭窗口
        Close() {
            this.form.name = null
            this.form.date = null
            this.form.back_warehouse_id = null
            this.form.enter_warehouse_id = null
            this.form.describe = null
            this.form.warehoser_log = []
        },

        // 更新出库产品
        UpdateWarehoserLog(data) {
            this.form.warehoser_log = data.map(element => {
                return {
                    productName: element.product.name,
                    productUnit: element.product.unit,
                    productParameter: element.product.parameter,
                    productNum: element.num,
                    supplierName: element.product.supplier.name,
                    product_id: element.product.id,
                    num: element.num,
                }
            })
        },

        // 提交表单
        FormSubmit() {
            // this.loading = true
            this.$http.post('/9api/ims/warehouse-move/create', this.form).then((rps) => {

                // 提交失败
                if (rps.data.state == 'error') {
                    this.formError = rps.data.body
                }

                // 提交成功
                if (rps.data.state == 'success') {
                    this.$emit('onChange', rps.data.body)
                    this.visible = false
                }

                this.loading = false
            })
        },
    }
}
</script>