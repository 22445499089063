<template>
    <el-button type="primary" size="mini" @click="ConfirmOpen()">重置任务工时记录</el-button>
</template>

<script>
export default {
    props: {
        projectId: {
            type: Number,
            default: null,
            require: true
        }
    },

    data () {
        return {
            visible: false
        }
    },

    methods: {
        ConfirmOpen() {
            this.$confirm('建议在工时核算结果不正确时使用<p><strong>通常只有2022年的项目会需要用到此功能</strong><p>此操作会删除原有的工时记录<p>然后根据任务信息重新生成新的工时记录<strong><p>工时会根据任务信息合计在指派人身上</strong>', '提示', {
                dangerouslyUseHTMLString: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                center: true,
                type: 'error'
            }).then(() => {
                this.$http.post('/9api/projects-flow/reset-task', { project_id: this.projectId }).then((rps) => {
                    this.$emit('onChange')
                })
            })
        }
    }
}
</script>