<!---------------------------------------------------------------------------- 
 | 主要菜单
 |----------------------------------------------------------------------------
 | 参数说明
 | title => String => 标题
 |----------------------------------------------------------------------------
 | 插槽
 | default => page内容
 | prefix => header左侧
 | suffix => header右侧
 ---------------------------------------------------------------------------->

 
<template>
    <div class="uw-content">
        <div class="uw-content-header">
            <div class="uw-content-header-main">
                <div class="uw-content-header-left">
                    <div class="uw-content-header-title" v-if="title">
                        <i class="el-icon-monitor"></i> {{ title }}
                    </div>
                    <div class="uw-content-header-solt">
                        <slot name="prefix"></slot>
                    </div>
                </div>
                <div class="uw-content-header-right">
                    <slot name="suffix"></slot>
                </div>
            </div>
        </div>

        <div class="uw-content-box">
            
            <uw-scrollbar>
                <div class="uw-content-page">
                    <slot></slot>
                </div>
            </uw-scrollbar>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: null
        },
    }
}
</script>

<style lang="less" scoped>
.uw-content {
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
    box-sizing: border-box;
    padding: 10px 10px 0 20px;
    display: flex;
    flex-direction: column;

    .uw-content-header {
        z-index: 1;

        .uw-content-header-main{
            height: 50px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .uw-content-header-left {
                display: flex;
                align-items: center;
                
                .uw-content-header-back {
                    font-size: 14px;
                    background: #f5f5f5;
                    color: #9e9e9e;
                    border-radius: 3px;
                    padding: 4px 6px;
                    margin: 0 10px 0 0;
                    cursor: pointer;
                    transition: 500ms;

                    &:hover {
                        background: #E1F5FE;
                    }
                }

                .uw-content-header-title {
                    font-size: 18px;
                    color: #42526e;
                    font-weight: bold;
                }

                .uw-content-header-solt {
                    margin-left: 10px;
                    padding-left: 10px;
                    border-left: 1px solid #EEEEEE;
                }
            }

            .uw-content-header-right {
                display: flex;
            }
        }
    }

    .uw-content-box {
        flex: 1;
        width: 100%;
        position: relative;
        
        .uw-content-page {
            height: 100%;
            width: 100%;
            box-sizing: border-box;
        }
    }
}
</style>