<template>
    <el-drawer :visible.sync="visible" size="80%" direction="rtl" :before-close="Close" :wrapperClosable="false" :show-close="false" append-to-body destroy-on-close>
        
        <div slot="title" :style="{ display: 'flex', justifyContent: 'space-between' }">
            <div class="w-margin-b-10" style="font-size: 18px; color: #000; font-weight: bold;">考核模板</div>
            <div>
                <el-button size="mini" @click="Close()">取消</el-button>
                <el-button size="mini" type="primary" @click="FormSubmit()" :loading="loading">保存</el-button>
            </div>
        </div>
           
        <div :style="{ padding: '0 20px 20px 20px' }">

            <!-- 表单 -->
            <el-form :model="form" label-width="120px" size="mini" label-position="top" >
                
                <!-- 考核模板名称 -->
                <el-row :gutter="20">
                    <el-col :span="6">
                        <el-form-item :rules="[{ required: true, message: '必选项' }]" label="考核模板名称" :error="formError.name?.toString()" prop="name">
                            <el-input v-model="form.name" />
                        </el-form-item>
                    </el-col>
                </el-row>

                <!-- 考核维度 -->
                <el-divider content-position="left"><b>考核维度</b></el-divider>

                <!-- 维度设置 -->
                <el-button type="primary" size="mini" @click="$refs.classCreate.Open()">新增考核维度</el-button>
                
                <!-- 维度权重 -->
                <div :style="{ margin: '10px 0' }">
                    <el-link type="primary" :underline="false">当前维度权重合计：{{ $lodash.array_sum(form.class, 2, 'weight') }} %</el-link>
                </div>

                <!-- 维度内容 -->
                <div v-for="(element, idx) in form.class" :key="idx" :style="{ marginBottom: '20px' }">
                    <vxe-table :data="element.children">
                        <vxe-colgroup>

                            <!-- 维度信息 -->
                            <template #header>
                                <div :style="{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }">
                                    <div :style="{ display: 'flex' }">
                                        <hrm-kpi-template-class-type v-model="element.type" />
                                        <el-link :style="{ marginLeft: '10px' }" :underline="false" type="primary">{{ element.name }}</el-link>
                                        <el-link :style="{ marginLeft: '10px' }" :underline="false">维度权重：</el-link>
                                        <el-link :style="{ marginLeft: '10px' }" :underline="false" type="warning">{{ element.weight }} %</el-link>
                                    </div>
                                    <div>
                                        <el-button type="primary" size="mini" @click="ClassChildrenAdd(element)">添加指标项</el-button>
                                        <el-button type="warning" size="mini" @click="$refs.classUpdate.Open(element)">编辑维度</el-button>
                                        <el-button type="danger" size="mini" @click="form.class.splice(idx, 1)">移除维度</el-button>
                                    </div>
                                </div>
                                <div>{{ element.describe }}</div>
                            </template>

                            <!-- 维度配置 -->
                            <vxe-column min-width="200" title="指标项名称" >
                                <template #default="{ row }">
                                    <el-input v-model="row.name" size="mini"  v-if="element.type == 'custom'" />
                                    <hrm-kpi-goals-type v-model="row.goals" edit   v-if="element.type == 'goals'" />
                                </template>
                            </vxe-column>
                            <vxe-column min-width="200" title="指标说明" :show-overflow="false">
                                <template #default="{ row }">
                                    <el-input type="textarea" autosize v-model="row.describe" size="mini" />
                                </template>
                            </vxe-column>
                            <vxe-column min-width="200" title="考核标准/目标" :show-overflow="false">
                                <template #default="{ row }">
                                    <el-input type="textarea" autosize v-model="row.target" size="mini" :disabled="element.type == 'goals'" />
                                </template>
                            </vxe-column>
                            <vxe-column width="120" title="指标项权重 %" :resizable="false">
                                <template #default="{ row }">
                                    <el-input-number v-model="row.weight" size="mini" :min="0" :max="100" :controls="false" :style="{ width: '100%' }"></el-input-number>
                                </template>
                            </vxe-column>
                            <vxe-column width="120" title="分值占比" :resizable="false">
                                <template #default="{ row }">
                                    {{ (element.weight * row.weight * 0.01) || '自定义' }}
                                </template>
                            </vxe-column>
                            <vxe-column width="60" title="操作" align="center" :resizable="false">
                                <template #default="{ rowIndex }">
                                    <el-link @click="element.children.splice(rowIndex, 1)" type="danger">移除</el-link>
                                </template>
                            </vxe-column>
                        </vxe-colgroup>
                    </vxe-table>
                </div>

                <!-- 考核流程 -->
                <el-divider content-position="left"><b>考核流程</b></el-divider>

                <!-- 流程设置 -->
                <el-dropdown @command="FlowCreate">
                    <el-button type="primary" size="mini">
                        新增考核流程<i class="el-icon-arrow-down el-icon--right"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="me">被考核人</el-dropdown-item>
                        <el-dropdown-item command="leader">直属领导考核</el-dropdown-item>
                        <el-dropdown-item command="he">指定考核人员</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>

                <!-- 维度权重 -->
                <div :style="{ margin: '10px 0' }">
                    <el-link type="primary" :underline="false">当前维度权重合计：{{ $lodash.array_sum(form.flow, 2, 'weight') }} %</el-link>
                </div>

                <!-- 流程内容 -->
                <vxe-table :data="form.flow" v-if="form.flow.length > 0">
                    <vxe-column min-width="80" title="考核人" >
                        <template #default="{ row }">
                            <span v-if="row.type == 'me'">被考核人</span>
                            <span v-if="row.type == 'leader'">直属领导考核</span>
                            <users-select v-model="row.users_id" v-if="row.type == 'he'"></users-select>
                        </template>
                    </vxe-column>
                    <vxe-column min-width="80" title="评分权重 %" >
                        <template #default="{ row }">
                            <el-input-number v-model="row.weight" size="mini" :min="0" :max="100" :controls="false" :style="{ width: '100%' }"></el-input-number>
                        </template>
                    </vxe-column>
                    <vxe-column min-width="80" title="评分方式" >
                        <template #default>
                            直接评分
                        </template>
                    </vxe-column>
                    <vxe-column min-width="80" title="评语设置" >
                        <template #default="{ row }">
                            <el-switch v-model="row.appraise_switch" active-text="开启" active-color="#0027ff" inactive-text="关闭" inactive-color="#ff4949"></el-switch>
                        </template>
                    </vxe-column>
                    <vxe-column min-width="80" title="评语可见" >
                        <template #default="{ row }">
                            <el-switch v-model="row.appraise_show" active-text="他人可见" active-color="#0027ff" inactive-text="仅自己" inactive-color="#ff4949"></el-switch>
                        </template>
                    </vxe-column>
                    <vxe-column min-width="80" title="驳回权限" >
                        <template #default="{ row }">
                            <el-switch v-model="row.reject_switch" active-text="可驳回" active-color="#0027ff" inactive-text="不可驳回" inactive-color="#ff4949"></el-switch>
                        </template>
                    </vxe-column>
                    <vxe-column min-width="60" title="操作" align="center">
                        <template #default="{ rowIndex }">
                            <el-link @click="form.flow.splice(rowIndex, 1)" type="danger">移除</el-link>
                        </template>
                    </vxe-column>
                </vxe-table>

                <!-- 考核结果 -->
                <el-divider content-position="left"><b>考核结果</b></el-divider>

                <!-- 结果设置 -->
                <el-button type="primary" size="mini" @click="ResultCreate()">新增配置</el-button>

                <!-- 结果内容 -->
                <vxe-table :data="form.result" v-if="form.result.length > 0" :style="{ marginTop: '10px' }">
                    <vxe-column min-width="80" title="等级" >
                        <template #default="{ row }">
                            <el-input size="mini" v-model="row.level" />
                        </template>
                    </vxe-column>
                    <vxe-column min-width="80" title="最低分" >
                        <template #default="{ row }">
                            <el-input-number v-model="row.min" size="mini" :min="0" :max="100" :controls="false" :style="{ width: '100%' }"></el-input-number>
                        </template>
                    </vxe-column>
                    <vxe-column min-width="80" title="最高分" >
                        <template #default="{ row }">
                            <el-input-number v-model="row.max" size="mini" :min="0" :max="100" :controls="false" :style="{ width: '100%' }"></el-input-number>
                        </template>
                    </vxe-column>
                    <vxe-column min-width="80" title="系数" >
                        <template #default="{ row }">
                            <el-input-number v-model="row.weight" size="mini" :min="0" :max="100" :controls="false" :style="{ width: '100%' }"></el-input-number>
                        </template>
                    </vxe-column>
                    <vxe-column min-width="60" title="操作" align="center">
                        <template #default="{ rowIndex }">
                            <el-link @click="form.result.splice(rowIndex, 1)" type="danger">移除</el-link>
                        </template>
                    </vxe-column>
                </vxe-table>

            </el-form>

        </div>

        <!-- 组件 -->
        <hrm-kpi-template-class-create ref="classCreate" @onChange="ClassCreate" />
        <hrm-kpi-template-class-update ref="classUpdate" />

    </el-drawer>
</template>
    
<script>
export default {
    data() {
        return {
            visible: false,

            formError: {},
            form: {
                name: null,
                class: [],
                flow: [],
                result: []
            }
        }
    },

    computed: {
        loading () {
            return !!this.$store.state.httpRequestCount
        }
    },
    
    methods: {

        // 窗口 - 打开
        Open() {
            this.visible = true
        },

        // 窗口 - 关闭
        Close() {
            this.formError = {}
            this.form.name = null
            this.form.class = []
            this.form.flow = []
            this.form.result = []
            this.visible = false
        },

        // 维度：创建
        ClassCreate(data)
        {
            this.form.class.push({ ...data, children: []})
        },

        // 指标项: 创建
        ClassChildrenAdd(element)
        {
            element.children.push({ name: null, goals: null, describe: null, target: null, weight: 0, appraise: null, score: 0 })
        },

        // 考核流程：创建
        FlowCreate(type) 
        {
            this.form.flow.push({ type: type, users_id: null, weight: 0, appraise: null, appraise_switch: false, appraise_show: false, reject: null, reject_switch: false })
        },

        // 考核结果：创建
        ResultCreate()
        {
            this.form.result.push({ level: null, min: 0, max: 0, weight: 1 })
        },

        // 表单提交
        FormSubmit() {
            this.$http.post('/9api/hrm/kpi-template/create', this.form).then(rps => {
                if (rps.data.state == 'error') {
                    this.formError = rps.data.body
                }
                if (rps.data.state == 'success') {
                    this.$emit('onChange', rps.data)
                    this.Close()
                }
            })
        }
    }
}
</script>