<template>
    <el-drawer :visible.sync="visible" size="80%" direction="rtl" append-to-body destroy-on-close :show-close="false" :before-close="Close">

        <div slot="title">

            <!-- 合同状态 -->
            <div class="w-margin-b-10">
                <span>{{ pay.model == 'ims_product_order' ? '付款单' : '回款单' }} - </span>
                <span v-if="pay.workflow_state == 0" >不需要审批</span>
                <span v-if="pay.workflow_state == 1" >等待审批</span>
                <span v-if="pay.workflow_state == 2" >正在审核中</span>
                <span v-if="pay.workflow_state == 3" >审批结果:已被拒绝</span>
                <span v-if="pay.workflow_state == 4" >审批结果:已通过</span>
                <span v-if="pay.workflow_state == 5" >等待撤销</span>
                <span v-if="pay.workflow_state == 6" >正在撤销中</span>
                <span v-if="pay.workflow_state == 7" >撤销结果:已被拒绝</span>
                <span v-if="pay.workflow_state == 8" >撤销结果:已同意撤销</span>
            </div>
            
            <div class="w-margin-b-10" style="font-size: 18px; color: #000; font-weight: bold;">{{ pay.number }}</div>

            <!-- 功能按钮 -->
            <div style="display: flex;">
                <!-- 审批组件 -->
                <workflow-approve-button class="w-margin-r-10" ref="dialogApprove" v-model="pay.workflow_id" @onChange="RePage()" />
                <el-button size="mini" icon="el-icon-edit" @click="RePage()">更新信息</el-button>
                <el-button style="margin-left: 5px;" size="mini" @click="DataDelete()" type="danger">移除</el-button>
            </div>

            <!-- 金额概览 -->
            <div class="w-margin-t-10" style="background-color: #f4f5f7; border-radius: 3px; padding: 15px; display: flex;">
                <el-statistic title="金额"><template slot="formatter"><span style="font-size: 16px;"><b>￥{{ pay.money }}</b></span></template></el-statistic>
                <el-statistic title="方式"><template slot="formatter"><span style="font-size: 14px;">{{ pay.type.name }}</span></template></el-statistic>
                <el-statistic title="日期"><template slot="formatter"><span style="font-size: 14px;">{{ pay.date }}</span></template></el-statistic>
                <el-statistic title="创建人"><template slot="formatter"><span style="font-size: 14px;">{{ pay.created_user.name }}</span></template></el-statistic>
                <el-statistic title="创建时间"><template slot="formatter"><span style="font-size: 14px;">{{ pay.created_at }}</span></template></el-statistic>
            </div>

        </div>

        <div class="w-padding-l-20 w-padding-r-20 w-padding-b-20">
            <el-tabs v-model="tabs">

                <!-- 基础信息 -->
                <el-tab-pane label="基础信息" name="base">
                    
                    <!-- 订单信息 -->
                    <el-descriptions title="订单信息" direction="vertical" size="mini" border :column="2">
                        <el-descriptions-item label="编号">{{ pay.number }}</el-descriptions-item>
                        <el-descriptions-item label="日期">{{ pay.date }}</el-descriptions-item>
                        <el-descriptions-item label="金额">{{ pay.money }}</el-descriptions-item>
                        <el-descriptions-item label="方式">{{ pay.type.name }}</el-descriptions-item>
                        <el-descriptions-item label="备注">{{ pay.describe ?? '无' }}</el-descriptions-item>
                    </el-descriptions>

                    <!-- 系统信息 -->
                    <el-descriptions title="系统信息" direction="vertical" size="mini" border :column="2" :style="{ marginTop: '20px' }">
                        <el-descriptions-item label="创建时间">{{ pay.created_at }}</el-descriptions-item>
                        <el-descriptions-item label="创建人">{{ pay.created_user.name }}</el-descriptions-item>
                        <el-descriptions-item label="更新时间">{{ pay.updated_at }}</el-descriptions-item>
                        <el-descriptions-item label="更新人">{{ pay.updated_user.name }}</el-descriptions-item>
                    </el-descriptions>

                </el-tab-pane>

                <!-- 附件 -->
                <el-tab-pane label="附件" name="files">
                    <ims-pay-upload-files :id="pay.id" @onChange="RePage" />
                    <files-table v-model="pay.files_id" />
                </el-tab-pane>

            </el-tabs>
        </div>

        <!-- 组件 -->
        <crm-contract-drawer ref="contractDrawer" />

    </el-drawer>
</template>

<script>
export default {
    data() {
        return {
            visible: false,
            loading: false,
            tabs: 'base',

            pay: {
                id: null,
                files_id: [],
                type: {},
                created_user: {},
                updated_user: {},
            }
        }
    },

    methods: {

        /**
        |--------------------------------------------------
        | 打开窗口
        |--------------------------------------------------
        |
        */

        Open(id) {
            this.visible = true
            this.DataGet(id)
        },

        /**
        |--------------------------------------------------
        | 关闭窗口
        |--------------------------------------------------
        |
        */

        Close(done) {
            this.$emit('onChange')
            done()
        },

        /**
        |--------------------------------------------------
        | 刷新
        |--------------------------------------------------
        |
        */

        RePage() {
            this.DataGet()
            this.$refs.dialogApprove.Update()
        },

        /**
        |--------------------------------------------------
        | 查询
        |--------------------------------------------------
        |
        */

        DataGet(id = this.pay.id) {
            this.loading = true

            this.$http.post(
                '/9api/ims/pay/search-first',
                {
                    search: { id: id },
                    append: ['created_user', 'updated_user', 'type']
                }
            ).then(rps => {
                this.pay = rps.data
                this.loading = false
            }) 
        },

        /**
        |--------------------------------------------------
        | 删除
        |--------------------------------------------------
        |
        */

        DataDelete() {
            this.$confirm('一旦删除将无法恢复，移除内容包含以下：(支付记录、关联附件)', '警告', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'error'
            }).then(() => {
                this.$http.post('/9api/ims/pay/delete', { id: this.pay.id }).then((rps) => {
                    this.$emit('onChange')
                    this.visible = false
                })
            })
        },
        
    }
}

</script>6
