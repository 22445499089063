<!---------------------------------------------------------------------------- 
 | 工单 - 批量转交
 |----------------------------------------------------------------------------
 | 方法：请注意大小写,参数描述见具体方法
 | $refs[ref_name].Open(*ids) 手动打开窗口,
 | $refs[ref_name].Close() 手动关闭窗口
 |----------------------------------------------------------------------------
 | 回调
 | @onChange = fn () => {} 成功时回调
 |
 ---------------------------------------------------------------------------->

 <template>
    <el-drawer class="w-el-drawer" :visible.sync="visible" :size="960" :with-header="false" direction="rtl" :before-close="Close" append-to-body>

        <!-- header -->
        <div class="w-el-drawer-header">
            <i class="el-icon-tickets"></i> 批量修改收单人
        </div>
        
        <!-- body -->
        <div class="w-el-drawer-body" v-loading.lock="loading">
            <el-form :model="form" label-width="120px" size="mini">

                <!-- 提示A -->
                <el-alert title="消息提示的文案" type="info">
                    <template slot="title">
                        <b>A：</b>您正在尝试修改<b> " {{ ids.length }} " </b>项工单。
                    </template>
                </el-alert>

                <!-- 提示B -->
                <el-alert title="消息提示的文案" type="info" class="w-margin-t-5">
                    <template slot="title">
                        <b>B：</b>只会修改属于您相关的工单
                    </template>
                </el-alert>

                <el-divider content-position="left">批量修改收单人</el-divider>

                <!-- 收单人 -->
                <el-form-item label="收单人" :rules="[{ required: true, message: '请选择收单人' }]" :error="formError.user_receive_id?.toString()" prop="user_receive_id">
                    <users-select v-model="form.user_receive_id" />
                </el-form-item>

                <!-- 协同人 -->
                <el-form-item label="协同人">
                    <users-select v-model="form.user_assist_id" multiple />
                </el-form-item>
            </el-form>
        </div>

        <!-- footer -->
        <div class="w-el-drawer-footer">
            <el-button size="mini" @click="Close()">取消</el-button>
            <el-button size="mini" type="primary" @click="SubmitForm()" :loading="loading">确 定</el-button>
        </div>

    </el-drawer>
</template>

<script>
export default {
    data() {
        return {
            // 窗口
            visible: false,
            loading: false,

            // 任务信息
            ids: [],

            // 表单信息
            formError: {},
            form: {
                user_receive_id: null,
                user_assist_id: null
            },
        }
    },

    methods: {

        /**
        |--------------------------------------------------
        | 窗口 - 打开
        |--------------------------------------------------
        |
        */

        Open(ids) {
            this.visible = true
            this.ids = ids

            this.formError = {}
            this.form.user_receive_id = null
            this.form.user_assist_id = null
        },

        /**
        |--------------------------------------------------
        | 窗口 - 关闭
        |--------------------------------------------------
        |
        */

        Close() {
            this.loading = false
            this.visible = false
        },


        /**
        |--------------------------------------------------
        | 修改收单人
        |--------------------------------------------------
        |
        */

        SubmitForm() {
            this.$http.post('/9api/wo/update-user-receive-multiple', { ids: this.ids, ...this.form }).then(rps => {
                this.$emit('onChange')
                this.Close()
            })
        }
    }
}
</script>