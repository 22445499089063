<!---------------------------------------------------------------------------- 
 | Card 卡片
 |----------------------------------------------------------------------------
 | 参数说明
 | title => String => 标题
 | subtitle => String => 副标题
 | padding => String => 内容边距
 | show-header => Boolean => 不显示头部
 | show-footer => Boolean => 不显示底部
 |----------------------------------------------------------------------------
 | 插槽
 | title => 标题
 | header => 头部
 | default => 内容
 | footer-start => 底部左侧
 | footer-end => 底部右侧
 ---------------------------------------------------------------------------->

<template>
    <div class="uw-card">
        
        <div class="uw-card-header" v-if="!showHeader" :style="{ padding: subtitle ? '8px 20px' : '16px 20px' }">

            <!-- 标题 -->
            <slot name="title">
                <div class="uw-card-header-title">
                    <span>{{ title }}</span>
                    <span class="uw-card-header-subtitle">{{ subtitle }}</span>
                </div>
            </slot>

            <!-- header插槽 -->
            <div>
                <slot name="header"></slot>
            </div>
        </div>

        <div class="uw-card-body" :style="{ padding: padding }">
            <slot></slot>
        </div>

        <div class="uw-card-footer" v-if="!showFooter" >
            <div><slot name="footer-start"></slot></div>
            <div><slot name="footer-end"></slot></div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        padding: {
            type: String,
            default: '20px'
        },

        title: {
            type: String,
            default: null
        },

        subtitle: {
            type: String,
            default: null
        },

        showHeader: {
            type: Boolean,
            default: false
        },

        showFooter: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="less" scoped>
    .uw-card {
        background-color: #FFFFFF;
        box-shadow: 0px 0px 6px 2px #f5f5f5;
        border-radius: 5px;

        .uw-card-header {
            border-bottom: 1px solid #F5F5F5;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .uw-card-header-title {
                font-size: 14px;
                display: flex;
                flex-direction: column;

                .uw-card-header-subtitle {
                    font-size: 12px;
                    color: #9e9e9e;
                    font-style: italic;
                }
            }
        }

        .uw-card-body {

        }

        .uw-card-footer {
            padding: 15px 20px;
            border-top: 1px solid #F5F5F5;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
</style>