<!---------------------------------------------------------------------------- 
 | 项目任务
 |----------------------------------------------------------------------------
 | 方法：请注意大小写,参数描述见具体方法
 | *v-model     默认值------默认为 null
 | *projects_id 默认值------默认为 null
 | width        表单宽度----默认为 ‘100%’
 | margin       表单外边距--默认为 ‘0px’
 |----------------------------------------------------------------------------
 | 回调
 | @onChange = fn () => {} 返回表单内容
 |
 ---------------------------------------------------------------------------->

 <template>
    <div>
        <el-input
            v-model="selectName"
            @focus="DialogOpend"
            @clear="SelectClear"
            size="mini"
            placeholder="请选择任务"
            clearable
            :style="{ width: width, margin: margin }"/>

        <el-dialog :visible.sync="dialogVisible" class="w-el-dialog" width="900px" append-to-body title="选择任务（双击选择）" :close-on-click-modal="false" :close-on-press-escape="false">
            
            <!-- 表容器 -->
            <div class="w-vxe-fullscreen w-padding-none" style="height: 600px;">
                <div class="w-vxe-flex" style="border-radius: 0; box-shadow: none; padding: 0;">

                    <!-- 表格 -->
                    <uw-table>

                        <!-- 检索 -->
                        <template slot="search">
                            <el-input @input="TableSearch()" v-model="search.name" clearable  size="mini" placeholder="任务名称"></el-input>
                        </template>

                        <!-- 数据 -->
                        <template slot="data">
                            <vxe-table
                                ref="xTable"
                                height="100%"
                                :data="table"
                                :loading="dialogLoading"
                                :row-config="{keyField: 'id'}"
                                :tree-config="{transform: true, childrenField: 'children', rowField: 'id', parentField: 'parent', indent: 10, reserve: true}"
                                @cell-dblclick="TableCellDblclick">
                        
                                <!-- 重要程度 -->
                                <vxe-column width="120" field="p" title="重要程度">
                                    <template #default="{ row }">
                                        <el-tag v-if="row.p == 1" size="small" effect="plain" type="danger">P1 非常重要</el-tag>
                                        <el-tag v-if="row.p == 2" size="small" effect="plain" type="warning">P2 重要</el-tag>
                                        <el-tag v-if="row.p == 3" size="small" effect="plain" type="primary">P3 次要</el-tag>
                                        <el-tag v-if="row.p == 4" size="small" effect="plain" type="success">P4 一般</el-tag>
                                    </template>
                                </vxe-column>

                                <!-- 任务阶段 -->
                                <vxe-column width="140" field="status" title="任务阶段">
                                    <template #default="{ row }">
                                        <el-tag size="small" v-if="row.status == 0" effect="plain" type="primary">未开始</el-tag>
                                        <el-tag size="small" v-if="row.status == 1" effect="plain" type="primary">进行中</el-tag>
                                        <el-tag size="small" v-if="row.status == 2" effect="plain" type="warning">已暂停</el-tag>
                                        <el-tag size="small" v-if="row.status == 4" effect="plain" type="info">已关闭</el-tag>
                                        <el-tag size="small" v-if="row.status == 5" effect="plain" type="success">已完成</el-tag>
                                        <el-tag size="small" v-if="row.status == 6" effect="plain" type="info">已取消</el-tag>
                                        <el-tag size="small" v-if="row.is_overdue == 1" effect="plain" type="danger" style="margin-left: 5px;">已延期</el-tag>
                                    </template>
                                </vxe-column>

                                <!-- 任务名称 -->
                                <vxe-column min-width="200" field="name" title="任务名称" tree-node>
                                    <template #default="{ row }">
                                        <el-link :style="{ color: row.name_color }" @click="$refs.taskIndex.Open(row.id)">{{ row.name }}</el-link>
                                    </template>
                                </vxe-column>

                                <!-- 指派给 -->
                                <vxe-column width="90" field="now_assign_user_name" title="指派给"></vxe-column>

                            </vxe-table>
                        </template>

                        <!-- 分页 -->
                        <template slot="pager">
                            <vxe-pager :total="page.total" :page-size.sync="page.size" :current-page.sync="page.current" @page-change="TableSearch()"></vxe-pager>
                        </template>

                    </uw-table>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: [String, Array, Number],
            default: null
        },

        projectsId: {
            type: Number,
            default: null
        },

        width: {
            type: String,
            default: '100%'
        },

        margin: {
            type: String,
            default: '0px'
        },
    },

    data () {
        return {
            dialogVisible: false,
            dialogLoading: false,

            table: [],

            // 分页
            page: {
                total: 0,
                size: 50,
                current: 1,
            },

            // 检索
            search: {
                is_overdue: null,
                number: null,
                name: null,
            },

            // 排序
            order: {
                method: 'desc',
                column: 'id'
            },

            selectObject: {},
            selectName: null
        }
    },

    watch: {
        value: {
            handler(val) {
                if (val) {
                    this.$http.post(
                        '/9api/projects-task/search-first', { id: val }
                    ).then(rps => {
                        this.selectName =  rps.data.name
                    })
                } else {
                    this.selectObject = {}
                    this.selectName = null
                }
            },
            immediate: true
        },
    },

    methods: {

        /**
        |--------------------------------------------------
        | 窗口 - 打开动画结束时
        |--------------------------------------------------
        |
        */

        DialogOpend() {
            this.dialogVisible = true
            this.TableSearch()
        },

        /**
        |--------------------------------------------------
        | 表格数据 - 查询
        |--------------------------------------------------
        |
        */

        TableSearch() {
            this.dialogLoading = true
            this.$http.post(
                '/9api/projects-task/search',
                {
                    project_id: this.projectsId,
                    page: this.page,
                    search: this.search,
                    order: this.order,
                    append: ['assign_user_object', 'assign_user_name', 'now_assign_user_name', 'complete_user_name', 'create_user_name', 'cancel_user_name', 'close_user_name', 'update_user_name' ]
                },
            ).then((rps) => {
                // 更新数据
                this.page.total = rps.data.total
                this.table = rps.data.body

                // 页码溢出
                if (this.table.length == 0 && this.page.current != 1) {
                    this.page.current = 1
                    this.TableSearch()
                }

                // 切换加载状态
                this.dialogLoading = false
            })
        },

        /**
        |--------------------------------------------------
        | 数据排序
        |--------------------------------------------------
        |
        */

        TableSort(column) {
            this.order.method = column.order ? column.order : this.order.method
            this.order.column = column.field
            this.TableSearch()
        },

        /**
        |--------------------------------------------------
        | 表格数据 - 行双击事件
        |--------------------------------------------------
        |
        */

        TableCellDblclick({ row }) {
            this.SelectChange(row)
        },

        /**
        |--------------------------------------------------
        | 清空选项
        |--------------------------------------------------
        |
        */

        SelectClear() {
            this.selectName = null
            this.selectObject = {}
            this.$emit('input', null)
            this.$emit('onChange', null)
        },

        /**
        |--------------------------------------------------
        | 选择事件
        |--------------------------------------------------
        |
        */

        SelectChange(item) {
            this.selectName =  item.name
            this.selectObject = item
            this.$emit('input', item.id)
            this.$emit('onChange', item)
            this.dialogVisible = false
        },
    }
}
</script>