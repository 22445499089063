<!---------------------------------------------------------------------------- 
 | CRM - 商机 - 详情
 |----------------------------------------------------------------------------
 | 方法：请注意大小写,参数描述见具体方法
 | $refs[ref_name].Open() 手动打开窗口,
 | $refs[ref_name].Close() 手动关闭窗口
 |----------------------------------------------------------------------------
 | 回调
 | @onChange = fn (Object) => {}
 |
 ---------------------------------------------------------------------------->

 <template>
    <el-drawer :visible.sync="visible" size="80%" direction="rtl" append-to-body destroy-on-close :show-close="false" :before-close="Close">
        
        <div slot="title">

            <!-- 商机状态 -->
            <div class="w-margin-b-10">
                商机 - 
                <span v-if="contract.draft">商机</span>
                <span v-if="business.state == 0" >放弃</span>
                <span v-if="business.state == 1" >跟进</span>
                <span v-if="business.state == 2" >成功</span>
                <span v-if="business.state == 3" >失败</span>
                <span v-if="business.state == 4" >搁置</span>
            </div>

            <!-- 合同编号及名称 -->
            <div class="w-margin-b-10" style="font-size: 18px; color: #000; font-weight: bold;">{{ business.number }} - {{ business.name }}</div>

            <!-- 功能按钮 -->
            <div style="display: flex;">
                <el-button-group >
                    <el-button size="mini" @click="DocumentaryUpdateState(1)">跟 进</el-button>
                    <el-button size="mini" @click="DocumentaryUpdateState(4)">搁 置</el-button>
                    <el-button size="mini" @click="DocumentaryUpdateState(3)">失 败</el-button>
                    <el-button size="mini" @click="DocumentaryUpdateState(0)">放 弃</el-button>
                </el-button-group>

                <el-button style="margin-left: 5px;" size="mini" icon="el-icon-edit" @click="$refs.drawerUpdate.Open(business_id)">修改商机</el-button>
                <el-button style="margin-left: 5px;" size="mini" icon="el-icon-refresh" @click="DocumentaryGet()">刷新</el-button>
                <el-button style="margin-left: 5px;" size="mini" icon="el-icon-delete" type="danger" @click="DocumentaryDelete()" :loading="loading">删除</el-button>

            </div>

            <!-- 金额概览 -->
            <div class="w-margin-t-10 w-margin-b-10" style="background-color: #f4f5f7; border-radius: 3px; padding: 15px; display: flex;">
                <el-statistic style="line-height: 2;" group-separator="," :precision="2" :value="business.sign_money - 0" title="商机金额"></el-statistic>
                <el-statistic style="line-height: 2;" group-separator="," :precision="2" :value="business.estimate_money - 0" title="预估成本"></el-statistic>
                <el-statistic style="line-height: 2;" group-separator="," :precision="2" :value="business.sign_money - business.estimate_money" title="预估利润 / 元"></el-statistic>
                <el-statistic style="line-height: 2;" title="预估利润 / % "><template slot="formatter">{{ ((business.sign_money - business.estimate_money)/business.sign_money*100).toFixed(2) }}%</template></el-statistic>
                <el-statistic style="line-height: 2;" title="负责人"><template slot="formatter"><b>{{ business.user_admin.name }}</b></template></el-statistic>
            </div>

            <crm-business-progress-panel :id="business.id" :progress="business.progress" :end-show="!!business.contract_id" :edit="[business.user_sale.id, business.user_affair.id, business.user_presale.id, business.user_admin.id ].includes($store.state.user.id)"  />

        </div>

        <div class="w-padding-l-20 w-padding-r-20 w-padding-b-20">
            <el-tabs v-model="tabs">

                <!-- 详细资料 -->
                <el-tab-pane label="详细资料" name="details">

                    <el-descriptions title="基本信息" :column="2" class="w-margin-b-20" size="mini">

                        <!-- 商机编号、商机名称 -->
                        <el-descriptions-item label="商机编号">{{ business.number }}</el-descriptions-item>
                        <el-descriptions-item label="商机名称">{{ business.name }}</el-descriptions-item>

                        <!-- 商机状态 -->
                        <el-descriptions-item label="商机状态">
                            <el-tag size="mini" effect="dark" v-if="business.state == 0" type="danger">放弃</el-tag>
                            <el-tag size="mini" effect="dark" v-if="business.state == 1" type="primary">跟进</el-tag>
                            <el-tag size="mini" effect="dark" v-if="business.state == 2" type="success">成功</el-tag>
                            <el-tag size="mini" effect="dark" v-if="business.state == 3" type="danger">失败</el-tag>
                            <el-tag size="mini" effect="dark" v-if="business.state == 4" type="warning">搁置</el-tag>
                        </el-descriptions-item>

                        <!-- 机会来源 -->
                        <el-descriptions-item label="机会来源">
                            <el-tag size="mini" class="w-margin-r-5" v-for="(item, idx) in business.source" :key="idx">{{ item }}</el-tag>
                        </el-descriptions-item>

                        <!-- 工作进度 -->
                        <el-descriptions-item label="工作进度">
                            <el-tag size="mini" class="w-margin-r-5" v-for="(item, idx) in business.progress.filter(v => v.value > 0)" :key="idx">{{ item.title }}</el-tag>
                        </el-descriptions-item>

                        <!-- 客户名称 -->
                        <el-descriptions-item label="客户名称">
                            <el-link type="primary">{{ business.customer.name }}</el-link>
                        </el-descriptions-item>

                        <!-- 关联合同 -->
                        <el-descriptions-item label="关联合同">
                            <el-link type="primary" @click="$refs.contractDrawer.Open(business.contract_id)" v-if="this.business.contract_id">{{ contract.number + '-' + contract.name }}</el-link>
                            <el-link type="info" :underline="false" v-if="!this.business.contract_id">未关联</el-link>
                        </el-descriptions-item>

                        <!-- 抄送信息 -->
                        <el-descriptions-item label="抄送人">
                            <el-tag size="mini" class="w-margin-r-5" v-for="(item, idx) in business.notify_users" :key="idx">{{ item.name }}</el-tag>
                            <el-link type="info" :underline="false" v-if="business.notify_users.length == 0">未抄送</el-link>
                        </el-descriptions-item>

                        <!-- 其它 -->
                        <el-descriptions-item label="销售">{{ business.user_sale.name }}</el-descriptions-item>
                        <el-descriptions-item label="商务">{{ business.user_affair.name }}</el-descriptions-item>
                        <el-descriptions-item label="售前">{{ business.user_presale.name }}</el-descriptions-item>
                        <el-descriptions-item label="管理员">{{ business.user_admin.name }}</el-descriptions-item>
                        <el-descriptions-item label="跟进日期">{{ business.updated_at }}</el-descriptions-item>
                        <el-descriptions-item label="创建日期">{{ business.created_at }}</el-descriptions-item>
                        
                    </el-descriptions>

                    <el-descriptions title="其它信息" :column="2" class="w-margin-b-20" size="mini">
                        <el-descriptions-item label="业务范围">
                            <crm-business-link-tender-range v-model="business.tender_range" :business_id="business.id" update />
                        </el-descriptions-item>
                        <el-descriptions-item label="报名费用">
                            <crm-business-link-tender-money v-model="business.tender_money" :business_id="business.id" update />
                        </el-descriptions-item>
                    </el-descriptions>

                    <el-descriptions title="成本预估" :column="2" class="w-margin-b-20" size="mini">
                        <el-descriptions-item label="预估工时（天）">{{ business.estimate_work_day }}</el-descriptions-item>
                        <el-descriptions-item label="预估成本（元）">{{ business.estimate_money?.toLocaleString() }}</el-descriptions-item>
                    </el-descriptions>

                    <el-descriptions title="商机金额（细分）" :column="2" class="w-margin-b-20" size="mini">
                        <el-descriptions-item label="集成费用（元）">{{ business.sign_money_jc?.toLocaleString() }}</el-descriptions-item>
                        <el-descriptions-item label="运维费用（元）">{{ business.sign_money_yw?.toLocaleString() }}</el-descriptions-item>
                        <el-descriptions-item label="安服费用（元）">{{ business.sign_money_af?.toLocaleString() }}</el-descriptions-item>
                        <el-descriptions-item label="工程费用（元）">{{ business.sign_money_gc?.toLocaleString() }}</el-descriptions-item>
                        <el-descriptions-item label="设备费用（元）">{{ business.sign_money_sb?.toLocaleString() }}</el-descriptions-item>
                        <el-descriptions-item label="开发费用（元）">{{ business.sign_money_kf?.toLocaleString() }}</el-descriptions-item>
                        <el-descriptions-item label="过单费用（元）">{{ business.sign_money_gd?.toLocaleString() }}</el-descriptions-item>
                        <el-descriptions-item label="其它费用（元）">{{ business.sign_money_qt?.toLocaleString() }}</el-descriptions-item>
                        <el-descriptions-item label="外采费用（元）">{{ business.sign_money_sw?.toLocaleString() }}</el-descriptions-item>
                        <el-descriptions-item label="费用统计（元）"><b>{{ business.sign_money?.toLocaleString() }}</b></el-descriptions-item>
                    </el-descriptions>
                </el-tab-pane>

                <!-- 待办任务 -->
                <el-tab-pane label="待办任务" name="task">
                    <crm-business-task-table :business_id="business.id" v-if="tabs == 'task'" />
                </el-tab-pane>

                <!-- 描述 -->
                <el-tab-pane label="描述" name="describe">
                    <el-empty  v-if="!business.describe" description="暂无描述"></el-empty>
                    <div v-if="business.describe" style="background-color: #f5f5f5; box-sizing: border-box; padding: 10px; border-radius: 5px;">
                        <div v-html="business.describe" class="quill-html"></div>
                    </div>
                </el-tab-pane>

                <!-- 附件 -->
                <el-tab-pane label="附件" name="files">
                    <files-table v-if="business.files_id.length > 0" v-model="business.files_id" />
                    <el-empty  v-if="business.files_id.length == 0" description="暂无附件"></el-empty>
                </el-tab-pane>

                <!-- 团队成员 -->
                <el-tab-pane label="团队成员" name="a">
                    <el-empty description="待完善"></el-empty>
                </el-tab-pane>

                <!-- 金额比例设置 -->
                <el-tab-pane label="金额比例设置" name="b">
                    <el-empty description="待完善"></el-empty>
                </el-tab-pane>

                <!-- 联系人 -->
                <el-tab-pane label="联系人" name="c">
                    <el-empty description="待完善"></el-empty>
                </el-tab-pane>
                
            </el-tabs>
        </div>

        <!-- 组件 -->
        <crm-business-update ref="drawerUpdate" @success="DocumentaryUpdate" />
        <crm-contract-drawer ref="contractDrawer" @onChange="DocumentaryUpdate" />

    </el-drawer>
</template>
    
<script>
import tableFiles from '@/components/files/table'

export default {
    components: {
        tableFiles,
    },
    
    data() {
        return {
            visible: false,
            loading: false,
            tabs: 'details',

            business_id: null,
            business: {
                progress: [],
                source: [],
                customer: {},
                user_sale: {},
                user_affair: {},
                user_presale: {},
                user_admin: {},
                notify_users: [],
                files_id: []
            },
            contract: {}
        }
    },
    
    methods: {
        /**
        |--------------------------------------------------
        | 窗口 - 打开
        |--------------------------------------------------
        |
        */

        Open(id) {
            new Promise((resolve) => {
                this.visible = true
                this.business_id = id
                resolve()
            }).then((rps) => {
                this.DocumentaryGet()
            })
        },

        /**
        |--------------------------------------------------
        | 获取数据
        |--------------------------------------------------
        |
        */

        DocumentaryGet() {
            // 开启加载状态
            this.loading = true

            // 获取跟单信息
            this.$http.post('/9api/crm/business/search-first', { id: this.business_id, append: ['source', 'user_sale', 'user_admin', 'user_affair', 'user_presale', 'customer', 'notify_users'] }).then(rps => {
                this.business = rps.data
                
                // 获取合同信息
                if (this.business.contract_id) {
                    this.$http.post('/9api/crm/contract/search-first', { id: this.business.contract_id, hidden: ['describe'] }).then(rps => {
                        this.contract = rps.data
                    })
                } else {
                    this.contract = {}
                }

                // 关闭加载状态
                this.loading = false
            })
        },

        /**
        |--------------------------------------------------
        | 更新数据
        |--------------------------------------------------
        |
        */

        DocumentaryUpdate() {
            this.$emit('onChange')
            this.DocumentaryGet()
        },

        /**
        |--------------------------------------------------
        | 状态变更
        |--------------------------------------------------
        |
        */

        DocumentaryUpdateState(state) {
            this.loading = true
            this.$http.post('/9api/sale-documentary/update-state', { id: this.business_id, state: state }).then((rps) => {
                this.DocumentaryGet()
                this.$emit('onChange')
            })
        },

        /**
        |--------------------------------------------------
        | 移除数据
        |--------------------------------------------------
        |
        */

        DocumentaryDelete() {
            this.$http.post('/9api/sale-documentary/delete', { id: this.business_id }).then((rps) => {
                this.$emit('onChange')
                this.visible = false
            })
        },

        
        /**
        |--------------------------------------------------
        | 窗口 - 关闭
        |--------------------------------------------------
        |
        */

        Close() {
            this.visible = false
            this.loading = false
        },
    }
}
</script>