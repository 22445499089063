<template>
    <el-dialog :visible.sync="visible" class="w-el-dialog" width="500px" :close-on-click-modal="false" :close-on-press-escape="false" append-to-body destroy-on-close>
            
        <!-- 标题 -->
        <template slot="title">
            编辑角色
        </template>

        <el-form :model="form" label-position="top" size="mini">

            <!-- 角色名称 -->
            <el-form-item label="角色名称" :rules="[{ required: true, message: '必填项' }]" :error="formError.name?.toString()" prop="name">
                <el-input v-model="form.name" placeholder="输入任意默认'字符串'"></el-input>
            </el-form-item>

            <!-- 角色权限 -->
            <el-form-item label="角色权限">
                <vxe-table
                    ref="xTable"
                    :data="routerViews"
                    :loading="loading"
                    :row-config="{keyField: 'id'}"
                    :tree-config="{transform: true, childrenField: 'children', rowField: 'id', parentField: 'parent', indent: 10, reserve: true}"
                    @checkbox-all="TableChangeEvent"
                    @checkbox-change="TableChangeEvent">

                    <!-- 复选框 -->
                    <vxe-column type="checkbox" width="45" fixed="left"></vxe-column>

                    <vxe-column min-width="150" field="name" title="菜单" tree-node></vxe-column>

                    <!-- 标签 -->
                    <vxe-column min-width="150" field="path" title="路由(校验用)"></vxe-column>
                </vxe-table>
            </el-form-item>

        </el-form>

        <!-- footer -->
        <template slot="footer">
            <el-button size="mini" :loading="loading" @click="visible = false">关 闭</el-button>
            <el-button size="mini" :loading="loading" type="primary"  @click="FormSubmit()">提交</el-button>
        </template>
        
    </el-dialog>
</template>

<script>
export default {
    data () {
        return {
            visible: false,
            loading: false,

            formError: {},
            form: {
                id: null,
                name: null,
                router_views_id: []
            },

            routerViews: [],
        } 
    },

    mounted() {
        this.$http.post('/9api/router-views/search-all').then(rps => {
            this.routerViews = rps.data
        })
    },

    methods: {
        /**
        |--------------------------------------------------
        | 打开窗口
        |--------------------------------------------------
        |
        */
        Open(id) {
            this.visible = true

            this.$http.post('/9api/users-role/search-first', { search: { id: id } }).then(rps => {
                this.form.id = rps.data.id
                this.form.name = rps.data.name
                this.form.router_views_id = rps.data.router_views_id

                this.$refs.xTable.setAllCheckboxRow(true)
                
                this.$refs.xTable.getData().forEach(element => {
                    if (!rps.data.router_views_id.includes(element.id)) {
                        this.$refs.xTable.setCheckboxRow(element, false)
                    }
                })
            })
        },

        /**
        |--------------------------------------------------
        | 关闭窗口
        |--------------------------------------------------
        |
        */

        Close() {
            this.formError = {}
            this.form.id = null
            this.form.name = null
            this.form.router_views_id = []

            this.$refs.xTable.clearCheckboxRow()

            this.loading = false
            this.visible = false
        },

        /**
        |--------------------------------------------------
        | 表格复选框点击事件事件
        |--------------------------------------------------
        |
        */

        TableChangeEvent() {
            let select = this.$refs.xTable.getCheckboxRecords().map(item => item.id)
            let minate = this.$refs.xTable.getCheckboxIndeterminateRecords().map(item => item.id)
            this.form.router_views_id = select.concat(minate)
        },

        /**
        |--------------------------------------------------
        | 提交表单
        |--------------------------------------------------
        |
        */

        FormSubmit() {
            this.$http.post('/9api/users-role/update', this.form).then(rps => {
                // 提交失败
                if (rps.data.state == 'error') {
                    this.formError = rps.data.body
                }

                // 提交成功
                if (rps.data.state == 'success') {
                    
                    // 操作通知
                    this.$emit('onChange', rps.data.body)
                    this.Close()
                }
            })
        }
    },
}
</script>