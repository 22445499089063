<!---------------------------------------------------------------------------- 
 | 工单报障 - 创建
 |----------------------------------------------------------------------------
 | 方法：请注意大小写,参数描述见具体方法
 | $refs[ref_name].Open(*id) 手动打开窗口,
 | $refs[ref_name].Close() 手动关闭窗口
 |----------------------------------------------------------------------------
 | 回调
 | @onChange = fn (Object) => {}
 |
 ---------------------------------------------------------------------------->

<template>
    <el-drawer :visible.sync="visible" size="80%" direction="rtl" append-to-body destroy-on-close :show-close="false" :before-close="Close">

        <div slot="title">

            <!-- 标题 -->
            <div class="w-margin-b-10">ID：{{ wo.id }} 工单 - {{ wo.number }}</div>
            <div class="w-margin-b-10" style="font-size: 18px; color: #000; font-weight: bold;">{{ wo.name }}</div>

            <!-- 功能按钮 -->
            <div :style="{ display: 'flex', marginTop: '5px' }">
                <!-- 状态管理 -->
                <el-button style="margin-left: 0px; margin-right: 5px;" size="mini" type="primary" v-if="wo.state == 0" @click="UpdateState(1)">确认</el-button>
                <el-button style="margin-left: 0px; margin-right: 5px;" size="mini" type="primary" v-if="wo.state == 1" @click="$refs.woUpdateFinish.Open(wo.id)">完成</el-button>
                <el-button style="margin-left: 0px; margin-right: 5px;" size="mini" type="warning" v-if="wo.state != 3" @click="$refs.woUpdateRelay.Open(wo.id)">转交</el-button>
                <el-button style="margin-left: 0px; margin-right: 5px;" size="mini" type="warning" v-if="[0, 1, 2].includes(wo.state)" @click="UpdateState(4)">暂停</el-button>
                <el-button style="margin-left: 0px; margin-right: 5px;" size="mini" type="warning" v-if="wo.state != 5" @click="UpdateState(5)">取消</el-button>
                <el-button style="margin-left: 0px; margin-right: 5px;" size="mini" type="warning" v-if="[4, 5].includes(wo.state)" @click="UpdateState(1)">激活</el-button>
                <el-button style="margin-left: 0px; margin-right: 5px;" size="mini" type="danger"  v-if="[2].includes(wo.state)" @click="UpdateState(5)">驳回</el-button>
                <el-button style="margin-left: 0px; margin-right: 5px;" size="mini" type="danger"  v-if="wo.state == 2" @click="$refs.woUpdateClose.Open(wo.id)">关闭</el-button>
                <!-- 基础功能 -->
                <el-button style="margin-left: 5px;" size="mini" @click="$refs.woPrint.Open(wo.id)">打 印</el-button>
                <el-button style="margin-left: 5px;" size="mini" icon="el-icon-refresh" @click="RePage()">刷新</el-button>
                <el-button style="margin-left: 5px;" size="mini" icon="el-icon-edit" @click="$refs.woUpdate.Open(wo.id)">编辑</el-button>
                <!-- 移除功能 -->
                <el-button style="margin-left: 10px;" size="mini" @click="DataDelete()" type="danger">移除</el-button>
            </div>
               
            <!-- 基本信息 -->
            <div class="w-margin-t-10" style="background-color: #f4f5f7; border-radius: 3px; padding: 15px; display: flex;">
                <el-statistic title="状态">
                    <template slot="formatter">
                        <span v-if="wo.state == 0" style="font-size: 16px;"><b>待确认</b></span>
                        <span v-if="wo.state == 1" style="font-size: 16px;"><b>进行中</b></span>
                        <span v-if="wo.state == 2" style="font-size: 16px;"><b>已完成</b></span>
                        <span v-if="wo.state == 3" style="font-size: 16px;"><b>已关闭</b></span>
                        <span v-if="wo.state == 4" style="font-size: 16px;"><b>已暂停</b></span>
                        <span v-if="wo.state == 5" style="font-size: 16px;"><b>已取消</b></span>
                    </template>
                </el-statistic>
                <el-statistic title="收单人"><template slot="formatter"><span style="font-size: 14px;">{{ wo.user_receive.name }}</span></template></el-statistic>
                <el-statistic title="派单人"><template slot="formatter"><span style="font-size: 14px;">{{ wo.user_sending.name }}</span></template></el-statistic>
                <el-statistic title="消耗工时"><template slot="formatter"><span style="font-size: 14px;">{{ wo.day_consume }} / P</span></template></el-statistic>
                <el-statistic title="预计工时"><template slot="formatter"><span style="font-size: 14px;">{{ wo.day_predict }} / P</span></template></el-statistic>
                <el-statistic title="剩余工时"><template slot="formatter"><span style="font-size: 14px;">{{ wo.day_surplus }} / P</span></template></el-statistic>
                <el-statistic title="截止时间"><template slot="formatter"><span style="font-size: 14px;"><b>{{ wo.range_at_end }}</b></span></template></el-statistic>
            </div>

        </div>

        <!-- body -->
        <div class="w-padding-l-20 w-padding-r-20 w-padding-b-20">
            <el-tabs v-model="tabs">
                
                <!-- 工单概况 -->
                <el-tab-pane label="工单概况" name="base">
                    <el-descriptions size="mini" border :column="2" class="w-margin-t-10">

                        <!-- 工单类型 -->
                        <el-descriptions-item label="工单类型" :span="2">{{ wo.template?.name }}</el-descriptions-item>

                        <!-- 工单名称 -->
                        <el-descriptions-item label="工单名称" :span="2">{{ wo.number }} - {{ wo.name }}</el-descriptions-item>

                        <!-- 关联客户 -->
                        <el-descriptions-item label="关联客户" :span="2">
                            <el-link :underline="false" type="primary"  v-if="wo.customer" @click="$refs.customerDrawer.Open(wo.customer_id)">{{ wo.customer.number + '-' + wo.customer.name }}</el-link>
                            <el-link :underline="false" type="info"     v-else>未关联</el-link>
                        </el-descriptions-item>

                        <!-- 关联项目 -->
                        <el-descriptions-item label="关联项目" :span="2">
                            <el-link :underline="false" type="primary"  v-if="wo.projects" @click="$refs.projectsDrawer.Open(wo.projects_id)">{{ wo.projects.number + '-' + wo.projects.name }}</el-link>
                            <el-link :underline="false" type="info"     v-else>未关联</el-link>
                        </el-descriptions-item>

                        <!-- 关联任务 -->
                        <el-descriptions-item label="关联任务" :span="2">
                            <el-link :underline="false" type="primary"  v-if="wo.projects_task" @click="$refs.projectsTaskDetails.Open(wo.projects_task.id)">{{ wo.projects_task.name }}</el-link>
                            <el-link :underline="false" type="info"     v-else>未关联</el-link>
                        </el-descriptions-item>

                        <!-- 紧急程度 -->
                        <el-descriptions-item label="紧急程度" :span="2">
                            <el-tag v-if="wo.p == 1" size="small" effect="plain" type="danger">P1 非常重要</el-tag>
                            <el-tag v-if="wo.p == 2" size="small" effect="plain" type="warning">P2 重要</el-tag>
                            <el-tag v-if="wo.p == 3" size="small" effect="plain" type="primary">P3 次要</el-tag>
                            <el-tag v-if="wo.p == 4" size="small" effect="plain" type="success">P4 一般</el-tag>
                        </el-descriptions-item>

                        <el-descriptions-item label="起止时间" :span="2">
                            <el-tag class="w-margin-r-5" size="mini" effect="dark" type="danger" v-if="wo.is_overdue">已逾期</el-tag>
                            {{ wo.range_at_start }} 至 {{ wo.range_at_end }}
                        </el-descriptions-item>

                        <el-descriptions-item label="预计工时" :span="2">{{ wo.day_predict }} / P</el-descriptions-item>
                        <el-descriptions-item label="消耗工时" :span="2">{{ wo.day_consume }} / P</el-descriptions-item>
                        <el-descriptions-item label="剩余工时" :span="2">{{ wo.day_surplus }} / P</el-descriptions-item>

                        <el-descriptions-item label="管理员">{{ wo.user_admin.name }}</el-descriptions-item>
                        <el-descriptions-item label="收单人">{{ wo.user_receive.name }}</el-descriptions-item>
                        <el-descriptions-item label="派单人">{{ wo.user_sending.name }}</el-descriptions-item>
                        <el-descriptions-item label="协同人">{{ wo.user_assist.map((item) => item.name).toString() }}</el-descriptions-item>
                    </el-descriptions>

                    <!-- 工单生命周期 -->
                    <h4>工单生命周期</h4>
                    <el-descriptions size="mini" border :column="2" class="w-margin-t-10">
                        <el-descriptions-item label="确认人">{{ wo.state_users.affirm_user?.name }}</el-descriptions-item>
                        <el-descriptions-item label="确认时间">{{ wo.affirm_at }}</el-descriptions-item>
                        <el-descriptions-item label="完成人">{{ wo.state_users.finish_user?.name }}</el-descriptions-item>
                        <el-descriptions-item label="完成时间">{{ wo.finish_at }}</el-descriptions-item>
                        <el-descriptions-item label="关闭人">{{ wo.state_users.close_user?.name }}</el-descriptions-item>
                        <el-descriptions-item label="关闭时间">{{ wo.close_at }}</el-descriptions-item>
                        <el-descriptions-item label="暂停人">{{ wo.state_users.suspend_user?.name }}</el-descriptions-item>
                        <el-descriptions-item label="暂停时间">{{ wo.suspend_at }}</el-descriptions-item>
                        <el-descriptions-item label="取消人">{{ wo.state_users.cancel_user?.name }}</el-descriptions-item>
                        <el-descriptions-item label="取消时间">{{ wo.cancel_at }}</el-descriptions-item>
                        <el-descriptions-item label="创建时间">{{ wo.created_at }}</el-descriptions-item>
                        <el-descriptions-item label="最后更新时间">{{ wo.updated_at }}</el-descriptions-item>
                    </el-descriptions>

                    <!-- 自定义信息 -->
                    <h4>自定义信息</h4>
                    <wo-template-form-disabled v-model="wo.template_value" :id="wo.template_id" />
                </el-tab-pane>

                <!-- 工单描述 -->
                <el-tab-pane label="工单描述" name="describe">
                    <froala-views v-model="wo.describe" />
                </el-tab-pane>

                <!-- 工时记录 -->
                <el-tab-pane label="工时记录" name="day">
                    <pm-wo-drawer-day :wo_id="wo.id" v-if="!loading && tabs == 'day'" @onChange="RePage" />
                </el-tab-pane>
                
                <!-- 附件 -->
                <el-tab-pane label="附件" name="files">
                    <files-table v-model="wo.files_id" />
                </el-tab-pane>

            </el-tabs>
        </div>

        <!-- 组件 -->
        <pm-projects-task-drawer ref="projectsTaskDetails" />
        <pm-wo-print ref="woPrint" />

        <pm-wo-update           ref="woUpdate"          @onChange="RePage" />
        <pm-wo-update-finish    ref="woUpdateFinish"    @onChange="RePage" />
        <pm-wo-update-relay     ref="woUpdateRelay"     @onChange="RePage" />
        <pm-wo-update-close     ref="woUpdateClose"     @onChange="RePage" />
        <pm-projects-drawer     ref="projectsDrawer"    @onChange="RePage" />
        <crm-customer-drawer    ref="customerDrawer"    @onChange="RePage" />

    </el-drawer>
</template>

<script>
export default {
    data() {
        return {
            visible: false,
            loading: false,
            tabs: 'base',

            wo: {
                id: null,
                customer: {},
                projects: {},
                projects_task: {},
                date_range: [],
                user_admin: {},
                user_receive: {},
                user_sending: {},
                user_assist: [],
                state_users: {
                    affirm_user: {},
                    finish_user: {},
                    close_user: {},
                    cancel_user: {},
                    suspend_user: {},
                },
                template: [],
                files_id: [],
            }
        }
    },

    methods: {

        /**
        |--------------------------------------------------
        | 打开窗口
        |--------------------------------------------------
        |
        */

        Open(id) {
            this.visible = true
            this.DataGet(id)
        },

        /**
        |--------------------------------------------------
        | 关闭窗口
        |--------------------------------------------------
        |
        */

        Close(done) {
            this.$emit('onChange')
            done()
        },

        /**
        |--------------------------------------------------
        | 刷新
        |--------------------------------------------------
        |
        */

        RePage() {
            this.DataGet()
        },

        /**
        |--------------------------------------------------
        | 数据查询
        |--------------------------------------------------
        |
        */

        DataGet(id = this.wo.id) {
            this.loading = true

            this.$http.post(
                '/9api/wo/search-first',
                {
                    id: id,
                    append: ['user_admin', 'user_receive', 'user_sending', 'user_assist', 'template', 'customer', 'projects', 'projects_task', 'state_users', 'appraise_user'],
                }
            ).then(rps => {
                this.wo = rps.data
                this.loading = false
            })
        },

        /**
        |--------------------------------------------------
        | 删除
        |--------------------------------------------------
        |
        */

        DataDelete() {
            this.$confirm('此操作将永久删除数据，以及关联的数据，包括且不仅限以下内容: 工时、附件、统计数据等', '请谨慎操作', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'error',
                center: true
            }).then(() => {
                this.$http.post('/9api/wo/delete', { id: this.wo.id }).then(rps => {
                    this.$emit('onChange')
                    this.visible = false
                })
            })
        },

        
        /**
        |--------------------------------------------------
        | 状态修改
        |--------------------------------------------------
        |
        */

        UpdateState(state) {
            this.$http.post('/9api/wo/update-state', { id: this.wo.id, state: state }).then(() => {
                this.$emit('onChange')
                this.RePage()
            })
        },
    }
}

</script>6
