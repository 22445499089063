<!---------------------------------------------------------------------------- 
 | 商机-业务范围
 |----------------------------------------------------------------------------
 | 方法：请注意大小写,参数描述见具体方法
 | *v-model         默认值----默认为 null
 | *contract_id     商机ID------默认为 null
 |----------------------------------------------------------------------------
 | 参数说明
 | update => Boolean => 是否可以修改
 |----------------------------------------------------------------------------
 |
 ---------------------------------------------------------------------------->

 <template>
    <el-popover trigger="click" placement="bottom-start" :disabled="!update">
        <el-link slot="reference" type="primary" :underline="update">
            {{ value || '暂未标记' }}
            <i v-if="update" class="el-icon-edit el-icon--right"></i>
        </el-link>
        <el-autocomplete clearable size="mini" v-model="date" :style="{ width: '100%' }" :fetch-suggestions="(queryString, cb) => cb([
            { 'value': '主业' },
            { 'value': '副业' }
        ])" @change="OnChange" @select="OnSelect"></el-autocomplete>
    </el-popover>
</template>

<script>
export default {
    props: {
        value: {
            type: String,
            default: '暂未标记'
        },

        business_id: {
            type: Number,
            default: null
        },

        update: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            date: null,
        }
    },

    watch: {
        value: {
            handler(val) {
                this.date = val
            },
            immediate: true
        }
    },

    methods: {
        OnChange(val) {
            if (this.business_id && this.update) {
                this.$http.post('/9api/crm/business/update-tender-range', {id: this.business_id, tender_range: val}).then(rps => {
                    this.$emit('input', val)
                })
            }
        },

        OnSelect(node) {
            if (this.business_id && this.update) {
                this.$http.post('/9api/crm/business/update-tender-range', {id: this.business_id, tender_range: node.value}).then(rps => {
                    this.$emit('input', node.value)
                })
            }
        },
    }
}
</script>