<template>
    <div class="w-file-upload">
        <el-upload :on-change="ImportData" drag :auto-upload="false" action="#" :show-file-list="false">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text"><b>工单管理：</b>将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>

        <el-dialog :visible.sync="visible" class="w-el-dialog" fullscreen title="批量导入" append-to-body :before-close="Close" :close-on-click-modal="false" :close-on-press-escape="false">
           
            <!-- 表格 -->
            <uw-table :show-footer="false" :show-header="false">
                <!-- 表数据 -->
                <template slot="data">
                    <!-- 表内容 -->
                    <vxe-table ref="xTable" :data="table" :loading="loading" :export-config="{}" height="100%">

                        <!-- 序号 -->
                        <vxe-column width="60" type="seq" title="序号"></vxe-column>

                        <!-- 项目编号 -->
                        <vxe-column width="120" field="projects.number" title="项目编号"></vxe-column>
                        
                        <!-- 关联客户 -->
                        <vxe-column min-width="150" field="customer.name" title="关联客户"></vxe-column>

                        <!-- 工单状态 -->
                        <vxe-column width="80" field="state" title="工单状态"></vxe-column>

                        <!-- 工单名称 -->
                        <vxe-column min-width="150" field="name" title="工单名称"></vxe-column>

                        <!-- 工单模板 -->
                        <vxe-column min-width="150" field="template.name" title="工单模板"></vxe-column>

                        <!-- 合同期限 -->
                        <vxe-column min-width="150" field="range_at_start" title="工单开始日期"></vxe-column>
                        <vxe-column min-width="150" field="range_at_end" title="工单结束日期"></vxe-column>

                        <!-- 参与成员 -->
                        <vxe-column width="80" field="user_receive.name" title="收单人"></vxe-column>
                        <vxe-column width="80" field="user_admin.name" title="管理员"></vxe-column>

                        <!-- 成本预估 -->
                        <vxe-column width="80" field="day_predict" title="预估工时"></vxe-column>
                        <vxe-column width="80" field="day_consume" title="消耗工时"></vxe-column>

                        <!-- 项目预期金额 -->
                        <vxe-column width="150" field="describe" title="工单描述"></vxe-column>

                        <!-- 操作 -->
                        <vxe-column title="错误提示" width="30%" align="center" fixed="right" v-if="tableError">
                            <template #default="{ row }">
                                <el-link type="danger">{{ row.error }}</el-link>
                            </template>
                        </vxe-column>
                    </vxe-table>
                </template>
            </uw-table>

            <template slot="footer">
                <el-button size="mini" :loading="loading" @click="Close()">取 消</el-button>
                <el-button size="mini" type="primary"  @click="$refs.xTable.openExport()" >导出当前数据 / 导入模板</el-button>
                <el-button size="mini" type="success"  :loading="loading"  @click="SubmitForm()">开始导入</el-button>
            </template>
            
        </el-dialog>
    </div>
</template>

<script>
import * as XLSX from 'xlsx/xlsx.mjs';

export default {
    data () {
        return {
            visible: false,
            loading: false,

            table: [],
            tableError: false
        }
    },

    methods: {
        ImportData(file) {
            
            this.visible = true
            this.loading = true

            // 读取文件流
            let dataBinary = new Promise((resolve) => {
                let reader = new FileReader();
                reader.readAsBinaryString(file.raw)
                reader.onload = (ev) => {

                    // 解读文件信息
                    let workBook = XLSX.read(ev.target.result, { type: "binary", cellDates: true });

                    // 解读第一个 Sheet
                    let firstWorkSheet = workBook.Sheets[workBook.SheetNames[0]]

                    // 解析 Sheet 内容
                    let data = XLSX.utils.sheet_to_json(firstWorkSheet);

                    // 读取数据
                    data.forEach(async (item) => {

                        console.log( )

                        // 插入数据
                        const { row: newRow } = this.$refs.xTable.insertAt({
                            'projects': { number: item['项目编号'] },
                            'customer': { name: item['关联客户'] },
                            'name': item['工单名称'],
                            'state': item['工单状态'],
                            'template': { name: item['工单模板'] },
                            'p': item['紧急程度'],
                            'number': item['工单单号'],
                            'user_receive': { name: item['收单人'] },
                            'user_admin': { name: item['管理员'] },
                            'range_at_start': new Date(item['工单开始日期']),
                            'range_at_end': new Date(item['工单结束日期']),
                            'day_predict': item['预估工时'],
                            'day_consume': item['消耗工时'],
                            'describe': item['工单描述'],
                            'error': false,
                        }, -1)

                        this.loading = false
                    })
                }
            })
        },

        /**
        |--------------------------------------------------
        | 提交表单
        |--------------------------------------------------
        |
        */

        SubmitForm() {
            // 开启加载状态
            this.loading = true

            // 获取数据
            let data = this.$refs.xTable.getTableData().tableData.filter(item => !item.parentField)

            data.forEach(async (element) => {

                // 提交表单
                await this.$http.post('/9api/test', element).then(rps => {
                    // 关闭加载状态
                    this.loading = false

                    // 提交失败
                    if (rps.data.state == 'error') {
                        this.tableError = true
                        element.error = rps.data.body
                    }
                    
                    // 提交成功
                    if (rps.data.state == 'success') {
                        this.$refs.xTable.remove(element)
                    }
                })
            });
        },

        /**
        |--------------------------------------------------
        | 关闭窗口
        |--------------------------------------------------
        |
        */

        Close() {
            this.table = []
            this.tableError = false
            this.visible = false
            this.loading = false
        }
    }
}
</script>