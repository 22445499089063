<template>
    <div>
        <!-- 创建按钮 -->
        <el-button size="mini" icon="el-icon-plus" @click="$refs.productCreate.open({ supplier_id: supplier_id, type_id: search.type_id })" :style="{ marginBottom: '10px' }" >
            新的产品
        </el-button>

        <!-- 数据表格 -->
        <vxe-table border ref="xTable" :data="table" :loading="loading" :export-config="{}" :print-config="{}" min-height="500px">

            <!-- 序号 -->
            <vxe-column width="60" type="seq" title="序号"></vxe-column>

            <!-- 产品名称 -->
            <vxe-column min-width="160" field="name" title="产品名称">
                <template #default="{ row }">
                    <el-link icon="el-icon-present" @click="$refs.productDetails.Open(row.id)" type="primary">{{ row.name }}</el-link>
                </template>
            </vxe-column>

            <!-- 产品品牌 -->
            <vxe-column min-width="100" field="brand" title="产品品牌"></vxe-column>

            <!-- 产品类别 -->
            <vxe-column min-width="100" field="type.name" title="产品类别"></vxe-column>

            <!-- 计量单位 -->
            <vxe-column min-width="80" field="unit" title="计量单位"></vxe-column>

            <!-- 规格参数 -->
            <vxe-column min-width="160" field="unit" title="规格参数">
                <template #default="{ row }">
                    {{ row.parameter?.toString() }}
                </template>
            </vxe-column>

            <!-- 产品品牌 -->
            <vxe-column min-width="100" field="maintenance" title="维保信息"></vxe-column>

            <!-- 备注说明 -->
            <vxe-column min-width="220" field="describe" title="备注说明"></vxe-column>

        </vxe-table>

        <!-- 组件 -->
        <ims-product-create ref="productCreate" @onChange="productSearch" />
        <ims-product-drawer ref="productDetails" @onChange="productSearch" />
    </div>
</template>
    
<script>
export default {
    props: {
        supplier_id: {
            type: Number,
            default: null
        }
    },

    data() {
        return {
            loading: false,

            table: [],

            search: {
                supplier_id: null
            }
        }
    },

    watch: {
        supplier_id: {
            handler(val) {
                if (val) {
                    this.search.supplier_id = val
                    this.productSearch()
                } else {
                    this.table = []
                }
            },
            immediate: true
        }
    },

    methods: {

        // 产品 - 查询
        productSearch() {
            this.loading = true
            this.$http.post('/9api/ims/product/search-all', { search: this.search, append: ['type', 'supplier'] }).then((rps) => {
                this.table = rps.data
                this.loading = false
            }) 
        },

    }
}
</script>