<template>
    <el-drawer :visible.sync="visible" size="80%" direction="rtl" :before-close="Close" :show-close="false" append-to-body destroy-on-close>
        
        <!-- Title -->
        <div slot="title">
            <div :style="{ display: 'flex', justifyContent: 'space-between' }">
                <div class="w-margin-b-10">考核模板</div>
                <el-button size="mini" @click="Close()">关闭</el-button>
            </div>
            <div style="font-size: 18px; color: #000; font-weight: bold;">{{ template.name }}</div>
        </div>
        
           
        <div :style="{ padding: '0 20px 20px 20px' }">
            
            <!-- 考核维度 -->
            <el-divider content-position="left"><b>考核维度</b></el-divider>

            <!-- 维度内容 -->
            <div v-for="(element, idx) in template.class" :key="idx" :style="{ marginBottom: '20px' }">
                <vxe-table :data="element.children">
                    <vxe-colgroup>

                        <!-- 维度信息 -->
                        <template #header>
                            <div :style="{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }">
                                <div :style="{ display: 'flex' }">
                                    <hrm-kpi-template-class-type v-model="element.type" />
                                    <el-link :style="{ marginLeft: '10px' }" :underline="false" type="primary">{{ element.name }}</el-link>
                                    <el-link :style="{ marginLeft: '10px' }" :underline="false">维度权重：</el-link>
                                    <el-link :style="{ marginLeft: '10px' }" :underline="false" type="warning">{{ element.weight }} %</el-link>
                                </div>
                            </div>
                            <div>{{ element.describe }}</div>
                        </template>

                        <!-- 维度配置 -->
                        <vxe-column min-width="80" title="指标项名称" >
                            <template #default="{ row }">
                                <span v-if="element.type == 'custom'">{{ row.name }}</span>
                                <hrm-kpi-goals-type v-model="row.goals" v-if="element.type == 'goals'" />
                            </template>
                        </vxe-column>

                        <!-- 指标信息 -->
                        <vxe-column min-width="200" title="指标说明" :show-overflow="false" field="describe"></vxe-column>
                        <vxe-column min-width="200" title="考核标准/目标" :show-overflow="false" field="target"></vxe-column>

                        <!-- 指标项权重 % -->
                        <vxe-column width="120" title="指标项权重 %" :resizable="false" field="weight">
                            <template #default="{ row }">
                                {{ row.weight || '按需评估' }}
                            </template>
                        </vxe-column>

                        <!-- 分值占比 -->
                        <vxe-column width="120" title="分值占比" :resizable="false" >
                            <template #default="{ row }">
                                {{ (element.weight * row.weight * 0.01) || '自定义' }}
                            </template>
                        </vxe-column>

                    </vxe-colgroup>
                </vxe-table>
            </div>

            <!-- 考核流程 -->
            <el-divider content-position="left"><b>考评流程</b></el-divider>

            <!-- 流程内容 -->
            <vxe-table :data="template.flow" v-if="template.flow.length > 0">

                <!-- 考评人 -->
                <vxe-column min-width="80" title="考评人" >
                    <template #default="{ row }">
                        <span v-if="row.type == 'me'">被考评人</span>
                        <span v-if="row.type == 'leader'">直属领导考评</span>
                        <users-select v-model="row.users_id" v-if="row.type == 'he'" disabled></users-select>
                    </template>
                </vxe-column>

                <!-- 评分权重 % -->
                <vxe-column min-width="80" field="weight" title="评分权重 %" ></vxe-column>

                <!-- 评分方式 -->
                <vxe-column min-width="80" title="评分方式" >
                    <template #default>
                        直接评分
                    </template>
                </vxe-column>
                
                <!-- 评语配置 -->
                <vxe-column min-width="80" title="评语设置" >
                    <template #default="{ row }">
                        {{ row.appraise_switch ? '开启' : '关闭' }}
                    </template>
                </vxe-column>
                <vxe-column min-width="80" title="评语可见" >
                    <template #default="{ row }">
                        {{ row.appraise_show ? '他人可见' : '仅自己' }}
                    </template>
                </vxe-column>
                <vxe-column min-width="80" title="驳回权限" >
                    <template #default="{ row }">
                        {{ row.appraise_show ? '可驳回' : '不可驳回' }}
                    </template>
                </vxe-column>

            </vxe-table>

            <!-- 考核结果 -->
            <el-divider content-position="left"><b>考核结果</b></el-divider>

            <!-- 结果内容 -->
            <vxe-table :data="template.result" v-if="template.result.length > 0" :style="{ marginTop: '10px' }">
                <vxe-column min-width="80" field="level" title="等级" ></vxe-column>
                <vxe-column min-width="80" field="min" title="最低分" ></vxe-column>
                <vxe-column min-width="80" field="max" title="最高分" ></vxe-column>
                <vxe-column min-width="80" field="weight" title="系数" ></vxe-column>
            </vxe-table>

        </div>

    </el-drawer>
</template>
    
<script>
export default {
    data() {
        return {
            visible: false,

            template: {
                id: null,
                name: null,
                class: [],
                flow: [],
                result: []
            }
        }
    },

    computed: {
        loading () {
            return !!this.$store.state.httpRequestCount
        }
    },
    
    methods: {

        // 窗口 - 打开
        Open(template) {
            this.template = template
            this.visible = true
        },

        // 窗口 - 关闭
        Close() {
            this.visible = false
        },
    }
}
</script>