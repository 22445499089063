<!---------------------------------------------------------------------------- 
 | 项目任务 - 类型
 |----------------------------------------------------------------------------
 | 方法：请注意大小写,参数描述见具体方法
 | *v-model     默认值------默认为 null
 | placeholder  表单提示----默认为 ‘请选择任务类型’
 | multiple     是否多选----默认为 false
 | clearable    是否可清空--默认为 false
 | filterable   是否可筛选--默认为 false
 | width        表单宽度----默认为 ‘100%’
 | margin       表单外边距--默认为 ‘0px’
 |----------------------------------------------------------------------------
 | 回调
 | @onChange = fn (data) => {} 返回表单内容
 |----------------------------------------------------------------------------
 | 备注：
 | 项目管理 - 任务 - 批量创建 ：存在独立的代码，需要单独修改代码
 |
 ---------------------------------------------------------------------------->

 <template>
    <el-cascader
        ref="cascader"
        @change="SelectChange"
        v-model="selectValue"
        :options="select"
        :props="{ label: 'name', value: 'id', emitPath: false, checkStrictly: true }"
        :style="{ width: width, margin: margin }"
        placeholder="请选择任务类型"
        size="mini"
        clearable
        filterable>
    </el-cascader>
</template>

<script>
export default {
    props: {
        value: {
            type: [String, Array, Number],
            default: null
        },

        width: {
            type: String,
            default: '100%'
        },

        margin: {
            type: String,
            default: '0px'
        }
    },

    watch: {
        value () {
            this.selectValue = this.value
        }
    },

    data () {
        return {
            select: [],
            selectValue: null,
        }
    },

    mounted () {
        this.$http.post('/9api/projects-task-type/search-tree').then(rps => {
            this.select = rps.data
            this.selectValue = this.value
        })
    },

    methods: {
        SelectChange(data) {
            this.$emit('input', data)
            this.$emit('onChange', this.$refs.cascader.getCheckedNodes()[0].data)
        }
    }
}
</script>