<!---------------------------------------------------------------------------- 
 | 数据字典 - 编辑字段
 |----------------------------------------------------------------------------
 | 方法：请注意大小写,参数描述见具体方法
 | $refs[ref_name].Open(*id) 手动打开窗口,
 | $refs[ref_name].Close() 手动关闭窗口
 |----------------------------------------------------------------------------
 | 回调
 | @onChange = fn (Object) => {} 成功后返信息
 |
 ---------------------------------------------------------------------------->
 <template>
    <el-dialog class="w-el-dialog" :visible.sync="visible" append-to-body title="编辑字段"  width="550px" :close-on-click-modal="false" :close-on-press-escape="false" :before-close="Close">
        
        <el-form :model="form" label-width="80px" size="mini" v-loading="loading">

            <el-form-item label="业务模块" :rules="[{ required: true, message: '必选项' }]" :error="formError.model?.toString()" prop="model">
                <data-dict-select-model v-model="form.model" />
            </el-form-item>

            <!-- 字段名称 -->
            <el-form-item label="字段名称"  :rules="[{ required: true, message: '必填项' }]" :error="formError.name?.toString()" prop="name">
                <el-input v-model="form.name" clearable placeholder="请填写字段名称"></el-input>
            </el-form-item>

            <el-form-item label="字段类型" :rules="[{ required: true, message: '必选项' }]" :error="formError.type?.toString()" prop="type">
                <data-dict-select-type v-model="form.type" @onChange="TypeChange" />
            </el-form-item>

            <el-form-item label="是否必填" prop="placeholder">
                <el-checkbox v-model="form.required">必填</el-checkbox>
            </el-form-item>
            
            <el-form-item label="提示词" prop="placeholder">
                <el-input v-model="form.placeholder" placeholder="字段为空时的提示内容"></el-input>
            </el-form-item>

            <el-form-item label="默认值/选项" v-if="form.type">
                
                <template v-if="['radio','checkbox','select', 'select-multiple'].includes(form.type)">
                    <el-input class="w-margin-b-5" v-for="(item, idx) in form.form.select" :key="idx" v-model="form.form.select[idx]" clearable>
                        <el-button slot="prepend" icon="el-icon-plus"   @click="form.form.select.splice(idx + 1, 0, '')"></el-button>
                        <el-button slot="append" icon="el-icon-delete"  @click="form.form.select.splice(idx, 1)" v-if="idx"></el-button>
                    </el-input>
                </template>

                <el-input v-if="form.type == 'text'" v-model="form.form.value" placeholder="请输入内容" clearable></el-input>
                <el-input v-if="form.type == 'textarea'" v-model="form.form.value" placeholder="请输入内容" type="textarea" clearable></el-input>
                <el-input-number v-if="form.type == 'int'" v-model="form.form.value" :precision="0" class="w-width-auto"></el-input-number>
                <el-input-number v-if="form.type == 'float'" v-model="form.form.value" :precision="2" class="w-width-auto"></el-input-number>

                <el-date-picker clearable v-if="form.type == 'date'" v-model="form.form.value" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
                <el-date-picker clearable v-if="form.type == 'date-range'" v-model="form.form.select" type="daterange" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
                <el-time-picker clearable v-if="form.type == 'time'" v-model="form.form.value" value-format="HH:mm:ss" placeholder="选择时间"></el-time-picker>
                <el-time-picker clearable v-if="form.type == 'time-range'" v-model="form.form.select" value-format="HH:mm:ss" placeholder="选择时间" is-range></el-time-picker>
                <el-date-picker clearable v-if="form.type == 'datetime'" v-model="form.form.value" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间"></el-date-picker>
                <el-date-picker clearable v-if="form.type == 'datetime-range'" v-model="form.form.select" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间"></el-date-picker>
            </el-form-item>
        </el-form>

        <div slot="footer">
            <el-button size="small" @click="Close()">取 消</el-button>
            <el-button size="small" type="primary" @click="FormSubmit()" :loading="loading">确 定</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    props: ['networkOn', 'treeOn'],
    data() {
        return {
            visible: false,
            loading: false,

            formError: {},
            form: {
                id: null,
                model: null,
                name: null,
                type: null,
                required: 0,
                placeholder: null,
                form: {
                    value: null,
                    select: null
                }
            }
        }
    },
    methods: {

        /**
        |--------------------------------------------------
        | 打开窗口
        |--------------------------------------------------
        |
        */

        Open(id) {
            this.visible = true
            this.loading = true

            // 获取合同信息
            this.$http.post('/9api/data-dict/first', { id: id }).then(async rps => {
                this.form.id = rps.data.id,
                this.form.model = rps.data.model,
                this.form.name = rps.data.name,
                this.form.type = rps.data.type,
                this.form.required = rps.data.required,
                this.form.placeholder = rps.data.placeholder,
                this.form.form = rps.data.form

                this.loading = false
            })

            
        },

        /**
        |--------------------------------------------------
        | 关闭窗口
        |--------------------------------------------------
        |
        */

        Close() {
            this.visible = false
        },

        /**
        |--------------------------------------------------
        | 提交表单
        |--------------------------------------------------
        |
        */

        FormSubmit() {

            this.loading = true

            this.$http.post('/9api/data-dict/update', this.form).then((rps) => {
                // 提交失败
                if (rps.data.state == 'error') {
                    this.formError = rps.data.body
                }
                // 提交成功
                if (rps.data.state == 'success') {
                    this.$emit('onChange', rps.data.body)
                    this.Close()
                }

                this.loading = false
            })
        },

        /**
        |--------------------------------------------------
        | 类型变更
        |--------------------------------------------------
        |
        */

        TypeChange(type) {
            if (['text','textarea','date','time','datetime'].includes(type)) {
                this.form.form.value = null
                this.form.form.select = null
            }

            if (['int','float'].includes(type)) {
                this.form.form.value = 0
                this.form.form.select = null
            }

            if (['checkbox'].includes(type)) {
                this.form.form.value = []
                this.form.form.select = ['选项1']
            }

            if (['select','select-multiple','radio'].includes(type)) {
                this.form.form.value = null
                this.form.form.select = ['选项1']
            }

            if (type == 'time-range') {
                this.form.form.value = null
                this.form.form.select = ['09:00:00', '16:00:00']
            }

            if (type == 'date-range') {
                this.form.form.value = null
                this.form.form.select = []
            }

            if (type == 'datetime-range') {
                this.form.form.value = null
                this.form.form.select = []
            }
        }
    }
}
</script>