<template>
    <div>
        <span :style="{ fontSize: '12px' }" v-if="!edit">{{ select.find(rps => rps.value == selectValue).label }}</span>
        <el-select
            v-if="edit"
            @change="SelectChange"
            v-model="selectValue"
            placeholder="请选择考核指标"
            size="mini"
            clearable
            :disabled="disabled"
            :style="{ width: width, margin: margin }">
            <el-option v-for="(item, idx) in select" :key="idx" :label="item.label" :value="item.value"></el-option>
        </el-select>
    </div>
</template>

<script>
export default {
    props: {
        edit: {
            type: Boolean,
            default: false
        },

        disabled: {
            type: Boolean,
            default: false
        },

        value: {
            type: String,
            default: null
        },

        width: {
            type: String,
            default: '100%'
        },

        margin: {
            type: String,
            default: '0px'
        },
    },
    
    watch: {
        value: {
            handler(val) {
                if (val) {
                    this.selectValue = val
                    this.selectName = this.select.find(rps => rps.value == val).label
                }
            },
            immediate: true
        },
    },

    data () {
        return {
            select: [
                { value: 'pm_p', label: 'PM 工时 / P' },
                { value: 'crm_sign_money', label: 'CRM 签单金额 / 元' }
            ],

            selectValue: null,
            selectName: null
        }
    },

    methods: {
        SelectChange(data) {
            this.$emit('input', data)
            this.$emit('onChange', data)
        }
    }
}
</script>