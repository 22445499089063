<template>
    <el-drawer :visible.sync="visible" size="80%" direction="rtl" append-to-body destroy-on-close :show-close="false" :before-close="Close">

        <div slot="title">

            <div class="w-margin-b-10">线索</div>
            <div class="w-margin-b-10" style="font-size: 18px; color: #000; font-weight: bold;">{{ leads.name }}</div>

            <!-- 功能按钮 -->
            <div style="display: flex;">
                <el-button size="mini" icon="el-icon-edit" @click="RePage()">刷新</el-button>
                <el-button style="margin-left: 5px;" size="mini" icon="el-icon-edit" @click="$refs.leadsUpdate.Open(leads.id)">编辑线索</el-button>
                <el-button style="margin-left: 5px;" size="mini" @click="DataDelete()" type="danger">移除线索</el-button>
                <el-button style="margin-left: 5px;" size="mini" @click="Conversion" type="primary">转化为客户</el-button>
            </div>

            <!-- 基本信息 -->
            <div class="w-margin-t-10" style="background-color: #f4f5f7; border-radius: 3px; padding: 15px; display: flex;">
                <el-statistic title="线索来源"><template slot="formatter"><span style="font-size: 14px;">{{ leads.source?.map(rps => rps.name).toString() || '未登记' }}</span></template></el-statistic>
                <el-statistic title="手机"><template slot="formatter"><span style="font-size: 14px;">{{ leads.phone || '未登记' }}</span></template></el-statistic>
                <el-statistic title="负责人"><template slot="formatter"><span style="font-size: 14px;">{{ leads.user_admin?.name || '未登记' }}</span></template></el-statistic>
                <el-statistic title="更新时间"><template slot="formatter"><span style="font-size: 14px;">{{ leads.updated_at }}</span></template></el-statistic>
            </div>

        </div>

        <div class="w-padding-l-20 w-padding-r-20 w-padding-b-20">
            <el-tabs v-model="tabs">

                <!-- 详细资料 -->
                <el-tab-pane label="详细资料" name="details">
                    
                    <!-- 基本信息 -->
                    <el-descriptions title="基本信息" size="mini" border :column="1" class="w-margin-b-20">
                        <el-descriptions-item label="线索名称">{{ leads.name }}</el-descriptions-item>
                        <el-descriptions-item label="线索来源">{{ leads.source?.map(rps => rps.name).toString() }}</el-descriptions-item>
                        <el-descriptions-item label="客户级别">{{ leads.level?.name }}</el-descriptions-item>
                        <el-descriptions-item label="客户行业">{{ leads.type?.name }}</el-descriptions-item>
                        <el-descriptions-item label="手机">{{ leads.phone }}</el-descriptions-item>
                        <el-descriptions-item label="电话">{{ leads.phone_t }}</el-descriptions-item>
                        <el-descriptions-item label="电子邮件">{{ leads.email }}</el-descriptions-item>
                        <el-descriptions-item label="所在区域">{{ leads.country ? leads.country.map(rps => rps.name).toString() : '' }}</el-descriptions-item>
                        <el-descriptions-item label="详细地址">{{ leads.address }}</el-descriptions-item>
                        <el-descriptions-item label="备注">{{ leads.remark }}</el-descriptions-item>
                    </el-descriptions>

                    <!-- 系统信息 -->
                    <el-descriptions title="系统信息" size="mini" border :column="2">
                        <el-descriptions-item label="创建时间">{{ leads.created_at }}</el-descriptions-item>
                        <el-descriptions-item label="创建人">{{ leads.created_user.name }}</el-descriptions-item>
                        <el-descriptions-item label="更新时间">{{ leads.updated_at }}</el-descriptions-item>
                        <el-descriptions-item label="更新人">{{ leads.updated_user.name }}</el-descriptions-item>
                    </el-descriptions>

                </el-tab-pane>

                <!-- 企业概况 -->
                <el-tab-pane label="企业概况" name="gs">
                    <crm-leads-drawer-enterprise />
                </el-tab-pane>

            </el-tabs>
        </div>

        <!-- 组件 -->
        <crm-leads-update ref="leadsUpdate" @onChange="RePage" />

    </el-drawer>
</template>

<script>
export default {
    data() {
        return {
            visible: false,
            loading: false,
            tabs: 'details',

            leads: {
                id: null,
                source: [],
                level: {},
                type: {},
                country: [],
                user_admin: {},
                created_user: {},
                updated_user: {}
            }
        }
    },

    methods: {

        /**
        |--------------------------------------------------
        | 打开窗口
        |--------------------------------------------------
        |
        */

        Open(id) {
            this.visible = true
            this.DataGet(id)
        },

        /**
        |--------------------------------------------------
        | 关闭窗口
        |--------------------------------------------------
        |
        */

        Close(done) {
            this.$emit('onChange')
            done()
        },

        /**
        |--------------------------------------------------
        | 刷新
        |--------------------------------------------------
        |
        */

        RePage() {
            this.DataGet()
        },

        /**
        |--------------------------------------------------
        | 查询
        |--------------------------------------------------
        |
        */

        DataGet(id = this.leads.id) {
            this.loading = true

            this.$http.post(
                '/9api/crm/leads/search-first',
                {
                    search: { id: id },
                    append: ['user_admin', 'created_user', 'updated_user', 'type', 'source', 'country', 'level']
                }
            ).then(rps => {
                this.leads = rps.data
                this.loading = false
            }) 
        },

        /**
        |--------------------------------------------------
        | 删除
        |--------------------------------------------------
        |
        */

        DataDelete() {
            this.$http.post('/9api/crm/leads/delete', { id: this.leads.id }).then(rps => {
                this.$emit('onChange')
                this.visible = false
            })
        },

        /**
        |--------------------------------------------------
        | 转化为客户
        |--------------------------------------------------
        |
        */

        Conversion() {
            this.$confirm('确定将这些线索转换为客户吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http.post('/9api/crm/leads/conversion', { id: this.leads.id }).then(rps => {
                    this.$emit('onChange')
                    this.visible = false
                })
            })
        }

        
    }
}

</script>6
