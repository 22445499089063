<template>
    <div>
        <el-input
            v-if="showInput"
            v-model="select.inputValue"
            @focus="Open"
            @clear="SelectClear"
            placeholder="回款记录"
            clearable
            :disabled="disabled"
            :style="{ width: width, margin: margin }"/>

        <el-dialog :visible.sync="dialogVisible" width="1000px" append-to-body title="回款记录" :close-on-click-modal="false" :close-on-press-escape="false">

            <!-- 提示 -->
            <el-alert v-if="showInput" title="提示：双击鼠标左键 进行选择" type="info" close-text="知道了" show-icon :style="{ marginBottom: '10px' }" />

            <!-- toolbar -->
            <uw-grid-search gutter="0 10px" :column="4" :style="{ marginBottom: '10px' }">
                <template slot="full">
                    <crm-contract-select v-model="post.search.contract_id" @onChange="TableSearch" />
                </template>
                <el-form-item label="客户名称">
                    <crm-customer-select v-model="post.search.customer_id" @onChange="TableSearch"/>
                </el-form-item>
                <el-form-item label="负责人">
                    <users-select v-model="post.search.users_ids" @onChange="TableSearch" multiple placeholder="销售查询" />
                </el-form-item>
                <el-form-item label="开票信息">
                    <el-select v-model="post.search.invoice_state" @change="TableSearch" placeholder="是否开具发票" clearable :style="{ width: '100%' }">
                        <el-option label="未开具发票" :value="0"></el-option>
                        <el-option label="已开具发票" :value="1"></el-option>
                        <el-option label="无需开具发票" :value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="款项日期">
                    <el-date-picker @change="TableSearch" v-model="post.search.pay_date" clearable type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :style="{ width: '100%' }"></el-date-picker>
                </el-form-item>
            </uw-grid-search>

            <!-- table -->
            <vxe-table ref="xTable" :data="table" show-footer :footer-method="TableFooterMethod" :loading="$store.state.loading" @sort-change="TableSort" @cell-dblclick="TableCellDblclick" height="500px">

                <!-- 序号 -->
                <vxe-column width="60" type="seq" title="序号"></vxe-column>

                <!-- 回款金额 -->
                <vxe-column width="120" field="pay_money" title="回款金额" sortable>
                    <template #default="{ row }">
                        <el-link type="primary">
                            ￥{{ row.pay_money.toLocaleString() }}
                        </el-link>
                    </template>
                </vxe-column>

                <!-- 冲销金额 -->
                <vxe-column width="120" field="pay_money_offset" title="冲销金额" sortable>
                    <template #default="{ row }">
                        <el-link type="primary">
                            ￥{{ row.pay_money_offset }}
                        </el-link>
                    </template>
                </vxe-column>

                <!-- 记业绩金额 -->
                <vxe-column width="120" field="pay_money_performance" title="记业绩金额" sortable>
                    <template #default="{ row }">
                        <el-link type="success">
                            ￥{{ row.pay_money_performance }}
                        </el-link>
                    </template>
                </vxe-column>

                <!-- 客户名称 -->
                <vxe-column width="200" field="customer.name" title="客户名称">
                    <template #default="{ row }">
                        <span class="el-span-primary" @click="$refs.customerDrawer.Open(row.customer.id)">{{ row.customer.name }}</span>
                    </template>
                </vxe-column>

                <!-- 对应合同 -->
                <vxe-column min-width="250" field="contract.name" title="对应合同">
                    <template #default="{ row }">
                        <span class="el-span-primary" @click="$refs.contractDrawer.Open(row.contract.id)">
                            {{ row.contract.number }} - {{ row.contract.name }}
                        </span>
                    </template>
                </vxe-column>

                <!-- 负责人 -->
                <vxe-column width="80" field="user.name" title="负责人"></vxe-column>

                <!-- 开具发票 -->
                <vxe-column width="100" field="invoice_state" title="开具发票">
                    <template #default="{ row }">
                        <el-link v-if="row.invoice_state == 0" type="warning">未开具发票</el-link>
                        <el-link v-if="row.invoice_state == 1" type="success">已开具发票</el-link>
                        <el-link v-if="row.invoice_state == 2" type="info">无需开具发票</el-link>
                    </template>
                </vxe-column>

                <!-- 回款日期 -->
                <vxe-column width="100" field="pay_date" title="回款日期" sortable></vxe-column>

                <!-- 回款备注 -->
                <vxe-column width="200" field="describe" title="回款备注"></vxe-column>

                <!-- 创建者 -->
                <vxe-column width="80" field="created_user.name" title="创建者"></vxe-column>

                <!-- 更新者 -->
                <vxe-column width="80" field="updated_user.name" title="更新者"></vxe-column>

            </vxe-table>

            <!-- pager -->
            <vxe-pager 
                :style="{ marginTop: '10px' }"
                :total="post.page.total"
                :page-size.sync="post.page.size"
                :current-page.sync="post.page.current"
                @page-change="TableSearch()">
            </vxe-pager>

        </el-dialog>

        <!-- 组件 -->
        <crm-contract-drawer ref="contractDrawer" @onChange="TableSearch" />
        <crm-customer-drawer ref="customerDrawer" @onChange="TableSearch" />

    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: [String, Array, Number],
            default: null
        },

        width: {
            type: String,
            default: '100%'
        },

        margin: {
            type: String,
            default: '0px'
        },

        disabled: {
            type: Boolean,
            default: false
        },

        showInput: {
            type: Boolean,
            default: true
        }
    },

    data () {
        return {
            dialogVisible: false,

            // 查询结果
            table: [],
            statistics: {},

            // 查询链接
            url: {
                default: '/9api/crm/contract-pay-record/search',
                first: '/9api/crm/contract-pay-record/search-first'
            },

            // 查询条件
            post: {
                page: {
                    total: 0,
                    size: 50,
                    current: 1,
                },
                search: {
                    contract_id: null,
                    customer_id: null,
                    users_ids: null,
                    invoice_state: null,
                    pay_type: 1,
                    pay_date: null,
                    auth_employee: false
                    
                },
                append: ['user', 'customer', 'contract', 'created_user', 'updated_user'],
                hidden: [],
                order: {
                    method: 'desc',
                    column: 'id'
                },
            },

            // 选择结果
            select: {
                data: {},
                inputValue: null
            }
        }
    },

    watch: {
        value: {
            handler(val) {
                val ? this.SelectSearch() : this.SelectClear()
            },
            immediate: true
        }
    },

    methods: {

        // 窗口：打开
        Open(search)
        {
            // 初始化检索条件
            if (search)
            {
                this.post.search = { ...this.post.search, ...search }
            }

            this.dialogVisible = true
            this.TableSearch()
        },

        // 数据：查询
        TableSearch()
        {
            this.$http.post(this.url.default, this.post).then((rps) => {
                // 更新数据
                this.table = rps.data.body
                this.post.page.total = rps.data.total
                this.statistics = rps.data.statistics

                // 页码溢出
                if (this.table.length == 0 && this.post.page.current != 1)
                {
                    this.post.page.current = 1
                    this.TableSearch()
                }
            })
        },

        // 表格：排序
        TableSort(column)
        {
            this.post.order.method = column.order ? column.order : this.post.order.method
            this.post.order.column = column.field
            this.TableSearch()
        },

        // 表格：双击事件
        TableCellDblclick({ row })
        {
            this.select.inputValue = row.name
            this.select.data = row
            this.$emit('input', row.id)
            this.$emit('onChange', row)
            this.dialogVisible = false
        },

        // 表尾合计
        TableFooterMethod() {
            return [
                ['本页', '￥' + this.statistics.page?.pay_money.toLocaleString(), '￥' + this.statistics.page?.pay_money_offset.toLocaleString(), '￥' + this.statistics.page?.pay_money_performance.toLocaleString()],
                ['合计', '￥' + this.statistics.all?.pay_money.toLocaleString(), '￥' + this.statistics.all?.pay_money_offset.toLocaleString(), '￥' + this.statistics.page?.pay_money_performance.toLocaleString()]
            ]
        },

        // 选择：查询
        SelectSearch()
        {
            this.$http.post(this.url.first, { id: this.value }).then(rps => {
                this.select.inputValue = rps.data.name
                this.select.data = rps.data
                this.$emit('input', rps.data.id)
                this.$emit('onChange', rps.data)
            })
        },

        // 选择：清除
        SelectClear()
        {
            this.select.inputValue = null
            this.select.data = {}
            this.$emit('input', null)
            this.$emit('onChange', null)
        },
    }
}
</script>