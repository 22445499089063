<template>
    <div :class="['uw-menu-item', isActive ? 'is-active' : '' ]" @click="onClick()">
        <slot>
            <i v-if="showIcon && icon" :class="icon"></i>
            <span v-if="showTitle && title">{{ title }}</span>
        </slot>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: null
        },
        path: {
            type: String,
            default: null
        },
        target: {
            type: String,
            default: '_self'
        },
        icon: {
            type: String,
            default: null
        },
        showIcon: {
            type: Boolean,
            default: true
        },
        showTitle: {
            type: Boolean,
            default: true
        }
    },

    computed: {
        isActive () {
            return [this.$route.fullPath, this.$route.path].includes(this.path)
        }
    },

    methods: {
        onClick()
        {
            if (this.target == '_blank')
            {
                window.open(this.path, this.target)
            }
            if (this.target == '_self')
            {
                this.$router.push(this.path)
            }
        },

        getIsActive()
        {
            return this.isActive
        }
    }
}
</script>