<!---------------------------------------------------------------------------- 
 | 数据字典 - 字段类型
 |----------------------------------------------------------------------------
 | 方法：请注意大小写,参数描述见具体方法
 | *v-model     默认值------默认为 null
 | width        表单宽度----默认为 ‘100%’
 | margin       表单外边距--默认为 ‘0px’
 |----------------------------------------------------------------------------
 | 回调
 | @onChange = fn (String) => {} 回调
 |
 ---------------------------------------------------------------------------->

 <template>
    <el-select
        @change="SelectChange"
        v-model="selectValue"
        size="mini"
        :style="{ width: width, margin: margin }"
        placeholder="请选择字段类型"
        clearable
        filterable>
        <el-option label="单行文本" value="text"></el-option>
        <el-option label="多行文本" value="textarea"></el-option>
        <el-option label="下拉菜单(单选)" value="select"></el-option>
        <el-option label="下拉菜单(多选)" value="select-multiple"></el-option>
        <el-option label="单选框" value="radio"></el-option>
        <el-option label="多选框" value="checkbox"></el-option>
        <el-option label="整数" value="int"></el-option>
        <el-option label="浮点数" value="float"></el-option>
        <el-option label="日期" value="date"></el-option>
        <el-option label="日期范围" value="date-range"></el-option>
        <el-option label="时间" value="time"></el-option>
        <el-option label="时间范围" value="time-range"></el-option>
        <el-option label="日期时间" value="datetime"></el-option>
        <el-option label="日期时间范围" value="datetime-range"></el-option>
    </el-select>
</template>

<script>
export default {
    props: {
        value: {
            type: [String, Array, Number],
            default: null
        },

        width: {
            type: String,
            default: '100%'
        },

        margin: {
            type: String,
            default: '0px'
        },
    },

    watch: {
        value () {
            this.selectValue = this.value
        }
    },

    data () {
        return {
            selectValue: null,
        }
    },

    methods: {
        SelectChange(value) {
            this.$emit('input', value)
            this.$emit('onChange', value)
        }
    }
}
</script>