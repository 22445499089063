<!---------------------------------------------------------------------------- 
 | 数据字典 - 业务模块
 |----------------------------------------------------------------------------
 | 方法：请注意大小写,参数描述见具体方法
 | *v-model     默认值------默认为 null
 | width        表单宽度----默认为 ‘100%’
 | margin       表单外边距--默认为 ‘0px’
 |----------------------------------------------------------------------------
 | 回调
 | @onChange = fn (String) => {} 回调
 |
 ---------------------------------------------------------------------------->

 <template>
    <el-select
        @change="SelectChange"
        v-model="selectValue"
        size="mini"
        :style="{ width: width, margin: margin }"
        placeholder="请选择业务模块"
        clearable
        filterable>
        <el-option label="工单" value="wo"></el-option>
    </el-select>
</template>

<script>
export default {
    props: {
        value: {
            type: [String, Array, Number],
            default: null
        },

        width: {
            type: String,
            default: '100%'
        },

        margin: {
            type: String,
            default: '0px'
        },
    },

    watch: {
        value () {
            this.selectValue = this.value
        }
    },

    data () {
        return {
            selectValue: null,
        }
    },

    methods: {
        SelectChange(value) {
            this.$emit('input', value)
            this.$emit('onChange', value)
        }
    }
}
</script>