<!---------------------------------------------------------------------------- 
 | 选取用户
 |----------------------------------------------------------------------------
 | 方法：请注意大小写
 | $refs[ref_name].Open() 打开抽屉
 | $refs[ref_name].Close() 手动关闭抽屉
 |----------------------------------------------------------------------------
 | props 
 | :width 宽度
 | :height 高度
 | :multiple 多选
 |----------------------------------------------------------------------------
 | 回调
 | @onChange = fn (Arr||json) => {} 确定后，返回用户信息
 | @onClose = fn () => {} 窗口关闭时回调
 |
 ---------------------------------------------------------------------------->
<template>
    <el-dialog class="w-el-dialog" :visible.sync="dialogVisible" fullscreen append-to-body :close-on-click-modal="false" :before-close="Close" :style="{ height: height, width: width, top: 'calc((100% - '+height+')/2)', left: 'calc((100% - '+width+')/2)'}">

        <!-- title -->
        <div slot="title">
            <i class="el-icon-user"></i> 用户选取 {{ !this.multiple ? '(双击选择用户)' : '' }}
        </div>
        
        <!-- 内容 -->
        <div class="comp-box">

            <!-- 组织信息 -->
            <div class="comp-box-left el-scroll-y">

                <!-- 组织结构 -->
                <el-tree ref="organize" :data="organize" :props="organizeProps" @node-click="OrganizeNodeClick">
                    <span slot-scope="{ data }" class="el-tree-custom-node">
                        <span>{{ data.name }}</span>
                    </span>
                </el-tree>
            </div>

            <!-- 用户表 -->
            <div class="comp-box-right">

                <!-- 表数据 -->
                <div class="comp-box-right-table">

                    <!-- 用户表 -->
                    <vxe-table
                        ref="xTable"
                        :data="tableData"
                        :loading="tableLoading"
                        @checkbox-change="CheckboxChange()"
                        @checkbox-all="CheckboxChange()"
                        @cell-dblclick="CellDBLClickEvent"
                        :checkbox-config="{ reserve: true }"
                        row-id="id"
                        :row-config="{isCurrent: true, isHover: true}"
                        :column-config="{isCurrent: true, isHover: true}"
                        :export-config="{}"
                        :filter-config="{showIcon: false}"
                        :print-config="{}"
                        height="100%"
                        border="inner">
                        
                        <!-- seq -->
                        <vxe-column width="45" type="checkbox" fixed="left" v-if="multiple"></vxe-column>

                        <!-- 姓名 -->
                        <vxe-column width="150" field="name">
                            <template #header>
                                <el-input clearable size="mini" placeholder="姓名" v-model="search.name" @change="UsersGet()" />
                            </template>
                            <template #default="{ row }">
                                <div style="display: flex; align-items: center; justify-content: space-between;">
                                    <el-avatar style="margin-right: 5px;" :size="26" v-if="row.avatar" :src="row.avatar" />
                                    <el-avatar style="margin-right: 5px;" :size="26" v-else> ? </el-avatar>
                                    {{  row.name }}
                                </div>
                            </template>
                        </vxe-column>

                        <!-- 性别 -->
                        <vxe-column show-overflow width="60" field="user_type" title="性别">
                            <template #default="{ row }">
                                <span v-if="row.sex == 0" style="color: #909399">未知</span>
                                <span v-if="row.sex == 1" style="color: #409EFF">男</span>
                                <span v-if="row.sex == 2" style="color: #F56C6C">女</span>
                            </template>
                        </vxe-column>

                        <!-- 状态 -->
                        <vxe-column width="60" field="status" title="状态">
                            <template v-slot="{ row }">
                                <span v-if="row.status == 0" style="color: #409EFF">在职</span>
                                <span v-else style="color: #F56C6C">离职</span>
                            </template>
                        </vxe-column>

                        <!-- 企业微信 -->
                        <vxe-column width="80" field="name" title="企业微信">
                            <template #default="{ row }">
                                <span v-if="row.wecom" style="color: #409EFF">已绑定</span>
                                <span v-else style="color: #909399">未绑定</span>
                            </template>
                        </vxe-column>

                        <!-- 工号/手机号/邮箱 -->
                        <vxe-column width="120" field="job_number" title="工号"></vxe-column>
                        <vxe-column width="150" field="phone" title="手机号">
                            <template #header>
                                <el-input clearable size="mini" placeholder="手机号" v-model="search.phone" @change="UsersGet()" />
                            </template>
                        </vxe-column>
                        <vxe-column width="150" field="email" title="邮箱"></vxe-column>

                        <!-- 用户权限/岗位 -->
                        <vxe-column min-width="200" field="users_role" title="用户权限/岗位">
                            <template #default="{ row }">
                                <span v-for="(item, idx) in row.users_role" style="color: #409EFF" :key="idx">{{ item.users_role_name }}&nbsp;&nbsp;&nbsp;</span>
                            </template>
                        </vxe-column>
                    </vxe-table>
                </div>

                <!-- 分页 -->
                <div class="comp-box-right-table-pager">
                    <vxe-pager 
                        :layouts="['Total', 'Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump',]"
                        :total="pageTotal"
                        :page-size.sync="pageSize"
                        :current-page.sync="pageCurrent"
                        @page-change="UsersGet()">
                    </vxe-pager>
                </div>
                
            </div>
        </div>

        <!-- footer -->
        <div slot="footer">

            <!-- 提示 -->
            <el-popover placement="top-start" title="已选" width="300" trigger="hover" v-if="multiple">

                <!-- 已选列表 -->
                <el-link type="primary" v-for="(item, idx) in checkbox" :key="idx">
                    {{ item.name }},&nbsp;
                </el-link>

                <!-- 已选统计 -->
                <el-link type="primary" slot="reference" style="margin-right: 20px;">
                    已选中 {{ checkbox.length }} 位用户
                </el-link>
            </el-popover>
            
            <!-- 功能 -->
            <el-button size="mini" @click="Close()">取 消</el-button>
            <el-button size="mini" v-if="multiple" @click="CheckboxRemove()">清空</el-button>
            <el-button size="mini" v-if="multiple" type="primary" @click="CheckboxSubmit()">确定</el-button>
        </div>

    </el-dialog>
</template>

<script>
export default {
    props: {
        width: {
            type: String,
            default: '95%',
        },
        height: {
            type: String,
            default: '90%',
        },
        multiple: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            // 窗口配置
            dialogVisible: false,

            // 用户数据
            tableData: [],
            tableLoading: false,

            // 筛选记录
            checkbox: [],
            
            // 检索条件
            search: {
                organize_id: 0,
                name: null,
                phone: null,
                status: 0
            },

            // 分页信息
            pageTotal: 0,
            pageSize: 50,
            pageCurrent: 1,

            // 组织相关 - 左侧结构
            organize: [],
            organizeProps: {
                children: 'children',
                label: 'users_organize_name'
            },
         }
    },
     
    async mounted () {
        this.UsersGet();
        this.OrganizeGet();
    },

    methods: {

        /**
         |--------------------------------------------------
         |窗口 - 打开
         |--------------------------------------------------
         |
         */

        Open () {
            this.dialogVisible = true
        },


        /**
         |--------------------------------------------------
         |窗口 - 关闭
         |--------------------------------------------------
         |
         */

        Close() {
            this.CheckboxRemove()
            this.$emit('onClose')
            this.dialogVisible = false
        },


        /**
         |--------------------------------------------------
         |用户组织 - 查询
         |--------------------------------------------------
         |
         */

        OrganizeGet() {
            this.$http.post('/9api/hrm/organize/cascader').then(rps => {
                this.organize = rps.data
            })
        },


        /**
         |--------------------------------------------------
         |用户组织 - 点击事件
         |--------------------------------------------------
         |
         */

        OrganizeNodeClick(data) {
            this.search.organize_id = data.id
            this.UsersGet()
        },


        /**
         |--------------------------------------------------
         |用户 - 查询
         |--------------------------------------------------
         |
         */
        async UsersGet() {
            
            // 开启加载提示
            this.tableLoading = true

            await this.$http.post('/9api/users/search', {search: this.search, pageSize: this.pageSize, pageCurrent: this.pageCurrent}).then(rps => {
                
                // 完成数据的加载
                this.tableLoading = false
                this.tableData = rps.data.data
                this.pageTotal = rps.data.total

                // 因为页码超出
                if (this.tableData.length == 0 && this.pageCurrent != 1) {
                    this.pageCurrent = 1
                    this.UsersGet ()
                }
            })
        },


        /**
         |--------------------------------------------------
         |checkbox - 事件
         |--------------------------------------------------
         |
         */

        CheckboxChange() {
            this.checkbox = [
                ...this.$refs.xTable.getCheckboxRecords(), 
                ...this.$refs.xTable.getCheckboxReserveRecords()
            ]
        },


        /**
         |--------------------------------------------------
         |checkbox - 清空
         |--------------------------------------------------
         |
         */

        CheckboxRemove() {
            this.$refs.xTable.removeCheckboxRow()
            this.$refs.xTable.clearCheckboxReserve()
            this.checkbox = []
        },


        /**
         |--------------------------------------------------
         |checkbox - 提交
         |--------------------------------------------------
         |
         */

        CheckboxSubmit() {
            this.$emit('onChange', this.checkbox)
            this.Close()
        },

        /**
        |--------------------------------------------------
        | 行 - 双击事件（用户选择）
        |--------------------------------------------------
        |
        */

        CellDBLClickEvent({ row }) {
            if (!this.multiple) {
                this.$emit('onChange', row)
                this.Close()
            }
        }
     }
 }
</script>

<style lang="less" scoped>
    .comp-box {
        width: 100%;
        height: 100%;

        .comp-box-left{
            float: left;
            width: 220px;
            height: 100%;
            box-sizing: border-box;
            padding: 0 10px 0 0;
            border-right: 1px solid #eeeeee;
        }

        .comp-box-right{
            float: right;
            width: calc(100% - 220px);
            height: 100%;
            box-sizing: border-box;
            padding: 0 0 0 10px;

            .comp-box-right-table {
                width: 100%;
                height: calc(100% - 40px);
            }

            .comp-box-right-table-pager {
                .vxe-pager {
                    align-items: flex-end;
                }
            }
        }
    }
</style>