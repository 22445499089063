<template>
    <div :style="{ height: '100%', display: 'flex', flexDirection: 'column' }">
        
        <!-- toolbar -->
        <uw-grid-search gutter="0 10px" :column="3" :style="{ marginBottom: '10px' }">
            <template slot="tools">
                <contract-button-files-upload @onChange="Search" :contract_id="contract.id" />
            </template>
        </uw-grid-search>

        <!-- 数据表 -->
        <div :style="{ flex: '1' }">
            <files-table v-model="contract.files_id" />
        </div>
    </div>
</template>

<script>
export default {
    props: [ 'contract_id', 'customer_id' ],

    data () {
        return {
            contract: {
                id: null,
                source: [],
                customer: {},
                project: {},
                time_limit: [],
                user_admin: {},
                user_affair: {},
                user_presale: {},
                user_sale: {},
                files_id: []
            }
        }
    },
    
    watch: {
        contract_id: {
            handler(val) {
                if(val) {
                    this.Search()
                }
            },
            immediate: true
        }
    },

    methods: {

        /**
        |--------------------------------------------------
        | 数据查询
        |--------------------------------------------------
        |
        */

        // 合同 - 查询
        Search() {
            this.$http.post(
                '/9api/crm/contract/search-first',
                {
                    id: this.contract_id,
                    hidden: ['describe']
                }
            ).then(rps => {
                this.contract = rps.data
            })
        },
    }
}
</script>