<!---------------------------------------------------------------------------- 
 | 参数：请注意大小写,参数描述见具体方法
 | Open(Object search) => 作用：打开窗口
 |----------------------------------------------------------------------------
 | 参数
 | :v-model     默认值------默认为 null
 | :width       默认值------默认为 100%
 | :margin      默认值------默认为 0px
 | :disabled    默认值------默认为 false
 | :showInput   默认值------默认为 true
 |----------------------------------------------------------------------------
 | 回调
 | @onChange = fn (data) => {} 返回表单内容
 ---------------------------------------------------------------------------->

 <template>
    <div>
        <el-input
            v-if="showInput"
            v-model="selectName"
            @focus="Open"
            @clear="SelectClear"
            placeholder="请选择客户"
            clearable
            :disabled="disabled"
            :style="{ width: width, margin: margin }"/>

        <el-dialog :visible.sync="dialogVisible" width="900px" append-to-body title="客户" :close-on-click-modal="false" :close-on-press-escape="false">
            
            <!-- toolbar -->
            <uw-grid-search gutter="0 10px" :column="3" :style="{ marginBottom: '10px' }">
                <template slot="full">
                    <el-input @input="TableSearch" v-model="search.name" clearable  placeholder="客户名称"></el-input>
                </template>
                <template slot="tools">
                    <el-button type="primary" @click="$refs.customerCreate.Open()">添加客户</el-button>
                </template>
                <el-form-item label="客户名称">
                    <el-input @input="TableSearch" v-model="search.name" clearable  placeholder="客户名称"></el-input>
                </el-form-item>
                <el-form-item label="客户编号">
                    <el-input @input="TableSearch" v-model="search.number" clearable  placeholder="客户编号"></el-input>
                </el-form-item>
                <el-form-item label="客户行业">
                    <crm-customer-type-select @onChange="TableSearch" v-model="search.type_id" clearable />
                </el-form-item>
                <el-form-item label="所属员工">
                    <users-select @onChange="TableSearch" v-model="search.users_ids" multiple />
                </el-form-item>
                <el-form-item label="添加日期">
                    <el-date-picker @change="TableSearch" v-model="search.created_at" clearable type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :style="{ width: '100%' }"></el-date-picker>
                </el-form-item>
            </uw-grid-search>

            <!-- table -->
            <vxe-table ref="xTable" :data="table" :loading="loading" @sort-change="TableSort" @cell-dblclick="TableCellDblclick" height="500px">

                <!-- 客户名称 -->
                <vxe-column min-width="250" field="name" title="客户名称" sortable>
                    <template #default="{ row }">
                        <el-link type="primary" icon="el-icon-office-building" @click="$refs.customerDrawer.Open(row.id)">{{ row.name }}</el-link>
                    </template>
                </vxe-column>

                <!-- 其它信息 -->
                <vxe-column min-width="120" field="number" title="客户编号" sortable></vxe-column>
                <vxe-column min-width="120" field="type" title="客户种类" sortable></vxe-column>
                <vxe-column min-width="90" field="user.name" title="所属员工"></vxe-column>
                <vxe-column min-width="140" field="created_at" title="添加时间"></vxe-column>

            </vxe-table>

            <!-- pager -->
            <vxe-pager 
                :style="{ marginTop: '10px' }"
                :total="page.total"
                :page-size.sync="page.size"
                :current-page.sync="page.current"
                @page-change="TableSearch()">
            </vxe-pager>

        </el-dialog>

        <!-- dialog -->
        <crm-customer-create ref="customerCreate" @onChange="TableSearch()" />
        <crm-customer-drawer ref="customerDrawer" @onChange="TableSearch()" />

    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: [String, Array, Number],
            default: null
        },

        width: {
            type: String,
            default: '100%'
        },

        margin: {
            type: String,
            default: '0px'
        },

        disabled: {
            type: Boolean,
            default: false
        },

        showInput: {
            type: Boolean,
            default: true
        }
    },

    watch: {
        value: {
            handler(val) {
                if (val) {
                    this.$http.post('/9api/crm/customer/first', { id: val }).then(rps => {
                        this.selectName = rps.data.name
                        this.$emit('onChange', rps.data)
                    })
                } else {
                    this.SelectClear()
                }
            },
            immediate: true
        }
    },

    data () {
        return {
            dialogVisible: false,
            
            table: [],

            // 分页
            page: {
                total: 0,
                size: 50,
                current: 1,
            },

            // 检索
            search: {
                name: null,
                number: null,
                type_id: null,
                users_ids: null,
                created_at: []
            },

            // 排序
            order: {
                method: 'desc',
                column: 'id'
            },

            selectObject: {},
            selectName: null
        }
    },

    // 计算熟悉：
    computed: {
        loading ()
        {
            return !!this.$store.state.httpRequestCount
        }
    },

    methods: {

        // 窗口：打开
        Open(search)
        {
            // 初始化检索条件
            if (search)
            {
                this.search = { ...this.search, ...search }
            }

            this.dialogVisible = true
            this.TableSearch()
        },

        // 数据：查询
        TableSearch()
        {
            this.$http.post(
                '/9api/crm/customer/search',
                {
                    page: this.page,
                    search: { ...this.search, ...{ contract_id: this.id } },
                    order: this.order,
                    append: ['user', 'type']
                }
            ).then((rps) => {
                // 更新数据
                this.page.total = rps.data.total
                this.table = rps.data.body

                // 页码溢出
                if (this.table.length == 0 && this.page.current != 1) {
                    this.page.current = 1
                    this.TableSearch()
                }
            })
        },

        // 表格：排序
        TableSort(column)
        {
            this.order.method = column.order ? column.order : this.order.method
            this.order.column = column.field
            this.TableSearch()
        },

        // 表格：双击事件
        TableCellDblclick({ row })
        {
            this.selectName = row.name
            this.selectObject = row
            this.$emit('input', row.id)
            this.$emit('onChange', row)
            this.dialogVisible = false
        },

        // 选项：清除
        SelectClear()
        {
            this.selectName = null
            this.selectObject = {}
            this.$emit('input', null)
            this.$emit('onChange', null)
        },
    }
}
</script>