<template>
    <el-dialog :visible.sync="visible" width="80%" append-to-body title="工单工时/工单记录" :close-on-click-modal="false" :close-on-press-escape="false" :before-close="Close">
    
        <!-- toolbar -->
        <uw-grid-search gutter="0 10px" :column="3" :style="{ marginBottom: '10px' }">
            <template slot="tools">
                <el-button type="primary" @click="$refs.xTable.print()" >打印</el-button>
                <el-button type="primary" @click="$refs.xTable.openExport()" >导出</el-button>
                <el-button type="primary" @click="Repair()">更新 / 修正</el-button>
            </template>
        </uw-grid-search>

        <!-- table -->
        <vxe-table ref="xTable" :data="table" :loading="$store.state.loading" :export-config="{}" :print-config="{}" min-height="500px">
                            
            <!-- 关联项目 -->
            <vxe-column min-width="150" title="关联项目">
                <template #default="{ row }">
                    <el-link type="info" :underline="false" v-if="!row.projects">未关联</el-link>
                    <a :style="{ cursor: 'pointer', color: '#0027ff' }" v-else @click="$refs.projectsDrawer.Open(row.projects.id)">{{ row.projects.number }}-{{ row.projects.name }}</a>
                </template>
            </vxe-column>

            <!-- 关联工单 -->
            <vxe-column min-width="150" title="关联工单" show-overflow>
                <template #default="{ row }">
                    <a :style="{ cursor: 'pointer', color: '#0027ff' }" @click="$refs.woDrawer.Open(row.id)" type="primary">{{ row.number }}-{{ row.name }}</a>
                </template>
            </vxe-column>

            <!-- 任务消耗 -->
            <vxe-column min-width="90" title="工单消耗/P">
                <template #default="{ row }">
                    <el-link :underline="false" icon="el-icon-time">
                        <span v-if="search.user_id">
                            {{ row.day_log.find(item => item.id == search.user_id)['day_consume'] }}
                        </span>
                        <span v-else>
                            {{ row.day_log.map(rps => rps.day_consume).reduce((acc, cur) => acc + cur, 0) }}
                        </span>
                    </el-link>
                </template>
            </vxe-column>

            <!-- 记录人 -->
            <vxe-column min-width="160" title="记录时间" field="finish_at"></vxe-column>

            <!-- 操作 -->
            <vxe-column width="60"  title="操作" align="center" fixed="right" v-if="$store.state.user.id == 12">
                <template #default="{ row }">
                    <el-link :underline="false" @click.native.stop="$refs.alarmCreate.Open({ wo_id: row.id })" type="danger"  v-if="!row.alarm_state">异常</el-link>
                </template>
            </vxe-column>

        </vxe-table>

        <!-- 工时组件 -->
        <pm-wo-alarm-create ref="alarmCreate" @onChange="Search" />
        <pm-projects-drawer ref="projectsDrawer" />
        <pm-wo-drawer       ref="woDrawer" />

    </el-dialog>
</template>

<script>
export default {

    data () {
        return {
            // 加载状态
            visible: false,

            table: [],

            search: {
                user_id: null,
                projects_id: null,
                finish_at: null,
                year: null,
                month: null
            }
        }
    },

    methods: {

        // 窗口 - 打开
        Open (config) {

            // 查询条件
            this.search.projects_id = config.projects_id
            this.search.user_id = config.user_id
            this.search.finish_at = config.finish_at
            this.search.year = config.year
            this.search.month = config.month
            
            // 显示窗口 
            this.visible = true
            this.Search()
        },
        
        // 窗口 - 关闭
        Close() {
            this.visible = false
            this.$emit('onChange')
        },
        
        // 获取数据
        Search()
        {
            this.$http.post('/9api/pm/wo/search-all-day_log', { search: this.search, append: ['projects', 'alarm_state'] }).then(rps => {
                this.table = rps.data
            })
        },

        // 更新 / 修正
        Repair()
        {
            this.$http.post('/9api/pm/wo/repair', this.search).then(rps => {
                this.Search()
            })
        }
    }
}
</script>