<!---------------------------------------------------------
-----------------------------------------------------------
    - 参数说明
    (选填):projects_id = 项目ID

    - ！组件说明
    - 请通过 "refs" 调用 "事件"
    - 请注意大小写

    - ！事件说明
    - function Load 加载数据
-----------------------------------------------------------
---------------------------------------------------------->

<template>
    <el-card shadow="hover">

        <!-- 标题 -->
        <div slot="header">最新动态</div>

        <el-timeline :reverse="true">
            <el-timeline-item v-for="(item, idx) in log" :timestamp="item.users_name + ' ' + item.created_at" :key="idx" placement="top">
                <div v-html="item.event_describe"></div>
            </el-timeline-item>
        </el-timeline>
    </el-card>
</template>

<script>
export default {
    props: {
        projects_id: {
            type: [Number, String],
            require: true
        }
    },

    data() {
        return {
            log: [],
        }
    },

    mounted () {
        this.Load()
    },

    methods: {

        /**
         * 日志 - 查询
         */
        Load() {
            this.$http.post('/9api/projects-log/search', { projects_id: this.projects_id }).then(rps => {
                this.log = rps.data
            })
        },
    }
}
</script>