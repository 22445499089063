<template>
    <div>
        <!-- 工具栏 -->
        <vxe-toolbar ref="xToolbar1" custom print export>
            <template #buttons>
                <el-button size="mini" @click="$refs.drawerCreate.Open({ contract_id: contract_id })">添加产品</el-button>
            </template>
        </vxe-toolbar>

        <!-- 数据 -->
        <vxe-table
            ref="xTable"
            border
            show-footer
            :data="table"
            :loading="loading"
            :export-config="{}"
            :print-config="{}"
            :footer-method="TableFooterMethod"
            min-height="500px">

            <!-- 序号 -->
            <vxe-column width="60" type="seq" title="序号"></vxe-column>

            <!-- 产品名称 -->
            <vxe-column width="120" field="pay_money" title="产品名称">
                <template #default="{ row }">
                    <el-link type="primary">
                        ￥{{ row.pay_money.toLocaleString() }}
                    </el-link>
                </template>
            </vxe-column>

            <!-- 冲销金额 -->
            <vxe-column width="120" field="pay_money_offset" title="产品类型">
                <template #default="{ row }">
                    <el-link type="primary">
                        ￥{{ row.pay_money_offset }}
                    </el-link>
                </template>
            </vxe-column>

            <!-- 记业绩金额 -->
            <vxe-column width="120" field="pay_money_performance" title="产品单位">
                <template #default="{ row }">
                    <el-link type="success">
                        ￥{{ row.pay_money_performance }}
                    </el-link>
                </template>
            </vxe-column>

            <!-- 负责人 -->
            <vxe-column width="80" field="user.name" title="负责人"></vxe-column>

            <!-- 开具发票 -->
            <vxe-column width="100" field="invoice_state" title="开具发票">
                <template #default="{ row }">
                    <el-link v-if="row.invoice_state == 0" type="warning">未开具发票</el-link>
                    <el-link v-if="row.invoice_state == 1" type="success">已开具发票</el-link>
                    <el-link v-if="row.invoice_state == 2" type="info">无需开具发票</el-link>
                </template>
            </vxe-column>

            <!-- 回款日期 -->
            <vxe-column width="100" field="pay_date" title="回款日期"></vxe-column>

            <!-- 回款备注 -->
            <vxe-column min-width="200" field="describe" title="回款备注"></vxe-column>

            <!-- 创建者 -->
            <vxe-column width="80" field="created_user.name" title="创建者"></vxe-column>

            <!-- 更新者 -->
            <vxe-column width="80" field="updated_user.name" title="更新者"></vxe-column>

            <vxe-column width="140" title="操作" fixed="right" align="center">
                <template #default="{ row }">
                    <el-link @click="$refs.drawerUpdate.Open(row.id)" icon="el-icon-edit-outline" type="primary" :underline="false">编辑</el-link>
                    <el-link @click="TableDelete(row.id)" icon="el-icon-delete" type="danger" :underline="false" class="w-margin-l-10">删除</el-link>
                </template>
            </vxe-column>
        </vxe-table>

        <contract-pay-enter-create ref="drawerCreate" @success="TableSearch" />
        <contract-pay-enter-update ref="drawerUpdate" @success="TableSearch" />
    </div>
</template>

<script>
export default {
    props: [ 'contract_id' ],

    data () {
        return {
            loading: false,
            
            table: [],
            statistics: {
                pay_money: 0,
                pay_money_offset: 0,
                pay_money_performance: 0
            },
        }
    },

    watch: {
        contract_id: {
            handler(val) {
                if(val) {
                    this.TableSearch()
                }
            },
            immediate: true
        }
    },

    methods: {

        /**
        |--------------------------------------------------
        | 表格数据 - 查询
        |--------------------------------------------------
        |
        */

        TableSearch() {
            this.loading = true
            this.$http.post(
                '/9api/crm/contract-pay-record/search-all',
                {
                    search: { contract_id: this.contract_id, pay_type: 1 },
                    statistics: true,
                    append: ['user', 'created_user', 'updated_user']
                }
            ).then((rps) => {
                // 更新数据
                this.table = rps.data.body
                this.statistics = rps.data.statistics

                // 切换加载状态
                this.loading = false
            })
        },

        /**
        |--------------------------------------------------
        | 表尾数据
        |--------------------------------------------------
        |
        */

        TableFooterMethod() {
            return [
                ['合计', '￥' + this.statistics.pay_money.toLocaleString(), '￥' + this.statistics.pay_money_offset.toLocaleString(), '￥' + this.statistics.pay_money_performance.toLocaleString()]
            ]
        },

        /**
        |--------------------------------------------------
        | 表格数据 - 移除
        |--------------------------------------------------
        |
        */

        TableDelete(id) {
            this.loading = true
            this.$http.post('/9api/crm/contract-pay-record/delete', { id: id }).then((rps) => {
                this.TableSearch()
            })
        },
    }
}
</script>