<!---------------------------------------------------------------------------- 
 | 项目管理 - 任务流程记录
 |----------------------------------------------------------------------------
 | 方法：请注意大小写,参数描述见具体方法
 | $refs[ref_name].Open(id) 手动打开窗口,
 | $refs[ref_name].Close() 手动关闭窗口
 |----------------------------------------------------------------------------
 | 回调
 | @onChange = fn () => {} 成功后返任务信息
 |
 ---------------------------------------------------------------------------->

 <template>
    <div :style="{ marginTop: '10px', display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: '10px' }" v-loading="loading">
        <div :style="{ backgroundColor: '#001cc3', padding: '10px', borderRadius: '4px', border: '1px solid #0027ff' }">
            <div :style="{ display: 'grid', gridTemplateColumns: '150px auto' }">
                <div :style="{ color: '#FFFFFF', fontSize: '14px' }">
                    <div>参与项目</div>
                    <div :style="{ margin: '10px 0 5px 0' }"><span :style="{ fontSize: '22px', fontWeight: 'bold' }">{{ result.count_projects }}</span>/个</div>
                    <div>
                        <div>相比上月 50%<i class="el-icon-top"></i></div>
                    </div>
                </div>
                <div id="trend_projects"></div>
            </div>
        </div>
        <div :style="{ backgroundColor: '#FFFFFF', padding: '10px', borderRadius: '4px', border: '1px solid #eeeeee' }">
            <div :style="{ display: 'grid', gridTemplateColumns: '150px auto' }">
                <div :style="{ fontSize: '14px' }">
                    <div>参与任务</div>
                    <div :style="{ margin: '10px 0 5px 0' }"><span :style="{ fontSize: '22px', fontWeight: 'bold' }">{{ result.count_projects_task }}</span>/个</div>
                    <div>相比上月 50%<i class="el-icon-top"></i></div>
                </div>
                <div id="trend_projects_task"></div>
            </div>
        </div>
        <div :style="{ backgroundColor: '#FFFFFF', padding: '10px', borderRadius: '4px', border: '1px solid #eeeeee' }">
            <div :style="{ display: 'grid', gridTemplateColumns: '150px auto' }">
                <div :style="{ fontSize: '14px' }">
                    <div>参与工单</div>
                    <div :style="{ margin: '10px 0 5px 0' }"s><span :style="{ fontSize: '22px', fontWeight: 'bold' }">{{ result.count_wo }}</span>/个</div>
                    <div>相比上月 50%<i class="el-icon-top"></i></div>
                </div>
                <div id="trend_wo"></div>
            </div>
        </div>
        <div :style="{ backgroundColor: '#FFFFFF', padding: '10px', borderRadius: '4px', border: '1px solid #eeeeee' }">
            <div :style="{ display: 'grid', gridTemplateColumns: '150px auto' }">
                <div :style="{ fontSize: '14px' }">
                    <div>小计消耗</div>
                    <div :style="{ margin: '10px 0 5px 0' }"><span :style="{ fontSize: '22px', fontWeight: 'bold' }">{{ result.sum_day_all }}</span>/P</div>
                    <div>相比上月 50%<i class="el-icon-top"></i></div>
                </div>
                <div id="trend_day_all"></div>
            </div>
        </div>
        <div :style="{ backgroundColor: '#FFFFFF', padding: '10px', borderRadius: '4px', border: '1px solid #eeeeee' }">
            <div :style="{ display: 'grid', gridTemplateColumns: '150px auto' }">
                <div :style="{ fontSize: '14px' }">
                    <div>任务消耗</div>
                    <div :style="{ margin: '10px 0 5px 0' }"><span :style="{ fontSize: '22px', fontWeight: 'bold' }">{{ result.sum_day_projects_task }}</span>/P</div>
                    <div>相比上月 50%<i class="el-icon-top"></i></div>
                </div>
                <div id="trend_day_projects_task"></div>
            </div>
        </div>
        <div :style="{ backgroundColor: '#FFFFFF', padding: '10px', borderRadius: '4px', border: '1px solid #eeeeee' }">
            <div :style="{ display: 'grid', gridTemplateColumns: '150px auto' }">
                <div :style="{ fontSize: '14px' }">
                    <div>工单消耗</div>
                    <div :style="{ margin: '10px 0 5px 0' }"><span :style="{ fontSize: '22px', fontWeight: 'bold' }">{{ result.sum_day_wo }}</span>/P</div>
                    <div>相比上月 50%<i class="el-icon-top"></i></div>
                </div>
                <div id="trend_day_wo"></div>
            </div>
        </div>
    </div>
</template>

<script>
import * as echarts from 'echarts';

export default {
    props: ['users_id'],

    data () {
        return {
            // 加载状态
            visible: false,
            loading: false,

            result: {
                users: {},
                count_projects: null,
                count_projects_task: null,
                count_wo: null,

                sum_day_projects_task: null,
                sum_day_wo: null,
                sum_day_all: null,

                trend_projects: {},
                trend_wo: {},
                trend_projects_task: {},
                trend_day_all: {},
                trend_day_projects_task: {},
                trend_day_wo: {}
            },
        }
    },

    watch: {
        users_id: {
            handler(val) {
                if (val) {
                    this.DataGet()
                }
            },
            immediate: true
        },
    },

    methods: {
        
        // 窗口 - 关闭
        Close() {
            this.loading = false
            this.visible = false
        },
        
        // 获取数据
        DataGet() {
            // 打开加载状态
            this.loading = true

            // 查询数据
            this.$http.post('/9api/pm/analysis/working-days-employee', { users_id: this.users_id }).then(rps => {
                this.result = rps.data
                this.EchartsCreate('trend_projects', rps.data.trend_projects.date, rps.data.trend_projects.value)
                this.EchartsCreate('trend_projects_task', rps.data.trend_projects_task.date, rps.data.trend_projects_task.value, true)
                this.EchartsCreate('trend_wo', rps.data.trend_wo.date, rps.data.trend_wo.value, true)
                this.EchartsCreate('trend_day_all', rps.data.trend_day_all.date, rps.data.trend_day_all.value, true)
                this.EchartsCreate('trend_day_projects_task', rps.data.trend_day_projects_task.date, rps.data.trend_day_projects_task.value, true)
                this.EchartsCreate('trend_day_wo', rps.data.trend_day_wo.date, rps.data.trend_day_wo.value, true)
                this.loading = false
            })
        },
        
        EchartsCreate(id, x_label, data, night) {
            let myChart = echarts.init(document.getElementById(id));
            // 绘制图表
            return myChart.setOption({
                tooltip: { trigger: 'axis' },
                grid: { left: '0', right: '0', top: '10', bottom: '10' },
                xAxis: {
                    show: false,
                    data: x_label
                },
                yAxis: { show: false, alignTicks: false, },
                series: [
                    {
                        name: '合计',
                        type: 'line',
                        data: data,
                        smooth: true,
                        lineStyle: { color: night ? '#0027ff' : '#FFFFFF', width: 2 },
                        itemStyle: { color: night ? '#0027ff' : '#FFFFFF' },
                        areaStyle: {
                            color: {
                                type: 'linear',
                                x: 0, y: 0, x2: 0, y2: 1,
                                colorStops: [
                                    { offset: 0, color: night ? 'rgba(0, 28, 195, 1)' : 'rgba(255, 255, 255, 1)' },
                                    { offset: 1, color: night ? 'rgba(0, 28, 195, 0)' : 'rgba(255, 255, 255, 0)' }
                                ],
                                global: false
                            }
                        }
                    }
                ]
            })
        },

        /**
        |--------------------------------------------------
        | 工时变更
        |--------------------------------------------------
        |
        */

        DaysChange() {
            this.FlowSearch()
            this.$emit('onChange')
        }
    }
}
</script>