<template>
    <div>
        <!-- 回显表单 -->
        <el-input
            v-model="selectName"
            @focus="DialogOpend"
            @clear="SelectClear"
            size="mini"
            placeholder="请选择采购订单"
            clearable
            :disabled="disabled"
            :style="{ width: width, margin: margin }"/>

        <!-- 选择对象 -->
        <el-dialog :visible.sync="dialogVisible" width="900px" append-to-body title="采购订单" :close-on-click-modal="false" :close-on-press-escape="false">
            
            <!-- toolbar -->
            <div :style="{ display: 'flex', marginBottom: '10px' }">
                <el-input @input="$lodash.debounce(SearchTable, 500)" v-model="search.number" clearable size="mini" placeholder="订单编号" :style="{ width: '200px' }"></el-input>
            </div>

            <!-- table -->
            <vxe-table ref="xTable" :data="table" border :loading="dialogLoading" height="500px">

                <!-- ID -->
                <vxe-column width="60" field="id" title="ID"></vxe-column>

                <!-- 审批状态 -->
                <vxe-column width="90" title="审批状态" align="center">
                    <template #default="{ row }">
                        <el-tag v-if="row.workflow_state == 0" size="mini" effect="dark" type="info">未审核</el-tag>
                        <el-tag v-if="row.workflow_state == 1" size="mini" effect="dark" type="warning">待审核</el-tag>
                        <el-tag v-if="row.workflow_state == 2" size="mini" effect="dark" >审核中</el-tag>
                        <el-tag v-if="row.workflow_state == 3" size="mini" effect="dark" type="danger">已拒绝</el-tag>
                        <el-tag v-if="row.workflow_state == 4" size="mini" effect="dark" type="success">已通过</el-tag>
                        <el-tag v-if="row.workflow_state == 5" size="mini" effect="dark" type="warning">待撤销</el-tag>
                        <el-tag v-if="row.workflow_state == 6" size="mini" effect="dark" type="warning">撤销中</el-tag>
                        <el-tag v-if="row.workflow_state == 7" size="mini" effect="dark" type="danger">撤销失败</el-tag>
                        <el-tag v-if="row.workflow_state == 8" size="mini" effect="dark" type="danger">已撤销</el-tag>
                    </template>
                </vxe-column>

                <!-- 订单类型 -->
                <vxe-column width="100" title="订单类型">
                    <template #default="{ row }">
                        {{ row.type == 'contract' ? '合同采购单' : '公司采购'  }}
                    </template>
                </vxe-column>

                <!-- 基础信息 -->
                <vxe-column min-width="160" field="number" title="采购单编号"></vxe-column>
                <vxe-column min-width="100" field="date" title="采购日期"></vxe-column>
                <vxe-column min-width="100" field="money_estimate" title="采购金额(预)"></vxe-column>
                <vxe-column min-width="100" field="money_actual" title="采购金额(实)"></vxe-column>
                <vxe-column min-width="120" field="describe" title="备注"></vxe-column>

                <!-- 系统信息 -->
                <vxe-column width="140" field="updated_at" title="创建时间"></vxe-column>
                <vxe-column width="80" field="updated_user.name" title="创建人"></vxe-column>
                <vxe-column width="140" field="created_at" title="更新时间"></vxe-column>
                <vxe-column width="80" field="created_user.name" title="更新人"></vxe-column>

                <!--  -->
                <vxe-column width="80" title="操作" fixed="right" align="center">
                    <template #default="{ row }">
                        <el-link :underline="false" type="primary" @click="SelectChange(row)">选择</el-link>
                    </template>
                </vxe-column>
            </vxe-table>

            <!-- pager -->
            <vxe-pager 
                :style="{ marginTop: '10px' }"
                :layouts="['Total', 'Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump',]"
                :total="page.total"
                :page-size.sync="page.size"
                :current-page.sync="page.current"
                @page-change="SearchTable()">
            </vxe-pager>
            
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: [String, Array, Number],
            default: null
        },

        width: {
            type: String,
            default: '100%'
        },

        margin: {
            type: String,
            default: '0px'
        },

        disabled: {
            type: Boolean,
            default: false
        },
    },

    watch: {
        value: {
            handler(val) {
                val ? this.SearchFirst(val) : this.SelectClear()
            },
            immediate: true
        }
    },

    data () {
        return {
            dialogVisible: false,
            dialogLoading: false,
            
            table: [],

            // 分页
            page: {
                total: 0,
                size: 50,
                current: 1,
            },

            // 检索
            search: {
                number: null,
            },

            // 排序
            order: {
                method: 'desc',
                column: 'id'
            },

            selectObject: {},
            selectName: null
        }
    },

    methods: {

        /**
        |--------------------------------------------------
        | 窗口 - 打开动画结束时
        |--------------------------------------------------
        |
        */

        DialogOpend() {
            this.dialogVisible = true
            this.SearchTable()
        },

        // 数据查询
        SearchTable() {
            this.dialogLoading = true
            this.$http.post(
                '/9api/ims/product-order/search',
                {
                    page: this.page,
                    search: this.search,
                    order: this.order,
                    append: ['created_user', 'updated_user']
                }
            ).then((rps) => {
                // 更新数据
                this.page.total = rps.data.total
                this.table = rps.data.body

                // 页码溢出
                if (this.table.length == 0 && this.page.current != 1) {
                    this.page.current = 1
                    this.SearchTable()
                }

                // 切换加载状态
                this.dialogLoading = false
            })
        },

        // 数据查询(单条)
        SearchFirst(id) {
            this.$http.post('/9api/ims/product-order/search-first', { search: {id: id} }).then(rps => {
                this.selectObject = rps.data
                this.selectName = rps.data.number
                this.$emit('input', rps.data.id)
                this.$emit('onChange', rps.data)
            })
        },

        // 清空选项
        SelectClear() {
            this.selectName = null
            this.selectObject = {}
            this.$emit('input', null)
            this.$emit('onChange', null)
        },

        // 选择事件
        SelectChange(item) {
            this.selectName = item.number
            this.selectObject = item
            this.$emit('input', item.id)
            this.$emit('onChange', item)
            this.dialogVisible = false
        },
    }
}
</script>