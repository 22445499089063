<template>
    <div>
        <!-- 创建按钮 -->
        <el-button size="mini" icon="el-icon-plus" @click="$refs.contactsCreate.Open({ customer_id: customer_id })" :style="{ marginBottom: '10px' }" >
            新建联系人
        </el-button>

        <!-- 数据表格 -->
        <vxe-table border ref="xTable" :data="table" :loading="loading" :export-config="{}" :print-config="{}" min-height="500px">

            <!-- 序号 -->
            <vxe-column width="60" type="seq" title="序号"></vxe-column>

            <!-- 联系人 -->
            <vxe-column min-width="120" field="name" title="联系人" sortable>
                <template #default="{ row }">
                    <el-link @click="$refs.contactsDrawer.Open(row.id)" :type="row.sex == '女' ? 'danger' : 'primary'" icon="el-icon-user">
                        {{ row.name }}
                    </el-link>
                </template>
            </vxe-column>

            <!-- 性别 -->
            <vxe-column min-width="80" field="name" title="性别" sortable>
                <template #default="{ row }">
                    <el-link :underline="false" :type="row.sex == '女' ? 'danger' : 'primary'"   v-if="row.sex">{{ row.sex }}</el-link>
                    <el-link :underline="false" type="info" v-else>{{ row.sex || '未记录' }}</el-link>
                </template>
            </vxe-column>

            <!-- 工作电话 -->
            <vxe-column min-width="160" field="phone" title="手机">
                <template #default="{ row }">
                    <el-link :underline="false" icon="el-icon-mobile-phone" v-if="row.phone || row.phone_t">{{ row.phone || row.phone_t }}</el-link>
                    <el-link :underline="false" icon="el-icon-mobile-phone" v-if="!row.phone && !row.phone_t" type="info">未记录</el-link>
                </template>
            </vxe-column>

            <!-- 关键决策者 -->
            <vxe-column min-width="90" field="policymakers" title="关键决策者">
                <template #default="{ row }">
                    <el-link :underline="false" icon="el-icon-circle-check"     type="warning"   v-if="row.policymakers == '是'">{{ row.policymakers }}</el-link>
                    <el-link :underline="false" icon="el-icon-warning-outline"  type="info"      v-else >{{ row.policymakers || 未记录 }}</el-link>
                </template>
            </vxe-column>

            <!-- 联系地址 -->
            <vxe-column min-width="90" field="country" title="联系地址">
                <template #default="{ row }">
                    {{ ((row.country || '') + (row.country_province || '') + (row.country_province_city || '') + (row.address || '')) || '未登记' }}
                </template>
            </vxe-column>

        </vxe-table>

        <!-- 组件 -->
        <crm-contacts-create ref="contactsCreate" @onChange="TableSearch" />
        <crm-contacts-drawer ref="contactsDrawer" @onChange="TableSearch" />

    </div>
</template>
    
<script>
export default {
    props: {
        customer_id: {
            type: Number,
            default: null
        }
    },

    data() {
        return {
            loading: false,

            table: [],

            search: {
                customer_id: null
            }
        }
    },

    watch: {
        customer_id: {
            handler(val) {
                if (val) {
                    this.search.customer_id = val
                    this.TableSearch()
                } else {
                    this.table = []
                }
            },
            immediate: true
        }
    },

    methods: {

        // 产品 - 查询
        TableSearch() {
            this.loading = true
            this.$http.post('/9api/crm/contacts/search-all', { search: this.search, append: ['user_admin'] }).then((rps) => {
                this.table = rps.data
                this.loading = false
            }) 
        },

    }
}
</script>