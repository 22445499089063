<template>
    <div class="um-menu-item-group">
        
        <div :class="['uw-menu-item-group-title', isActive ? 'is-active' : '' ]">
            <slot name="title">
                {{ title }}
            </slot>
        </div>
        
        <div class="uw-menu-item-group-content">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: null
        }
    },
    
    data () {
        return {
            isActive: false,
        }
    },

    watch: {
        '$slots.default': {
            handler (newSlots) {
                this.isActive = !!this.$slots.default.find(rps => rps.componentInstance?.getIsActive() == true)
            },
            immediate: true,
            deep: true
        }
    },

    methods: {
        getIsActive()
        {
            return this.isActive
        }
    },

    updated()
    {
        this.isActive = !!this.$slots.default.find(rps => rps.componentInstance?.getIsActive() == true)
    }
}
</script>