<!---------------------------------------------------------------------------- 
 | 项目管理 - 任务取消
 |----------------------------------------------------------------------------
 | 方法：请注意大小写,参数描述见具体方法
 | $refs[ref_name].Open(row) 手动打开窗口,
 | $refs[ref_name].Close() 手动关闭窗口
 |----------------------------------------------------------------------------
 | 回调
 | @onSave = fn () => {} 回调
 |
 ---------------------------------------------------------------------------->

 <template>
    <el-drawer class="w-el-drawer" :visible.sync="visible" :size="960" :with-header="false" direction="rtl" :before-close="Close" append-to-body destroy-on-close>

        <!-- header -->
        <div class="w-el-drawer-header">
            <i class="el-icon-tickets"></i> 任务取消 - {{ task.name }}
        </div>

        <!-- body -->
        <div class="w-el-drawer-body" v-loading.lock="loading">
            <el-form :model="form" label-width="120px" size="mini">

                <el-divider content-position="left">基础信息</el-divider>

                <el-form-item label="任务截止时间">
                    {{  task.start_date[1]  }}
                </el-form-item>
                <el-form-item label="任务预估工时">
                    {{  task.working_days  }} P
                </el-form-item>
                <el-form-item label="任务已用工时">
                    {{  task.consume_days  }} P
                </el-form-item>
                <el-form-item label="任务可用工时">
                    {{  (task.working_days - task.consume_days) > 0 ? (task.working_days - task.consume_days) : 0  }} P
                </el-form-item>

                <el-divider content-position="left">任务取消</el-divider>

                <el-form-item label="备注">
                    <froala-editor v-model="form.describe" />
                </el-form-item>
            </el-form>
        </div>

        <!-- footer -->
        <div class="w-el-drawer-footer">
            <el-button size="mini" @click="Close()">取消</el-button>
            <el-button size="mini" type="primary" @click="SubmitForm()" :disabled="loading">确定</el-button>
        </div>
    </el-drawer>
</template>

<script>

export default {
    data() {
        return {
            // 窗口
            visible: false,
            loading: false,

            // 任务信息
            task: {
                start_date: []
            },

            // 表单信息
            form: {
                describe: null,
            },
        }
    },
    methods: {

        /**
        |--------------------------------------------------
        | 窗口 - 打开
        |--------------------------------------------------
        |
        */

        async Open(row) {
            this.task = row
            this.visible = true
        },


        /**
        |--------------------------------------------------
        | 窗口 - 关闭
        |--------------------------------------------------
        |
        */

        Close() {
            this.form.describe = null

            this.loading = false
            this.visible = false
        },

        /**
        |--------------------------------------------------
        | 任务取消
        |--------------------------------------------------
        |
        */

        SubmitForm() {
            this.$http.post('/9api/projects-task/flow-cancel', { id: this.task.id, form: this.form }).then(rps => {
                this.$emit('onSave')
                this.Close()
            })
        }
    }
}
</script>