<!---------------------------------------------------------------------------- 
 | 数据字典 - 复选框
 |----------------------------------------------------------------------------
 | 方法：请注意大小写,参数描述见具体方法
 | *v-model     默认值------默认为 null
 | *model       字典模块----默认为 null
 |----------------------------------------------------------------------------
 | 回调
 | @onChange = fn () => {} 返回表单内容
 |
 ---------------------------------------------------------------------------->
 
<template>
    <el-checkbox-group v-model="selectValue" @change="TableChange" style="display: grid; grid-template-columns: repeat(6, 1fr);">
        <el-checkbox v-for="(item, idx) in table" :key="idx" :label="item.id">{{ item.name }}</el-checkbox>
    </el-checkbox-group>
</template>

<script>
export default {
    props: {
        value: {
            type: [Array],
            default: null
        },

        model: {
            type: String,
            default: null
        }
    },

    data() {
        return {

            table: [],

            // 排序
            order: {
                method: 'desc',
                column: 'id'
            },

            selectValue: []
        }
    },

    watch: {
        'model': {
            handler() {
                this.TableSearch()
            },
            immediate: true
        },

        value: {
            handler(val) {
                this.selectValue = val ?? []
            },
            immediate: true
        }
    },

    methods: {

        /**
        |--------------------------------------------------
        | 数据查询
        |--------------------------------------------------
        |
        */

        TableSearch() {
            // 切换加载状态
            this.loading = true

            this.$http.post(
                '/9api/data-dict/search-model',
                {
                    search: { model: this.model },
                    order: this.order
                }
            ).then(rps => {
                
                // 更新数据
                this.table = rps.data

                // 切换加载状态
                this.loading = false
            })
        },

        /**
        |--------------------------------------------------
        | 数据更新
        |--------------------------------------------------
        |
        */

        TableChange(val) {
            this.$emit('input', val)
            this.$emit('onChange', val)
        }

    }
}
</script>