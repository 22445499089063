<template>
    <div class="w-file-upload">
        <el-upload :on-change="ImportData" drag :auto-upload="false" action="#" :show-file-list="false">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text"><b>合同管理：</b>将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>

        <el-dialog :visible.sync="visible" class="w-el-dialog" fullscreen title="批量导入" append-to-body :before-close="Close" :close-on-click-modal="false" :close-on-press-escape="false">
           
            <!-- 表格 -->
            <uw-table :show-footer="false" :show-header="false">
                <!-- 表数据 -->
                <template slot="data">
                    <!-- 表内容 -->
                    <vxe-table ref="xTable" :data="table" :loading="loading" :export-config="{}" height="100%">

                        <!-- 序号 -->
                        <vxe-column width="60" type="seq" title="序号"></vxe-column>

                        <!-- 销售跟单编号 -->
                        <vxe-column min-width="150" field="sale_documentary.number" title="销售跟单编号" required></vxe-column>

                        <!-- 合同编号 -->
                        <vxe-column min-width="150" field="number" title="合同编号" required></vxe-column>

                        <!-- 合同名称 -->
                        <vxe-column min-width="150" field="name" title="合同名称" required></vxe-column>
                        
                        <!-- 客户名称 -->
                        <vxe-column min-width="150" field="customer.name" title="关联客户" required></vxe-column>

                        <!-- 合同来源 -->
                        <vxe-column min-width="150" field="source.name" title="合同来源" required></vxe-column>

                        <!-- 合同状态 -->
                        <vxe-column min-width="150" field="state" title="合同状态" required></vxe-column>

                        <!-- 合同期限 -->
                        <vxe-column min-width="150" field="time_limit_start" title="合同开始日期" required></vxe-column>
                        <vxe-column min-width="150" field="time_limit_end" title="合同结束日期" required></vxe-column>

                        <!-- 签单日期 -->
                        <vxe-column min-width="150" field="sign_date" title="签单日期" required></vxe-column>

                        <!-- 参与成员 -->
                        <vxe-column min-width="150" field="user_affair.name" title="商务" required></vxe-column>
                        <vxe-column min-width="150" field="user_presale.name" title="售前" required></vxe-column>
                        <vxe-column min-width="150" field="user_sale.name" title="销售" required></vxe-column>
                        <vxe-column min-width="150" field="user_admin.name" title="管理员" required></vxe-column>

                        <!-- 成本预估 -->
                        <vxe-column min-width="100" field="estimate_work_day" title="预估工时" required></vxe-column>
                        <vxe-column min-width="100" field="estimate_money" title="预估成本" required></vxe-column>

                        <!-- 项目预期金额 -->
                        <vxe-column min-width="100" field="sign_money" title="预期金额" required></vxe-column>
                        <vxe-column min-width="100" field="sign_money_jc" title="集成费用" required></vxe-column>
                        <vxe-column min-width="100" field="sign_money_yw" title="运维费用" required></vxe-column>
                        <vxe-column min-width="100" field="sign_money_af" title="安服费用" required></vxe-column>
                        <vxe-column min-width="100" field="sign_money_gc" title="工程费用" required></vxe-column>
                        <vxe-column min-width="100" field="sign_money_sb" title="设备费用" required></vxe-column>
                        <vxe-column min-width="100" field="sign_money_kf" title="开发费用" required></vxe-column>
                        <vxe-column min-width="100" field="sign_money_gd" title="过单费用" required></vxe-column>
                        <vxe-column min-width="100" field="sign_money_qt" title="其它费用" required></vxe-column>
                        <vxe-column min-width="100" field="sign_money_sw" title="商务费用" required></vxe-column>

                        <!-- 操作 -->
                        <vxe-column title="错误提示" width="30%" align="center" fixed="right" v-if="tableError">
                            <template #default="{ row }">
                                <el-link type="danger">{{ row.error }}</el-link>
                            </template>
                        </vxe-column>
                    </vxe-table>
                </template>
            </uw-table>

            <template slot="footer">
                <el-button size="mini" :loading="loading" @click="Close()">取 消</el-button>
                <el-button size="mini" type="primary"  @click="$refs.xTable.openExport()" >导出当前数据 / 导入模板</el-button>
                <el-button size="mini" type="success"  :loading="loading"  @click="SubmitForm()">开始导入</el-button>
            </template>
            
        </el-dialog>
    </div>
</template>

<script>
import * as XLSX from 'xlsx/xlsx.mjs';

export default {
    data () {
        return {
            visible: false,
            loading: false,

            table: [],
            tableError: false
        }
    },

    methods: {
        ImportData(file) {
            
            this.visible = true
            this.loading = true

            // 读取文件流
            let dataBinary = new Promise((resolve) => {
                let reader = new FileReader();
                reader.readAsBinaryString(file.raw)
                reader.onload = (ev) => {

                    // 解读文件信息
                    let workBook = XLSX.read(ev.target.result, { type: "binary", cellDates: true });

                    // 解读第一个 Sheet
                    let firstWorkSheet = workBook.Sheets[workBook.SheetNames[0]]

                    // 解析 Sheet 内容
                    let data = XLSX.utils.sheet_to_json(firstWorkSheet);

                    // 读取数据
                    data.forEach(async (item) => {

                        // 插入数据
                        const { row: newRow } = this.$refs.xTable.insertAt({
                            'sale_documentary': { number: item['销售跟单编号'] },
                            'sale_documentary_id': await this.$http.post('/9api/crm/business/search-first', { number: item['销售跟单编号'] }).then((rps) => rps.data.id),
                            'number': item['合同编号'],
                            'name': item['合同名称'],
                            'customer': { name: item['关联客户'] },
                            'customer_id': await this.$http.post('/9api/crm/customer/first', { name: item['关联客户'] }).then((rps) => rps.data.id),
                            'source': { name: item['合同来源'] },
                            'source_id': await this.$http.post('/9api/contract-source/search-first', { name: item['项目来源'] }).then((rps) => [rps.data.id]),
                            'state': item['合同状态'],
                            'time_limit_start': item['合同开始日期'],
                            'time_limit_end': item['合同结束日期'],
                            'time_limit': [item['合同开始日期'], item['合同结束日期']],
                            'sign_date': item['签单日期'],
                            'user_affair': { name: item['商务'] },
                            'user_affair_id': await this.$http.post('/9api/hrm/employee/search-first', { name: item['商务'], hidden: ['wecom'] }).then((rps) => rps.data.id),
                            'user_presale': { name: item['售前'] },
                            'user_presale_id': await this.$http.post('/9api/hrm/employee/search-first', { name: item['售前'], hidden: ['wecom'] }).then((rps) => rps.data.id),
                            'user_sale': { name: item['销售'] },
                            'user_sale_id': await this.$http.post('/9api/hrm/employee/search-first', { name: item['销售'], hidden: ['wecom'] }).then((rps) => rps.data.id),
                            'user_admin': { name: item['管理员'] },
                            'user_admin_id': await this.$http.post('/9api/hrm/employee/search-first', { name: item['管理员'], hidden: ['wecom'] }).then((rps) => rps.data.id),
                            'estimate_work_day': item['预估工时'],
                            'estimate_money': item['预估成本'],
                            'sign_money': item['预期金额'],
                            'sign_money_jc': item['集成费用'],
                            'sign_money_yw': item['运维费用'],
                            'sign_money_af': item['安服费用'],
                            'sign_money_gc': item['工程费用'],
                            'sign_money_sb': item['设备费用'],
                            'sign_money_kf': item['开发费用'],
                            'sign_money_gd': item['过单费用'],
                            'sign_money_qt': item['其它费用'],
                            'sign_money_sw': item['商务费用'],
                            'workflow_template': {"cc": [12], "type": "sign", "state": null, "users": [await this.$http.post('/9api/hrm/employee/search-first', { name: item['销售'], hidden: ['wecom'] }).then((rps) => rps.data.id)], "children": null, "multiple": true, "users_verify": []},
                            'describe': null,
                            'files_id': [],
                            'draft': 0,
                            'error': false,
                        }, -1)

                        this.loading = false
                    })
                }
            })
        },

        /**
        |--------------------------------------------------
        | 提交表单
        |--------------------------------------------------
        |
        */

        SubmitForm() {
            // 开启加载状态
            this.loading = true

            // 获取数据
            let data = this.$refs.xTable.getTableData().tableData.filter(item => !item.parentField)

            data.forEach(async (element) => {

                // 提交表单
                await this.$http.post('/9api/contract/create', element).then(rps => {
                    // 关闭加载状态
                    this.loading = false

                    // 提交失败
                    if (rps.data.status == 'error') {
                        this.tableError = true
                        element.error = rps.data.error
                    }
                    
                    // 提交成功
                    if (rps.data.status == 'success') {
                        this.$refs.xTable.remove(element)
                    }
                })
            });
        },

        /**
        |--------------------------------------------------
        | 关闭窗口
        |--------------------------------------------------
        |
        */

        Close() {
            this.table = []
            this.tableError = false
            this.visible = false
            this.loading = false
        }
    }
}
</script>