<!---------------------------------------------------------------------------- 
 | Select
 | IMS - 供应商
 |----------------------------------------------------------------------------
 | 方法：请注意大小写,参数描述见具体方法
 | *v-model     默认值------默认为 null
 |----------------------------------------------------------------------------
 | 回调
 | @onChange = fn (data) => {} 返回表单内容
 |
 ---------------------------------------------------------------------------->

 <template>
    <div>
        <el-input v-model="selectName" @focus="dialogOpend" @clear="selectClear" size="mini" placeholder="请选择供应商" clearable :style="{ width: width, margin: margin }"/>

        <el-dialog
            :visible.sync="dialogVisible"
            width="900px"
            append-to-body
            title="供应商（双击选择）"
            :close-on-click-modal="false"
            :close-on-press-escape="false">
            
            <!-- toolbar -->
            <uw-grid-search gutter="0 10px" :column="3" :style="{ marginBottom: '10px' }">
                <template slot="full">
                    <el-input @input="$lodash.debounce(Search, 500)" v-model="post.search.name" clearable  placeholder="供应商名称"></el-input>
                </template>
                <template slot="tools">
                    <el-button type="primary" @click="$refs.supplierCreate.Open()">添加供应商</el-button>
                </template>
            </uw-grid-search>

            <!-- table -->
            <vxe-table ref="xTable" :data="table" :loading="dialogLoading" @cell-dblclick="selectChangeDblclick" height="500px">

                <!-- 序号 -->
                <vxe-column width="60" type="seq" title="序号"></vxe-column>

                <!-- 基础信息 -->
                <vxe-column min-width="240" field="name" title="供应商名称"></vxe-column>
                <vxe-column min-width="100" field="level" title="供应商级别"></vxe-column>
                <vxe-column min-width="80" field="contacts" title="联系人"></vxe-column>
                <vxe-column min-width="120" field="phone" title="联系方式"></vxe-column>
                <vxe-column min-width="120" field="notes" title="备注"></vxe-column>
                <vxe-column min-width="120" field="address" title="联系地址"></vxe-column>

                <!-- 系统信息 -->
                <vxe-column width="140" field="updated_at" title="创建时间"></vxe-column>
                <vxe-column width="80" field="updated_user.name" title="创建人"></vxe-column>
                <vxe-column width="140" field="created_at" title="更新时间"></vxe-column>
                <vxe-column width="80" field="created_user.name" title="更新人"></vxe-column>
            </vxe-table>

            <!-- pager -->
            <vxe-pager 
                :style="{ marginTop: '10px' }"
                :total="post.page.total"
                :page-size.sync="post.page.size"
                :current-page.sync="post.page.current"
                @page-change="Search()">
            </vxe-pager>

        </el-dialog>

        <!-- 组件 -->
        <ims-supplier-create ref="supplierCreate" @onChange="Search()" />
        <ims-supplier-drawer ref="supplierDrawer" @onChange="Search()" />
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: [String, Array, Number],
            default: null
        },

        width: {
            type: String,
            default: '100%'
        },

        margin: {
            type: String,
            default: '0px'
        },
    },

    data () {
        return {
            dialogVisible: false,
            dialogLoading: false,

            table: [],

            post: {
                page: {
                    total: 0,
                    size: 50,
                    current: 1,
                },
                search: {
                    name: null
                },
                order: {
                    method: 'desc',
                    column: 'id'
                },
                append: ['created_user', 'updated_user']
            },

            selectName: null
        }
    },

    watch: {
        value: {
            handler(val) {
                if (val) {
                    this.$http.post('/9api/ims/supplier/search-first', { search: { id: val } }).then(rps => {
                        this.selectName =  rps.data.name
                    })
                } else {
                    this.selectName =  null
                }
            },
            immediate: true
        }
    },

    methods: {
        // 打开窗口
        dialogOpend() {
            this.dialogVisible = true
            this.Search()
        },

        // 供应商 - 查询
        Search() {
            this.$http.post('/9api/ims/supplier/search', this.post).then(rps => {
                
                // 更新数据
                this.post.page.total = rps.data.total
                this.table = rps.data.body

                // 页码溢出
                if (this.table.length == 0 && this.post.page.current != 1) {
                    this.post.page.current = 1
                    this.Search()
                }
            })
        },

        // 已选中 - 清空
        selectClear() {
            this.selectName = null
            this.$emit('input', null)
            this.$emit('onChange', null)
        },

        // 选中事件 - 双击
        selectChangeDblclick({ row }) {
            this.selectChangeButton(row)
        },

        // 选中事件 - 按钮
        selectChangeButton(item) {
            this.$emit('input', item.id)
            this.$emit('onChange', item)
            this.dialogVisible = false
        },
    }
}
</script>