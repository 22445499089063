<!---------------------------------------------------------------------------- 
 | 项目管理 - 任务转交
 |----------------------------------------------------------------------------
 | 方法：请注意大小写,参数描述见具体方法
 | $refs[ref_name].Open(row) 手动打开窗口,
 | $refs[ref_name].Close() 手动关闭窗口
 |----------------------------------------------------------------------------
 | 回调
 | @onSave = fn (Object) => {} 成功后返任务信息
 |
 ---------------------------------------------------------------------------->

<template>
    <el-drawer class="w-el-drawer" :visible.sync="visible" :size="960" :with-header="false" direction="rtl" :before-close="Close" append-to-body destroy-on-close>

        <!-- header -->
        <div class="w-el-drawer-header">
            <i class="el-icon-tickets"></i> 任务转交 - {{ task.name }}
        </div>
        
        <!-- body -->
        <div class="w-el-drawer-body" v-loading.lock="loading">
            <el-form :model="form" label-width="120px" size="mini">

                <el-divider content-position="left">基础信息</el-divider>

                <!-- 任务截止时间 -->
                <el-form-item label="任务截止时间">
                    {{  task.start_date[1]  }}
                </el-form-item>
                <el-form-item label="任务预估工时">
                    {{  task.working_days  }} P
                </el-form-item>
                <el-form-item label="任务已用工时">
                    {{  task.consume_days  }} P
                </el-form-item>
                <el-form-item label="任务可用工时"> 
                    {{  (task.working_days - task.consume_days) > 0 ? (task.working_days - task.consume_days) : 0 }} P
                </el-form-item>

                <el-divider content-position="left">已消耗工时 / P</el-divider>

                <el-form-item :label="item.user_name" v-for="(item, idx) in form.users" :key="idx">
                    <el-input-number v-model="item.consume_days" :min="0" :max="999999" label="消耗工时"></el-input-number>
                </el-form-item>

                <el-divider content-position="left">任务转交</el-divider>

                <!-- 指派给 -->
                <el-form-item label="指派给" :rules="[{ required: true, message: '请选择转交对象' }]" :error="formError.now_assign?.toString()" prop="now_assign">
                    <inputTeam v-model="form.now_assign" :project_id="task.project_id" clearable filterable width="350px"></inputTeam>
                </el-form-item>

                <!-- 参与成员 / 协助成员 -->
                <el-form-item label="参与成员 / 协助成员">
                    <inputTeam v-model="form.assign" :project_id="task.project_id" clearable filterable multiple width="350px"></inputTeam>
                </el-form-item>

                <!-- 任务开始 - 备注 -->
                <el-form-item label="备注">
                    <froala-editor v-model="form.describe" />
                </el-form-item>

                <!-- 附件 -->
                <el-form-item label="附件">
                    <files-upload v-model="form.files_id" model="projects_task" stage="任务转交" />
                </el-form-item>
            </el-form>
        </div>

        <!-- footer -->
        <div class="w-el-drawer-footer">
            <el-button size="mini" @click="Close()">取消</el-button>
            <el-button size="mini" type="primary" @click="SubmitForm()" :loading="loading" v-if="!formState">确 定</el-button>
        </div>
    </el-drawer>
</template>

<script>
import inputTeam from '@/views/projects/inputProjectsTeam'

export default {
    components: {
        inputTeam,
    },

    data() {
        return {
            // 窗口
            visible: false,
            loading: false,


            // 任务信息
            task: {
                start_date: []
            },
            team: [],

            // 表单信息
            formState: false,
            formError: {},
            form: {
                describe: null,
                users: [],
                assign: [],
                now_assign: null,
                files_id: []
            },
        }
    },

    methods: {

        /**
        |--------------------------------------------------
        | 窗口 - 打开
        |--------------------------------------------------
        |
        */

        Open(row) {
            
            // 加载内容
            new Promise((resolve) => {
                this.task = row
                this.loading = true
                this.visible = true
                resolve()
            }).then(() => {
                // 初始化参与人员表单
                this.form.assign = this.task.assign
                this.form.now_assign = this.task.now_assign

                // 添加工时消耗表单
                this.task.assign_user_object.forEach(element => {
                    this.form.users.push({
                        user_id: element.id,
                        user_name: element.name,
                        consume_days: 0
                    })
                })

                this.loading = false
            })
        },

        /**
        |--------------------------------------------------
        | 窗口 - 关闭
        |--------------------------------------------------
        |
        */

        Close() {
            this.formState = false
            this.formError = []

            this.form.describe = null
            this.form.users = []
            this.form.assign = []
            this.form.now_assign = null,
            this.form.files_id = []
            
            this.loading = false
            this.visible = false
        },


        /**
        |--------------------------------------------------
        | 任务转交
        |--------------------------------------------------
        |
        */

        SubmitForm() {

            this.$http.post('/9api/projects-task/flow-change', { id: this.task.id, form: this.form }).then(rps => {

                // 提交失败
                if (rps.data.state == 'error') {
                    this.formError = rps.data.body
                }

                // 提交成功
                if (rps.data.state == 'success') {
                    this.task = rps.data.body
                    this.$emit('onSave', rps.data.body)

                    this.formState = true
                    this.Close()
                }
            })
        }
    }
}
</script>