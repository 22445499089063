<template>
    <div :style="{ height: '100%' }">

        <!-- 表格 -->
        <uw-table :show-footer="false">

            <!-- 检索 -->
            <template slot="search">
                <!-- 指派给谁 -->
                <el-select v-model="search.now_assign" @change="TaskSearch()" placeholder="指派给谁的任务?" size="mini" clearable filterable>
                    <el-option v-for="(item, idx) in team" :key="idx" :label="item.user.name" :value="item.user.id"></el-option>
                </el-select>

                <!-- 任务状态 -->
                <el-select v-model="search.status" @change="TaskSearch()" placeholder="任务状态?" size="mini" clearable filterable>
                    <el-option :value="0" label="未开始"></el-option>
                    <el-option :value="1" label="进行中"></el-option>
                    <el-option :value="2" label="已暂停"></el-option>
                    <el-option :value="4" label="已关闭"></el-option>
                    <el-option :value="5" label="已完成"></el-option>
                    <el-option :value="6" label="已取消"></el-option>
                </el-select>

                <!-- 延期状态 -->
                <el-select v-model="search.is_overdue" @change="TaskSearch()" placeholder="是否已延期?" size="mini" clearable filterable>
                    <el-option :value="0" label="未延期"></el-option>
                    <el-option :value="1" label="已延期"></el-option>
                </el-select>

                <!-- 任务名称 -->
                <el-input @input="TaskSearch()" v-model="search.name" clearable  size="mini" placeholder="任务名称"></el-input>
            </template>

            <!-- 按钮 -->
            <template slot="button">
                <el-dropdown trigger="click">
                    <el-button type="primary" size="mini">批量管理<i class="el-icon-arrow-down el-icon--right"></i></el-button>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item @click.native.stop="$refs.taskCreateMultiple.Open(projects_id)">批量创建任务</el-dropdown-item>
                        <el-dropdown-item @click.native.stop="TaskEditAssign()">批量修改任务成员</el-dropdown-item>
                        <el-dropdown-item @click.native.stop="TaskDeleteMultiple()" divided>批量删除</el-dropdown-item>
                        <el-dropdown-item @click.native.stop="TaskFlowStartMultiple()" divided>批量开始</el-dropdown-item>
                        <el-dropdown-item @click.native.stop="TaskFlowSuspendMultiple()">批量暂停</el-dropdown-item>
                        <el-dropdown-item @click.native.stop="TaskFlowCancelMultiple()">批量取消</el-dropdown-item>
                        <el-dropdown-item @click.native.stop="TaskFlowCloseMultiple()">批量关闭</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
                <el-button style="margin-left: 5px;" @click="$refs.taskCreate.Open(projects_id)" type="primary" size="mini">新建任务</el-button>
                <el-button style="margin-left: 15px;" type="primary" size="mini" @click="$refs.xTable.print()" >打印</el-button>
                <el-button style="margin-left: 5px;" type="primary" size="mini" @click="$refs.xTable.openExport()" >导出</el-button>
            </template>

            <!-- 表数据 -->
            <template slot="data">
                <vxe-table
                    ref="xTable"
                    :data="task"
                    :loading="loading"
                    :export-config="{}"
                    :print-config="{}"
                    height="100%"
                    virtual-scroll-y
                    :row-config="{keyField: 'id'}"
                    :tree-config="{transform: true, lazy: true, childrenField: 'children', rowField: 'id', parentField: 'parent', indent: 10, reserve: true}">

                    <!-- 复选框 -->
                    <vxe-column type="checkbox" width="45" fixed="left"></vxe-column>
                    
                    <!-- 重要程度 -->
                    <vxe-column width="120" field="p" title="重要程度">
                        <template #default="{ row }">
                            <el-tag v-if="row.p == 1" size="small" effect="plain" type="danger">P1 非常重要</el-tag>
                            <el-tag v-if="row.p == 2" size="small" effect="plain" type="warning">P2 重要</el-tag>
                            <el-tag v-if="row.p == 3" size="small" effect="plain" type="primary">P3 次要</el-tag>
                            <el-tag v-if="row.p == 4" size="small" effect="plain" type="success">P4 一般</el-tag>
                        </template>
                    </vxe-column>

                    <!-- 任务阶段 -->
                    <vxe-column width="140" field="status" title="任务阶段">
                        <template #default="{ row }">
                            <el-tag size="small" v-if="row.status == 0" effect="plain" type="primary">未开始</el-tag>
                            <el-tag size="small" v-if="row.status == 1" effect="plain" type="primary">进行中</el-tag>
                            <el-tag size="small" v-if="row.status == 2" effect="plain" type="warning">已暂停</el-tag>
                            <el-tag size="small" v-if="row.status == 4" effect="plain" type="info">已关闭</el-tag>
                            <el-tag size="small" v-if="row.status == 5" effect="plain" type="success">已完成</el-tag>
                            <el-tag size="small" v-if="row.status == 6" effect="plain" type="info">已取消</el-tag>
                            <el-tag size="small" v-if="row.is_overdue == 1" effect="plain" type="danger" style="margin-left: 5px;">已延期</el-tag>
                        </template>
                    </vxe-column>

                    <!-- 任务名称 -->
                    <vxe-column min-width="200" field="name" title="任务名称" tree-node>
                        <template #default="{ row }">
                            <el-link :style="{ color: row.name_color }" @click="$refs.taskIndex.Open(row.id)">{{ row.name }}</el-link>
                        </template>
                    </vxe-column>

                    <!-- 指派给 -->
                    <vxe-column width="90" field="now_assign_user_name" title="指派给"></vxe-column>

                    <!-- 参与人员 -->
                    <vxe-column width="80" field="assign" title="参与人员" :visible="true">
                        <template #default="{ row }">
                            <el-tooltip placement="top" :content="row.assign_user_name.toString()">
                                <el-link icon="el-icon-user">{{ row.assign.length }}</el-link>
                            </el-tooltip>
                        </template>
                    </vxe-column>

                    <!-- 其它人员 -->
                    <vxe-column width="90" field="complete_user_name" title="完成者" :visible="false"></vxe-column>
                    <vxe-column width="90" field="create_user_name" title="创建者" :visible="false"></vxe-column>
                    <vxe-column width="90" field="cancel_user_name" title="取消者" :visible="false"></vxe-column>
                    <vxe-column width="90" field="close_user_name" title="关闭者" :visible="false"></vxe-column>
                    <vxe-column width="90" field="update_user_name" title="更新者" :visible="false"></vxe-column>

                    <!-- 概要 -->
                    <vxe-column width="120" field="type" title="任务类型"></vxe-column>
                    <vxe-column min-width="100" field="consume_days" title="任务消耗/P"></vxe-column>
                    <vxe-column min-width="100" field="wo_sum_day_consume" title="工单消耗/P"></vxe-column>
                    <vxe-column min-width="100" field="working_days" title="预期/P"></vxe-column>
                    <vxe-column min-width="100" title="小计/P">
                        <template #default="{ row }">
                            {{ Number(row.consume_days) + Number(row.wo_sum_day_consume) }}
                        </template>
                    </vxe-column>

                    <!-- 时间 -->
                    <vxe-column width="90" field="start_date[0]" title="开始时间"></vxe-column>
                    <vxe-column width="90" field="start_date[1]" title="截止时间"></vxe-column>
                    <vxe-column width="140" field="assign_date" title="任务实际分配时间" :visible="false"></vxe-column>
                    <vxe-column width="140" field="actual_start_date" title="实际开始时间" :visible="false"></vxe-column>
                    <vxe-column width="140" field="actual_complete_date" title="实际完成时间" :visible="false"></vxe-column>
                    <vxe-column width="140" field="cancel_date" title="任务取消时间" :visible="false"></vxe-column>
                    <vxe-column width="140" field="close_date" title="任务关闭时间" :visible="false"></vxe-column>
                    <vxe-column width="140" field="created_at" title="任务创建日期" :visible="false"></vxe-column>
                    <vxe-column width="140" field="updated_at" title="任务更新日期" :visible="false"></vxe-column>
                    
                    <vxe-column width="45" title="操作" fixed="right" align="center">
                        <template #default="{ row }">
                            <el-dropdown trigger="click">
                                <el-link icon="el-icon-more" type="primary" :underline="false"></el-link>
                                <el-dropdown-menu slot="dropdown">

                                    <el-dropdown-item @click.native.stop="$refs.taskStart.Open(row)" :disabled="[1, 4].includes(row.status) || row.children.length != 0">
                                        <el-link :underline="false" :disabled="[1, 4].includes(row.status) || row.children.length != 0">开始</el-link>
                                    </el-dropdown-item>
                                    <el-dropdown-item @click.native.stop="$refs.taskChange.Open(row)" :disabled="[4].includes(row.status) || row.children.length != 0">
                                        <el-link :underline="false" :disabled="[4].includes(row.status) || row.children.length != 0">转交</el-link>
                                    </el-dropdown-item>
                                    <el-dropdown-item @click.native.stop="$refs.taskComplete.Open(row)" :disabled="[0, 2, 4, 5, 6].includes(row.status) || row.children.length != 0">
                                        <el-link :underline="false" :disabled="[0, 2, 4, 5, 6].includes(row.status) || row.children.length != 0">完成</el-link>
                                    </el-dropdown-item>
                                    <el-dropdown-item @click.native.stop="$refs.taskClose.Open(row)" :disabled="[0, 1, 2, 4, 6].includes(row.status) || row.children.length != 0">
                                        <el-link :underline="false" :disabled="[0, 1, 2, 4, 6].includes(row.status) || row.children.length != 0">关闭</el-link>
                                    </el-dropdown-item>

                                    <el-dropdown-item @click.native.stop="$refs.taskEdit.Open(row.id)" divided>
                                        <el-link type="primary" :underline="false">修改</el-link>
                                    </el-dropdown-item>
                                    <el-dropdown-item @click.native.stop="$refs.taskSuspend.Open(row)" :disabled="[2, 4].includes(row.status) || row.children.length != 0">
                                        <el-link type="warning" :underline="false" :disabled="[2, 4].includes(row.status) || row.children.length != 0">暂停</el-link>
                                    </el-dropdown-item>
                                    <el-dropdown-item @click.native.stop="$refs.taskCancel.Open(row)" :disabled="[4, 6].includes(row.status || row.children.length != 0)">
                                        <el-link type="warning" :underline="false" :disabled="[4, 6].includes(row.status) || row.children.length != 0">取消</el-link>
                                    </el-dropdown-item>
                                    <el-dropdown-item @click.native.stop="TaskDelete([row.id])">
                                        <el-link type="danger" :underline="false">移除</el-link>
                                    </el-dropdown-item>

                                    <el-dropdown-item  @click.native.stop="$refs.taskCreate.Open(projects_id, row.id)" divided :disabled="row.parent != null">
                                        <el-link :underline="false" :disabled="row.parent != null">新建子任务</el-link>
                                    </el-dropdown-item>
                                    <el-dropdown-item @click.native.stop="$refs.taskCreateMultiple.Open(projects_id, row.id)" :disabled="row.parent != null">
                                        <el-link :underline="false" :disabled="row.parent != null">批量创建子任务</el-link>
                                    </el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </template>
                    </vxe-column>
                </vxe-table>
            </template>

        </uw-table>

        <!-- 任务创建 -->
        <taskCreate ref="taskCreate" @onSave="TaskSearch()"></taskCreate>
        <taskCreateMultiple ref="taskCreateMultiple" @onSave="TaskSearch()" />

        <!-- 任务编辑 -->
        <taskEdit ref="taskEdit" @onSave="TaskSearch()"></taskEdit>
        <taskEditAssign ref="taskEditAssign" @onSave="TaskSearch()"></taskEditAssign>

        <!-- 任务 - 开始/转交/完成/关闭 -->
        <taskStart ref="taskStart" @onSave='TaskSearch()'></taskStart>
        <taskChange ref="taskChange" @onSave='TaskSearch()'></taskChange>
        <taskComplete ref="taskComplete" @onSave='TaskSearch()'></taskComplete>
        <taskClose ref="taskClose" @onSave='TaskSearch()'></taskClose>

        <!-- 任务 - 取消/暂停/移除 -->
        <taskCancel ref="taskCancel" @onSave='TaskSearch()'></taskCancel>
        <taskSuspend ref="taskSuspend" @onSave='TaskSearch()'></taskSuspend>

        <!-- 任务 - 详情 -->
        <pm-projects-task-drawer ref="taskIndex" @onChange="TaskSearch()" />
    </div>
</template>

<script>
import taskCreate from '@/views/projects/drawerProjectsTaskCreate'
import taskCreateMultiple from '@/views/projects/drawerProjectsTaskCreateMultiple'

import taskEdit from '@/views/projects/drawerProjectsTaskEdit'
import taskEditAssign from '@/views/projects/drawerProjectsTaskEditAssign'

import taskStart from '@/views/projects/drawerProjectsTaskFlowStart'
import taskChange from '@/views/projects/drawerProjectsTaskFlowChange'
import taskComplete from '@/views/projects/drawerProjectsTaskFlowComplete'
import taskClose from '@/views/projects/drawerProjectsTaskFlowClose'
import taskCancel from '@/views/projects/drawerProjectsTaskFlowCancel'
import taskSuspend from '@/views/projects/drawerProjectsTaskFlowSuspend'

export default {
    props: ['projects_id'],

    components: {
        taskCreate,
        taskCreateMultiple,
        taskEdit,
        taskEditAssign,
        taskStart,
        taskChange,
        taskComplete,
        taskClose,
        taskCancel,
        taskSuspend,
    },

    data () {
        return {
            loading: true,

            task: [],
            team: [],

            // 检索
            search: {
                now_assign: null,
                status: null,
                is_overdue: null,
                name: null
            },

            // 排序
            order: {
                method: 'desc',
                column: 'id'
            },
        }
    },

    watch: {
        projects_id: {
            handler (val) {
                if (val) {
                    this.TaskSearch()
                    this.TeamSearch()
                }
            },
            immediate: true
        }
    },

    methods: {

        /**
        |--------------------------------------------------
        | 项目任务 - 检索
        |--------------------------------------------------
        |
        */

        TaskSearch() {
            // 切换加载状态
            this.loading = true

            // 检索请求
            const append = ['wo_sum_day_consume', 'type', 'assign_user_object', 'assign_user_name', 'now_assign_user_name', 'complete_user_name', 'create_user_name', 'cancel_user_name', 'close_user_name', 'update_user_name' ]
            this.$http.post(
                '/9api/pm/projects-task/search-all',
                {
                    search: { ...this.search, project_id: this.projects_id,  },
                    order: this.order,
                    append: append,
                    hidden: ['describe']
                }
            ).then(rps => {
                this.task = rps.data
                this.loading = false
            })
        },


        /**
        |--------------------------------------------------
        | 项目任务 - 移除
        |--------------------------------------------------
        |
        */

        TaskDelete(ids) {

            this.$confirm('此操作将永久删除数据，以及关联的数据，包括且不仅限以下内容: 任务工时、任务附件、子任务、统计数据等', '请谨慎操作', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'error',
                center: true
            }).then(() => {
                // 切换加载状态
                this.loading = true

                this.$http.post('/9api/projects-task/delete', { ids: ids }).then(rps => {
                    this.TaskSearch()
                })
            })
        },

        TaskDeleteMultiple() {
            this.TaskDelete(this.$refs.xTable.getCheckboxRecords().map(item => item.id))
        },


        /**
        |--------------------------------------------------
        | 项目任务 - 批量修改参任务成员
        |--------------------------------------------------
        |
        */

        TaskEditAssign() {
            const ids = this.$refs.xTable.getCheckboxRecords().map(item => item.id)
            this.$refs.taskEditAssign.Open(this.projects_id, ids)
        },


        /**
        |--------------------------------------------------
        | 项目成员 - 检索
        |--------------------------------------------------
        |
        */

        TeamSearch() {
            this.$http.post('/9api/projects-team/search', { project_id: this.projects_id, append: 'user' }).then(rps => {
                this.team = rps.data
            })
        },

        /**
        |--------------------------------------------------
        | 批量操作
        |--------------------------------------------------
        |
        */

        TaskFlowStartMultiple() {
            this.loading = true
            this.$http.post('/9api/projects-task/flow-start-multiple', { ids: this.$refs.xTable.getCheckboxRecords().map(item => item.id)}).then(rps => {
                this.TaskSearch()
            })
        },

        TaskFlowSuspendMultiple() {
            this.loading = true
            this.$http.post('/9api/projects-task/flow-suspend-multiple', { ids: this.$refs.xTable.getCheckboxRecords().map(item => item.id)}).then(rps => {
                this.TaskSearch()
            })
        },

        TaskFlowCancelMultiple() {
            this.loading = true
            this.$http.post('/9api/projects-task/flow-cancel-multiple', { ids: this.$refs.xTable.getCheckboxRecords().map(item => item.id)}).then(rps => {
                this.TaskSearch()
            })
        },

        TaskFlowCloseMultiple() {
            this.loading = true
            this.$http.post('/9api/projects-task/flow-close-multiple', { ids: this.$refs.xTable.getCheckboxRecords().map(item => item.id)}).then(rps => {
                this.TaskSearch()
            })
        }
    }
}
</script>
