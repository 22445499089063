<template>
    <el-dialog :visible.sync="visible" width="900px" title="申领产品" append-to-body destroy-on-close @close="Close()" :close-on-click-modal="false" :close-on-press-escape="false">

        <!-- 表单 -->
        <el-form :model="form" size="mini" label-position="top">
            
            <el-divider content-position="left"><b>基本信息</b></el-divider>
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="申领仓库" :rules="[{ required: true, message: '必选项' }]" :error="formError.warehouse_id?.toString()" prop="warehouse_id">
                        <ims-warehouse-select @onChange="form.log = []" v-model="form.warehouse_id" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="申领备注">
                        <el-input type="textarea" :rows="1" v-model="form.describe"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>

            <!-- 出库产品 -->
            <el-divider content-position="left"><b>申领产品</b></el-divider>
            <el-button type="primary" size="mini" @click="$refs.storageSearch.Open({ warehouse_id: form.warehouse_id })">添加产品</el-button>
            <vxe-table border ref="xTable" :data="form.log" :loading="loading" :style="{ marginTop: '10px' }">
                <vxe-column min-width="120" field="productName" title="产品名称"></vxe-column>
                <vxe-column min-width="120" field="productUnit" title="计量单位"></vxe-column>
                <vxe-column min-width="120" field="warehouseName" title="仓库名称"></vxe-column>
                <vxe-column min-width="120" field="num_max" title="库存数量"></vxe-column>
                <vxe-column min-width="120" title="申领数量">
                    <template #default="{ row }">
                        <el-input-number size="mini" v-model="row.num" :min="0" :precision="2" :controls="false" :style="{ width: '100%' }"></el-input-number>
                    </template>
                </vxe-column>
            </vxe-table>

        </el-form>

        <div slot="footer">
            <el-button size="small" @click="visible = false">取 消</el-button>
            <el-button size="small" type="primary" @click="FormSubmit()" :loading="loading">确 定</el-button>
        </div>

        <!-- 组件 -->
        <ims-warehouse-storage-search ref="storageSearch" @onChange="UpdateLog" />

    </el-dialog>
</template>

<script>
export default {
    data () {
        return {
            visible: false,

            formError: {},
            form: {
                warehouse_id: null,
                describe: null,
                log: []
            }
        }
    },

    // 计算属性
    computed: {
        loading () {
            return !!this.$store.state.httpRequestCount
        }
    },
    
    methods: {

        // 打开窗口
        Open() {
            this.visible = true
        },

        // 关闭窗口
        Close() {
            this.form.warehouse_id = null
            this.form.describe = null
            this.form.log = []
        },

        // 更新出库产品
        UpdateLog(data) {
            this.form.log = data.map(rps => {
                return {
                    product_id: rps.product_id,
                    productName: rps.product.name,
                    productUnit: rps.product.unit,
                    warehouse_id: rps.warehouse_id,
                    warehouseName: rps.warehouse.name,
                    num_max: rps.num,
                    num: 0.00
                }
            })
        },

        // 提交表单
        FormSubmit() {
            this.$http.post('/9api/ims/product-apply/create', this.form).then((rps) => {
                if (rps.data.state == 'error') {
                    this.formError = rps.data.body
                }
                if (rps.data.state == 'success') {
                    this.$emit('onChange', rps.data.body)
                    this.visible = false
                }
            })
        },
    }
}
</script>