<template>
    <div>
        <el-alert :style="{ marginBottom: '20px' }" title="此功能暂不可用，以后有需求会对接工商信息！" type="error" effect="dark"></el-alert>

        <!-- 基本信息 -->
        <div style="background-color: #f4f5f7; border-radius: 3px; padding: 15px; display: grid; font-size: 14px; grid-template-columns: auto auto; gap: 5px 10px; border: 1px solid #0027ff;">
            <div><span :style="{ color: '#616161' }">法定代表人：</span>黄长平</div>
            <div><span :style="{ color: '#616161' }">统一社会信用代码：</span>91441900771898314G</div>
            <div><span :style="{ color: '#616161' }">注册资本：</span>1000 万人民币</div>
            <div><span :style="{ color: '#616161' }">地址：</span>广东省东莞市南城街道黄金路1号天安数码城6栋4单元1001室</div>
            <div><span :style="{ color: '#616161' }">电话：</span>0769-22343300</div>
            <div><span :style="{ color: '#616161' }">邮箱：</span>872005501@qq.com</div>
        </div>

        <!-- 详细资料 -->
        <el-tabs v-model="m">

            <el-tab-pane label="工商信息" name="details1">
                <el-descriptions size="mini" border :column="2" :labelStyle="{ width: '150px' }">
                    <el-descriptions-item label="法定代表人">黄长平</el-descriptions-item>
                    <el-descriptions-item label="经营状态">存续</el-descriptions-item>
                    <el-descriptions-item label="注册资本">1000 万人民币</el-descriptions-item>
                    <el-descriptions-item label="实缴资本">1000 万人民币</el-descriptions-item>
                    <el-descriptions-item label="统一社会信用代码">91441900771898314G</el-descriptions-item>
                    <el-descriptions-item label="工商注册号">441900000223546</el-descriptions-item>
                    <el-descriptions-item label="组织机构代码">771898314</el-descriptions-item>
                    <el-descriptions-item label="成立日期">2005-03-15</el-descriptions-item>
                    <el-descriptions-item label="企业类型">有限责任公司（自然人投资或控股）</el-descriptions-item>
                    <el-descriptions-item label="营业期限">2005-03-15--</el-descriptions-item>
                    <el-descriptions-item label="登记机关">东莞市市场监督管理局</el-descriptions-item>
                    <el-descriptions-item label="核准日期">2020-09-03</el-descriptions-item>
                    <el-descriptions-item label="注册地址" :span="2">广东省东莞市南城街道黄金路1号天安数码城6栋4单元1001室</el-descriptions-item>
                    <el-descriptions-item label="经营范围" :span="2">大数据产品、云计算产品、物联网产品的销售及技术研发；大数据服务平台的研发及维护；大数据信息咨询服务；网络信息安全产品的开发与销售；计算机软硬件产品的开发与销售；教育设备销售；声学工程及其技术咨询服务；计算机系统集成、计算机信息技术咨询与服务；机电安装工程设计与施工；安防工程、智能化工程设计与施工；设备租赁；增值电信业务经营（互联网信息服务）；销售：计算机及其周边设备、电子产品、教育设备、办公设备、办公用品、通讯设备、投影设备、摄影器材。（依法须经批准的项目，经相关部门批准后方可开展经营活动）</el-descriptions-item>
                </el-descriptions>
            </el-tab-pane>

            <el-tab-pane label="主要人员" name="details2">
                <vxe-table :data="a">
                    <vxe-column width="60" type="seq" title="序号" fixed="left"></vxe-column>
                    <vxe-column min-width="160" field="name" title="姓名"></vxe-column>
                    <vxe-column min-width="160" field="appointment" title="职务"></vxe-column>
                </vxe-table>
            </el-tab-pane>

            <el-tab-pane label="股东信息" name="details3">
                <vxe-table :data="b">
                    <vxe-column width="60" type="seq" title="序号" fixed="left"></vxe-column>
                    <vxe-column min-width="160" field="name" title="发起人/股东"></vxe-column>
                    <vxe-column min-width="160" field="outher1" title="持股比例"></vxe-column>
                    <vxe-column min-width="160" field="outher2" title="股东类型"></vxe-column>
                    <vxe-column min-width="160" field="outher3" title="投资金额（万元）"></vxe-column>
                </vxe-table>
            </el-tab-pane>

            <el-tab-pane label="分支机构" name="details4">
                <vxe-table :data="c">
                    <vxe-column width="60" type="seq" title="序号" fixed="left"></vxe-column>
                    <vxe-column min-width="160" field="name" title="公司名称"></vxe-column>
                    <vxe-column min-width="160" field="outher1" title="负责人"></vxe-column>
                    <vxe-column min-width="160" field="outher2" title="成立时间"></vxe-column>
                    <vxe-column min-width="160" field="outher3" title="经营状态"></vxe-column>
                </vxe-table>
            </el-tab-pane>

            <el-tab-pane label="对外投资" name="details5">
                <vxe-table :data="d">
                    <vxe-column width="60" type="seq" title="序号" fixed="left"></vxe-column>
                    <vxe-column min-width="160" field="name" title="被投资企业"></vxe-column>
                    <vxe-column min-width="160" field="outher1" title="股东类型"></vxe-column>
                    <vxe-column min-width="160" field="outher2" title="持股比例"></vxe-column>
                    <vxe-column min-width="160" field="outher3" title="投资金额（万元）"></vxe-column>
                </vxe-table>
            </el-tab-pane>

            <el-tab-pane label="变更记录" name="details6">
                <vxe-table :data="e">
                    <vxe-column width="60" type="seq" title="序号" fixed="left"></vxe-column>
                    <vxe-column min-width="160" field="name" title="变更日期"></vxe-column>
                    <vxe-column min-width="160" field="outher1" title="变更事项"></vxe-column>
                    <vxe-column min-width="160" field="outher2" title="变更前"></vxe-column>
                    <vxe-column min-width="160" field="outher3" title="变更后"></vxe-column>
                </vxe-table>
            </el-tab-pane>

        </el-tabs>
    </div>
</template>

<script>
export default {
    data () {
        return {
            m: 'details1',
            a: [
                { id: 0,  name: '张国斐', appointment: '监事'},
                { id: 1,  name: '杨超洪', appointment: '董事长'},
                { id: 2,  name: '骆林勇', appointment: '董事'},
                { id: 3,  name: '魏书山', appointment: '董事'},
                { id: 4,  name: '黄长平', appointment: '经理,董事'},
                { id: 5,  name: '叶胜明', appointment: '董事'},
                { id: 6,  name: '戴科冕', appointment: '董事'},
                { id: 7,  name: '曾楚悦', appointment: '董事'},
                { id: 8,  name: '李斌乐', appointment: '董事'},
                { id: 8,  name: '陈继军', appointment: '董事'},
                { id: 8,  name: '刘天晴', appointment: '董事'},
                { id: 8,  name: '罗茜', appointment: '监事'}
            ],
            b: [
                { id: 0,  name: '魏书山', outher1: '23.50%', outher2: '自然人股东', outher3: '235'},
                { id: 0,  name: '刘天晴', outher1: '5.00%', outher2: '自然人股东', outher3: '50'},
                { id: 0,  name: '杨超洪', outher1: '35.00%', outher2: '自然人股东', outher3: '350'},
                { id: 0,  name: '黄长平', outher1: '23.50%', outher2: '自然人股东', outher3: '235'},
                { id: 0,  name: '罗茜', outher1: '10.00%', outher2: '自然人股东', outher3: '100'},
                { id: 0,  name: '林卫波', outher1: '3.00%', outher2: '自然人股东', outher3: '30'}
            ],
            c: [
                { id: 0,  name: '广东网安科技有限公司广州分公司', outher1: '罗茜', outher2: '2013-12-11', outher3: '在营（开业）企业'},
                { id: 0,  name: '广东网安科技有限公司深圳分公司', outher1: '钟竣豪', outher2: '2018-04-18', outher3: '存续（在营、开业、在册）'}
            ],
            d: [
                { id: 0,  name: '数据技术', outher1: '工商股东', outher2: '100.00%', outher3: '1000'},
                { id: 0,  name: '信息咨询', outher1: '工商股东', outher2: '54.00%', outher3: '54'},
                { id: 0,  name: '广西网络', outher1: '工商股东', outher2: '40.00%', outher3: '240'},
                { id: 0,  name: '广州科技', outher1: '工商股东', outher2: '51.00%', outher3: '510'},
                { id: 0,  name: '数字大数据', outher1: '工商股东', outher2: '51.00%', outher3: '510'},
                { id: 0,  name: '数字安全', outher1: '工商股东', outher2: '10.00%', outher3: '66.8'},
                { id: 0,  name: '广东科技', outher1: '工商股东', outher2: '0.00%', outher3: '0'},
                { id: 0,  name: '君谊院', outher1: '工商股东', outher2: '5.00%', outher3: '5'},
            ],
            e: [
                { id: 0,  name: '2020-04-08', outher1: '经营范围变更', outher2: 'xxxxxxxxx', outher3: 'xxxxxxxxxxxxx'},
                { id: 0,  name: '2020-04-08', outher1: '经营范围变更', outher2: 'xxxxxxxxx', outher3: 'xxxxxxxxxxxxx'},
                { id: 0,  name: '2020-04-08', outher1: '经营范围变更', outher2: 'xxxxxxxxx', outher3: 'xxxxxxxxxxxxx'},
                { id: 0,  name: '2020-04-08', outher1: '经营范围变更', outher2: 'xxxxxxxxx', outher3: 'xxxxxxxxxxxxx'},
                { id: 0,  name: '2020-04-08', outher1: '经营范围变更', outher2: 'xxxxxxxxx', outher3: 'xxxxxxxxxxxxx'},
                { id: 0,  name: '2020-04-08', outher1: '经营范围变更', outher2: 'xxxxxxxxx', outher3: 'xxxxxxxxxxxxx'}
            ]
        }
    }
}
</script>