<!---------------------------------------------------------------------------- 
 | 票据 - 类型
 |----------------------------------------------------------------------------
 | 方法：请注意大小写,参数描述见具体方法
 | *v-model     默认值------默认为 null
 | width        表单宽度----默认为 ‘100%’
 | margin       表单外边距--默认为 ‘0px’
 |----------------------------------------------------------------------------
 | 回调
 | @onChange = fn () => {} 返回表单内容
 |
 ---------------------------------------------------------------------------->

 <template>
    <el-select
        @change="SelectChange"
        v-model="selectValue"
        size="mini"
        :style="{ width: width, margin: margin }"
        placeholder="请选择类型"
        clearable
        filterable>
        <el-option v-for="(item, idx) in select" :key="idx" :label="item.name"  :value="item.id"></el-option>
    </el-select>
</template>

<script>
export default {
    props: {
        value: {
            type: [String, Array, Number],
            default: null
        },

        width: {
            type: String,
            default: '100%'
        },

        margin: {
            type: String,
            default: '0px'
        },
    },

    watch: {
        value () {
            this.selectValue = this.value
        }
    },

    data () {
        return {
            select: [],
            selectValue: null,
        }
    },

    mounted () {
        this.$http.post('/9api/news-type/all').then(rps => {
            this.select = rps.data
        })
    },

    methods: {
        SelectChange(id) {
            this.$emit('input', id)
            this.$emit('onChange')
        }
    }
}
</script>