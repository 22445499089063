<template>
    <el-drawer :visible.sync="visible" size="80%" direction="rtl" append-to-body destroy-on-close :show-close="false" :before-close="Close">

        <div slot="title">

            <div class="w-margin-b-10">{{ product.supplier.name }}</div>
            <div class="w-margin-b-10" style="font-size: 18px; color: #000; font-weight: bold;">{{ product.name }}</div>

            <!-- 功能按钮 -->
            <div style="display: flex;">
                <el-button size="mini" icon="el-icon-edit" @click="RePage()">刷新</el-button>
                <el-button style="margin-left: 5px;" size="mini" icon="el-icon-edit" @click="$refs.dialogUpdate.Open(product.id)">修改产品</el-button>
                <el-button style="margin-left: 5px;" size="mini" @click="DataDelete()" type="danger">移除产品</el-button>
            </div>

            <!-- 金额概览 -->
            <div class="w-margin-t-10" style="background-color: #f4f5f7; border-radius: 3px; padding: 15px; display: flex;">
                <el-statistic title="产品品牌"><template slot="formatter"><span style="font-size: 16px;"><b>{{ product.brand }}</b></span></template></el-statistic>
                <el-statistic title="产品类别"><template slot="formatter"><span style="font-size: 16x;"><b>{{ product.type.name }}</b></span></template></el-statistic>
                <el-statistic title="计量单位"><template slot="formatter"><span style="font-size: 14px;">{{ product.unit }}</span></template></el-statistic>
                <el-statistic title="更新时间"><template slot="formatter"><span style="font-size: 14px;">{{ product.updated_at }}</span></template></el-statistic>
                <el-statistic title="创建者"><template slot="formatter"><span style="font-size: 14px;">{{ product.created_user.name }}</span></template></el-statistic>
            </div>

        </div>

        <div class="w-padding-l-20 w-padding-r-20 w-padding-b-20">
            <el-tabs v-model="tabs">

                <!-- 详细资料 -->
                <el-tab-pane label="详细资料" name="details">
                    
                    <!-- 基本信息 -->
                    <el-descriptions title="基本信息" direction="vertical" size="mini" border :column="2" class="w-margin-b-20">
                        <el-descriptions-item label="产品名称">{{ product.name }}</el-descriptions-item>
                        <el-descriptions-item label="计量单位">{{ product.unit }}</el-descriptions-item>
                        <el-descriptions-item label="供应商名称">
                            <el-link icon="el-icon-office-building" @click="$refs.supplierDetails.Open(product.supplier.id)" type="primary">
                                {{ product.supplier.name }}
                            </el-link>
                        </el-descriptions-item>
                        <el-descriptions-item label="产品品牌">{{ product.brand }}</el-descriptions-item>
                        <el-descriptions-item label="产品类别">{{ product.type.name }}</el-descriptions-item>
                        <el-descriptions-item label="维保信息">{{ product.maintenance }}</el-descriptions-item>
                        <el-descriptions-item label="备注说明" :span="2">{{ product.describe }}</el-descriptions-item>
                        <el-descriptions-item label="规格参数" :span="2">
                            <span v-if="product.parameter.length == 0">暂未配置</span>
                            <el-tag v-for="(v, i) in product.parameter" :key="i" size="mini" effect="plain" :style="{ marginRight: '5px', marginBottom: '5px' }">{{ v }}</el-tag>
                        </el-descriptions-item>
                    </el-descriptions>

                    <!-- 系统信息 -->
                    <el-descriptions title="系统信息" direction="vertical" size="mini" border :column="2">
                        <el-descriptions-item label="创建时间">{{ product.created_at }}</el-descriptions-item>
                        <el-descriptions-item label="创建人">{{ product.created_user.name }}</el-descriptions-item>
                        <el-descriptions-item label="更新时间">{{ product.updated_at }}</el-descriptions-item>
                        <el-descriptions-item label="更新人">{{ product.updated_user.name }}</el-descriptions-item>
                    </el-descriptions>

                </el-tab-pane>
            </el-tabs>
        </div>

        <!-- 组件 -->
        <ims-product-update ref='dialogUpdate' @onChange="RePage()" />
        <ims-supplier-drawer ref="supplierDetails" @onChange="RePage()" />

    </el-drawer>
</template>

<script>
export default {
    data() {
        return {
            visible: false,
            loading: false,
            tabs: 'details',

            product: {
                type: {},
                supplier: {},
                parameter: [],
                created_user: {},
                updated_user: {}
            }
        }
    },

    methods: {

        /**
        |--------------------------------------------------
        | 打开窗口
        |--------------------------------------------------
        |
        */

        Open(id) {
            this.visible = true
            this.DataGet(id)
        },

        /**
        |--------------------------------------------------
        | 关闭窗口
        |--------------------------------------------------
        |
        */

        Close(done) {
            this.$emit('onChange')
            done()
        },

        /**
        |--------------------------------------------------
        | 刷新
        |--------------------------------------------------
        |
        */

        RePage() {
            this.DataGet()
        },

        /**
        |--------------------------------------------------
        | 查询
        |--------------------------------------------------
        |
        */

        DataGet(id = this.product.id) {
            this.loading = true

            this.$http.post(
                '/9api/ims/product/search-first',
                {
                    search: { id: id },
                    append: ['created_user', 'updated_user', 'type', 'supplier']
                }
            ).then(rps => {
                this.product = rps.data
                this.loading = false
            })
        },

        /**
        |--------------------------------------------------
        | 删除
        |--------------------------------------------------
        |
        */

        DataDelete() {
            this.$http.post('/9api/ims/product/delete', {id: this.product.id}).then(rps => {
                this.$emit('onChange')
                this.visible = false
            })
        },
    }
}

</script>6
