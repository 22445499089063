<!---------------------------------------------------------------------------- 
 | 规则配置
 |----------------------------------------------------------------------------
 | 方法：请注意大小写
 | $refs[ref_name].Open(Array *rule) 打开抽屉
 | $refs[ref_name].Close() 手动关闭抽屉
 |----------------------------------------------------------------------------
 | props 
 | :work (string) contract=合同
 |----------------------------------------------------------------------------
 | 回调
 | @onSave = fn (json) => {} 确定后，返回配置规则
 | @onClose = fn () => {} 窗口关闭时回调
 |
 ---------------------------------------------------------------------------->
<template>
    <el-drawer class="el-drawer-xxb" :visible.sync="visible" :before-close="Close" :size="960" :with-header="false" direction="rtl" :append-to-body="true">
        
        <!-- header -->
        <div class="el-drawer-header">
            <i class="el-icon-paperclip"></i> 规则配置（自动应用）
        </div>

        <!-- body -->
        <div class="el-drawer-body el-scroll-y" v-loading="loading">

            <!-- 条件说明 -->
            <el-link class="el-margin-b-10">条件配置说明：</el-link><br>
            <el-link class="el-margin-b-10" type="info">* 不同模块的规则不一致，例如“合同管理”有合同名称，而“工单管理”有工单名称。</el-link><br>
            <el-link class="el-margin-b-10" type="info">* 如果存在多个条件，则多个条件同时命中，才会进入下一个流程。</el-link><br>
            <el-link class="el-margin-b-10" type="info">* 如果没有配置规则，则默认向下执行。</el-link><br>
            <el-link class="el-margin-b-10" type="info">* 所有规则按照优先级执行，可以通过流传面板配置规则的优先级别。</el-link><br>
            <el-link class="el-margin-b-10" type="info">* 原则上请配置至少一条规则(也可以不配置)</el-link><br>
            <el-link class="el-margin-b-10" type="warning">* 规则在配置过程中，会自动应用规则。多余的<b>“应用规则”按钮</b>是预留的，防止以后需求变动大改。</el-link><br>
            <el-link class="el-margin-b-10" type="warning">* 流传面板逻辑复杂，如果需要特殊规则或其他功能，请联系“研发部-许祥宾”排期开发。</el-link><br>
            <el-link class="el-margin-b-10" type="warning">* 如果设置的条件过于复杂，导致页面排版错误(大概率)或其它问题，并不会响应功能，请联系“研发部-许祥宾”并继续使用。</el-link><br>
            <el-link class="el-margin-b-10" type="danger">* 重要-如果流程执行过程报错，一定是因为<b>后台</b>更改了字段名导致流程故障，如果您的需求涉及到更改表单，请慎重。</el-link><br>
            
            <!-- 条件配置 -->
            <el-divider></el-divider>
            <el-row class="el-margin-b-10">
                <el-col :span="24">
                    <el-button size="mini" type="primary" icon="el-icon-plus" @click="RulePush()">添加规则</el-button>
                </el-col>
            </el-row>

            <el-row v-for="(element, idx) in rule" :key="idx" :gutter="10" class="el-margin-b-10">
                <!-- 验证条件 -->
                <el-col :span="7">
                    <el-select v-model="element.column.value" placeholder="请选择" size="mini" class="el-width-auth">
                        <el-option v-for="(item, key) in ruleColumn" :key="key" :label="item.label" :value="item.value" @click.native="ColumnChange(element.column, item)" />
                    </el-select>
                </el-col>

                <!-- 验证规则 -->
                <el-col :span="7">
                    <el-select v-model="element.where.value" placeholder="请选择" size="mini" class="el-width-auth">
                        <el-option v-for="(item, key) in ruleWhere" :key="key" :label="item.label" :value="item.value"  @click.native="WhereChange(element.where, item)"></el-option>
                    </el-select>
                </el-col>

                <!-- 匹配值 -->
                <el-col :span="7">
                    <!-- 字符串 -->
                    <el-input v-model="element.value" v-if="element.column.type == 'string'" placeholder="请输入内容" size="mini" class="el-width-auth"></el-input>

                    <!-- 日期时间 -->
                    <el-date-picker v-model="element.value" v-if="element.column.type == 'datetime'" placeholder="选择日期时间" size="mini" class="el-width-auth" type="datetime"></el-date-picker>

                    <!-- 浮点数 -->
                    <el-input v-model="element.value" v-if="element.column.type == 'number'" placeholder="请输入内容" size="mini" class="el-width-auth" type="number"></el-input>

                </el-col>

                <!-- 动态删减 -->
                <el-col :span="3">
                    <el-button size="mini" type="danger" icon="el-icon-delete" @click="RuleDel(idx)"></el-button>
                </el-col>
            </el-row>

            <el-empty description="未配置规则" v-if="rule.length == 0"></el-empty>
        </div>

        <!-- footer -->
        <div class="el-drawer-footer">
            <el-button size="medium" @click="Close()">关闭窗口</el-button>
            <el-button size="medium" type="primary" @click="RuleSave()" :loading="loading">应用规则</el-button>
        </div>
    </el-drawer>
</template>

<script>
export default {
    props: {
        work: {
            type: String,
            default: 'contract'
        }
    },

    data () {
        return {
            // 窗口
            visible: false,
            loading: false,

            // 规则配置
            rule: [],
            ruleColumn: [],
            ruleWhere: [
                { value: '=', label: '等于' },
                { value: '>', label: '大于' },
                { value: '>=', label: '大于等于' },
                { value: '<', label: '小于' },
                { value: '<=', label: '小于等于' },
                { value: 'like', label: '包含' },
                { value: 'not like', label: '不包含' },
            ],
        }
    },

    mounted() {
        if (this.work == 'contract') {
            this.ruleColumn = [
                { value: 'name', label: '合同名称', type: 'string' },
                { value: 'number', label: '合同编号', type: 'string' },
                { value: 'source', label: '合同来源', type: 'string' },
                { value: 'status', label: '合同状态', type: 'string' },
                { value: 'describe', label: '合同描述', type: 'string' },
                { value: 'sign_date', label: '签单日期', type: 'datetime' },
                { value: 'sign_money', label: '签单金额', type: 'number' },
                { value: 'estimate_money', label: '预估成本', type: 'number' },
                { value: 'created_at', label: '创建时间', type: 'datetime' },
                { value: 'updated_at', label: '更新时间', type: 'datetime' },
            ]
        }
    },

    methods: {
        /**
         |--------------------------------------------------
         |窗口 - 关闭
         |--------------------------------------------------
         |
         */

        Close() {
            this.$emit('onClose')
            this.visible = false
        },


        /**
         |--------------------------------------------------
         |窗口 - 打开
         |--------------------------------------------------
         |rule - json - 配置规则
         |--------------------------------------------------
         |
         */

        Open(rule) {
            // 开始加载
            this.loading = true
            
            // 应用规则
            this.rule = rule
            this.loading = false
            this.visible = true
        },


        /**
         |--------------------------------------------------
         |验证规则 - 保存
         |--------------------------------------------------
         |
         */

        RuleSave() {
            this.ruleOld = this.rule
            this.$emit('onSave', this.rule)
            this.Close()
        },



        /**
         |--------------------------------------------------
         |验证规则 - 添加
         |--------------------------------------------------
         |
         */

        RulePush() {
            this.rule.push({ column: Object.assign({}, this.ruleColumn[0]), where: Object.assign({}, this.ruleWhere[0]), value: null, state: null })
        },


        /**
         |--------------------------------------------------
         |验证规则 - 移除
         |--------------------------------------------------
         |
         */
        RuleDel(idx) {
            this.rule.splice(idx, 1)
        },


        /**
         |--------------------------------------------------
         |验证规则 - 替换条件
         |--------------------------------------------------
         |
         */

        ColumnChange(column, item) {
            column.value = item.value
            column.label = item.label
            column.type = item.type
        },


        /**
         |--------------------------------------------------
         |验证规则 - 替换规则
         |--------------------------------------------------
         |
         */

        WhereChange(where, item) {
            where.value = item.value
            where.label = item.label
        }
    }
}
</script>