<template>
    <div>
        <!-- 阶段任务 -->
        <div>
            <el-button-group :style="{ marginRight: '10px' }">
                <el-button size="mini" :type="!search.users ? 'primary' : ''" @click="search.users = false; taskSearch()">全部</el-button>
                <el-button size="mini" :type="search.users ? 'primary' : ''"  @click="search.users = true;  taskSearch()">我参与的</el-button>
            </el-button-group>
            <el-button size="mini" icon="el-icon-plus" @click="$refs.taskCreate.open({ business_id: business_id  })">新建任务</el-button>
        </div>

        <el-alert v-if="alertShow" :style="{ marginTop: '10px' }" title="您当前没有参与任何任务！" description="【接收人、驳回人、完成人、关闭人、验收人、创建人、更新人】都不与您存在关系！！" show-icon effect="dark" type="error" close-text="知道了"></el-alert>
        
        <vxe-table border ref="xTable" :data="table" :loading="loading" :expand-config="{}" :style="{ marginTop: '10px' }">

            <!-- 状态 -->
            <vxe-column width="70" field="state" title="状态" align="center">
                <template #default="{ row }">
                    <el-tag effect="dark" size="mini" type="warning"    v-if="row.state == 0">待接收</el-tag>
                    <el-tag effect="dark" size="mini" type="success"    v-if="row.state == 1">已接收</el-tag>
                    <el-tag effect="dark" size="mini" type="success"    v-if="row.state == 2">已完成</el-tag>
                    <el-tag effect="dark" size="mini" type="danger"     v-if="row.state == 3">已驳回</el-tag>
                    <el-tag effect="dark" size="mini"                   v-if="row.state == 4">已验收</el-tag>
                    <el-tag effect="dark" size="mini" type="info"       v-if="row.state == 5">已关闭</el-tag>
                </template>
            </vxe-column>

            <!-- 任务名称 -->
            <vxe-column min-width="220" field="name" title="任务名称">
                <template #default="{ row }">
                    <el-link type="primary" @click="$refs.taskDrawer.open(row.id)" :icon="row.files_id.length > 0 ? 'el-icon-paperclip' : null">{{ row.name }}</el-link>
                </template>
            </vxe-column>

            <!-- 截止时间 -->
            <vxe-column min-width="100" field="datetime" title="截止时间">
                <template #default="{ row }">
                    {{ row.datetime[0] }} 至 {{ row.datetime[1] }}
                    <el-tag v-if="row.timeout > 0" effect="plain" size="mini" type="danger">超期 {{ row.timeout }} 天</el-tag>
                </template>
            </vxe-column>

            <!-- 消耗工时 -->
            <vxe-column width="100" field="work_day" title="消耗工作日">
                <template #default="{ row }">
                    <span v-if="row.state == 0">-</span>
                    <span v-else>{{ row.consume_days }} / 天</span>
                </template>
            </vxe-column>

            <!-- 指派给 -->
            <vxe-column width="80" field="user_assign.name" title="指派给"></vxe-column>

            <!-- 负责人 -->
            <vxe-column width="80" field="user_admin.name" title="负责人"></vxe-column>

            <!-- 协助人 -->
            <vxe-column width="160" field="user_admin.name" title="协助人">
                <template #default="{ row }">
                    {{ row.user_helper.map(rps => rps.name).join('，') }}
                </template>
            </vxe-column>

            <!-- 提交动作 -->
            <vxe-column width="80" field="receive_user.name" title="实际接收人"></vxe-column>

            <!-- 确认动作 -->
            <vxe-column width="80" field="complete_user.name" title="实际完成人"></vxe-column>

        </vxe-table>

        <!-- 组件 -->
        <crm-business-task-create ref="taskCreate" @onChange="taskSearch" />
        <crm-business-task-drawer ref="taskDrawer" @onChange="taskSearch" />
    </div>
</template>

<script>
export default {
    props: ['business_id'],

    data () {
        return {
            loading: false,
            alertShow: false,

            table: [],
            
            search: {
                users: true
            }
        }
    },

    mounted() {
        this.taskSearch()
    },

    methods: {

        // 进展 - 查询
        taskSearch() {
            this.loading = true

            this.$http.post('/9api/crm/business-task/search-all', { search: { business_id: this.business_id, ...this.search }, append: [ 'timeout', 'user_assign', 'user_helper', 'user_admin', 'receive_user', 'complete_user', 'reject_user'] }).then((rps) => {
                this.table = rps.data

                // 查询结果不存在则查询全部数据
                if (rps.data.length == 0 && this.search.users) {
                    this.alertShow = true
                    this.search.users = false
                    this.taskSearch()
                }

                this.loading = false
            })
        }

    }
}
</script>