<template>
    <el-form :model="form" size="mini" label-position="top" :style="{ maxWidth: '800px', margin: 'auto' }">

        <div :style="{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderBottom: '1px solid #EEEEEE', marginBottom: '10px' }">
            <h4>订单信息</h4>
            <el-button type="primary" @click="FormSubmit()">保存</el-button>
        </div>
        
        <el-row :gutter="20">
            <el-col :span="24" v-if="form.type == 'contract'">
                <el-form-item label="关联合同" :rules="[{ required: true, message: '必选项' }]" :error="formError.contract_id?.toString()" prop="contract_id">
                    <crm-contract-select v-model="form.contract_id" v-show="!disabled" @click="$refs.contractDrawer.Open(form.contract_id)" />
                    <span class="el-span-primary" @click="$refs.contractDrawer.Open(form.contract_id)" v-if="disabled">
                        {{ form.contract?.number }} - {{ form.contract?.name }}
                    </span>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="采购日期" :rules="[{ required: true, message: '必填项' }]" :error="formError.date?.toString()" prop="date">
                    <el-date-picker v-model="form.date" type="date" value-format="yyyy-MM-dd" :style="{ width: '100%' }"></el-date-picker>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="采购备注">
                    <el-input type="textarea" :rows="1" v-model="form.describe"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        
        <!-- 交货信息 -->
        <template v-if="form.type == 'contract'">
            <div :style="{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderBottom: '1px solid #EEEEEE', marginBottom: '10px' }">
                <h4>交货信息</h4>
                <el-button type="primary" @click="FormSubmit()">保存</el-button>
            </div>
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="交货时间">
                        <el-date-picker v-model="form.delivery_date" type="date" value-format="yyyy-MM-dd" :style="{ width: '100%' }"></el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="交货地址">
                        <el-input v-model="form.delivery_address" clearable></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="联系人">
                        <el-input v-model="form.delivery_name" clearable></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="电话">
                        <el-input v-model="form.delivery_phone" clearable></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </template>
        
    </el-form>
</template>

<script>
export default {
    props: ['id'],

    data () {
        return {
            visible: false,

            formError: {},
            form: {
                type: 'company',
                contract_id: null,
                date: null,
                money: 0,
                discount: 0,
                describe: null,
                product_log: [],
                delivery_date: null,
                delivery_address: null,
                delivery_name: null,
                delivery_phone: null,
                created_user: {},
                updated_user: {}
                
            }
        }
    },

    watch: {
        id: {
            handler(val) {
                if (val) {
                    this.DataGet()
                }
            },
            immediate: true
        }
    },
    
    methods: {

        DataGet() {
            this.$http.post('/9api/ims/product-order/search-first', { search: { id: this.id }, append: ['created_user', 'updated_user', 'contract'] }).then(rps => {
                this.form = rps.data
            })
        },

        // 关闭窗口
        Close() {
            this.form.id = null
            this.form.type = null
            this.form.contract_id = null
            this.form.date = null
            this.form.money = 0
            this.form.describe = null
            this.form.product = []
            this.form.delivery_date = null
            this.form.delivery_address = null
            this.form.delivery_name = null
            this.form.delivery_phone = null
        },

        // 提交表单
        FormSubmit() {
            this.$http.post('/9api/ims/product-order/update', this.form).then((rps) => {

                // 提交失败
                if (rps.data.state == 'error') {
                    this.formError = rps.data.body
                }

                // 提交成功
                if (rps.data.state == 'success') {
                    this.$emit('onChange', rps.data.body)
                    this.disabled = true
                }
            })
        },
    }
}
</script>