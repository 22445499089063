<template>
    <el-dialog :visible.sync="visible" title="新增产品" width="900px" append-to-body destroy-on-close @close="Close()" :close-on-click-modal="false" :close-on-press-escape="false">

        <!-- 表单 -->
        <el-form :model="form" label-position="top">

            <!-- 添加产品 -->
            <el-button type="primary" @click="$refs.productSearch.open()">添加产品</el-button>

            <!-- 产品列表 -->
            <vxe-table border ref="xTable" :data="form.product" :loading="loading" :style="{ marginTop: '10px' }">

                <!-- 序号 -->
                <vxe-column type="checkbox" width="50" fixed="left"></vxe-column>

                <!-- 产品名称 -->
                <vxe-column min-width="100" field="name" title="产品名称" fixed="left"></vxe-column>
                
                <!-- 计量单位 -->
                <vxe-column min-width="80" field="unit" title="计量单位"></vxe-column>
                
                <!-- 付款方式 -->
                <vxe-column min-width="160" title="付款方式">
                    <template #default="{ row }">
                        <config-pay-type-select v-model="row.pay_type_id" />
                    </template>
                </vxe-column>

                <!-- 采购数量 -->
                <vxe-column min-width="120" title="采购数量">
                    <template #default="{ row }">
                        <el-input-number v-model="row.num" @change="ProductUpdate" :min="1" :precision="2" :controls="false" :style="{ width: '100%' }"></el-input-number>
                    </template>
                </vxe-column>

                <!-- 发票税率/% -->
                <vxe-column min-width="120" title="发票税率/%">
                    <template #default="{ row }">
                        <el-input-number v-model="row.taxation_rate" :controls="false" :style="{ width: '100%' }"></el-input-number>
                    </template>
                </vxe-column>

                <!-- 采购单价 -->
                <vxe-column min-width="120" title="采购单价">
                    <template #default="{ row }">
                        <el-input-number v-model="row.money" @change="ProductUpdate" :min="0" :precision="2" :controls="false" :style="{ width: '100%' }"></el-input-number>
                    </template>
                </vxe-column>

                <!-- 折扣/% -->
                <vxe-column min-width="120" title="折扣/%">
                    <template #default="{ row }">
                        <el-input-number v-model="row.discount" @change="ProductUpdate" :precision="2" :controls="false" :style="{ width: '100%' }"></el-input-number>
                    </template>
                </vxe-column>

                <!-- 合计 -->
                <vxe-column min-width="100" field="money_total" title="合计"></vxe-column>

                <!-- 折扣/% -->
                <vxe-column min-width="120" title="整单折扣/%">
                    <template #default="{ row }">
                        <el-input-number v-model="row.discount_document" @change="ProductUpdate" :precision="2" :controls="false" :style="{ width: '100%' }"></el-input-number>
                    </template>
                </vxe-column>

                <!-- 整单折扣后合计 -->
                <vxe-column min-width="120" field="money_discount" title="整单折扣后合计"></vxe-column>

                <!-- 到货周期 -->
                <vxe-column width="220" field="describe" title="到货周期">
                    <template #default="{ row }">
                        <el-input v-model="row.arrive_date" placeholder="请输入内容"></el-input>
                    </template>
                </vxe-column>

                <!-- 备注说明 -->
                <vxe-column width="220" field="describe" title="备注说明">
                    <template #default="{ row }">
                        <el-input v-model="row.describe" placeholder="请输入内容"></el-input>
                    </template>
                </vxe-column>

                <!-- 移除 -->
                <vxe-column width="60" align="center" fixed="right">
                    <template #default="{ row }">
                        <el-link type="danger" :underline="false" @click="ProductDelete(row.id)">移除</el-link>
                    </template>
                </vxe-column>

            </vxe-table>

        </el-form>

        <div slot="footer">
            <el-button size="small" @click="visible = false">取 消</el-button>
            <el-button size="small" type="primary" @click="FormSubmit()" :loading="loading">确 定</el-button>
        </div>

        <!-- 组件 -->
        <ims-product-search ref="productSearch" @onChange="ProductAdd" />

    </el-dialog>
</template>

<script>
export default {
    data () {
        return {
            visible: false,
            loading: false,

            formError: {},
            form: {
                product_order_id: null,
                state: 0,
                product: [],
            }
        }
    },
    
    methods: {

        // 打开窗口
        Open(config) {
            if (config) {
                this.form.product_order_id = config.product_order_id
                this.form.state = config.state
            }
            this.visible = true
            this.loading = false
        },

        // 关闭窗口
        Close() {
            this.form.product_order_id = null
            this.form.state = 0
            this.form.product = []
        },

        // 产品列表 - 添加
        ProductAdd(data) {
            data.forEach(element => {
                if (!this.form.product.find(rps => rps.id == element.id)) {
                    this.$set(element, 'num', 1)
                    this.$set(element, 'discount', 0)
                    this.$set(element, 'money', 0)
                    this.$set(element, 'money_total', 0)
                    this.$set(element, 'discount_document', 0)
                    this.$set(element, 'money_discount', 0)
                    this.$set(element, 'pay_date', null)
                    this.$set(element, 'taxation_rate', 0)
                    this.$set(element, 'arrive_date', null)
                    this.$set(element, 'describe', element.describe)
                    this.form.product.push(element)
                }
            })
        },

        // 产品列表 - 移除
        ProductDelete(id) {
            const index = this.form.product.findIndex(rps => rps.id == id)
            this.form.product.splice(index, 1)
            this.ProductUpdate()
        },

        // 产品列表 - 更新数据
        ProductUpdate() {
            this.form.product.forEach(element => {
                element.money_total = (element.money * element.num * (1 - element.discount * 0.01)).toFixed(2)
                element.money_discount = (element.money_total * (1 - element.discount_document * 0.01)).toFixed(2)
            })
        },

        // 提交表单
        FormSubmit() {
            // this.loading = true
            this.$http.post('/9api/ims/product-order-log/create', this.form).then((rps) => {

                // 提交失败
                if (rps.data.state == 'error') {
                    this.formError = rps.data.body
                }

                // 提交成功
                if (rps.data.state == 'success') {
                    this.$emit('onChange', rps.data.body)
                    this.visible = false
                }

                this.loading = false
            })
        },
    }
}
</script>