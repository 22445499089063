<!---------------------------------------------------------------------------- 
 | 项目管理 - 附加上传
 |----------------------------------------------------------------------------
 | :stage 上传阶段的标识
 |
 ---------------------------------------------------------------------------->

 <template>
    <div class="w-file-upload" v-loading="loading" element-loading-text="正在上传附件,请不要关闭窗口">
        <el-upload ref="file" drag multiple :http-request="HttpRequest" action="#">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
                将文件拖到此处或 <em>单击上载</em><br/>
                支持任意文件的上传，不限制大小
            </div>
        </el-upload>
        <div v-if="files.length > 0">
            <vxe-table :data="files" :show-header="false" stripe class="w-margin-t-10 w-width-auto">
                <vxe-column width="120" field="name">
                    <template #default="{ row }">
                        <el-link icon="el-icon-circle-check" type="success" :underline="false">{{ row.stage }}</el-link>
                    </template>
                </vxe-column>
                <vxe-column min-width="120" field="name">
                    <template #default="{ row }">
                        <el-link icon="el-icon-tickets" type="primary">{{ row.name }}</el-link>
                    </template>
                </vxe-column>
                <vxe-column width="160" field="name">
                    <template #default="{ row }">
                        <el-link icon="el-icon-date" type="info" :underline="false">{{ row.created_at }}</el-link>
                    </template>
                </vxe-column>
                <vxe-column width="120" field="name">
                    <template #default="{ row }">
                        <div style="display: flex; align-items: center; justify-content: flex-start;">
                            <el-avatar style="margin-right: 5px;" :size="26" :src="row.user.avatar"> {{ row.user.name[0] }} </el-avatar>
                            {{  row.user.name }}
                        </div>
                    </template>
                </vxe-column>
                <vxe-column width="120" field="name">
                    <template #default="{ row }">
                        <el-link type="primary" class="w-margin-r-10" @click.stop="$refs.filesPreview.Open(row.id, row.path, 'preview')">预览</el-link>
                        <el-link type="primary" class="w-margin-r-10" @click.stop="$refs.filesPreview.Open(row.id, row.path, 'down')">下载</el-link>
                        <el-link type="danger" @click.stop="FilesDelete(row.id)">移除</el-link>
                    </template>
                </vxe-column>
            </vxe-table>
        </div>

        <files-preview ref="filesPreview" :preview-type="previewType" :preview-direction="previewDirection" :preview-modal="previewModal" :drawer-size="drawerSize" />
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Array,
            default: function () {
                return []
            }
        },
        stage: {
            type: String,
            default: '主动上传'
        },
        drawerSize: {
            type: [String, Number],
            default: '80%'
        },
        previewType: {
            type: String,
            default: 'dialog'
        },
        previewDirection: {
            type: String,
            default: 'rtl'
        },
        previewModal: {
            type: Boolean,
            default: true
        }
    },

    data () {
        return {
            loading: false,
            files: [],
            filesUrl: null
        }
    },

    watch: {
        value: {
            handler () {
                if (JSON.stringify(this.value) != JSON.stringify(this.files.map(item => item.id))) {
                    this.FilesSearchIds()
                }
            },
            immediate: true,
        }
    },

    methods: {

        /**
        |--------------------------------------------------
        | 文件上传
        |--------------------------------------------------
        |
        */

        HttpRequest(param) {
            // 构建表单
            const form = new FormData()
            form.append("file", param.file)
            form.append("stage", this.stage)
            
            // 上传附件
            this.$http.post(
                '/9api/components-files/upload',
                form,
                {
                    onUploadProgress: (progressEvent) => {
                        param.onProgress({percent: parseInt(((progressEvent.loaded / progressEvent.total) * 100) | 0, 10)})
                    }
                }
            ).then(rps => {

                // 移除文件
                param.onError()

                // 上传失败
                if (rps.data.state == 'error') {
                    this.$notify.error({ title: '提示', message: param.file.name + '【' + param.file.type + '】' + '(大小:' + param.file.size + ')' + '上传失败', duration: 3000 })
                }

                // 上传成功
                if (rps.data.state == 'success') {
                    new Promise((resolve) => {
                        this.files.push(rps.data.body)
                        resolve()
                    }).then(rps => {
                        this.$emit('input', this.files.map(item => item.id))
                        this.$emit('onChange')
                    })
                }
            })
        },

        /**
        |--------------------------------------------------
        | 查询文件
        |--------------------------------------------------
        |
        */

        FilesSearchIds() {
            this.$http.post('/9api/components-files/search-ids', {ids: this.value}).then(rps => {
                new Promise((resolve) => {
                    this.files = rps.data
                    resolve()
                }).then(rps => {
                    this.$emit('input', this.files.map(item => item.id))
                })
            })
        },

        /**
        |--------------------------------------------------
        | 移除文件
        |--------------------------------------------------
        |
        */

        FilesDelete(id) {
            this.$http.post('/9api/components-files/delete', {id: id}).then(rps => {
                new Promise((resolve) => {
                    this.files = this.files.filter(item => item.id != id)
                    resolve()
                }).then(rps => {
                    this.$emit('input', this.files.map(item => item.id))
                })
            })
        },
    }
}
</script>