<template>
    <el-drawer :visible.sync="visible" v-loading="loading" size="75%" direction="rtl" append-to-body destroy-on-close :show-close="false" :close="$emit('onChange')">

        <div slot="title">

            <!-- 合同状态 -->
            <div class="w-margin-b-10">
                <span v-if="task.state == 0" >待办任务 - 待接收</span>
                <span v-if="task.state == 1" >待办任务 - 已接收</span>
                <span v-if="task.state == 2" >待办任务 - 已完成</span>
                <span v-if="task.state == 3" >待办任务 - 已驳回</span>
                <span v-if="task.state == 4" >待办任务 - 已验收</span>
                <span v-if="task.state == 4" >待办任务 - 已关闭</span>
            </div>

            <!-- 任务名称 -->
            <div class="w-margin-b-10" style="font-size: 18px; color: #000; font-weight: bold;">{{ task.name }}</div>

            <!-- 功能按钮 -->
            <div style="display: flex;">

                <el-button-group>
                    <el-button size="mini" :disabled="!(task.state != 5 && task.state != 1 && task.state != 2 && task.state != 4)" @click="taskStateReceive()">接收</el-button>
                    <el-button size="mini" :disabled="!(task.state != 5 && task.state == 1)" @click="taskStateComplete()">完成</el-button>
                    <el-button size="mini" :disabled="!(task.state != 5 && task.state == 2)" @click="taskStateReject()">驳回</el-button>
                    <el-button size="mini" :disabled="!(task.state != 5 && task.state == 2)" @click="taskStateAccept()">负责人验收</el-button>
                    <el-button size="mini" :disabled="!(task.state != 5)" @click="taskStateClose()">销售验收</el-button>
                </el-button-group>

                <!-- 其它 -->
                <el-button style="margin-left: 5px;" size="mini" icon="el-icon-refresh" @click="taskSearch()">刷新</el-button>
                <el-button style="margin-left: 5px;" size="mini" icon="el-icon-edit" @click="$refs.taskUpdate.open(task.id)">修改任务</el-button>
                <el-button style="margin-left: 5px;" size="mini" @click="taskDelete()" type="danger">移除任务</el-button>
                
            </div>

            <!-- 金额概览 -->
            <div class="w-margin-t-10" style="background-color: #f4f5f7; border-radius: 3px; padding: 15px; display: flex;">
                <el-statistic style="line-height: 2;" title="消耗工作日"><template slot="formatter"><b>{{ task.consume_days }}/天</b></template></el-statistic>
                <el-statistic style="line-height: 2;" title="负责人"><template slot="formatter"><b>{{ task.user_admin.name }}</b></template></el-statistic>
                <el-statistic style="line-height: 2;" title="指派给"><template slot="formatter"><b>{{ task.user_assign.name }}</b></template></el-statistic>
                <el-statistic style="line-height: 2;" title="协助人"><template slot="formatter"><b>{{ task.user_helper.map(rps => rps.name).join('，') || '无' }}</b></template></el-statistic>
                <el-statistic style="line-height: 2;" title="截止日期"><template slot="formatter"><b>{{ task.datetime[1] }}</b></template></el-statistic>
            </div>

        </div>

        <div class="w-padding-l-20 w-padding-r-20 w-padding-b-20">
            
            <files-upload v-model="task.files_id" model="crm_business_progress" @onChange="taskFilesUpdate" stage="任务创建" />
            
            <!-- 操作日志 -->
            <h4>操作日志</h4>
            <vxe-table :data="task.log" class="w-margin-b-20">
                <vxe-column width="60" type="seq" title="序号"></vxe-column>
                <vxe-column width="120" field="ip" title="IP"></vxe-column>
                <vxe-column width="80" field="users.name" title="用户"></vxe-column>
                <vxe-column width="150" field="created_at" title="时间"></vxe-column>
                <vxe-column width="80" field="event" title="事件">
                    <template #default="{ row }">
                        <el-link :underline="false" type="success" v-if="row.event == 'create'">创建</el-link>
                        <el-link :underline="false" type="primary" v-if="row.event == 'update'">更新</el-link>
                    </template>
                </vxe-column>
                <vxe-column field="remarks" title="事件描述">
                    <template #default="{ row }">
                        {{ row.remarks?.join('，') }}
                    </template>
                </vxe-column>
            </vxe-table>

            <!-- 系统信息 -->
            <el-descriptions title="系统信息" size="mini" border :column="4" class="w-margin-b-20">
                <el-descriptions-item label="创建人">{{ task.created_user?.name }}</el-descriptions-item>
                <el-descriptions-item label="创建时间">{{ task.created_at }}</el-descriptions-item>
                <el-descriptions-item label="更新人">{{ task.updated_user?.name }}</el-descriptions-item>
                <el-descriptions-item label="更新时间">{{ task.updated_at }}</el-descriptions-item>
                <el-descriptions-item label="接收人">{{ task.receive_user?.name }}</el-descriptions-item>
                <el-descriptions-item label="接收时间">{{ task.receive_at }}</el-descriptions-item>
                <el-descriptions-item label="完成人">{{ task.complete_user?.name }}</el-descriptions-item>
                <el-descriptions-item label="完成时间">{{ task.complete_at }}</el-descriptions-item>
                <el-descriptions-item label="驳回人">{{ task.reject_user?.name }}</el-descriptions-item>
                <el-descriptions-item label="驳回时间">{{ task.reject_at }}</el-descriptions-item>
                <el-descriptions-item label="验收人">{{ task.accept_user?.name }}</el-descriptions-item>
                <el-descriptions-item label="验收时间">{{ task.accept_at }}</el-descriptions-item>
                <el-descriptions-item label="关闭人">{{ task.close_user?.name }}</el-descriptions-item>
                <el-descriptions-item label="关闭时间">{{ task.close_at }}</el-descriptions-item>
            </el-descriptions>
        </div>

        <!-- 组件 -->
        <crm-business-task-update ref="taskUpdate" @onChange="taskSearch()" />

    </el-drawer>
</template>

<script>
export default {
    data() {
        return {
            visible: false,
            loading: false,
            tabs: 'details',

            task: {
                id: null,
                datetime: [],
                user_assign: {},
                user_admin: {},
                updated_user: {},
                created_user: {},
                files_id: [],
                log: []
            }
        }
    },

    methods: {

        // 打开窗口
        open(id) {
            this.visible = true
            this.taskSearch(id)
        },

        // 任务- 详情
        taskSearch(id = this.task.id) {
            this.loading = true
            this.$http.post(
                '/9api/crm/business-task/search-first',
                {
                    search: { id: id },
                    append: ['user_assign', 'user_helper', 'user_admin', 'receive_user', 'complete_user', 'reject_user', 'close_user', 'accept_user', 'created_user', 'updated_user', 'log']
                }
            ).then(rps => {
                this.task = rps.data
                this.loading = false
            })
        },

        // 任务 - 附件更新
        taskFilesUpdate() {
            this.$http.post('/9api/crm/business-task/update-files', { id: this.task.id, files_id: this.task.files_id })
        },

        // 任务 - 移除
        taskDelete() {
            this.$http.post('/9api/crm/business-task/delete', {id: this.task.id}).then(rps => {
                if (rps.data.state == 'success' ) {
                    this.visible = false
                }
            })
        },

        // 任务 - 接收
        taskStateReceive() {
            this.$confirm('请问是否确定接收任务', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'success'
            }).then(() => {
                this.loading = true
                this.$http.post('/9api/crm/business-task/update-state', { id: this.task.id, state: 1 }).then(rps => {
                    this.taskSearch()
                })
            })
        },

        // 任务 - 完成
        taskStateComplete() {
            this.$prompt('请确认任务是否已完成！', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputType: 'textarea'
            }).then(({ value }) => {
                this.loading = true
                this.$http.post('/9api/crm/business-task/update-state', { id: this.task.id, state: 2, message: value  }).then(rps => {
                    this.taskSearch()
                })
            })
        },

        // 任务 - 驳回
        taskStateReject() {
            this.$prompt('请输入驳回原因', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputType: 'textarea'
            }).then(({ value }) => {
                this.loading = true
                this.$http.post('/9api/crm/business-task/update-state', { id: this.task.id, state: 3, message: value  }).then(rps => {
                    this.taskSearch()
                })
            })
        },

        // 任务 - 验收
        taskStateAccept() {
            this.$confirm('此任务已完成，是够确认验收', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'success'
            }).then(() => {
                this.loading = true
                this.$http.post('/9api/crm/business-task/update-state', { id: this.task.id, state: 4 }).then(rps => {
                    this.taskSearch()
                })
            })
        },

        // 任务 - 关闭
        taskStateClose() {
            this.$confirm('任务关闭后将无法删除、修改、回退，此任务将归档与系统！是否继续', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.loading = true
                this.$http.post('/9api/crm/business-task/update-state', { id: this.task.id, state: 5 }).then(rps => {
                    this.taskSearch()
                })
            })
        },
    }
}

</script>6
