<template>
    <div :style="{ height: '100%', display: 'flex', flexDirection: 'column' }">

        <!-- 数据表 -->
        <div :style="{ flex: '1' }">
            <vxe-table ref="xTable" :data="table" :loading="loading" height="100%">

                <!-- 序号 -->
                <vxe-column width="60" type="seq" title="序号"></vxe-column>

                <!-- 审批状态 -->
                <vxe-column width="90" title="审批状态" align="center">
                    <template #default="{ row }">
                        <el-tag v-if="row.workflow_state == 0" size="mini" effect="dark" type="info">未审核</el-tag>
                        <el-tag v-if="row.workflow_state == 1" size="mini" effect="dark" type="warning">待审核</el-tag>
                        <el-tag v-if="row.workflow_state == 2" size="mini" effect="dark" >审核中</el-tag>
                        <el-tag v-if="row.workflow_state == 3" size="mini" effect="dark" type="danger">已拒绝</el-tag>
                        <el-tag v-if="row.workflow_state == 4" size="mini" effect="dark" type="success">已通过</el-tag>
                        <el-tag v-if="row.workflow_state == 5" size="mini" effect="dark" type="warning">待撤销</el-tag>
                        <el-tag v-if="row.workflow_state == 6" size="mini" effect="dark" type="warning">撤销中</el-tag>
                        <el-tag v-if="row.workflow_state == 7" size="mini" effect="dark" type="danger">撤销失败</el-tag>
                        <el-tag v-if="row.workflow_state == 8" size="mini" effect="dark" type="danger">已撤销</el-tag>
                    </template>
                </vxe-column>

                <!-- 采购单编号 -->
                <vxe-column min-width="160" title="采购单编号">
                    <template #default="{ row }">
                        <span @click="$refs.orderDrawer.Open(row.id)" class="el-span-primary">{{ row.number }}</span>
                    </template>
                </vxe-column>

                <!-- 基础信息 -->
                <vxe-column min-width="100" field="date" title="采购日期"></vxe-column>
                <vxe-column min-width="100" field="money_estimate" title="采购金额(预)"></vxe-column>
                <vxe-column min-width="100" field="money_actual" title="采购金额(实)"></vxe-column>
                <vxe-column min-width="120" field="describe" title="备注"></vxe-column>

                <!-- 系统信息 -->
                <vxe-column width="140" field="updated_at" title="创建时间"></vxe-column>
                <vxe-column width="80" field="updated_user.name" title="创建人"></vxe-column>
                <vxe-column width="140" field="created_at" title="更新时间"></vxe-column>
                <vxe-column width="80" field="created_user.name" title="更新人"></vxe-column>

            </vxe-table>
        </div>

        <!-- 组件 -->
        <ims-product-order-drawer ref="orderDrawer" @onChange="TableSearch" />
    </div>
</template>

<script>
export default {
    props: {
        contract_id: {
            type: Number,
            default: null
        },
    },

    data() {
        return {
            loading: false,
            table: [],
        }
    },

    watch: {
        id: {
            handler(val) {
                this.TableSearch()
            },
            immediate: true
        }
    },
    
    methods: {
        TableSearch() {
            this.loading = true
            this.$http.post('/9api/ims/product-order/search-all', { search: { contract_id: this.contract_id }, append: ['created_user', 'updated_user'] }).then(rps => {
                this.table = rps.data
                this.loading = false
            })
        },
    }
}
</script>