<template>
    <el-drawer ref="elDrawer" :visible.sync="visible" size="90%" direction="rtl" append-to-body destroy-on-close :show-close="false" :before-close="Close">

        <div slot="title">

            <div class="w-margin-b-10">项目-{{ projects.number }}</div>
            <div class="w-margin-b-10" style="font-size: 18px; color: #000; font-weight: bold;">{{ projects.name }}</div>

             <!-- 功能按钮 -->
             <div style="display: flex;">
                <el-button-group>
                    <el-button size="mini" type="primary" @click="$refs.projectsUpdateStatus.Open(projects.id, 1)" :disabled="!(projects.status == 0 || projects.status == 2)">开始项目</el-button>
                    <el-button size="mini" type="primary" @click="$refs.projectsUpdateStatus.Open(projects.id, 2)" :disabled="projects.status == 2">暂停项目</el-button>
                    <el-button size="mini" type="primary" @click="$refs.projectsUpdateStatus.Open(projects.id, 5)">转待验收</el-button>
                    <el-button size="mini" type="primary" @click="$refs.projectsUpdateStatus.Open(projects.id, 4)">关闭项目</el-button>
                </el-button-group>

                <el-button style="margin-left: 15px;" size="mini" icon="el-icon-edit" @click="$refs.projectsUpdate.Open(projects.id)">编辑与变更</el-button>
                <el-button style="margin-left: 5px;" size="mini" @click="DataDelete()" type="danger">移除项目</el-button>
            </div>

        </div>
        
        <div class="w-padding-l-20 w-padding-r-20 w-padding-b-20">
            <el-tabs v-model="tabs">

                <!-- 项目概况 -->
                <el-tab-pane label="项目概况" name="base">
                    <pm-projects-drawer-base :projects_id="projects.id" v-if="tabs == 'base' && !loading" />
                </el-tab-pane>

                <!-- 描述 -->
                <el-tab-pane label="描述" name="describe">
                    <pm-projects-drawer-describe :projects_id="projects.id" v-if="tabs == 'describe' && !loading" />
                </el-tab-pane>

                <!-- 任务管理 -->
                <el-tab-pane label="任务管理" name="task">
                    <pm-projects-drawer-task :id="projects.id" :height="tabsBodyHeight" v-if="tabs == 'task' && !loading" />
                </el-tab-pane>

                <!-- 工单报障 -->
                <el-tab-pane label="工单报障" name="wo">
                    <pm-projects-drawer-wo :projects_id="projects.id" :height="tabsBodyHeight" v-if="tabs == 'wo' && !loading" />
                </el-tab-pane>

                <!-- 团队管理 -->
                <el-tab-pane label="团队管理" name="team">
                    <pm-projects-drawer-team :id="projects.id" v-if="tabs == 'team' && !loading" />
                </el-tab-pane>

            </el-tabs>
        </div>

        <!-- 组件 -->
        <pm-projects-update ref='projectsUpdate' @success="RePage()" />
        <pm-projects-update-status ref="projectsUpdateStatus" @success="RePage()" />

    </el-drawer>
</template>

<script>

export default {

    // 存储数据
    data() {
        return {
            visible: false,
            loading: false,

            tabs: 'base',

            projects: {
                id: null,
                name: null,
                number: null
            },
        }
    },

    // 计算属性
    computed: {
        tabsBodyHeight() {
            return this.$refs.elDrawer.$el.querySelector('.el-drawer__body').offsetHeight - 75
        },
    },

    // 方法定义
    methods: {

        // 代开窗口
        Open(id) {
            this.visible = true
            this.DataGet(id)
        },

        // 关闭窗口
        Close(done) {
            this.$emit('onChange')
            done()
        },

        // 查询数据
        DataGet(id = projects.id) {
            this.loading = true

            this.$http.post('/9api/projects/search-first', { id: id, hidden: ['describe'] }).then(rps => {
                this.projects = rps.data
                this.loading = false
            })
        },

        // 移除数据
        DataDelete() {
            this.$confirm('此操作将永久删除该项目信息，以及关联的文档、工时、工单、任务等一系列数据，请再三考虑！', '警告', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'error'
            }).then(() => {
                this.$http.post('/9api/pm/projects/delete', { id: this.projects.id }).then(rps => {
                    this.$emit('onChange')
                    this.visible = false
                })
            })
        },

        
    }
}

</script>

