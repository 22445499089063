<!---------------------------------------------------------------------------- 
 | 工作流
 | 如果需要完善组件，请仔深入了解前端及后端代码，非全栈工程师不建议修改。
 |
 ---------------------------------------------------------------------------->

 <template>
    <div>
        <el-input size="mini" disabled :value="value ? '已配置-审批流程' : '未配置-审批流程'" :class="[value ? 'is-success' : '']"> 
            <el-button slot="prepend" icon="el-icon-edit" @click="DialogOpen()">配置流程</el-button>
        </el-input>

        <el-dialog class="w-el-dialog" :visible.sync="dialogVisible" fullscreen append-to-body>

            <!-- title -->
            <div slot="title">
                <i class="el-icon-guide"></i> 流程管理
            </div>

            <!-- 流程 -->
            <div class="wf">

                <!-- 流程节点 -->
                <workflow-cmpt-node :option.sync="workflow.value" :work="workflow.model" :showStart="true" />

                <!-- 流程结束 -->
                <div class="wf-end">
                    <div class="wf-end-box">
                        <div class="wf-end-circle"></div>
                        <div class="wf-end-text">流程结束</div>
                    </div>
                </div>
            </div>

            <!-- footer -->
            <div slot="footer">
                <el-button size="medium" @click="dialogVisible = false">取 消</el-button>
                <el-button size="medium" @click="workflow.value = null">清空流程</el-button>
                <el-button size="medium" type="primary" @click="FlowUse()">应用流程</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import './cmpt.less'

export default {
    props: {
        value: {
            type: Object,
            default: null
        },
        model: {
            type: String,
            default: null
        },
        init: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            dialogVisible: false,

            workflow: {
                value: null,
                model: null
            },
        }
    },

    mounted() {
        // 配置默认流程
        if (this.init) {
            this.$http.post('/9api/workflow/template', { model: this.model } ).then(rps => {
                this.$emit('input', rps.data.template)
            })
        }
    },

    methods: {

        /**
        |--------------------------------------------------
        | 窗口 -打开
        |--------------------------------------------------
        |
        */

        DialogOpen () {
            this.workflow.value = {...this.value}

            // 设置流程模块
            this.workflow.model = this.model

            // 初始化流程
            if (this.value)
            {
                // 直接引用流程
                this.workflow.value = {...this.value}
                this.dialogVisible = true

            }
            else {
                // 配置默认流程
                this.$http.post('/9api/workflow/template', { model: this.model } ).then(rps => {
                    this.workflow.value = rps.data.template
                    this.dialogVisible = true
                })
            }
        },


        /**
        |--------------------------------------------------
        | 流程 - 应用
        |--------------------------------------------------
        |
        */

        FlowUse() {
            this.$emit('input', this.workflow.value)
            this.$emit('onChang', this.workflow.value)
            this.dialogVisible = false
        },
    }
}
</script>

