<!---------------------------------------------------------------------------- 
 | 项目管理 - 创建任务
 |----------------------------------------------------------------------------
 | 方法：请注意大小写,参数描述见具体方法
 | $refs[ref_name].Open(*project_id, parent) 手动打开窗口,
 | $refs[ref_name].Close() 手动关闭窗口
 |----------------------------------------------------------------------------
 | 回调
 | @onSave = fn (Object) => {} 成功后返任务信息
 |
 ---------------------------------------------------------------------------->

<template>
    <el-drawer class="w-el-drawer" :visible.sync="visible" :size="960" :with-header="false" direction="rtl" :before-close="Close" append-to-body destroy-on-close>

        <!-- header -->
        <div class="w-el-drawer-header">
            <i class="el-icon-tickets"></i> 新的任务
        </div>

        <!-- body -->
        <div class="w-el-drawer-body">

            <el-form :model="form" label-width="120px" size="mini">
                <el-form-item label="任务名称" :rules="[{ required: true, message: '请填写任务名称, 最多填入64个字符' }]" :error="formError.name?.toString()" prop="name">
                    <el-input v-model="form.name" style="float:left;" >
                        <el-color-picker slot="prepend" v-model="form.name_color" show-alpha :predefine="color"></el-color-picker>
                    </el-input>
                </el-form-item>

                <!-- 任务类型 -->
                <el-form-item label="任务类型" :rules="[{ required: true, message: '请选择任务类型' }]" :error="formError.type_id?.toString()" prop="type_id">
                    <projects-task-type-select v-model="form.type_id" width="350px" @onChange="(rps) => form.working_days = rps.working_days" />
                </el-form-item>

                <!-- 重要程度/优先级 -->
                <el-form-item label="重要程度 / 优先级" :rules="[{ required: true, message: '请选择任务的重要程度或优先级' }]" :error="formError.p?.toString()" prop="p">
                    <projects-task-select-p v-model="form.p" clearable filterable width="350px" />
                </el-form-item>

                <!-- 指派给 -->
                <el-form-item label="指派给" :rules="[{ required: true, message: '请选择任务指派人' }]" :error="formError.now_assign?.toString()" prop="now_assign">
                    <inputTeam v-model="form.now_assign" :project_id="form.project_id" clearable filterable width="350px"></inputTeam>
                </el-form-item>

                <!-- 参与成员 / 协助成员 -->
                <el-form-item label="参与成员 / 协助成员">
                    <inputTeam v-model="form.assign" :project_id="form.project_id" clearable filterable multiple width="350px"></inputTeam>
                </el-form-item>
                
                <!-- 起止日期 -->
                <el-form-item label="起止日期" :rules="[{ required: true, message: '请选择任务起止日期' }]" :error="formError.start_date?.toString()" prop="start_date">
                    <el-date-picker v-model="form.start_date" type="daterange" value-format="yyyy-MM-dd" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
                    <el-link type="info" :underline="false" class="w-margin-l-10">共计 {{ form.start_date_count }} P</el-link>
                </el-form-item>

                <!-- 预估工时 -->
                <el-form-item label="预估工时 / P" :rules="[{ required: true, message: '请填写工时信息，且需要大于0' }]" :error="formError.working_days?.toString()" prop="working_days">
                    <el-input-number v-model="form.working_days" :min="0" label="预估工时"></el-input-number>
                </el-form-item>

                <!-- 任务描述 -->
                <el-form-item label="任务描述">
                    <froala-editor v-model="form.describe" />
                </el-form-item>

                <el-form-item label="附件">
                    <files-upload v-model="form.files_id" model="projects_task" stage="任务创建" />
                </el-form-item>
            </el-form>
        </div>

        <!-- footer -->
        <div class="w-el-drawer-footer">
            <el-button size="medium" @click="Close()">关 闭</el-button>
            <el-button size="medium" type="waring" @click="ReForm()" :disabled="loading" v-if="form.id">重置表单</el-button>
            <el-button size="medium" type="primary" @click="SubmitForm()" :disabled="loading" v-if="!form.id">确定</el-button>
        </div>
    </el-drawer>
</template>

<script>
import inputTeam from '@/views/projects/inputProjectsTeam'

export default {
    components: {
        inputTeam,
    },

    data () {
        return {
            // 颜色选择器
            color: [
                '#f44336', '#e91e63', '#9c27b0', '#673ab7', '#3f51b5', 
                '#2196f3', '#03a9f4', '#00bcd4', '#009688', '#4caf50', 
                '#8bc34a', '#cddc39', '#ffeb3b', '#ffc107', '#ff9800', 
                '#ff5722', '#795548', '#9e9e9e', '#607d8b', '#000000'
            ],

            // 加载状态
            visible: false,
            loading: false,

            // 表单
            formError: {},
            form: {
                id: null,
                project_id: null,
                parent: null,
                name: null,
                name_color: '#5489EA',
                p: 2,
                type_id: null,
                assign: [],
                now_assign: null,
                start_date: [],
                start_date_count: 0,
                working_days: 0,
                describe: null,
                files_id: []
            }
        }
    },

    watch: {
        // 计算可用天数
        'form.start_date': function (newValue, oldValue) {
            const start_date = new Date(newValue[0])
            const end_date = new Date(newValue[1])
            this.form.start_date_count = ((end_date.getTime() - start_date.getTime()) / 86400 / 1000 ) + 1
        }
    },

    methods: {

        /**
        |--------------------------------------------------
        | 窗口 - 打开
        |--------------------------------------------------
        |
        */

        Open (project_id, parent) {
            this.visible = true
            this.form.project_id = project_id
            this.form.parent = parent ? parent : null
        },
        

        /**
        |--------------------------------------------------
        | 窗口 - 关闭
        |--------------------------------------------------
        |
        */

        Close() {
            this.ReForm()
            this.loading = false
            this.visible = false
        },
        

        /**
        |--------------------------------------------------
        | 创建任务
        |--------------------------------------------------
        |
        */

        SubmitForm () {
            this.loading = true
            this.$http.post('/9api/projects-task/create', this.form).then(rps => {

                // 提交失败
                if (rps.data.state == 'error') {
                    this.formError = rps.data.body
                }

                // 提交成功
                if (rps.data.state == 'success') {
                    this.form.id = rps.data.body.id
                    this.$emit('onSave', rps.data.body)
                }

                this.loading = false
            })
        },

        /**
        |--------------------------------------------------
        | 重置表单
        |--------------------------------------------------
        |
        */

        ReForm() {
            this.formError = {}
            this.form.id = null
            this.form.project_id = null
            this.form.parent = null
            this.form.name = null
            this.form.name_color = '#5489EA'
            this.form.p = 2
            this.form.type_id = null
            this.form.assign = []
            this.form.now_assign = null
            this.form.start_date = []
            this.form.start_date_count = 0
            this.form.working_days = 0
            this.form.describe = null
            this.form.files_id = []
        }
    }
}
</script>