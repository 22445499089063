<!---------------------------------------------------------------------------- 
 | 创建合同
 |----------------------------------------------------------------------------
 | 方法：请注意大小写,参数描述见具体方法
 | $refs[ref_name].Open() 手动打开窗口,
 | $refs[ref_name].Close() 手动关闭窗口
 |----------------------------------------------------------------------------
 | 回调
 | @onChange = fn (Object) => {} 成功后返回合同信息
 |
 ---------------------------------------------------------------------------->

 <template>
    <el-drawer class="w-el-drawer" :visible.sync="visible" :size="840" :with-header="false" direction="rtl" append-to-body>
        
        <!-- header -->
        <div class="w-el-drawer-header">
            <i class="el-icon-tickets"></i> {{ news.name ?? '加载中......' }}
        </div>

        <!-- body -->
        <div class="w-el-drawer-body"  v-loading="loading">

            <!-- 公告内容 -->
            <el-alert type="info" :closable="false">
                <div slot="title">
                    <el-link :underline="false" icon="el-icon-user">发布人：{{ news.users_admin.name }}</el-link>
                    <el-link :underline="false" icon="el-icon-date" class="w-margin-l-20">发布时间：{{ news.updated_at }}</el-link>
                </div>
                <div style="white-space: break-spaces;">{{ news.overview }}</div>
            </el-alert>

            
            <froala-views v-model="news.describe" />
            <el-empty v-if="!news.describe" description="描述文字"></el-empty>

            <!-- 附件信息 -->
            <template v-if="news.files_id.length > 0">
                <el-divider content-position="left">附件</el-divider>
                <files-table v-model="news.files_id" />
            </template>

            <!-- 已读用户 -->
            <template>
                <el-divider content-position="left">阅读记录</el-divider>
                <el-link :underline="false" type="primary">已阅读：{{ news.users_read.length }}</el-link>
                <el-link :underline="false" type="info" class="w-margin-l-10">未阅读：{{ news.users_read_no.length }}</el-link>
                <div class="w-margin-t-10">
                    <div style="float: left; display: flex; align-items: center; flex-direction: column; justify-content: flex-start; margin: 0 5px 5px 0;" v-for="(item, idx) in news.users_read" :key="'y'+idx">
                        <el-avatar :size="26" :src="item.avatar" class="w-margin-b-5">
                            {{ item.name[0] }}
                        </el-avatar>
                        <el-link type="primary" :underline="false">{{  item.name }}</el-link>
                    </div>
                    <div style="float: left; display: flex; align-items: center; flex-direction: column; justify-content: flex-start; margin: 0 5px 5px 0;" v-for="(item, idx) in news.users_read_no" :key="'n'+idx">
                        <el-avatar :size="26" :src="item.avatar" class="w-margin-b-5">
                            {{ item.name[0] }}
                        </el-avatar>
                        <el-link type="info" :underline="false">{{  item.name }}</el-link>
                    </div>
                </div>
            </template>
        </div>

        <!-- footer -->
        <div class="w-el-drawer-footer">
            <el-button size="medium" @click="visible = false">关 闭</el-button>
        </div>
    </el-drawer>
</template>

<script>
export default {
    data () {
        return {
            visible: false,
            loading: false,

            news: {
                files_id: [],
                users_admin: {},
                users_read: [],
                users_read_no: []
            }
        }
    },

    methods: {

        /**
        |--------------------------------------------------
        | 窗口 - 打开
        |--------------------------------------------------
        |
        */

        Open(id) {
            // 窗口配置
            this.visible = true
            this.loading = true

            this.$http.post('/9api/news/first', { id: id, append: ['users_admin', 'users_read', 'users_read_no'] }).then(rps => {
                this.news = rps.data

                this.loading = false
            })
        },
    }
}
</script>