<!---------------------------------------------------------------------------- 
 | 合同状态
 |----------------------------------------------------------------------------
 | 方法：请注意大小写,参数描述见具体方法
 | *v-model         合同状态----默认为 null
 | *contract_id     合同ID------默认为 null
 |----------------------------------------------------------------------------
 | 参数说明
 | update => Boolean => 是否可以修改
 |----------------------------------------------------------------------------
 |
 ---------------------------------------------------------------------------->

<template>
    <el-dropdown trigger="click" @command="OnChange" :disabled="!update">
        <el-link type="primary" :underline="update">
            {{ value }}
            <i v-if="update" class="el-icon-edit el-icon--right"></i>
        </el-link>
        <el-dropdown-menu slot="dropdown">
            <el-dropdown-item icon="el-icon-guide" command="待审核">待审核</el-dropdown-item>
            <el-dropdown-item icon="el-icon-guide" command="已寄出">已寄出</el-dropdown-item>
            <el-dropdown-item icon="el-icon-guide" command="已收回">已收回</el-dropdown-item>
            <el-dropdown-item icon="el-icon-guide" command="待打印签章寄出">待打印签章寄出</el-dropdown-item>
            <el-dropdown-item icon="el-icon-guide" command="未收回">未收回</el-dropdown-item>
            <el-dropdown-item icon="el-icon-guide" command="结束">结束</el-dropdown-item>
        </el-dropdown-menu>
    </el-dropdown>
</template>

<script>
export default {
    props: {
        value: {
            type: String,
            default: null
        },

        contract_id: {
            type: Number,
            default: null
        },

        update: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        OnChange(val) {
            if (this.contract_id && this.update) {
                this.$http.post('/9api/contract/update-state', {id: this.contract_id, state: val}).then(rps => {
                    this.$emit('input', val)
                })
            }
        },
    }
}
</script>