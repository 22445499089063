<template>
    <div v-loading="loading">
        <!-- 基本信息 -->
        <el-descriptions title="基本信息" border direction="vertical" :column="1" class="w-margin-b-20">
            <el-descriptions-item label="考核周期">{{ plan.date.join(' 至 ') }}</el-descriptions-item>
            <el-descriptions-item label="考核模板"><span class="el-span-primary" @click="$refs.templateDrawer.Open(plan.template)">{{ plan.template.name }}</span></el-descriptions-item>
            <el-descriptions-item label="考核描述">{{ plan.describe }}</el-descriptions-item>
            <el-descriptions-item label="考核范围">{{ plan.users.map(rps => rps.name).join(', ') }}</el-descriptions-item>
        </el-descriptions>

        <!-- 系统信息 -->
        <el-descriptions title="系统信息" border direction="vertical" :column="2">
            <el-descriptions-item label="创建时间">{{ plan.created_at }}</el-descriptions-item>
            <el-descriptions-item label="创建人">{{ plan.created_users.name }}</el-descriptions-item>
            <el-descriptions-item label="更新时间">{{ plan.updated_at }}</el-descriptions-item>
            <el-descriptions-item label="更新人">{{ plan.updated_users.name }}</el-descriptions-item>
        </el-descriptions>

        <!-- 组件 -->
        <hrm-kpi-template-drawer ref="templateDrawer" @onChange="TableSearch()" />
    </div>
</template>

<script>
export default {
    props: {
        id: {
            type: Number,
            default: null
        },
    },

    data() {
        return {
            plan: {
                date: [],
                users_id: [],
                users: [],
                template: {},
                created_users: {},
                updated_users: {},
            },
        }
    },

    computed: {
        loading () {
            return !!this.$store.state.httpRequestCount
        }
    },

    watch: {
        id: {
            handler(val) {
                this.TableSearch()
            },
            immediate: true
        }
    },
    
    methods: {

        // 订单记录
        TableSearch() {
            this.$http.post(
                '/9api/hrm/kpi-plan/search-first',
                {
                    search: { id: this.id },
                    append: ['created_users', 'updated_users', 'users']
                }
            ).then(rps => {
                this.plan = rps.data
            }) 
        },
    }
}
</script>