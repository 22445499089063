<!---------------------------------------------------------------------------- 
 | 工作流节点
 |----------------------------------------------------------------------------
 | 如果需要完善组件，请仔深入了解前端及后端代码，非全栈工程师不建议修改。
 |----------------------------------------------------------------------------
 | props 
 | *:showStart (Boolean) , 是否初始化开始节点，默认为flase, 实例化必须为True,
 | :option.sync (Object) 节点配置信息
 | :work (string) contract=合同
 |
 ---------------------------------------------------------------------------->

<template>
    <div>
        
        <!-- 流程开始 -->
        <div v-if="showStart" class="wf-start">
            <div class="wf-start-box">
                <div class="wf-start-text">流程开始</div>
                <div class="wf-start-circle"></div>
            </div>
            <div class="wf-line-box">
                <button @click="AddSignFather(option)"><i class="el-icon-coordinate"></i></button>
                <button @click="AddBranchFather(option)"><i class="el-icon-connection"></i></button>
            </div>
        </div>

        <!-- 审批节点 -->
        <div class="wf-sign" v-if="option?.type == 'sign'">
            <div :class="['wf-sign-box', option.multiple ? 'multiple' : '' ]" >
                <div class="wf-sign-header">
                    <div class="wf-sign-header-title">
                        <i class="el-icon-sort" @click="MultipleSwitch()">
                            {{ option.multiple ? '会签' : ' 或签' }} - 审核人
                        </i>
                    </div>
                    <i class="el-icon-close" @click="DelSign(option)"></i>
                </div>
                <div class="uw-workflow-users">
                    <div class="uw-workflow-users-box">
                        <div style="font-size: 12px; padding-bottom: 5px;">审批人</div>
                        <users-select multiple type="avatar" v-model="option.users" />
                    </div>
                    <div class="uw-workflow-users-box">
                        <div style="font-size: 12px; padding-bottom: 5px;">抄送人</div>
                        <users-select multiple type="avatar" v-model="option.cc" />
                    </div>
                </div>
            </div>

            <div class="wf-line-box">
                <button @click="AddSignChildren(option)"><i class="el-icon-coordinate" ></i></button>
                <button @click="AddBranchChildren(option)"><i class="el-icon-connection" ></i></button>
            </div>
        </div>
        
        <!-- 条件节点 -->
        <div class="wf-branch" v-if="option?.type == 'branch'">
            <div class="wf-branch-box-wrap">
                <div class="wf-branch-box">
                    <div class="wf-branch-create" @click="AddBranchBrother(option)">添加条件</div>
                    <div class="wf-col-box" v-for="(item, idx) in option.branch" :key="idx">
                        <div class="condition-node">
                            <div class="condition-node-box">
                                <div class="auto-judge" @click="$refs.cmptRule.Open(item.rule)">
                                    <div class="wf-branch-item-box-header">
                                        <el-dropdown trigger="click">
                                            <el-link type="info" icon="el-icon-edit">优先级{{ idx + 1 }}</el-link>
                                            <el-dropdown-menu slot="dropdown">
                                                <el-dropdown-item v-for="val in option.branch.length" :key="val" @click.native.stop="SortBranch(idx, val - 1)">优先级{{ val }}</el-dropdown-item>
                                            </el-dropdown-menu>
                                        </el-dropdown>
                                        <div class="header-title">条件</div>
                                        <i class="header-close el-icon-close" @click="DelBranch(idx)"></i>
                                    </div>
                                    <div class="wf-branch-item-box-text">
                                        <div v-for="(r, u) in item.rule" :key="u">
                                            <el-link :underline="false" type="primary">
                                                {{ r.column.label + ' ' + r.where.label + ' ' + r.value }}
                                            </el-link>
                                        </div>
                                        <el-link :underline="false" type="info" v-if="item.rule.length == 0">未配置</el-link>
                                    </div>
                                </div>
                                <div class="wf-line-box">
                                    <button @click="AddSignChildren(item)"><i class="el-icon-coordinate" ></i></button>
                                    <button @click="AddBranchChildren(item)"><i class="el-icon-connection" ></i></button>
                                </div>
                            </div>
                        </div>
                        <node v-if="item.children" :option.sync="item.children"></node>

                        <div class="top-left-cover-line" v-if="idx == 0"></div>
                        <div class="bottom-left-cover-line" v-if="idx == 0"></div>
                        <div class="top-right-cover-line" v-if="idx == option.branch.length - 1"></div>
                        <div class="bottom-right-cover-line" v-if="idx == option.branch.length - 1"></div>
                    </div>
                </div>

                <div class="wf-line-box">
                    <div class="wf-node-line-box">
                        <div class="wf-node-line">
                            <button @click="AddSignChildren(option)"><i class="el-icon-coordinate"></i></button>
                            <button @click="AddBranchChildren(option)"><i class="el-icon-connection"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 审批节点 - 递归 -->
        <node v-if="option?.children" :option.sync="option.children"></node>

        <!-- 组件 -->
        <workflow-cmpt-rule ref="cmptRule" :work="work" />
    </div>
</template>

<script>
export default {
    name: 'node',

    props: {
        showStart: {
            type: Boolean,
            default: false
        },
        option: {
            type: Object,
            default: null
        },
        work: {
            type: String,
            default: 'contract'
        }
    },

    data () {
        return {
            users: [],
            cc: []
        }
    },

    methods: {

        /**
        |--------------------------------------------------
        |审批节点 - 添加父节点
        |--------------------------------------------------
        |
        */

        AddSignFather(node) {
            this.$emit('update:option', {
                type: 'sign',
                multiple: false,
                users: [],
                users_verify: [],
                cc: [],
                state: null,
                children: node
            })
        },


        /**
         |--------------------------------------------------
         |审批节点 - 添加子节点
         |--------------------------------------------------
         |
         */

        AddSignChildren(node) {
            node.children = {
                type: 'sign',
                multiple: false,
                users: [],
                users_verify: [],
                cc: [],
                state: null,
                children: node.children
            }
        },


        /**
         |--------------------------------------------------
         |审批节点 - 添删除节点
         |--------------------------------------------------
         |
         */
        DelSign(node) {
            this.$emit('update:option', node.children)
        },


        /**
         |--------------------------------------------------
         |条件节点 - 添加父节点
         |--------------------------------------------------
         |
         */

        AddBranchFather(node) {
            this.$emit('update:option', {
                type: 'branch',
                children: null,
                state: null,
                branch: [
                    {
                        children: node,
                        rule: []
                    },
                    {
                        children: null,
                        rule: []
                    }
                ]
            })
        },


        /**
         |--------------------------------------------------
         |条件节点 - 添加兄弟节点
         |--------------------------------------------------
         |
         */

        AddBranchBrother(node) {
            node.branch.push({
                children: null,
                rule: []
            })
        },


        /**
         |--------------------------------------------------
         |条件节点 - 添加子节点
         |--------------------------------------------------
         |
         */

        AddBranchChildren(node) {
            node.children = {
                type: 'branch',
                children: null,
                state: null,
                branch: [
                    {
                        children: node.children,
                        rule: []
                    },
                    {
                        children: null,
                        rule: []
                    }
                ]
            }
        },


        /**
         |--------------------------------------------------
         |条件节点 - 移除节点
         |--------------------------------------------------
         |
         */

        DelBranch(idx) {
            if (this.option.branch.length > 2) {
                this.option.branch.splice(idx, 1);
            } else {
                this.$emit('update:option', this.option.children)
            }
        },


        /**
         |--------------------------------------------------
         |条件节点 - 优先级配置
         |--------------------------------------------------
         |
         */

        SortBranch(idx1, idx2) {
            let arr = this.option.branch[idx2]
            this.$set(this.option.branch, idx2, this.option.branch[idx1])
            this.$set(this.option.branch, idx1, arr)
        },


        /**
         |--------------------------------------------------
         |审批方式切换
         |--------------------------------------------------
         |multiple | true = 会签， false = 或签 
         |--------------------------------------------------
         |
         */

        MultipleSwitch() {
            this.option.multiple = !this.option.multiple
        },

    }
}
</script>

<style lang="less" scoped>
    .uw-workflow-users {
        padding: 10px;
        display: flex;
        justify-content: flex-start;
        background-color: #FFFFFF;
        
        .uw-workflow-users-box {
            border-radius: 5px;
            border: 1px dashed #e0e0e0;
            padding: 5px 0 5px 5px;

            &:hover {
                border: 1px dashed #3296fa;
            }
        }

        .uw-workflow-users-box:first-child {
            margin-right: 10px;
        }
    }
</style>