<template>
    <el-dialog :visible.sync="visible" class="w-el-dialog" width="600px" append-to-body title="修改任务类型" :close-on-click-modal="false" :close-on-press-escape="false">

        <el-form :model="form" label-width="120px" size="mini">

            <el-form-item label="父目录">
                <projects-task-type-select v-model="form.parent" />
            </el-form-item>

            <!-- 任务类型名称 -->
            <el-form-item label="任务类型名称"  :rules="[{ required: true, message: '必填项' }]" :error="formError.name?.toString()" prop="name">
                <el-input v-model="form.name" clearable placeholder="请填写任务类型名称"></el-input>
            </el-form-item>

            <el-form-item label="参考工时/P">
                <el-input-number v-model="form.working_days" :min="0" :precision="2"></el-input-number>
            </el-form-item>

        </el-form>

        <div slot="footer">
            <el-button size="small" @click="visible = false">取 消</el-button>
            <el-button size="small" type="primary" @click="FormSubmit()" :loading="loading">确 定</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    data () {
        return {
            visible: false,
            loading: false,

            formError: {},
            form: {
                id: null,
                name: null,
                parent: null,
                working_days: 0
            }

        }
    },

    methods: {

        /**
        |--------------------------------------------------
        | 打开窗口
        |--------------------------------------------------
        |
        */

        Open(id) {
            this.visible = true
            this.loading = true

            this.$http.post('/9api/projects-task-type/search-first', { search: { id: id } }).then((rps) => {
                this.form.id = rps.data.id
                this.form.name = rps.data.name
                this.form.parent = rps.data.parent
                this.form.working_days = rps.data.working_days
                this.loading = false
            })
        },

        /**
        |--------------------------------------------------
        | 提交表单
        |--------------------------------------------------
        |
        */

        FormSubmit() {

            this.loading = true

            this.$http.post('/9api/projects-task-type/update', this.form).then((rps) => {

                // 提交失败
                if (rps.data.state == 'error') {
                    this.formError = rps.data.body
                }

                // 提交成功
                if (rps.data.state == 'success') {
                    this.$emit('onChange', rps.data.body)
                    this.visible = false
                }

                this.loading = false
            })
        },
    }
}
</script>