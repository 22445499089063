<!---------------------------------------------------------------------------- 
 | 商机 - 工作流程
 |----------------------------------------------------------------------------
 | 方法：请注意大小写,参数描述见具体方法
 | :id 商机ID
 | :progress 商机工作流程
 | :endShow 结束状态
 | :edit 是否可以编辑
 |
 ---------------------------------------------------------------------------->

<template>
    <div>
        <div  class="progress">

            <!-- 进度 -->
            <div class="progress-work">
                <div class="progress-work-sub">

                    <!-- 流程 -->
                    <div v-for="(v, i) in progress" :key="i" :class="['progress-work-sub-item', (v.state || v.active || tabs == i) ? 'is-active' : null]" @click="tabs = i; workRuleShow = true" >
                        <div class="progress-work-sub-item-name">{{ v.title }}</div>
                        <div class="progress-work-sub-item-value" v-if="v.active">{{ v.value }} / 事项</div>

                        <!-- 箭头 -->
                        <div class="progress-work-sub-item-arrow-left" v-if="i != 0"></div>
                        <div class="progress-work-sub-item-arrow-right"></div>
                    </div>

                    <!-- 结束 -->
                    <div :class="['progress-work-sub-item', endShow ? 'is-active' : null]">
                        <div class="progress-work-sub-item-name">合同签订</div>
                        <div class="progress-work-sub-item-value"></div>
                        <div class="progress-work-sub-item-arrow-left"></div>
                    </div>
                </div>

                <!-- 流程推进功能 -->
                <div :style="{ width: '100px', textAlign: 'right' }">
                    <el-link :underline="false" :style="{ fontSize: '16px' }" icon="el-icon-full-screen" @click="workRuleShow = !workRuleShow">{{ workRuleShow ? '收起' : '展开' }}</el-link>
                </div>
            </div>

            <div v-if="workRuleShow">

                <!-- 关键任务 -->
                <div v-for="(v, i) in progress" :key="i">
                    <div v-if="tabs == i">
                        <div :style="{ margin: '5px 0', color: '#9e9e9e' }">请管理员勾选需要协助的工作事项！</div>
                        <div v-for="(m, n) in v.rule" :key="n">
                            <el-checkbox v-model="m.state" :disabled="v.state || !edit" @change="(state) => progressRuleChange(state, m, v)">{{ m.name }}</el-checkbox>
                            <span v-if="m.state" :style="{ fontSize: '12px', marginLeft: '5px', color: '#0027ff' }">由 {{ m.user?.name }} 于 {{ m.datetime }} 确认</span>
                        </div>
                    </div>
                </div>

                <!-- 配置面板 -->
                <div v-if="!endShow && edit" :style="{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '10px', paddingTop: '10px', borderTop: '1px solid #eeeeee' }">
                    <div>
                        <el-input v-model="ruleForm" v-if="this.progress[this.tabs]?.active" size="mini" clearable placeholder="补充选项" prefix-icon="el-icon-chat-dot-round">
                            <el-button slot="append" icon="el-icon-check" @click="progressAdd()">补充</el-button>
                        </el-input>
                    </div>
                    <div v-if="!endShow">
                        <el-button size="mini" @click="progressSave()">保存</el-button>
                        <el-button size="mini" @click="progressGoto()" type="primary">推进到当前阶段</el-button>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    props: ['id', 'progress', 'endShow', 'edit'],

    data () {
        return {
            tabs: 0,
            workRuleShow: false,
            ruleForm: null
        }
    },

    watch: {
        progress: {
            handler() {
                this.tabs = this.progress.findIndex((rps) => rps.active)
            },
            immediate: true
        }
    },

    methods: {

        // 事项 - 变更
        progressRuleChange(state, data, root) {
            data.user_id = this.$store.state.user.id
            data.user = { name: this.$store.state.user.name }
            data.datetime = this.$lodash.date_time()
            root.value = root.rule.filter((v) => v.state).length
        },

        // 进展 - 补充
        progressAdd() {
            if (this.ruleForm) {
                this.progress[this.tabs].rule.push({ name: this.ruleForm, state: false, user_id: null, user: null, datetime: null })
                this.ruleForm = null
                this.progressSave()
            }
        },

        // 进展 - 推进
        progressGoto() {
            for (let i = 0; i < this.progress.length; i++) {
                if (i < this.tabs) {
                    this.progress[i].state = true
                    this.progress[i].active = false
                }
                if (i > this.tabs) {
                    this.progress[i].state = false
                    this.progress[i].active = false
                }
            }

            this.progress[this.tabs].state = false
            this.progress[this.tabs].active = true
            this.progressSave()
        },

        // 进展 - 保存
        progressSave() {
            this.$http.post('/9api/crm/business/update-progress', { id: this.id, progress: this.progress })
            this.$notify({ title: '提示', message: '保存成功', type: 'success' });
        }

    }
}
</script>

<style lang="less" scoped>
.progress {
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    padding: 10px;

    .progress-work {
        display: flex;
        justify-content: space-between;

        .progress-work-sub {
            .progress-work-sub-item {
                cursor: pointer;
                height: 26px;
                line-height: 26px;
                box-sizing: border-box;
                float: left;
                margin: 5px 6px 5px 0;
                position: relative;
                display: flex;
                font-size: 14px;
                padding: 0 28px;
                background-color: #eeeeee;

                .progress-work-sub-item-name {
                    padding-right: 15px;
                }

                .progress-work-sub-item-arrow-left {
                    left: -13px;
                    position: absolute;
                    height: 26px;
                    width: 26px;
                    background-color: #FFFFFF;
                    -webkit-transform: scale(0.7) rotate(45deg);
                    transform: scale(0.7) rotate(45deg);
                }

                .progress-work-sub-item-arrow-right {
                    right: -13px;
                    position: absolute;
                    height: 26px;
                    width: 26px;
                    background-color: #eeeeee;
                    -webkit-transform: scale(0.7) rotate(45deg);
                    transform: scale(0.7) rotate(45deg);
                    z-index: 1;
                }
                

                &.is-active {
                    background-color: #0027ff;
                    color: #FFFFFF;

                    .progress-work-sub-item-arrow-right {
                        background-color: #0027ff;
                    }
                }
            }
        }
    }
}
</style>