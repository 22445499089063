<template>
    <div :style="{ height: '100%', display: 'flex', flexDirection: 'column' }">
        
        <!-- toolbar -->
        <uw-grid-search gutter="0 10px" :column="3" :style="{ marginBottom: '10px' }">
            <template slot="tools">
                <el-button type="primary" @click="$refs.visitCreate.Open({ contract_id: contract_id, customer_id: customer_id })">新建回访</el-button>
            </template>
        </uw-grid-search>

        <!-- 数据表 -->
        <div :style="{ flex: '1' }">
            <vxe-table ref="xTable" show-footer :data="table" height="100%">

                <!-- 序号 -->
                <vxe-column width="60" type="seq" title="序号" fixed="left"></vxe-column>

                <!-- 回访编号 -->
                <vxe-column min-width="160" field="number" title="回访编号" fixed="left" sortable>
                    <template #default="{ row }">
                        <el-link type="primary" icon="el-icon-paperclip" @click="$refs.visitDrawer.Open(row.id)">{{ row.number }}</el-link>
                    </template>
                </vxe-column>

                <!-- 回访时间 -->
                <vxe-column min-width="100" field="date" title="回访时间"></vxe-column>

                <!-- 回访形式 -->
                <vxe-column min-width="120" field="type" title="回访形式" sortable>
                    <template #default="{ row }">
                        <el-link :underline="false">{{ row.type?.name }}</el-link>
                    </template>
                </vxe-column>

                <!-- 客户名称 -->
                <vxe-column min-width="160" field="customer" title="客户名称" sortable>
                    <template #default="{ row }">
                        <el-link :underline="false" type="primary" @click="$refs.customerDrawer.Open(row.customer?.id)">{{ row.customer?.name }}</el-link>
                    </template>
                </vxe-column>

                <!-- 联系人 -->
                <vxe-column min-width="120" field="contacts" title="联系人" sortable>
                    <template #default="{ row }">
                        <el-link :underline="false" type="primary" @click="$refs.contactsDrawer.Open(row.contacts?.id)">{{ row.contacts?.name }}</el-link>
                    </template>
                </vxe-column>

                <!-- 客户满意度 -->
                <vxe-column min-width="120" field="satisficing" title="客户满意度" sortable>
                    <template #default="{ row }">
                        <el-link :underline="false">{{ row.satisficing?.name }}</el-link>
                    </template>
                </vxe-column>

                <!-- 客户反馈 -->
                <vxe-column min-width="160" field="remarks" title="客户反馈"></vxe-column>

                <!-- 回访人 -->
                <vxe-column min-width="90" field="user_admin" title="回访人">
                    <template #default="{ row }">
                        <el-link :underline="false" icon="el-icon-user" v-if="row.user_admin">{{ row.user_admin?.name }}</el-link>
                    </template>
                </vxe-column>
            </vxe-table>
        </div>

        <crm-visit-create ref="visitCreate" @onChange="TableSearch()" />
        <crm-visit-drawer ref="visitDrawer" @onChange="TableSearch()" />
        <crm-customer-drawer ref="customerDrawer" @onChange="TableSearch()" />
        <crm-contacts-drawer ref="contactsDrawer" @onChange="TableSearch()" />
    </div>
</template>

<script>
export default {
    props: [ 'contract_id', 'customer_id' ],

    data () {
        return {
            loading: false,
            table: [],
        }
    },
    
    watch: {
        contract_id: {
            handler(val) {
                if(val) {
                    this.TableSearch()
                }
            },
            immediate: true
        }
    },

    methods: {

        /**
        |--------------------------------------------------
        | 数据查询
        |--------------------------------------------------
        |
        */

        TableSearch() {
            this.loading = true
            this.$http.post(
                '/9api/crm/visit/search-all',
                {
                    search: { contract_id: this.contract_id },
                    append: ['user_admin', 'type', 'customer', 'contacts', 'satisficing']
                }
            ).then((rps) => {
                this.table = rps.data
                this.loading = false
            })
        },
    }
}
</script>