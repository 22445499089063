<!---------------------------------------------------------
-----------------------------------------------------------
    - ！组件说明
    - 组件为抽屉形态，从又向左滑动，
    - 请通过 "refs" 调用 "事件" 打开窗口
    - 请注意大小写
    
    - ！事件说明
    - function Open 打开窗口
    ----------------------
    (必填):id = 项目ID
    (必填):status = 变更转态 1 || 2 || 4 || 5
    
    - ！回调说明
    - @success = 操作成功
-----------------------------------------------------------
---------------------------------------------------------->

<template>
    <el-drawer class="w-el-drawer" :visible.sync="visible" :size="960" :with-header="false" direction="rtl" :before-close="Close" append-to-body destroy-on-close>   
        
        <!-- header -->
        <div class="w-el-drawer-header">
            <i class="el-icon-tickets"></i> {{ lable }}
        </div>

        <!-- body -->
        <div class="w-el-drawer-body">
            <froala-editor v-model="event_explain" />
        </div>

        <!-- footer -->
        <div class="w-el-drawer-footer">
            <el-button size="medium" @click="Close()">取消 / 返回</el-button>
            <el-button size="medium" type="primary" @click="ProjectsStatusChange()" :loading="loading">确认变更</el-button>
        </div>
    </el-drawer>
</template>
    
<script>
export default {
    data() {
        return {
            visible: false,
            loading: false,
            lable: null,

            id: null,
            status: null,
            event_explain: null,
        }
    },
    
    methods: {
        /**
         * 窗口 - 打开
         */
        Open(id, status) {

            switch(status) {
                case 1:
                    this.lable = '开始项目'
                    break;
                case 2:
                    this.lable = '暂停/挂起项目'
                    break;
                case 4:
                    this.lable = '关闭项目'
                    break;
                case 5:
                    this.lable = '转待验收'
                    break;
                default:
                    this.Close()
                    break;
            }

            this.id = id
            this.status = status

            this.visible = true
        },

        /**
         * 窗口 - 关闭
         */
        Close() {
            this.id = null
            this.status = null
            this.event_explain = null

            this.visible = false
            this.loading = false
        },

        /**
         * 项目 - 状态变更
         */
        ProjectsStatusChange() {
            this.loading = true

            this.$http.post('/9api/projects/status-change', { id: this.id, status: this.status, event_explain: this.event_explain }).then(rps => {
                this.$notify.success({
                    title: '提示',
                    message: rps.data.success,
                    duration: 2000
                })
                this.$emit("success")
                this.Close()
            })
        }
    }
}
</script>