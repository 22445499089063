<template>
    <div>
        <!-- 表内容 -->
        <vxe-table ref="xTable" :data="table" :loading="loading">

            <!-- 状态 -->
            <vxe-column width="80" title="状态" align="center">
                <template #default="{ row }">
                    <el-tag effect="dark" v-if="row.state == 0" type="warning">未开始</el-tag>
                    <el-tag effect="dark" v-if="row.state == 1">进行中</el-tag>
                    <el-tag effect="dark" v-if="row.state == 2">评审完成</el-tag>
                    <el-tag effect="dark" v-if="row.state == 3">已归档</el-tag>
                    <el-tag effect="dark" v-if="row.state == 4" type="danger">有争议</el-tag>
                </template>
            </vxe-column>

            <!-- 被考核人 -->
            <vxe-column min-width="120" title="被考核人">
                <template #default="{ row }">
                    <div style="display: flex; align-items: center; justify-content: flex-start;">
                        <el-avatar style="margin-right: 5px;" :size="26" :src="row.users?.avatar"> {{ row.users?.name[0] }} </el-avatar>
                        {{  row.users?.name }}
                    </div>
                </template>
            </vxe-column>

            <!-- 评审员 -->
            <vxe-column min-width="120" title="评审员">
                <template #default="{ row }">
                    <el-avatar v-for="(e, i) in row.flow_all_users" :key="i" style="margin-right: 5px;" :size="26" :src="e?.avatar"> {{ e?.name[0] }} </el-avatar>
                </template>
            </vxe-column>

            <!-- 已评审 -->
            <vxe-column min-width="120" title="已评审">
                <template #default="{ row }">
                    <el-avatar v-for="(e, i) in row.flow_finish_users" :key="i" style="margin-right: 5px;" :size="26" :src="e?.avatar"> {{ e?.name[0] }} </el-avatar>
                </template>
            </vxe-column>

            <!-- 考核结果 -->
            <vxe-column min-width="160" field="result_score" title="最终得分"></vxe-column>
            <vxe-column min-width="160" field="result_level" title="最终评级"></vxe-column>

            <!-- 操作 -->
            <vxe-column width="100"  title="操作" align="center" fixed="right">
                <template #default="{ row }">
                    <span class="el-span-primary" @click="$refs.dossierDrawer.Open(row.id)">考核/记录</span>
                </template>
            </vxe-column>

        </vxe-table>

        <!-- 组件 -->
        <hrm-kpi-dossier-drawer ref="dossierDrawer" @onChange="$emit('onChange')" />
        
    </div>
</template>

<script>
export default {
    props: {
        planId: {
            type: Number,
            default: null
        },
    },

    data () {
        return {
            // 数据
            table: [],

            // 分页
            page: {
                total: 0,
                size: 50,
                current: 1,
            },

            // 排序
            order: {
                method: 'desc',
                column: 'id'
            },
        }
    },

    watch: {
        planId: {
            handler(val) {
                this.TableSearch()
            },
            immediate: true
        }
    },

    computed: {
        loading () {
            return !!this.$store.state.httpRequestCount
        }
    },

    mounted () {
        this.TableSearch()
    },

    methods: {

        // 查询
        TableSearch()
        {
            this.$http.post(
                '/9api/hrm/kpi-dossier/search',
                {
                    page: this.page,
                    search: { plan_id: this.planId },
                    order: this.order,
                    append: ['users', 'flow_finish_users', 'flow_all_users'],
                    hidden: ['t_class', 't_flow', 't_result']
                }
            ).then((rps) => {

                // 更新数据
                this.page.total = rps.data.total
                this.table = rps.data.body

                // 页码溢出
                if (this.table.length == 0 && this.page.current != 1) {
                    this.page.current = 1
                    this.TableSearch()
                }
            })
        }
    }
}
</script>