<template>
    <el-dialog :visible.sync="visible" title="任务记录/任务工时" width="80%" :with-header="false" direction="rtl" :before-close="Close" append-to-body>
        
        <div :style="{ marginBottom: '10px' }">
            <el-button type="primary" size="mini" @click="$refs.xTable.print()" >打印</el-button>
            <el-button type="primary" size="mini" @click="$refs.xTable.openExport()" >导出</el-button>
        </div>
        <!-- 数据 -->
        <vxe-table ref="xTable" :data="table" :loading="loading" :export-config="{}" :print-config="{}" >
                            
            <!-- 项目编号 -->
            <vxe-column min-width="150" title="关联项目">
                <template #default="{ row }">
                    <el-link type="info" :underline="false" v-if="!row.project">未关联</el-link>
                    <a :style="{ cursor: 'pointer', color: '#0027ff' }" v-else @click="$refs.projectsDrawer.Open(row.project.id)">{{ row.project.number }}-{{ row.project.name }}</a>
                </template>
            </vxe-column>

            <!-- 项目名称 -->
            <vxe-column min-width="150" title="关联任务">
                <template #default="{ row }">
                    <el-link type="info" :underline="false" v-if="!row.project_task">未关联</el-link>
                    <a :style="{ cursor: 'pointer', color: '#0027ff' }" v-else @click="$refs.projectsTaskDrawer.Open(row.project_task.id)">{{ row.project_task.name }}</a>
                </template>
            </vxe-column>

            <!-- 任务动作 -->
            <vxe-column min-width="90" field="stage" title="任务动作" align="center"></vxe-column>

            <!-- 任务消耗 -->
            <vxe-column min-width="90" title="任务消耗/P">
                <template #default="{ row }">
                    <el-link :underline="false" type="primary" icon="el-icon-time"  @click="$refs.daysEdit.Open(row.id, row.consume_days)">{{ row.consume_days }}</el-link>
                </template>
            </vxe-column>

            <!-- 任务对象 -->
            <vxe-column min-width="90" title="任务对象">
                <template #default="{ row }">
                    {{ row.user.name }}
                </template>
            </vxe-column>

            <!-- 记录人 -->
            <vxe-column min-width="70" title="记录人" field="update_user.name"></vxe-column>
            <vxe-column min-width="160" title="记录时间" field="updated_at"></vxe-column>

            <!-- 操作 -->
            <vxe-column width="60"  title="操作" align="center" fixed="right" v-if="$store.state.user.id == 12">
                <template #default="{ row }">
                    <el-link :underline="false" @click.native.stop="$refs.alarmCreate.Open({ task_id: row.model_id })" type="danger" v-if="!row.projects_task.alarm_state">异常</el-link>
                </template>
            </vxe-column>

        </vxe-table>

        <!-- 工时组件 -->
        <pm-task-alarm-create ref="alarmCreate" @onChange="DataGet" />
        <pm-projects-drawer ref="projectsDrawer" @onchange="DataGet"  />
        <pm-projects-task-drawer ref="projectsTaskDrawer" @onchange="DataGet"  />
        <daysEdit ref="daysEdit" @onSave="DataGet"></daysEdit>

    </el-dialog>
</template>

<script>
import daysEdit from '@/views/projects/DialogProjectsFlowEditTaskDays'

export default {
    components: {
        daysEdit
    },

    data () {
        return {
            // 加载状态
            visible: false,
            loading: false,

            table: [],

            search: {
                user_id: null,
                project_id: null,
                stage: null,
                created_at: null,
                year: null,
                month: null
            }
        }
    },

    methods: {

        // 窗口 - 打开
        Open (config) {

            // 查询条件
            this.search.project_id = config.project_id
            this.search.user_id = config.user_id
            this.search.stage = config.stage
            this.search.created_at = config.created_at
            this.search.year = config.year
            this.search.month = config.month
            
            // 显示窗口 
            this.visible = true
            this.DataGet()
        },
        
        // 窗口 - 关闭
        Close() {
            this.loading = false
            this.visible = false
            this.$emit('onChange')
        },
        
        // 获取数据
        DataGet() {
            // 打开加载状态
            this.loading = true

            // 查询数据
            this.$http.post('/9api/pm/projects-flow/search-all', { search: this.search, append: ['project', 'project_task', 'user', 'update_user', 'projects_task'] }).then(rps => {
                this.table = rps.data
                this.loading = false
            })
        },
    }
}
</script>