<template>
    <el-dialog :visible.sync="visible" width="400px" title="编辑产品类别" append-to-body class="w-el-dialog">

        <!-- 表单 -->
        <el-form :model="form" label-width="120px" size="mini" label-position="top">

            <!-- 类别名称 -->
            <el-form-item label="类别名称" :rules="[{ required: true, message: '必填项' }]" :error="formError.name?.toString()" prop="name">
                <el-input v-model="form.name"  clearable />
            </el-form-item>

            <!-- 类别父级 -->
            <el-form-item label="类别父级" :rules="[{ required: true, message: '必选项' }]" :error="formError.parent?.toString()" prop="parent">
                <ims-product-type-select v-model="form.parent" />
            </el-form-item>

        </el-form>

        <div slot="footer">
            <el-button size="small" @click="visible = false">取 消</el-button>
            <el-button size="small" type="primary" @click="FormSubmit()" :loading="loading">确 定</el-button>
        </div>

    </el-dialog>
</template>

<script>
export default {
    data () {
        return {
            visible: false,
            loading: false,

            formError: {},
            form: {
                id: null,
                parent: null,
                name: null,
            }
        }
    },

    methods: {

        /**
        |--------------------------------------------------
        | 打开窗口
        |--------------------------------------------------
        |
        */

        Open(id) {
            this.visible = true
            this.loading = true

            this.$http.post('/9api/ims/product-type/search-first', { search: { id: id } }).then(rps => {
                this.form = rps.data
                this.loading = false
            })
        },

        /**
        |--------------------------------------------------
        | 提交表单
        |--------------------------------------------------
        |
        */

        FormSubmit() {

            this.loading = true

            this.$http.post('/9api/ims/product-type/update', this.form).then((rps) => {

                // 提交失败
                if (rps.data.state == 'error') {
                    this.formError = rps.data.body
                }

                // 提交成功
                if (rps.data.state == 'success') {
                    this.$emit('onChange', rps.data.body)
                    this.visible = false
                }

                this.loading = false
            })
        },
    }
}
</script>