<template>
    <el-drawer :visible.sync="visible" size="80%" direction="rtl" append-to-body destroy-on-close :show-close="false" :before-close="Close">

        <div slot="title">

            <div class="w-margin-b-10">
                考核计划 - 
                <el-tag effect="plain" v-if="plan.state == 0" type="warning">未开始</el-tag>
                <el-tag effect="plain" v-if="plan.state == 1">进行中</el-tag>
                <el-tag effect="plain" v-if="plan.state == 2" type="info">已归档</el-tag>
            </div>
            <div class="w-margin-b-10" style="font-size: 18px; color: #000; font-weight: bold;">{{ plan.name }}</div>

            <!-- 功能按钮 -->
            <div :style="{ display: 'flex' }">
                <el-button icon="el-icon-refresh"   :disabled="loading" type="primary"  @click="Reset()">刷新</el-button>
                <el-button icon="el-icon-edit"      :disabled="loading" type="primary"  @click="$refs.planUpdate.Open(plan.id)">修改</el-button>
                <el-button icon="el-icon-check"     :disabled="loading" type="primary"  @click="UpdateConfirm()" v-if="plan.state == 0">开始考核</el-button>
                <el-button icon="el-icon-check"     :disabled="loading" type="primary"  @click="UpdateClose()" v-if="plan.state == 1">归档</el-button>
                <el-button icon="el-icon-delete"    :disabled="loading" type="danger"   @click="Delete()">移除</el-button>
            </div>

            <!-- 基本信息 -->
            <div class="w-margin-t-10" style="background-color: #E8EAF6; border-radius: 3px; padding: 15px; display: flex; border: 1px solid #0027ff">
                <el-statistic title="待考核"><template slot="formatter"><span style="font-size: 14px; font-weight: bold;">{{ plan.count_wait }} / 人</span></template></el-statistic>
                <el-statistic title="考核中"><template slot="formatter"><span style="font-size: 14px; font-weight: bold;">{{ plan.count_progress }} / 人</span></template></el-statistic>
                <el-statistic title="已完成"><template slot="formatter"><span style="font-size: 14px; font-weight: bold;">{{ plan.count_finish }} / 人</span></template></el-statistic>
                <el-statistic title="已驳回"><template slot="formatter"><span style="font-size: 14px; font-weight: bold;">{{ plan.count_reject }} / 人</span></template></el-statistic>
                <el-statistic title="已归档"><template slot="formatter"><span style="font-size: 14px; font-weight: bold;">{{ plan.count_close }} / 人</span></template></el-statistic>
            </div>

        </div>

        <div class="w-padding-l-20 w-padding-r-20 w-padding-b-20">
            <el-tabs v-model="tabs" :key="tabsKey">

                <!-- 计划详情 -->
                <el-tab-pane label="计划详情" name="base">
                    <hrm-kpi-plan-drawer-base :id="plan.id" v-if="tabs == 'base'" />
                </el-tab-pane>

                <!-- 考核成员 -->
                <el-tab-pane label="考核成员" name="dossier">
                    <hrm-kpi-plan-drawer-dossier :plan-id="plan.id" v-if="tabs == 'dossier'" @onChange="Reset" />
                </el-tab-pane>

            </el-tabs>
        </div>

        <!-- 组件 -->
        <hrm-kpi-plan-update ref="planUpdate" @onChange="Reset()" />

    </el-drawer>
</template>

<script>
export default {
    data() {
        return {
            visible: false,
            tabs: 'base',
            tabsKey: Date.now(),

            plan: {
                id: null,
                template: {},
                users_id: [],
                date: [],
                created_user: {},
                updated_user: {}
            }
        }
    },

    computed: {
        loading () {
            return !!this.$store.state.httpRequestCount
        }
    },

    methods: {

        // 窗口：打开
        Open(id)
        {
            this.visible = true
            this.Search(id)
        },

        // 窗口：关闭
        Close(done)
        {
            this.$emit('onChange')
            done()
        },

        // 刷新
        Reset()
        {
            this.Search()
            this.tabsKey = Date.now()
        },

        // 查询
        Search(id = this.plan.id)
        {
            this.$http.post(
                '/9api/hrm/kpi-plan/search-first',
                {
                    search: { id: id },
                    append: ['created_users', 'updated_users'],
                    hidden: ['template']
                }
            ).then(rps => {
                this.plan = rps.data
            }) 
        },

        // 修改：开始考核
        UpdateConfirm()
        {
            this.$http.post('/9api/hrm/kpi-plan/update-confirm', { id: this.plan.id }).then(() => this.Reset())
        },

        // 修改：归档
        UpdateClose()
        {
            this.$http.post('/9api/hrm/kpi-plan/update-close', { id: this.plan.id }).then(() => this.Reset())
        },

        // 删除
        Delete()
        {
            this.$http.post('/9api/hrm/kpi-plan/delete', { id: this.plan.id }).then(rps => {
                this.$emit('onChange')
                this.visible = false
            })
        },

        
    }
}

</script>6
