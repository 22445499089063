<template>
    <el-select
        @change="SelectChange"
        v-model="selectValue"
        :style="{ width: width, margin: margin }"
        size="mini"
        placeholder="请选择仓库"
        clearable>
        <el-option v-for="(item, idx) in select" :key="idx" :label="item.name" :value="item.id"></el-option>
    </el-select>
</template>

<script>
export default {
    props: {
        value: {
            type: [String, Array, Number],
            default: null
        },

        width: {
            type: String,
            default: '100%'
        },

        margin: {
            type: String,
            default: '0px'
        },
    },

    watch: {
        value () {
            this.selectValue = this.value
        },
    },

    data () {
        return {
            select: [],
            selectValue: null,
        }
    },

    mounted() {
        this.$http.post('/9api/ims/warehouse/search-all').then(rps => {
            this.select = rps.data
        })
    },

    methods: {
        SelectChange(data) {
            this.$emit('input', data)
            this.$emit('onChange', data)
        }
    }
}
</script>