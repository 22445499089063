<!---------------------------------------------------------------------------- 
 | CRM - 合同信息（需要前置条件）
 |----------------------------------------------------------------------------
 | 方法：请注意大小写,参数描述见具体方法
 | *v-model     默认值------默认为 null
 | width        表单宽度----默认为 ‘100%’
 | margin       表单外边距--默认为 ‘0px’
 |----------------------------------------------------------------------------
 | 回调
 | @onChange = fn (data) => {} 返回表单内容
 |
 ---------------------------------------------------------------------------->

 <template>
    <el-select
        @change="SelectChange"
        v-model="selectValue"
        :style="{ width: width, margin: margin }"
        size="mini"
        placeholder="请选择客户联系人"
        clearable>
        <el-option v-for="(item, idx) in select" :key="idx" :label="item.name" :value="item.id">
            <span style="color: #8492a6; font-size: 13px">{{ item.number }} - </span>
            <span>{{ item.name }}</span>
        </el-option>
    </el-select>
</template>

<script>
export default {
    props: {
        customer_id: {
            type: [String, Array, Number],
            default: null
        },

        value: {
            type: [String, Array, Number],
            default: null
        },

        province: {
            type: String,
            default: null
        },

        width: {
            type: String,
            default: '100%'
        },

        margin: {
            type: String,
            default: '0px'
        },
    },

    watch: {
        value () {
            this.selectValue = this.value
        },

        customer_id () {
            this.SearchAll()
        },
    },

    data () {
        return {
            select: [],
            selectValue: null,
        }
    },

    mounted() {
        this.SearchAll()
    },

    methods: {
        SearchAll () {
            if (this.customer_id) {
                this.$http.post('/9api/crm/contract/search-all', { search: { customer_id: this.customer_id } }).then(rps => {
                    this.select = rps.data
                    this.selectValue = this.value
                })
            }
        },

        SelectChange(data) {
            this.$emit('input', data)
            this.$emit('onChange', data)
        }
    }
}
</script>