<!---------------------------------------------------------------------------- 
 | 合同管理 - 回款计划 - 创建
 |----------------------------------------------------------------------------
 | 方法：请注意大小写,参数描述见具体方法
 | $refs[ref_name].Open({ contract_id }) 手动打开窗口,
 | $refs[ref_name].Close() 手动关闭窗口
 |----------------------------------------------------------------------------
 | 回调
 | @success = fn (Object) => {}
 |
 ---------------------------------------------------------------------------->

<template>
    <el-drawer :visible.sync="visible" :size="650" direction="rtl" :show-close="false" :before-close="Close" append-to-body destroy-on-close>
        
        <div slot="title" :style="{ display: 'flex', justifyContent: 'space-between' }">
            <div class="w-margin-b-10" style="font-size: 18px; color: #000; font-weight: bold;">新建回款计划</div>
            <div>
                <el-button size="mini" @click="Close()">取消</el-button>
                <el-button size="mini" type="primary" @click="FormSubmit()" :loading="loading">保存</el-button>
            </div>
        </div>
           
        <div :style="{ padding: '0 20px 20px 20px' }">

            <!-- 表单 -->
            <el-form :model="form" label-width="120px" size="mini" label-position="top" >
                
                <el-row :gutter="20">

                    <!-- 关联合同 -->
                    <el-col :span="24">
                        <el-form-item :rules="[{ required: true, message: '必填项' }]" label="关联合同" :error="formError.contract_id?.toString()" prop="contract_id">
                            <crm-contract-select v-model="form.contract_id" @onChange="ContractOnChange" />
                        </el-form-item>
                    </el-col>

                    <!-- 计划负责人 -->
                    <el-col :span="12">
                        <el-form-item :rules="[{ required: true, message: '必选项' }]" label="计划负责人" :error="formError.user_id?.toString()" prop="user_id">
                            <users-select v-model="form.user_id" />
                        </el-form-item>
                    </el-col>

                    <!-- 计划回款日期 -->
                    <el-col :span="12">
                        <el-form-item :rules="[{ required: true, message: '必填项' }]" label="计划回款日期" :error="formError.pay_date_plan?.toString()" prop="pay_date_plan">
                            <el-date-picker v-model="form.pay_date_plan" value-format="yyyy-MM-dd" type="date" placeholder="请选择计划回款日期" clearable :style="{ width: '100%' }" />
                        </el-form-item>
                    </el-col>

                    <!-- 回款金额 -->
                    <el-col :span="12">
                        <el-form-item :rules="[{ required: true, message: '必填项' }]" label="回款金额" :error="formError.pay_money?.toString()" prop="pay_money">
                            <el-input-number v-model="form.pay_money" :min="0" :precision="2" label="回款金额" :style="{ width: '100%' }" :controls="false"  @change="form.pay_money_offset = form.pay_money"></el-input-number>
                        </el-form-item>
                    </el-col>

                    <!-- 冲销金额 -->
                    <el-col :span="12">
                        <el-form-item :rules="[{ required: true, message: '必填项' }]" label="冲销金额" :error="formError.pay_money_offset?.toString()" prop="pay_money_offset">
                            <el-input-number v-model="form.pay_money_offset" :min="0" :precision="2" label="冲销金额" :controls="false" :style="{ width: '100%' }" ></el-input-number>
                        </el-form-item>
                    </el-col>

                    <!-- 开具发票 -->
                    <el-col :span="12">
                        <el-form-item label="开具发票">
                            <el-checkbox v-model="form.invoice_state">需要开具发票</el-checkbox>
                        </el-form-item>
                    </el-col>

                    <!-- 回款计划描述 -->
                    <el-col :span="12">
                        <el-form-item label="回款计划描述">
                            <el-input v-model="form.describe" type="textarea" :rows="2" placeholder="回款计划描述"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>

            <!-- 合同附件 -->
            <template v-if="contract.files_id && contract.files_id.length > 0">
                <el-divider content-position="left"><b>关联附件</b></el-divider>
                <files-table v-model="contract.files_id" :span="24" preview-type="drawer" preview-direction="ltr" :preview-modal="false" :drawer-size="drawerSize" />
            </template>

        </div>

    </el-drawer>
</template>
    
<script>
export default {
    data() {
        return {
            visible: false,

            drawerSize: '80%',

            formError: {},
            form: {
                contract_id: null,
                user_id: null,
                pay_date_plan: null,
                pay_money: 0,
                pay_money_offset: 0,
                invoice_state: true,
                describe: null,
            },

            contract: {
                files_id: []
            },
        }
    },

    computed: {
        loading () {
            return !!this.$store.state.httpRequestCount
        }
    },

    mounted() {
        this.ListenerResizeFn()
        window.addEventListener('resize', this.ListenerResizeFn)
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.ListenerResizeFn)
    },
    
    methods: {

        // 窗口大小变化事件
        ListenerResizeFn() {
            this.drawerSize = window.innerWidth - 650
        },

        // 窗口 - 打开
        Open(config) {
            if (config) this.form.contract_id = config.contract_id
            this.visible = true
        },

        // 窗口 - 关闭
        Close() {
            this.formError = {}
            this.form.contract_id = null
            this.form.pay_date_plan = null
            this.form.user_id = null
            this.form.pay_money = 0
            this.form.pay_money_offset = 0
            this.form.invoice_state = true
            this.form.describe = null
            this.contract.files_id = []
            this.visible = false
        },
        
        // 合同信息 - 映射表单
        ContractOnChange(data) {
            if (data) {
                this.$http.post('/9api/contract-pay-plan/search-all', { search: { contract_id: data.id } }).then((rps) => {
                    this.contract.files_id = data?.files_id
                    this.form.pay_money = (data.pay_sum_a - this.$lodash.array_sum(rps.data.body, 2, 'pay_money')).toFixed(2)
                    this.form.pay_money_offset = (data.pay_sum_a - this.$lodash.array_sum(rps.data.body, 2, 'pay_money')).toFixed(2)
                    this.form.user_id = data?.user_sale_id
                })
            }
        },

        // 表单提交
        FormSubmit() {
            this.$http.post('/9api/contract-pay-plan/create', this.form).then(rps => {
                if (rps.data.state == 'error') {
                    this.formError = rps.data.body
                }
                if (rps.data.state == 'success') {
                    this.$emit("success")
                    this.Close()
                }
            })
        }
    }
}
</script>