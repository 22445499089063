<!---------------------------------------------------------------------------- 
 | Icon
 |----------------------------------------------------------------------------
 | *v-model 默认值------默认为 '#icon-w-foodsanmingzhi-01'
 |
 ---------------------------------------------------------------------------->

<template>
    <el-popover placement="top-start" width="220" trigger="click">
        <svg slot="reference" class="w-icon is-border" aria-hidden="true" :font-size="size">
            <use :xlink:href="selectValue"></use>
        </svg>

        <span class="w-margin-b-5">食物</span>
        <div style="display: grid; grid-gap: 2px; grid-template-columns: auto auto auto auto auto auto auto;">
            <svg class="w-icon is-border" aria-hidden="true" font-size="16px"  v-for="(item, idx) in icon.food" :key="idx" @click="SelectChange(item)">
                <use :xlink:href="item"></use>
            </svg>
        </div>
    </el-popover>
</template>

<script>
export default {
    props: {
        value: {
            type: String,
            default: '#icon-w-foodsanmingzhi-01'
        },
        size: {
            type: String,
            default: '18px'
        }
    },

    watch: {
        value () {
            this.selectValue = this.value ? this.value : '#icon-w-foodsanmingzhi-01'
        }
    },

    data () {
        return {
            selectValue: '#icon-w-foodsanmingzhi-01',

            icon: {
                food: [
                    '#icon-w-foodsanmingzhi-01', '#icon-w-foodbangbangtang-01', '#icon-w-foodbaozi-01', '#icon-w-foodbaomihua-01',
                    '#icon-w-foodbingqilin-01', '#icon-w-foodbinggan-01', '#icon-w-foodbuding-01', '#icon-w-fooddangao-01',
                    '#icon-w-fooddanta-01', '#icon-w-fooddiaoyubing-01', '#icon-w-foodfengmi-01', '#icon-w-foodguaizhangtang-01',
                    '#icon-w-foodfantuan-01', '#icon-w-foodguantou-01', '#icon-w-foodhanbao-01', '#icon-w-foodhongjiu-01',
                    '#icon-w-foodhuoji-01', '#icon-w-foodjiroujuan-01', '#icon-w-foodjitui-01', '#icon-w-foodjiweijiu-01', 
                    '#icon-w-foodjiandan-01', '#icon-w-foodjiangbingren-01', '#icon-w-foodjiaozi-01', '#icon-w-foodkafei-01',
                    '#icon-w-foodkaoya-01', '#icon-w-foodkele-01', '#icon-w-foodmifan-01', '#icon-w-foodmianhuatang-01', 
                    '#icon-w-foodmianbao-01', '#icon-w-foodnaicha-01', '#icon-w-foodnailao-01', '#icon-w-foodniujiaobao-01', 
                    '#icon-w-foodniunai-01', '#icon-w-foodniupai-01', '#icon-w-foodpaobing-01', '#icon-w-foodpisa-01', 
                    '#icon-w-foodpijiu-01', '#icon-w-foodqiaokeli-01', '#icon-w-foodregou-01', '#icon-w-foodshousi-01', 
                    '#icon-w-foodshutiao-01', '#icon-w-foodtake-01', '#icon-w-foodtangguo-01', '#icon-w-foodtanghulu-01', 
                    '#icon-w-foodtianfuluo-01', '#icon-w-foodtiantianquan-01', '#icon-w-foodtusi-01', '#icon-w-foodxuegao-01', 
                    '#icon-w-foodyimian-01', '#icon-w-foodzongzi-01'
                ]
            }
        }
    },

    mounted () {
        this.selectValue = this.value ? this.value : '#icon-w-foodsanmingzhi-01'
        this.$emit('input', this.selectValue)
    },

    methods: {
        SelectChange(icon) {
            this.selectValue = icon
            this.$emit('input', icon)
        }
    }

}
</script>