<!---------------------------------------------------------------------------- 
 | CRM - 编辑客户联系人 
 |----------------------------------------------------------------------------
 | 方法：请注意大小写,参数描述见具体方法
 | $refs[ref_name].Open() 手动打开窗口,
 |----------------------------------------------------------------------------
 | 回调
 | @onChange = fn (Object) => {} 成功后返信息
 |
 ---------------------------------------------------------------------------->

 <template>
    <el-dialog :visible.sync="visible" width="600px" append-to-body title="编辑回访信息" :close-on-click-modal="false" :close-on-press-escape="false" :before-close="Close">

        <!-- 表单 -->
        <el-form :model="form" size="mini" label-position="top" v-loading="loading">
            
            <!-- 基础信息 -->
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="回访日期" :rules="[{ required: true, message: '必填项' }]" :error="formError.date?.toString()" prop="date">
                        <el-date-picker v-model="form.date" type="date" placeholder="选择日期" value-format="yyyy-MM-dd" :style="{ width: '100%' }"></el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="回访人" :rules="[{ required: true, message: '必填项' }]" :error="formError.user_admin_id?.toString()" prop="user_admin_id">
                        <users-select v-model="form.user_admin_id" />
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="回访形式">
                        <crm-visit-type-select v-model="form.type_id" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="客户名称" :rules="[{ required: true, message: '必填项' }]" :error="formError.customer_id?.toString()" prop="customer_id">
                        <crm-customer-select v-model="form.customer_id" @onChange="(rps) => form.contract_id = form.contacts_id = null" />
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="合同编号" :rules="[{ required: true, message: '必选项' }]" :error="formError.contract_id?.toString()" prop="contract_id">
                        <crm-customer-select-contract v-model="form.contract_id" :customer_id="form.customer_id" clearable />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="联系人" :rules="[{ required: true, message: '必选项' }]" :error="formError.contacts_id?.toString()" prop="contacts_id">
                        <crm-customer-select-contacts v-model="form.contacts_id" :customer_id="form.customer_id" />
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="客户满意度">
                        <crm-visit-satisficing-select v-model="form.satisficing_id" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="客户反馈">
                        <el-input type="textarea" :rows="3" v-model="form.remarks" placeholder="备注" clearable />
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        

        <span slot="footer">
            <el-button size="mini" @click="Close()">取 消</el-button>
            <el-button size="mini" type="primary" @click="SubmitForm()" :loading="loading">确 定</el-button>
        </span>
    </el-dialog>
</template>

<script>

export default {
    data () {
        return {
            // 加载状态
            visible: false,
            loading: false,

            formError: {},
            form: {
                id: null,
                date: null,
                user_admin_id: this.$store.state.user.id,
                satisficing_id: null,
                type_id: null,
                customer_id: null,
                contacts_id: null,
                contract_id: null,
                remarks: null
            }
        }
    },

    methods: {

        /**
        |--------------------------------------------------
        | 窗口 - 打开
        |--------------------------------------------------
        |
        */
       
        Open (id) {
            this.visible = true
            this.loading = true

            this.$http.post('/9api/crm/visit/search-first', { search: { id: id } }).then(rps => {
                this.form = rps.data
                this.loading = false
            })
        },


        /**
        |--------------------------------------------------
        | 窗口 - 关闭
        |--------------------------------------------------
        |
        */

        Close() {
            this.loading = false
            this.visible = false
        },

        /**
        |--------------------------------------------------
        | 提交表单
        |--------------------------------------------------
        |
        */

        SubmitForm() {
            // 开启加载状态
            this.loading = true
            this.formError = {}

            // 提交表单
            this.$http.post('/9api/crm/visit/update', this.form).then(rps => {
                // 关闭加载状态
                this.loading = false

                // 提交失败
                if (rps.data.state == 'error') {
                    this.formError = rps.data.body
                }
                
                // 提交成功
                if (rps.data.state == 'success') {
                    this.$emit('onChange', rps.data.body)
                    this.Close()
                }
            })
        }
    }  
}
</script>