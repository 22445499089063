<template>
    <el-drawer :visible.sync="visible" size="80%" direction="rtl" append-to-body destroy-on-close :show-close="false" :before-close="Close">

        <div slot="title">

            <!-- 产品申领 -->
            <div class="w-margin-b-10">
                <span>申领单 - </span>
                <el-tag v-if="apply.confied_state == 0" size="mini" effect="dark" type="info">待审核</el-tag>
                <el-tag v-if="apply.confied_state == 1" size="mini" effect="dark">已同意</el-tag>
                <el-tag v-if="apply.confied_state == 2" size="mini" effect="dark" type="danger">已拒绝</el-tag>
            </div>
            
            <div class="w-margin-b-10" style="font-size: 18px; color: #000; font-weight: bold;">{{ apply.number }}</div>

            <!-- 功能按钮 -->
            <div style="display: flex;">
                <el-button size="mini" @click="UpdateState(1)" type="primary">同意</el-button>
                <el-button size="mini" @click="UpdateState(2)" type="danger">拒绝</el-button>
                <el-button size="mini" icon="el-icon-refresh" @click="SearchData()">更新信息</el-button>
                <el-button size="mini" icon="el-icon-delete" @click="Delete()" type="danger">移除</el-button>
            </div>

            <!-- 申领简要 -->
            <div class="w-margin-t-10" style="background-color: #f4f5f7; border-radius: 3px; padding: 15px; display: flex;">
                <el-statistic title="申领仓库"><template slot="formatter"><span style="font-size: 14px;">{{ apply.warehouse?.name }}</span></template></el-statistic>
                <el-statistic title="申领人"><template slot="formatter"><span style="font-size: 14px;">{{ apply.created_user?.name }}</span></template></el-statistic>
                <el-statistic title="申领时间"><template slot="formatter"><span style="font-size: 14px;">{{ apply.created_at }}</span></template></el-statistic>
                <el-statistic title="申领备注"><template slot="formatter"><span style="font-size: 14px;">{{ apply.describe ?? '未填写' }}</span></template></el-statistic>
            </div>

            <!-- 审核简要 -->
            <div class="w-margin-t-10" style="background-color: #f4f5f7; border-radius: 3px; padding: 15px; display: flex;" v-if="apply.confied_at">
                <el-statistic title="申领状态"><template slot="formatter"><span style="font-size: 14px;">{{ apply.confied_state == 0 ? '待审核' : (apply.confied_state == 1 ?  '已同意' :  '已拒绝')  }}</span></template></el-statistic>
                <el-statistic title="审核人"><template slot="formatter"><span style="font-size: 14px;">{{ apply.confied_user?.name }}</span></template></el-statistic>
                <el-statistic title="审核时间"><template slot="formatter"><span style="font-size: 14px;">{{ apply.confied_at }}</span></template></el-statistic>
                <el-statistic title="审核结果"><template slot="formatter"><span style="font-size: 14px;">{{ apply.confied_describe ?? '未填写' }}</span></template></el-statistic>
            </div>

        </div>

        <div :style="{ padding: '0 20px 20px 20px' }">
            <el-tabs v-model="tabs">

                <!-- 基础信息 -->
                <el-tab-pane label="申领产品" name="log">
                    <ims-product-apply-drawer-log v-if="tabs == 'log'" :id="apply.id" @onChange="SearchData" />
                </el-tab-pane>

                <!-- 基础信息 -->
                <el-tab-pane label="出库记录" name="warehouseDocumentBack">
                    <ims-product-apply-drawer-warehouse-document-back v-if="tabs == 'warehouseDocumentBack'" :id="apply.id" @onChange="SearchData" />
                </el-tab-pane>

                <!-- 基础信息 -->
                <el-tab-pane label="入库记录" name="warehouseDocumentEnter">
                    <ims-product-apply-drawer-warehouse-document-enter v-if="tabs == 'warehouseDocumentEnter'" :id="apply.id" @onChange="SearchData" />
                </el-tab-pane>

            </el-tabs>
        </div>

        <!-- 组件 -->
        <ims-product-drawer ref="productDrawer" @onChange="SearchData()" />
        <ims-supplier-drawer ref="supplierDrawer" @onChange="SearchData()" />

    </el-drawer>
</template>

<script>
export default {
    data() {
        return {
            visible: false,

            tabs: 'log',

            apply: {
                id: null,
                warehouse: {},
                created_user: {},
                updated_user: {},
                confied_user: {}
            }
        }
    },

    computed: {
        loading () {
            return !!this.$store.state.httpRequestCount
        }
    },

    methods: {

        // 打开窗口
        Open(id) {
            this.visible = true
            this.SearchData(id)
        },

        // 关闭窗口
        Close(done) {
            this.$emit('onChange')
            done()
        },

        // 检索数据
        SearchData(id = this.apply.id) {
            this.$http.post(
                '/9api/ims/product-apply/search-first',
                { search: { id: id }, append: ['created_user', 'updated_user', 'confied_user', 'warehouse'] }
            ).then(rps => {
                this.apply = rps.data
            })
        },

        // 审批
        UpdateState(state) {
            this.$prompt('审批备注', '仓库管理员审批', {
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            }).then(({ value }) => {
                this.$http.post('/9api/ims/product-apply/update-state', { id: this.apply.id, confied_state: state, confied_describe: value  }).then((rps) => {
                    this.SearchData()
                })
            })
        },

        // 删除
        Delete() {
            this.$confirm('移除后将无法恢复！', '警告', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'error'
            }).then(() => {
                this.$http.post('/9api/ims/product-apply/delete', { id: this.apply.id }).then((rps) => {
                    this.$emit('onChange')
                    this.visible = false
                })
            })
        },
        
    }
}

</script>6
