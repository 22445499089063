<!---------------------------------------------------------------------------- 
 | 表格
 |----------------------------------------------------------------------------
 | 参数说明
 | height => String => default: 100% => 高度
 | showHeader => Boolean => default: true => 页头是否显示
 | showFooter => Boolean => default: true => 页尾是否显示
 |----------------------------------------------------------------------------
 | 插槽
 | search => 检索
 | button => 按钮
 | data => 数据
 | footer => 页脚
 | pager => 分页
 ---------------------------------------------------------------------------->

<template>
    <div class="uw-table" :style="{ height: height }">
        
        <!-- 页头 -->
        <div class="uw-table-header" v-if="showHeader">

            <!-- 检索 -->
            <div class="uw-table-header-search">
                <slot name="search"></slot>
            </div>

            <!-- 按钮 -->
            <div class="uw-table-header-button">
                <slot name="button"></slot>
            </div>
        </div>

        <div class="uw-table-data">
            <slot name="data"></slot>
        </div>
        
        <!-- 页脚 -->
        <div class="uw-table-footer" v-if="showFooter">
            <div class="uw-table-footer-slot">
                <slot name="footer"></slot>
            </div>

            <!-- 分页 -->
            <div class="uw-table-footer-pager">
                <slot name="pager"></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        height: {
            type: String,
            default: '100%'
        },
        showHeader: {
            type: Boolean,
            default: true
        },
        showFooter: {
            type: Boolean,
            default: true
        }
    }
}
</script>

<style lang="less" scoped>
.uw-table {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    .uw-table-header {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        margin-bottom: 20px;
        padding: 16px;
        border-radius: 5px;
        background-color: #F5F5F5;

        .uw-table-header-search {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-column-gap: 5px;
            grid-row-gap: 5px;
        }

        .uw-table-header-button {
            margin-left: 30px;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
        }
    }

    .uw-table-data {
        flex-grow: 1;
    }

    .uw-table-footer {
        margin-top: 10px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .uw-table-footer-slot {

        }

        .uw-table-footer-pager {

        }
    }
}
</style>