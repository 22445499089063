<template>
    <div id="froalaViews">
        <el-empty v-if="!value" description="暂无内容"></el-empty>
        <div v-html="value"></div>
    </div>
</template>

<script>
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/froala_style.min.css';

export default {
    props: {
        value: {
            type: String,
            default: null
        }
    },
}
</script>