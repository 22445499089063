<template>
    <div>
        <!-- 出库记录 -->
        <el-empty v-if="warehouseEnter.filter(rps => rps.state == 1) == 0" description="暂无记录"></el-empty>
        <div v-for="(item, idx) in warehouseEnter.filter(rps => rps.state == 1)" :key="'enter-' + idx" :style="{ marginBottom: '20px' }">
            <div :style="{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '14px' }">
                <div>
                    <span :style="{ marginRight: '10px' }">出库单号：<span class="el-span-primary">{{ item.number }}</span></span>
                    <span :style="{ marginRight: '10px' }">出库时间：{{ item.created_at }}</span>
                    <span :style="{ marginRight: '10px' }">负责人：{{ item.created_user.name }}</span>
                </div>
            </div>
            <vxe-table border ref="xTable" :data="item.warehouse_log" :loading="loading" :style="{ marginTop: '10px' }">
                <vxe-column min-width="120" field="product.name" title="产品名称"></vxe-column>
                <vxe-column min-width="80" field="product.unit" title="计量单位"></vxe-column>
                <vxe-column min-width="100" title="规格参数">
                    <template #default="{ row }">
                        {{ row.product.parameter?.toString() }}
                    </template>
                </vxe-column>
                <vxe-column min-width="80" field="num" title="本次出库"></vxe-column>
                <vxe-column min-width="80" field="warehouse.name" title="出库仓库"></vxe-column>
                <vxe-column min-width="90" field="describe" title="备注"></vxe-column>
            </vxe-table>
            <div :style="{ fontSize: '14px', marginTop: '5px' }" v-if="item.describe">备注：{{ item.describe }}</div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        id: {
            type: Number,
            default: null
        },
    },

    data() {
        return {
            warehouseEnter: []
        }
    },

    computed: {
        loading () {
            return !!this.$store.state.httpRequestCount
        }
    },

    watch: {
        id: {
            handler(val) {
                this.TableSearch()
            },
            immediate: true
        }
    },
    
    methods: {

        // 订单记录
        TableSearch() {
            this.$http.post('/9api/ims/warehouse-document/search-all', { search: { model: 'ims_product_apply', model_id: this.id, action: 0 }, append: ['warehouse_log', 'created_user'] }).then(rps => {
                this.warehouseEnter = rps.data
            })
        }
    }
}
</script>