<template>
    <div>
        <!-- 采购信息 -->
        <el-divider content-position="left"><b>申请单</b></el-divider>
        <div>
            <el-button type="primary" size="mini" @click="$refs.prCreateProduct.Open({ product_order_id: id })">添加产品</el-button>
            <el-button type="primary" size="mini" @click="UpdateState()">转为采购单</el-button>
        </div>
        <vxe-table border ref="xTableS" :data="table.filter(rps => !rps.state)" :loading="loading" :style="{ marginTop: '10px' }">
            <!-- 产品信息 -->
            <vxe-column min-width="120" title="供应商">
                <template #default="{ row }">
                    <span :style="{ color: '#0027ff', cursor: 'pointer' }" @click="$refs.supplierDetails.Open(row.product.supplier?.id)">{{ row.product.supplier?.name }}</span>
                </template>
            </vxe-column>
            <vxe-column min-width="120" title="产品名称">
                <template #default="{ row }">
                    <span :style="{ color: '#0027ff', cursor: 'pointer' }" @click="$refs.productDetails.Open(row.product.id)">{{ row.product.name }}</span>
                </template>
            </vxe-column>
            <vxe-column min-width="100" field="product.type.name" title="产品类别"></vxe-column>
            <vxe-column min-width="100" field="product.brand" title="产品品牌"></vxe-column>
            <vxe-column min-width="100" title="规格参数">
                <template #default="{ row }">
                    {{ row.product.parameter?.toString() }}
                </template>
            </vxe-column>
            <vxe-column min-width="80" field="num" title="采购数量"></vxe-column>
            <vxe-column min-width="80" field="product.unit" title="计量单位"></vxe-column>
            <vxe-column min-width="90" field="money" title="采购单价/￥"></vxe-column>
            <vxe-column min-width="70" field="money_total" title="合计/￥"></vxe-column>
            <vxe-column min-width="100" field="product.maintenance" title="保修期"></vxe-column>
            <vxe-column width="220" field="describe" title="备注说明"></vxe-column>
            <vxe-column min-width="70" field="discount" title="折扣/%"></vxe-column>
            <vxe-column min-width="100" field="discount_document" title="整单折扣/%"></vxe-column>
            <vxe-column min-width="100" field="money_discount" title="整单折后价/￥"></vxe-column>
            
            <!-- 功能 -->
            <vxe-column width="80" align="center" fixed="right">
                <template #default="{ row }">
                    <el-link type="primary" :underline="false" @click="$refs.prUpdateProduct.Open(row.id)" :style="{ marginRight: '10px' }">编辑</el-link>
                    <el-link type="danger"  :underline="false" @click="Delete(row.id)">移除</el-link>
                </template>
            </vxe-column>
        </vxe-table>

        <!-- 采购信息 -->
        <el-divider content-position="left"><b>订货单</b></el-divider>
        <div :style="{ display: 'flex' }">
            <el-button type="primary" size="mini" @click="$refs.productLogCreate.Open({ product_order_id: id, state: 1 })">添加产品</el-button>
            <files-prints-xls-dropdown model="ims_product_order" :id="id" :style="{ marginLeft: '5px' }" />
        </div>

        <vxe-table border ref="xTableC" :data="table.filter(rps => rps.state)" :loading="loading" :style="{ marginTop: '10px' }">
            <!-- 产品信息 -->
            <vxe-column min-width="120" title="供应商">
                <template #default="{ row }">
                    <span :style="{ color: '#0027ff', cursor: 'pointer' }" @click="$refs.supplierDetails.Open(row.product.supplier?.id)">{{ row.product.supplier?.name }}</span>
                </template>
            </vxe-column>
            <vxe-column min-width="120" title="产品名称">
                <template #default="{ row }">
                    <span :style="{ color: '#0027ff', cursor: 'pointer' }" @click="$refs.productDetails.Open(row.product.id)">{{ row.product.name }}</span>
                </template>
            </vxe-column>
            <vxe-column min-width="100" field="product.type.name" title="产品类别"></vxe-column>
            <vxe-column min-width="100" field="product.brand" title="产品品牌"></vxe-column>
            <vxe-column min-width="100" title="规格参数">
                <template #default="{ row }">
                    {{ row.product.parameter?.toString() }}
                </template>
            </vxe-column>
            <vxe-column min-width="80" field="num" title="采购数量"></vxe-column>
            <vxe-column min-width="100" field="product.unit" title="计量单位"></vxe-column>
            <vxe-column min-width="80" field="pay_date" title="付款日期"></vxe-column>
            <vxe-column min-width="80" field="pay_type.name" title="付款方式"></vxe-column>
            <vxe-column min-width="80" field="taxation_rate" title="发票税率"></vxe-column>
            <vxe-column min-width="80" field="arrive_date" title="到货周期"></vxe-column>
            <vxe-column min-width="90" field="money" title="采购单价/￥"></vxe-column>
            <vxe-column min-width="70" field="money_total" title="合计/￥"></vxe-column>
            <vxe-column min-width="100" field="product.maintenance" title="保修期"></vxe-column>
            <vxe-column width="220" field="describe" title="备注说明"></vxe-column>
            <vxe-column min-width="70" field="discount" title="折扣/%"></vxe-column>
            <vxe-column min-width="100" field="discount_document" title="整单折扣/%"></vxe-column>
            <vxe-column min-width="100" field="money_discount" title="整单折后价/￥"></vxe-column>
            
            <!-- 功能 -->
            <vxe-column width="80" align="center" fixed="right">
                <template #default="{ row }">
                    <el-link type="primary" :underline="false" @click="$refs.productLogUpdate.Open(row.id)" :style="{ marginRight: '10px' }">编辑</el-link>
                    <el-link type="danger"  :underline="false" @click="Delete(row.id)">移除</el-link>
                </template>
            </vxe-column>
        </vxe-table>

        <!-- 组件 -->
        <ims-pr-create-product ref="prCreateProduct" @onChange="TableSearch" />
        <ims-pr-update-product ref="prUpdateProduct" @onChange="TableSearch" />

        <ims-product-drawer ref="productDetails" @onChange="TableSearch" />
        <ims-supplier-drawer ref="supplierDetails" @onChange="TableSearch" />
        <ims-product-log-create ref="productLogCreate" @onChange="TableSearch" />
        <ims-product-log-update ref="productLogUpdate" @onChange="TableSearch" />
    </div>
</template>

<script>
export default {
    props: {
        id: {
            type: Number,
            default: null
        },
    },

    data() {
        return {
            loading: false,
            table: [],
        }
    },

    watch: {
        id: {
            handler(val) {
                this.TableSearch()
            },
            immediate: true
        }
    },
    
    methods: {
        TableSearch() {
            this.$http.post('/9api/ims/product-order-log/search-all', { search: { product_order_id: this.id }, append: ['product', 'pay_type'] }).then(rps => {
                this.table = rps.data
                this.$emit('onChange')
            })
        },

        Delete(id) {
            this.$http.post('/9api/ims/product-order-log/delete', { id: id }).then(rps => {
                this.TableSearch()
            })
        },

        UpdateState() {
            this.$http.post('/9api/ims/product-order-log/update-state', { product_order_id: this.id }).then(rps => {
                this.TableSearch()
            })
        },
    }
}
</script>