<!---------------------------------------------------------------------------- 
 | 项目管理 - 项目模板创建
 |----------------------------------------------------------------------------
 | 方法：请注意大小写,参数描述见具体方法
 | $refs[ref_name].Open(id, name) 手动打开窗口,
 | $refs[ref_name].Close() 手动关闭窗口
 |----------------------------------------------------------------------------
 | 回调
 | @onSave = fn (Object) => {} 成功后返任务信息
 |
 ---------------------------------------------------------------------------->

 <template>
      <el-dialog class="w-el-dialog" :visible.sync="visible" append-to-body title="请填写模板名称" :close-on-click-modal="false" :close-on-press-escape="false" :before-close="Close">
            
            <el-form :model="form" label-width="120px" size="mini">

                <!-- 项目类型名称 -->
                <el-form-item label="模板名称">
                    <el-input v-model="form.name" placeholder="请输入模板名称" clearable></el-input>
                </el-form-item>

                <el-form-item label="状态">
                    <el-radio-group v-model="form.top">
                        <el-radio :label="0">不置顶</el-radio>
                        <el-radio :label="1">置顶</el-radio>
                    </el-radio-group>
                </el-form-item>

            </el-form>

            <span slot="footer">
                <el-button size="mini" @click="Close()">取 消</el-button>
                <el-button size="mini" type="primary" @click="TemplateEdit()" :loading="loading">确 定</el-button>
            </span>
      </el-dialog>
 </template>

 <script>
 export default {
    data () {
        return {
            // 加载状态
            visible: false,
            loading: false,
            loadingText: null,

            form: {
                id: null,
                name: null,
                top: 0
            }
        }
    },

    methods: {

        /**
        |--------------------------------------------------
        | 窗口 - 打开
        |--------------------------------------------------
        |
        */

        Open (row) {
            this.form.id = row.id
            this.form.name = row.name
            this.form.top = row.top
            this.visible = true
        },


        /**
        |--------------------------------------------------
        | 窗口 - 关闭
        |--------------------------------------------------
        |
        */

        Close() {
            this.loading = false
            this.visible = false
        },


        /**
        |--------------------------------------------------
        | 修改模板信息
        |--------------------------------------------------
        |
        */

        TemplateEdit() {
            this.loading = true
            this.$http.post('/9api/projects-template/edit', this.form).then(rps => {
                if (rps.data.state == 'success') {
                    this.$emit('onSave', rps.data.body)
                    this.Close()
                }
                this.loading = false
            })
        }
    }  
 }
 </script>