<!---------------------------------------------------------------------------- 
 | CRM - 编辑客户联系人 
 |----------------------------------------------------------------------------
 | 方法：请注意大小写,参数描述见具体方法
 | $refs[ref_name].Open() 手动打开窗口,
 |----------------------------------------------------------------------------
 | 回调
 | @onChange = fn (Object) => {} 成功后返信息
 |
 ---------------------------------------------------------------------------->

 <template>
    <el-dialog :visible.sync="visible" width="350px" append-to-body title="转交工单" :close-on-click-modal="false" :close-on-press-escape="false" :before-close="Close">

        <!-- 表单 -->
        <el-form :model="form" size="mini" label-position="top" v-loading="loading">
            
            <!-- 收单人 -->
            <el-form-item label="收单人" :rules="[{ required: true, message: '请选择收单人' }]" prop="user_receive_id">
                <users-select v-model="form.user_receive_id" />
            </el-form-item>

            <!-- 协同人 -->
            <el-form-item label="协同人">
                <users-select v-model="form.user_assist_id" multiple />
            </el-form-item>

        </el-form>
        

        <span slot="footer">
            <el-button size="mini" @click="Close()">取 消</el-button>
            <el-button size="mini" type="primary" @click="SubmitForm()" :loading="loading">确 定</el-button>
        </span>
    </el-dialog>
</template>

<script>

export default {
    data () {
        return {
            // 加载状态
            visible: false,
            loading: false,

            formError: {},
            form: {
                id: null,
                user_receive_id: null,
                user_assist_id: null
            }
        }
    },

    methods: {

        /**
        |--------------------------------------------------
        | 窗口 - 打开
        |--------------------------------------------------
        |
        */
       
        Open (id) {
            this.visible = true
            this.form.id = id
        },


        /**
        |--------------------------------------------------
        | 窗口 - 关闭
        |--------------------------------------------------
        |
        */

        Close() {
            this.loading = false
            this.visible = false
        },

        /**
        |--------------------------------------------------
        | 提交表单
        |--------------------------------------------------
        |
        */

        SubmitForm() {
            // 开启加载状态
            this.loading = true
            this.formError = {}

            // 提交表单
            this.$http.post('/9api/pm/wo/update-relay', this.form).then(rps => {
                // 关闭加载状态
                this.loading = false
                this.$emit('onChange')
                this.Close()
            })
        }
    }  
}
</script>