<template>
    <div v-loading="$store.state.loading">
        <froala-views v-model="projects.describe" style="max-width: 960px; margin: auto;" />
    </div>
</template>
    
<script>
export default {
    props: ['projects_id'],

    data() {
        return {
            projects: {}
        }
    },

    watch: {
        projects_id: {
            handler (val) {
                if (val) {
                    this.Search()
                }
            },
            immediate: true
        }
    },

    methods: {
        Search() {
            this.$http.post('/9api/projects/first', { id: this.projects_id }).then(async rps => {
                this.projects = rps.data
            })
        }
    }
}
</script>
