<template>
    <div :class="['el-dialog-menu-nav-item', isActive ? 'is-active' : '' ]" @click="$emit('onClick')">
        <i :class="icon" v-if="icon"></i>
        <span>{{ title }}</span>
    </div>
</template>

<script>
export default {
    props: {
        icon: {
            type: String,
            default: null
        },
        title: {
            type: String,
            default: null
        },
        isActive: {
            type: Boolean,
            default: false
        }
    },
}
</script>