<template>
    <el-dialog :visible.sync="visible" class="w-el-dialog" append-to-body title="修改票据类型" :close-on-click-modal="false" :close-on-press-escape="false">

        <el-form :model="form" label-width="80px" size="mini">

            <!-- 票据类型 -->
            <el-form-item label="票据类型"  :rules="[{ required: true, message: '必填项' }]" :error="formError.name?.toString()" prop="name">
                <el-input v-model="form.name" clearable placeholder="请填写票据类型名称"></el-input>
            </el-form-item>

            <el-form-item label="税率 / %" :rules="[{ required: true, message: '必选项' }]" :error="formError.taxation_rate?.toString()" prop="taxation_rate">
                <el-input-number v-model="form.taxation_rate" :precision="2" class="w-width-auto"></el-input-number>
            </el-form-item>

            <el-form-item label="状态">
                <el-radio-group v-model="form.state">
                    <el-radio :label="0">不启用</el-radio>
                    <el-radio :label="1">启用</el-radio>
                </el-radio-group>
            </el-form-item>

        </el-form>

        <div slot="footer">
            <el-button size="small" @click="visible = false">取 消</el-button>
            <el-button size="small" type="primary" @click="FormSubmit()" :loading="loading">确 定</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    data () {
        return {
            visible: false,
            loading: false,

            formError: {},
            form: {
                id: null,
                name: null,
                taxation_rate: 0,
                state: 1
            }

        }
    },

    methods: {

        /**
        |--------------------------------------------------
        | 打开窗口
        |--------------------------------------------------
        |
        */

        Open(id) {
            this.visible = true
            this.loading = true

            this.$http.post('/9api/config-invoice-type/search-first', { search: { id: id } }).then((rps) => {
                this.form.id = rps.data.id
                this.form.name = rps.data.name
                this.form.taxation_rate = rps.data.taxation_rate
                this.form.state = rps.data.state
                this.loading = false
            })
        },

        /**
        |--------------------------------------------------
        | 提交表单
        |--------------------------------------------------
        |
        */

        FormSubmit() {

            this.loading = true

            this.$http.post('/9api/config-invoice-type/update', this.form).then((rps) => {

                // 提交失败
                if (rps.data.state == 'error') {
                    this.formError = rps.data.body
                }

                // 提交成功
                if (rps.data.state == 'success') {
                    this.$emit('onChange', rps.data.body)
                    this.visible = false
                }

                this.loading = false
            })
        },
    }
}
</script>