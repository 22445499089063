<!---------------------------------------------------------------------------- 
 | 参数：请注意大小写,参数描述见具体方法
 | Open(Object search) => 作用：打开窗口
 |----------------------------------------------------------------------------
 | 参数
 | :v-model     默认值------默认为 null
 | :width       默认值------默认为 100%
 | :margin      默认值------默认为 0px
 | :disabled    默认值------默认为 false
 | :showInput   默认值------默认为 true
 |----------------------------------------------------------------------------
 | 回调
 | @onChange = fn (data) => {} 返回表单内容
 ---------------------------------------------------------------------------->

 <template>
    <div>
        <el-input
            v-if="showInput"
            v-model="select.inputValue"
            @focus="Open"
            @clear="SelectClear"
            placeholder="线索名称"
            clearable
            :disabled="disabled"
            :style="{ width: width, margin: margin }"/>

        <el-dialog :visible.sync="dialogVisible" width="900px" append-to-body title="线索" :close-on-click-modal="false" :close-on-press-escape="false">
            
            <!-- toolbar -->
            <uw-grid-search gutter="0 10px" :column="3" :style="{ marginBottom: '10px' }">
                <template slot="full">
                    <el-input v-model="post.search.name" placeholder="线索名称" />
                </template>
                <template slot="tools">
                    <el-button type="primary" @click="$refs.leadsCreate.Open()">新建线索</el-button>
                </template>
                <el-form-item label="线索来源">
                    <crm-leads-source-select v-model="post.search.source_id" @onChange="TableSearch" />
                </el-form-item>
                <el-form-item label="客户级别">
                    <crm-customer-level-select v-model="post.search.level_id" @onChange="TableSearch" />
                </el-form-item>
                <el-form-item label="客户行业">
                    <crm-customer-type-select v-model="post.search.type_id" @onChange="TableSearch" />
                </el-form-item>
                <el-form-item label="客户负责人">
                    <users-select @onChange="TableSearch" v-model="post.search.user_admin_ids" multiple />
                </el-form-item>
                <el-form-item label="创建日期">
                    <el-date-picker @change="TableSearch" v-model="post.search.created_at" clearable type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :style="{ width: '100%' }"></el-date-picker>
                </el-form-item>
            </uw-grid-search>

            <!-- table -->
            <vxe-table ref="xTable" :data="table" :loading="$store.state.loading" @sort-change="TableSort" @cell-dblclick="TableCellDblclick" height="500px">

                <!-- 序号 -->
                <vxe-column width="60" type="seq" title="序号" fixed="left"></vxe-column>

                <!-- 客户名称 -->
                <vxe-column min-width="250" field="name" title="线索名称" fixed="left" sortable>
                    <template #default="{ row }">
                        <el-link type="primary" icon="el-icon-paperclip" @click="$refs.leadsDrawer.Open(row.id)">{{ row.name }}</el-link>
                    </template>
                </vxe-column>

                <!-- 线索来源 -->
                <vxe-column min-width="120" field="source" title="线索来源" sortable>
                    <template #default="{ row }">
                        <el-link :underline="false">{{ row.source?.map(rps => rps.name).toString() }}</el-link>
                    </template>
                </vxe-column>

                <!-- 客户级别 -->
                <vxe-column min-width="120" field="level" title="客户级别" sortable>
                    <template #default="{ row }">
                        <el-link :underline="false">{{ row.level?.name }}</el-link>
                    </template>
                </vxe-column>

                <!-- 客户行业 -->
                <vxe-column min-width="120" field="type" title="客户行业" sortable>
                    <template #default="{ row }">
                        <el-link :underline="false">{{ row.type?.name }}</el-link>
                    </template>
                </vxe-column>

                <!-- 联系方式 -->
                <vxe-column min-width="160" field="phone" title="手机"></vxe-column>
                <vxe-column min-width="160" field="phone_t" title="电话"></vxe-column>
                <vxe-column min-width="160" field="email" title="邮箱"></vxe-column>

                <!-- 所属员工 -->
                <vxe-column min-width="90" field="user_admin" title="负责人">
                    <template #default="{ row }">
                        <el-link :underline="false" icon="el-icon-user" v-if="row.user_admin">{{ row.user_admin?.name }}</el-link>
                        <el-link :underline="false" icon="el-icon-user" type="info" v-if="!row.user">未分配</el-link>
                    </template>
                </vxe-column>

                <!-- 所在区域 -->
                <vxe-column min-width="160" field="country" title="所在区域">
                    <template #default="{ row }">
                        {{  row.country ? row.country.map(rps => rps.name).toString() : '' }}
                    </template>
                </vxe-column>

                <!-- 详细地址 -->
                <vxe-column min-width="250" field="address" title="详细地址">
                    <template #default="{ row }">
                        <el-link :underline="false" icon="el-icon-place" v-if="row.address">{{ row.address }}</el-link>
                        <el-link :underline="false" icon="el-icon-place" type="info" v-if="!row.address">未知</el-link>
                    </template>
                </vxe-column>

            </vxe-table>

            <!-- pager -->
            <vxe-pager 
                :style="{ marginTop: '10px' }"
                :total="post.page.total"
                :page-size.sync="post.page.size"
                :current-page.sync="post.page.current"
                @page-change="TableSearch()">
            </vxe-pager>

        </el-dialog>

        <!-- 组件 -->
        <crm-leads-create ref="leadsCreate" @onChange="TableSearch()" />
        <crm-leads-drawer ref="leadsDrawer" @onChange="TableSearch()" />

    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: [String, Array, Number],
            default: null
        },

        width: {
            type: String,
            default: '100%'
        },

        margin: {
            type: String,
            default: '0px'
        },

        disabled: {
            type: Boolean,
            default: false
        },

        showInput: {
            type: Boolean,
            default: true
        }
    },

    data () {
        return {
            dialogVisible: false,

            // 查询结果
            table: [],

            // 查询链接
            url: {
                default: '/9api/crm/leads/search',
                first: '/9api/crm/leads/first'
            },

            // 查询条件
            post: {
                page: {
                    total: 0,
                    size: 50,
                    current: 1,
                },
                search: {
                    name: null,
                    source_id: null,
                    level_id: null,
                    type_id: null,
                    user_admin_ids: null,
                    created_at: []
                },
                append: ['user_admin', 'type', 'source', 'country', 'level'],
                hidden: [],
                order: {
                    method: 'desc',
                    column: 'id'
                },
            },

            // 选择结果
            select: {
                data: {},
                inputValue: null
            }
        }
    },

    watch: {
        value: {
            handler(val) {
                val ? this.SelectSearch() : this.SelectClear()
            },
            immediate: true
        }
    },

    methods: {

        // 窗口：打开
        Open(search)
        {
            // 初始化检索条件
            if (search)
            {
                this.post.search = { ...this.post.search, ...search }
            }

            this.dialogVisible = true
            this.TableSearch()
        },

        // 数据：查询
        TableSearch()
        {
            this.$http.post(this.url.default, this.post).then((rps) => {
                // 更新数据
                this.post.page.total = rps.data.total
                this.table = rps.data.body

                // 页码溢出
                if (this.table.length == 0 && this.post.page.current != 1)
                {
                    this.post.page.current = 1
                    this.TableSearch()
                }
            })
        },

        // 表格：排序
        TableSort(column)
        {
            this.post.order.method = column.order ? column.order : this.post.order.method
            this.post.order.column = column.field
            this.TableSearch()
        },

        // 表格：双击事件
        TableCellDblclick({ row })
        {
            this.select.inputValue = row.name
            this.select.data = row
            this.$emit('input', row.id)
            this.$emit('onChange', row)
            this.dialogVisible = false
        },

        // 选择：查询
        SelectSearch()
        {
            this.$http.post(this.url.first, { id: this.value }).then(rps => {
                this.select.inputValue = rps.data.name
                this.select.data = rps.data
                this.$emit('input', rps.data.id)
                this.$emit('onChange', rps.data)
            })
        },

        // 选择：清除
        SelectClear()
        {
            this.select.inputValue = null
            this.select.data = {}
            this.$emit('input', null)
            this.$emit('onChange', null)
        },
    }
}
</script>