<template>
    <div :class="['uw-users-avatar', type ]">
        <img class="uw-users-avatar-src" v-if="src" :src="src" >
        <div class="uw-users-avatar-text" v-else >{{ name[0] }}</div>
        <span >{{ name }}</span>
    </div>
</template>

<script>
export default {
    props: {
        src: {
            type: String,
            default: null
        },
        name: {
            type: String,
            default: null
        },
        type: {
            type: String,
            default: 'primary'
        },
    }
}
</script>

<style lang="less" scoped>
    .uw-users-avatar {
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;

        .uw-users-avatar-src,
        .uw-users-avatar-text {
            border-radius: 50%;
            width: 40px;
            height: 40px;
            background-color: #3296fa;
            color: #FFF;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 5px;
        }
        span {
            font-size: 12px;
        }

        &.primary {
            .uw-users-avatar-src { background-color: #3296fa; }
            .uw-users-avatar-text { background-color: #3296fa; }
            span { color: #3296fa; }
        }

        &.info {
            .uw-users-avatar-src { background-color: #e0e0e0; }
            .uw-users-avatar-text { background-color: #e0e0e0; }
            span { color: #e0e0e0; }
        }
    }
</style>