<!---------------------------------------------------------------------------- 
 | 项目管理 - 批量修改参与人员
 |----------------------------------------------------------------------------
 | 方法：请注意大小写,参数描述见具体方法
 | $refs[ref_name].Open(project_id, task_ids) 手动打开窗口,
 | $refs[ref_name].Close() 手动关闭窗口
 |----------------------------------------------------------------------------
 | 回调
 | @onSave = fn () => {} 成功时回调
 |
 ---------------------------------------------------------------------------->

<template>
    <el-drawer class="w-el-drawer" :visible.sync="visible" :size="960" :with-header="false" direction="rtl" :before-close="Close" append-to-body>

        <!-- header -->
        <div class="w-el-drawer-header">
            <i class="el-icon-tickets"></i> 批量修改参与成员
        </div>
        
        <!-- body -->
        <div class="w-el-drawer-body" v-loading.lock="loading">
            <el-form :model="form" label-width="120px" size="mini">

                <el-divider content-position="left">修改描述</el-divider>

                <el-link><b>A：</b>您正在尝试修改<b> " {{ ids.length }} " </b>项任务的参与人员。</el-link><br>
                <el-link><b>B：</b>如果您勾选了<b>不是您创建的任务</b>，可能会导致部分任务修改失败。</el-link><br>

                <el-divider content-position="left">批量修改参与成员</el-divider>

                <!-- 指派给 -->
                <el-form-item label="指派给" :rules="[{ required: true, message: '请选择转交对象' }]" :error="formError.now_assign?.toString()" prop="now_assign">
                    <inputTeam v-model="form.now_assign" :project_id="project_id" clearable filterable width="350px"></inputTeam>
                </el-form-item>

                <!-- 参与成员 / 协助成员 -->
                <el-form-item label="参与成员 / 协助成员">
                    <inputTeam v-model="form.assign" :project_id="project_id" clearable filterable multiple width="350px"></inputTeam>
                </el-form-item>
            </el-form>
        </div>

        <!-- footer -->
        <div class="w-el-drawer-footer">
            <el-button size="mini" @click="Close()">取消</el-button>
            <el-button size="mini" type="primary" @click="SubmitForm()" :loading="loading">确 定</el-button>
        </div>

    </el-drawer>
</template>

<script>
import inputTeam from '@/views/projects/inputProjectsTeam'

export default {
    components: {
        inputTeam,
    },

    data() {
        return {
            // 窗口
            visible: false,
            loading: false,

            // 任务信息
            ids: [],
            project_id: null,

            // 表单信息
            formError: {},
            form: {
                assign: [],
                now_assign: null
            },
        }
    },

    methods: {

        /**
        |--------------------------------------------------
        | 窗口 - 打开
        |--------------------------------------------------
        |
        */

        Open(project_id, task_ids) {
            this.visible = true
            this.project_id = project_id
            this.ids = task_ids
        },

        /**
        |--------------------------------------------------
        | 窗口 - 关闭
        |--------------------------------------------------
        |
        */

        Close() {

            this.formError = []

            this.form.assign = []
            this.form.now_assign = null
            
            this.loading = false
            this.visible = false
        },


        /**
        |--------------------------------------------------
        | 批量修改参与人员
        |--------------------------------------------------
        |
        */

        SubmitForm() {

            this.$http.post('/9api/projects-task/edit-assign', { ids: this.ids, form: this.form }).then(rps => {

                // 提交失败
                if (rps.data.state == 'error') {
                    this.formError = rps.data.body
                }

                // 提交成功
                if (rps.data.state == 'success') {
                    this.$emit('onSave')
                    this.Close()
                }
            })
        }
    }
}
</script>