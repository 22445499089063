<!---------------------------------------------------------------------------- 
 | 客户管理 - 添加客户
 |----------------------------------------------------------------------------
 | 方法：请注意大小写,参数描述见具体方法
 | $refs[ref_name].Open() 手动打开窗口,
 | $refs[ref_name].Close() 手动关闭窗口
 |----------------------------------------------------------------------------
 | 回调
 | @onChange = fn (Object) => {} 成功后返信息
 |
 ---------------------------------------------------------------------------->

 <template>
    <el-dialog class="w-el-dialog" :visible.sync="visible" width="800px" append-to-body title="添加客户" :close-on-click-modal="false" :close-on-press-escape="false" :before-close="Close">
        
        <!-- 表单 -->
        <el-form :model="form" size="mini" label-position="top" v-loading="loading">
            
            <!-- 基础信息 -->
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="客户名称" :rules="[{ required: true, message: '必填项' }]" :error="formError.name?.toString()" prop="name">
                        <el-input v-model="form.name" @input="form.invoice_unit_name = form.name" @blur="BaiduMap()" placeholder="请填写客户名称" clearable />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="所属员工" :rules="[{ required: true, message: '必填项' }]" :error="formError.user_id?.toString()" prop="user_id">
                        <users-select v-model="form.user_id" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="客户种类" :rules="[{ required: true, message: '必选项' }]" :error="formError.type_id?.toString()" prop="type_id">
                        <crm-customer-type-select v-model="form.type_id" placeholder="请选择客户种类" clearable />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="工作电话">
                        <el-input v-model="form.work_phone" @input="form.invoice_unit_phone = form.work_phone" placeholder="请填写客户工作电话" clearable />
                    </el-form-item>
                </el-col>
            </el-row>

            <!-- 位置信息 -->
            <el-divider content-position="left">客户地址（选填）</el-divider>
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="国家或地区">
                        <config-country-select v-model="form.country" clearable />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="省份">
                        <config-country-select-province v-model="form.country_province" :country="form.country" clearable />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="城市">
                        <config-country-select-city v-model="form.country_province_city" :province="form.country_province" clearable />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="详细地址">
                        <el-input v-model="form.address" @input="form.invoice_unit_address = form.address" clearable />
                    </el-form-item>
                </el-col>
            </el-row>

            <!-- 开票信息 -->
            <el-divider content-position="left">开票信息（选填）</el-divider>
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="开票单位名称">
                        <el-input v-model="form.invoice_unit_name" clearable />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="开票单位税号">
                        <el-input v-model="form.invoice_unit_number" clearable />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="开票单位地址">
                        <el-input v-model="form.invoice_unit_address" clearable />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="开票单位电话">
                        <el-input v-model="form.invoice_unit_phone" clearable />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="开票单位银行名称">
                        <el-input v-model="form.invoice_unit_bank_name" clearable />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="开票单位银行卡号">
                        <el-input v-model="form.invoice_unit_bank_number" clearable />
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        

        <span slot="footer">
            <el-button size="mini" @click="Close()">取 消</el-button>
            <el-button size="mini" type="primary" @click="SubmitForm()" :loading="loading">确 定</el-button>
        </span>
    </el-dialog>
</template>

<script>
export default {
    data () {
        return {
            // 加载状态
            visible: false,
            loading: false,

            formError: {},
            form: {
                name: null,
                user_id: null,
                type_id: null,
                work_phone: null,
                country: null,
                country_province: null,
                country_province_city: null,
                address: null,
                invoice_unit_name: null,
                invoice_unit_number: null,
                invoice_unit_address: null,
                invoice_unit_phone: null,
                invoice_unit_bank_name: null,
                invoice_unit_bank_number: null,
            }
        }
    },

    methods: {

        /**
        |--------------------------------------------------
        | 窗口 - 打开
        |--------------------------------------------------
        |
        */

        Open () {
            this.visible = true
        },


        /**
        |--------------------------------------------------
        | 窗口 - 关闭
        |--------------------------------------------------
        |
        */

        Close() {
            this.loading = false
            this.visible = false
        },


        /**
        |--------------------------------------------------
        | 提交表单
        |--------------------------------------------------
        |
        */

        SubmitForm() {
            // 开启加载状态
            this.loading = true
            this.formError = {}

            // 提交表单
            this.$http.post('/9api/customer/create', this.form).then(rps => {
                // 关闭加载状态
                this.loading = false

                // 提交失败
                if (rps.data.state == 'error') {
                    this.formError = rps.data.body
                }
                
                // 提交成功
                if (rps.data.state == 'success') {
                    this.$emit('onChange', rps.data.body)
                    this.Close()
                }
            })
        },

        /**
        |--------------------------------------------------
        | 地理定位
        |--------------------------------------------------
        |
        */

        BaiduMap() {
            this.$http.post('/9api/baidu-map/address-search', { value: this.form.name }).then(rps => {
                this.form.country = '中国'
                this.form.country_province = rps.data.province
                this.form.country_province_city = rps.data.city
                this.form.address = rps.data.address
            })
        }
    }  
}
</script>