<!---------------------------------------------------------------------------- 
 | 虚拟滚动
 |----------------------------------------------------------------------------
 | 参数说明
 | show-slider => Boolean => default: true => 是否显示滚动条
 ---------------------------------------------------------------------------->

<template>
    <div class="uw-scrollbar" ref="scrollbar">
        <div class="uw-scrollbar-content" ref="content" :style="{ transform: contentTransform }">
            <slot></slot>
        </div>

        <!-- Y滚动条 -->
        <div class="uw-scrollbar-y-slider" ref="ySlider">
            <div class="uw-scrollbar-y-handle" ref="yHandle" :style="{ height: yHandleHeight + 'px', transform: yHandleTransform }"></div>
        </div>

        <!-- x滚动条 -->
        <div class="uw-scrollbar-x-slider" ref="xSlider">
            <div class="uw-scrollbar-x-handle" ref="xHandle" :style="{ width: xHandleWidth + 'px', transform: xHandleTransform }"></div>
        </div>
    </div>
</template>

<script>
export default {
    
    props: {
        showSlider: {
            type: Boolean,
            default: true
        },
    },

    data () {
        return {
            contentOffsetX: 0,
            contentOffsetY: 0,

            yHandleOffset: 0,
            yHandleHeight: 50,
            
            xHandleOffset: 0,
            xHandleWidth: 10,

            yHandleDown: false,
            xHandleDown: false,
            scrollbarOver: false
        }
    },

    // 计算属性 - 用计算虚拟滚动条偏移与显示
    computed: {
        contentTransform() {
            return `translate3d(${this.contentOffsetX}px, ${this.contentOffsetY}px, 0px)`
        },
        yHandleTransform() {
            return `translateY(${this.yHandleOffset}px)`
        },
        xHandleTransform() {
            return `translateX(${this.xHandleOffset}px)`
        },
        visible () {
            return this.yHandleDown || this.xHandleDown || this.scrollbarOver
        }
    },

    // 监听事件
    watch: {
        visible () {
            this.scrollbarVisible()
        }
    },

    // 此组件实例化后
    created () {
        this.$nextTick(() => {
            this.saveHtmlElementById()
        })
    },

    // 此组件销毁之前
    beforeDestroy() {
        this.unbindScrollbarEvent()
    },

    methods: {

        // 获取DOM元素
        saveHtmlElementById() {
            const { scrollbar, content, ySlider, yHandle, xSlider, xHandle } = this.$refs
            this.$element = {
                $scrollbar: scrollbar,
                $content: content,
                $ySlider: ySlider,
                $yHandle: yHandle,
                $xSlider: xSlider,
                $xHandle: xHandle,
            }
            this.bindContentEvent()
            this.bindHandleEvent()
        },

        // 鼠标滚动事件
        bindContentEvent() {
            const { $scrollbar, $content, $yHandle, $xHandle } = this.$element

            // 鼠标事件
            $scrollbar.onmouseover = (e) => { this.scrollbarOver = true }
            $scrollbar.onmouseout = (e) => { this.scrollbarOver = false }

            // 滚轮事件
            const eventWheel = (e) => {

                const yContentSpace = $content.scrollHeight - $content.offsetHeight;
                const xContentSpace = $content.scrollWidth - $content.offsetWidth;

                $yHandle.style.transition = '500ms'
                $xHandle.style.transition = '500ms'
                $content.style.transition = '500ms'

                if (yContentSpace > 0) {
                    this.contentOffsetY += e.wheelDeltaY
                    this.contentOffsetY = this.contentOffsetY < 0 ? Math.max(this.contentOffsetY, -yContentSpace) : 0
                    this.yHandleOffset = this.transferOffset('handleY')
                }
                if (yContentSpace == 0 && xContentSpace > 0) {
                    this.contentOffsetX += e.wheelDeltaY
                    this.contentOffsetX = this.contentOffsetX < 0 ? Math.max(this.contentOffsetX, -xContentSpace) : 0
                    this.xHandleOffset = this.transferOffset('handleX')
                }
            }

            // 添加监听事件
            $scrollbar.addEventListener('wheel', eventWheel, { passive: true })

            // 移除监听事件
            this.unbindScrollbarEvent = () => {
                $scrollbar.removeEventListener('wheel', eventWheel, { passive: true })
            }
        },

        // 手柄移动事件
        bindHandleEvent() {
            const { $scrollbar, $content, $ySlider, $yHandle, $xSlider, $xHandle } = this.$element

            // Y轴手柄拖拽
            $yHandle.onmousedown = (e) => {
                $yHandle.style.transition = 'none'
                $xHandle.style.transition = 'none'
                $content.style.transition = 'none'
                $scrollbar.style.userSelect = 'none'
                
                this.yHandleDown =true

                const yHandleSpace = $ySlider.offsetHeight - this.yHandleHeight
                const startY = e.clientY
                const startTop = this.yHandleOffset 
                const _this = this

                window.onmousemove = (e) => {
                    const deltaY = e.clientY - startY
                    this.yHandleOffset = startTop + deltaY < 0 ? 0 : Math.min(startTop + deltaY, yHandleSpace);
                    this.contentOffsetY = this.transferOffset('contentY')
                }
                window.onmouseup = function() {
                    window.onmousemove = null
                    window.onmouseup = null
                    _this.yHandleDown = false
                    $scrollbar.style.userSelect = 'auto'
                }
            }

            // X轴手柄拖拽
            $xHandle.onmousedown = (e) => {
                $yHandle.style.transition = 'none'
                $xHandle.style.transition = 'none'
                $content.style.transition = 'none'
                $scrollbar.style.userSelect = 'none'

                this.xHandleDown =true

                const xHandleSpace = $xSlider.offsetWidth - this.xHandleWidth
                const startX = e.clientX
                const startLeft = this.xHandleOffset 
                const _this = this

                window.onmousemove = (e) => {
                    const deltaX = e.clientX - startX
                    this.xHandleOffset = startLeft + deltaX < 0 ? 0 : Math.min(startLeft + deltaX, xHandleSpace);
                    this.contentOffsetX = this.transferOffset('contentX')
                }
                window.onmouseup = function() {
                    window.onmousemove = null
                    window.onmouseup = null
                    _this.xHandleDown = false
                    $scrollbar.style.userSelect = 'auto'
                }
            }
        },
        
        // 偏移换算
        transferOffset (to) {
            const { $content, $ySlider, $xSlider } = this.$element
            const contentSpaceHeight = $content.scrollHeight - $content.offsetHeight
            const contentSpaceWidth = $content.scrollWidth - $content.offsetWidth

            const yHandleSpace = $ySlider.offsetHeight - this.yHandleHeight
            const xHandleSpace = $xSlider.offsetWidth - this.xHandleWidth

            const yAssistRatio = yHandleSpace / contentSpaceHeight
            const xAssistRatio = xHandleSpace / contentSpaceWidth

            const _this = this

            const computeOffset = {
                handleY () { return -_this.contentOffsetY * yAssistRatio },
                handleX () { return -_this.contentOffsetX * xAssistRatio },
                contentY () { return -_this.yHandleOffset / yAssistRatio },
                contentX () { return -_this.xHandleOffset / xAssistRatio }
            }
            return computeOffset[to]()
        },

        // 滚动显示与隐藏
        scrollbarVisible () {
            const { $scrollbar, $content, $ySlider, $xSlider } = this.$element

            if ((this.yHandleDown || this.xHandleDown || this.scrollbarOver) && this.showSlider) {
                if ($content.scrollHeight > $content.offsetHeight) {
                    $ySlider.style.display = 'block'
                    this.yHandleHeight = $ySlider.offsetHeight / ( $content.scrollHeight / $ySlider.offsetHeight )
                }
                if ($content.scrollWidth > $content.offsetWidth) {
                    $xSlider.style.display = 'block'
                    this.xHandleWidth = $xSlider.offsetWidth / ( $content.scrollWidth / $xSlider.offsetWidth )
                }
            } else {
                $ySlider.style.display = 'none'
                $xSlider.style.display = 'none'
            }
        },
    },
}
</script>

<style lang="less" scoped>
    .uw-scrollbar {
        height: 100%;
        width: 100%;
        overflow: hidden;
        position: relative;

        .uw-scrollbar-content {
            width: 100%;
            height: 100%;
            transition: 500ms;
        }

        .uw-scrollbar-y-slider {
            position: absolute;
            top: 0;
            right: 0;
            width: 10px;
            height: 100%;
            display: none;

            .uw-scrollbar-y-handle {
                cursor: pointer;
                box-sizing: border-box;
                border-right: 4px solid #03a9f4;
                height: 0px;
            }
        }

        .uw-scrollbar-x-slider {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 10px;
            display: none;

            .uw-scrollbar-x-handle {
                cursor: pointer;
                box-sizing: border-box;
                border-bottom: 4px solid #03a9f4;
                height: 100%;
                width: 0px;
            }
        }
    }
</style>