<!---------------------------------------------------------------------------- 
 | 项目管理 - 项目模板
 |----------------------------------------------------------------------------
 | 方法：请注意大小写,参数描述见具体方法
 | $refs[ref_name].Open() 手动打开窗口,
 | $refs[ref_name].Close() 手动关闭窗口
 |----------------------------------------------------------------------------
 | 回调
 | @onSelect = fn (Object) => {} 返回模板的具体任务 
 |
 ---------------------------------------------------------------------------->

<template>
    <el-drawer class="w-el-drawer" :visible.sync="visible" :before-close="Close"  size="50%" :with-header="false" direction="rtl" append-to-body>
        <!-- header -->
        <div class="w-el-drawer-header">
            <i class="el-icon-tickets"></i> 项目模板
        </div>
        
        <div class="w-el-drawer-body" v-loading.lock="loading" :element-loading-text="loadingText">

            <!-- 我的模板 -->
            <el-divider content-position="left">我的模板</el-divider>
            <vxe-table :data="template.my" size="mini" border="none" stripe :show-header="false" :row-config="{isCurrent: true, isHover: true}">

                <!-- 头像 -->
                <vxe-column width="60">
                    <template #default="{ row }">
                        <el-tooltip effect="dark" :content="row.user.name" placement="top-start">
                            <el-avatar :size="26" style="margin-top: 5px" v-if="row.user.avatar" :src="row.user.avatar" />
                            <el-avatar :size="26" style="margin-top: 5px" v-else> {{ row.user.name[0] }} </el-avatar>
                        </el-tooltip>
                    </template>
                </vxe-column>

                <!-- 名称 -->
                <vxe-column field="name">
                    <template #default="{ row }">
                        <el-tag type="danger" size="mini" v-if="row.top">已置顶</el-tag>
                        <el-link type="primary" icon="el-icon-edit" @click="$refs.templateUpdate.Open(row)">{{ row.name }}</el-link>
                    </template>
                </vxe-column>

                <!-- 操作 -->
                <vxe-column align="right" fixed="right" width="120">
                    <template #default="{ row }">
                        <el-link type="primary" @click="TemplateSelect(row.id)">选择</el-link>
                        <el-link type="primary" style="margin-left: 5px" @click="$refs.templateChange.Open(row.id)">编辑</el-link>
                        
                        <!-- 移除确认 -->
                        <el-popconfirm title="确认要删除这个模板？" icon="el-icon-info" icon-color="red" @confirm="TemplateDelete(row.id)">
                            <el-link type="danger" slot="reference" style="margin-left: 5px">删除</el-link>
                        </el-popconfirm>
                    </template>
                </vxe-column>
            </vxe-table>

            <!-- 同事的模板 -->
            <el-divider content-position="left">同事的模板</el-divider>
            <vxe-table :data="template.other" size="mini" border="none" stripe :show-header="false"  :row-config="{isCurrent: true, isHover: true}">

                <!-- 头像 -->
                <vxe-column width="60">
                    <template #default="{ row }">
                        <el-tooltip effect="dark" :content="row.user.name" placement="top-start">
                            <el-avatar :size="26" style="margin-top: 5px" v-if="row.user.avatar" :src="row.user.avatar" />
                            <el-avatar :size="26" style="margin-top: 5px" v-else> {{ row.user.name[0] }} </el-avatar>
                        </el-tooltip>
                    </template>
                </vxe-column>

                <!-- 名称 -->
                <vxe-column field="name"></vxe-column>

                <!-- 操作 -->
                <vxe-column align="right" fixed="right" width="120">
                    <template #default="{ row }">
                        <el-link type="primary" @click="TemplateSelect(row.id)">选择</el-link>
                    </template>
                </vxe-column>
            </vxe-table>
        </div>

        <!-- footer -->
        <div class="w-el-drawer-footer">
            <el-button size="medium" type="primary" @click="$refs.templateCreate.Open()">新的模板</el-button>
            <el-button size="medium" @click="Close()">关闭窗口</el-button>
        </div>

        <!-- 模板管理 -->
        <projects-template-change ref="templateChange" @onSave="TemplateList()" />
        <projects-template-update ref="templateUpdate" @onSave="TemplateList()" />
        <projects-template-create ref="templateCreate" @onSave="TemplateList()" />

    </el-drawer>
</template>

<script>
export default {
    data () {
        return {
            // 加载状态
            visible: false,
            loading: false,
            loadingText: null,

            template: {
                my: [],
                other: []
            }
        }
    }, 

    methods: {
        /**
        |--------------------------------------------------
        | 窗口 - 打开
        |--------------------------------------------------
        |
        */

        Open () {
            this.visible = true
            this.TemplateList()
        },


        /**
        |--------------------------------------------------
        | 窗口 - 关闭
        |--------------------------------------------------
        |
        */

        Close() {
            this.loading = false
            this.visible = false
        },

        /**
        |--------------------------------------------------
        | 模板 - 列表
        |--------------------------------------------------
        |
        */

        TemplateList() {
            this.loading = true
            this.$http.post('/9api/projects-template/list').then(rps => {
                this.template = rps.data
                this.loading = false
            })
        },

        /**
        |--------------------------------------------------
        | 模板 - 删除
        |--------------------------------------------------
        |
        */

        TemplateDelete(id) {
            this.$http.post('/9api/projects-template/delete', { id: id }).then(rps => {
                this.TemplateList()
            })
        },

        /**
        |--------------------------------------------------
        | 模板 - 选择
        |--------------------------------------------------
        |
        */

        TemplateSelect(template_id) {
            this.$http.post('/9api/projects-template/task-search', { template_id: template_id }).then(rps => {
                this.$emit('onSelect', rps.data)
                this.Close()
            })
        }
    }
}
</script>