<template>
    <el-dialog :visible.sync="visible" width="800px" title="新的产品" append-to-body destroy-on-close @close="close()">

        <!-- 表单 -->
        <el-form :model="form" label-width="120px" size="mini" label-position="top">

            <!-- 产品名称 / 供应商 -->
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="产品名称" :rules="[{ required: true, message: '必填项' }]" :error="formError.name?.toString()" prop="name">
                        <el-input v-model="form.name"  clearable />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="供应商" :rules="[{ required: true, message: '必选项' }]" :error="formError.supplier_id?.toString()" prop="supplier_id">
                        <ims-supplier-select v-model="form.supplier_id" />
                    </el-form-item>
                </el-col>
            </el-row>

            

            <!-- 类别父级 / 计量单位 -->
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="计量单位" :rules="[{ required: true, message: '必选项' }]" :error="formError.unit?.toString()" prop="unit">
                        <el-select v-model="form.unit" placeholder="请选择" :style="{ width: '100%' }">
                            <el-option label="次" value="次"></el-option>
                            <el-option label="个" value="个"></el-option>
                            <el-option label="块" value="块"></el-option>
                            <el-option label="只" value="只"></el-option>
                            <el-option label="把" value="把"></el-option>
                            <el-option label="枚" value="枚"></el-option>
                            <el-option label="瓶" value="瓶"></el-option>
                            <el-option label="盒" value="盒"></el-option>
                            <el-option label="台" value="台"></el-option>
                            <el-option label="吨" value="吨"></el-option>
                            <el-option label="箱" value="箱"></el-option>
                            <el-option label="米" value="米"></el-option>
                            <el-option label="千克" value="千克"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="产品类别" :rules="[{ required: true, message: '必选项' }]" :error="formError.type_id?.toString()" prop="type_id">
                        <ims-product-type-select v-model="form.type_id" />
                    </el-form-item>
                </el-col>
            </el-row>

            <!-- 产品品牌 / 规格参数 -->
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="产品品牌">
                        <el-input v-model="form.brand" clearable />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="规格参数">
                        <el-select :style="{ width: '100%' }" v-model="form.parameter" multiple filterable allow-create default-first-option placeholder="输入描述后请按'ENTER'键确认'"></el-select>
                    </el-form-item>
                </el-col>
            </el-row>

            <!-- 维保信息 / 备注说明 -->
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="维保信息">
                        <el-input v-model="form.maintenance" clearable />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="备注说明">
                        <el-input type="textarea" placeholder="请输入内容" :autosize="{ minRows: 4, maxRows: 6 }" v-model="form.describe" maxlength="254" show-word-limit></el-input>
                    </el-form-item>
                </el-col>
            </el-row>

        </el-form>

        <div slot="footer">
            <el-button size="small" @click="visible = false">取 消</el-button>
            <el-button size="small" type="primary" @click="formSubmit()" :loading="loading">确 定</el-button>
        </div>

    </el-dialog>
</template>

<script>
export default {
    data () {
        return {
            visible: false,
            loading: false,

            formError: {},
            form: {
                type_id: null,
                supplier_id: null,
                name: null,
                unit: null,
                brand: null,
                parameter: [],
                maintenance: null,
                describe: null
            }
        }
    },

    methods: {

        // 打开窗口
        open(form) {
            // 初始表单
            if (form.type_id) { this.form.type_id = form.type_id }
            if (form.supplier_id) { this.form.supplier_id = form.supplier_id }
            
            // 初始窗口
            this.visible = true
            this.loading = false
        },

        // 关闭窗口
        close() {
            this.form.type_id = null
            this.form.supplier_id = null
            this.form.name = null
            this.form.unit = null
            this.form.parameter = []
            this.form.describe = null
        },

        // 提交表单
        formSubmit() {

            this.loading = true

            this.$http.post('/9api/ims/product/create', this.form).then((rps) => {

                // 提交失败
                if (rps.data.state == 'error') {
                    this.formError = rps.data.body
                }

                // 提交成功
                if (rps.data.state == 'success') {
                    this.$emit('onChange', rps.data.body)
                    this.visible = false
                }

                this.loading = false
            })
        },
    }
}
</script>