<template>
    <el-dialog :visible.sync="visible" width="600px" append-to-body title="编辑考核指标" :close-on-click-modal="false" :close-on-press-escape="false">
            
        <!-- 表单 -->
        <el-form :model="form" size="mini" label-position="top" v-loading="loading">
            
            <!-- 基础信息 -->
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="考核对象">
                        <users-select v-model="form.users_id" disabled></users-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="考核方式" :rules="[{ required: true, message: '必填项' }]" :error="formError.type?.toString()" prop="type">
                        <hrm-kpi-goals-type v-model="form.type" edit />
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="考核年度" :rules="[{ required: true, message: '必选项' }]" :error="formError.year?.toString()" prop="year">
                        <el-date-picker v-model="form.year" type="year" value-format="yyyy"  size="mini" :style="{ width: '100%' }"></el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="考核目标">
                        <el-input-number v-model="form.target_sum" :min="0" :controls="false" :style="{ width: '100%' }" @blur="UpdateSum"></el-input-number>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-divider content-position="left"><el-link type="primary" :underline="false"><b>减轻负担,给自己一点呼吸的空间</b></el-link></el-divider>

            <div :style="{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '10px' }">
                <div :style="{ border: '1px solid #E0E0E0', padding: '10px', borderRadius: '4px' }">
                    <div>
                        <el-link :underline="false" type="info" :style="{ marginBottom: '5px' }">第一季度</el-link>
                        <el-input-number v-model="form.target_q1" :min="0" :controls="false" size="mini" :style="{ width: '100%' }" disabled></el-input-number>
                    </div>
                    <div :style="{ marginTop: '10px' }">
                        <el-link :underline="false" :style="{ marginBottom: '5px' }">1 月份</el-link>
                        <el-input-number v-model="form.target_m1" :min="0" :controls="false" size="mini" :style="{ width: '100%' }" @blur="UpdateQ1"></el-input-number>
                    </div>
                    <div :style="{ marginTop: '10px' }">
                        <el-link :underline="false" :style="{ marginBottom: '5px' }">2 月份</el-link>
                        <el-input-number v-model="form.target_m2" :min="0" :controls="false" size="mini" :style="{ width: '100%' }" @blur="UpdateQ1"></el-input-number>
                    </div>
                    <div :style="{ marginTop: '10px' }">
                        <el-link :underline="false" :style="{ marginBottom: '5px' }">3 月份</el-link>
                        <el-input-number v-model="form.target_m3" :min="0" :controls="false" size="mini" :style="{ width: '100%' }" @blur="UpdateQ1"></el-input-number>
                    </div>
                </div>
                <div :style="{ border: '1px solid #E0E0E0', padding: '10px', borderRadius: '4px' }">
                    <div>
                        <el-link :underline="false" type="info" :style="{ marginBottom: '5px' }">第二季度</el-link>
                        <el-input-number v-model="form.target_q2" :min="0" :controls="false" size="mini" :style="{ width: '100%' }" disabled></el-input-number>
                    </div>
                    <div :style="{ marginTop: '10px' }">
                        <el-link :underline="false" :style="{ marginBottom: '5px' }">4 月份</el-link>
                        <el-input-number v-model="form.target_m4" :min="0" :controls="false" size="mini" :style="{ width: '100%' }" @blur="UpdateQ2"></el-input-number>
                    </div>
                    <div :style="{ marginTop: '10px' }">
                        <el-link :underline="false" :style="{ marginBottom: '5px' }">5 月份</el-link>
                        <el-input-number v-model="form.target_m5" :min="0" :controls="false" size="mini" :style="{ width: '100%' }" @blur="UpdateQ2"></el-input-number>
                    </div>
                    <div :style="{ marginTop: '10px' }">
                        <el-link :underline="false" :style="{ marginBottom: '5px' }">6 月份</el-link>
                        <el-input-number v-model="form.target_m6" :min="0" :controls="false" size="mini" :style="{ width: '100%' }" @blur="UpdateQ2"></el-input-number>
                    </div>
                </div>
                <div :style="{ border: '1px solid #E0E0E0', padding: '10px', borderRadius: '4px' }">
                    <div>
                        <el-link :underline="false" type="info" :style="{ marginBottom: '5px' }">第三季度</el-link>
                        <el-input-number v-model="form.target_q3" :min="0" :controls="false" size="mini" :style="{ width: '100%' }" disabled></el-input-number>
                    </div>
                    <div :style="{ marginTop: '10px' }">
                        <el-link :underline="false" :style="{ marginBottom: '5px' }">7 月份</el-link>
                        <el-input-number v-model="form.target_m7" :min="0" :controls="false" size="mini" :style="{ width: '100%' }" @blur="UpdateQ3"></el-input-number>
                    </div>
                    <div :style="{ marginTop: '10px' }">
                        <el-link :underline="false" :style="{ marginBottom: '5px' }">8 月份</el-link>
                        <el-input-number v-model="form.target_m8" :min="0" :controls="false" size="mini" :style="{ width: '100%' }" @blur="UpdateQ3"></el-input-number>
                    </div>
                    <div :style="{ marginTop: '10px' }">
                        <el-link :underline="false" :style="{ marginBottom: '5px' }">9 月份</el-link>
                        <el-input-number v-model="form.target_m9" :min="0" :controls="false" size="mini" :style="{ width: '100%' }" @blur="UpdateQ3"></el-input-number>
                    </div>
                </div>
                <div :style="{ border: '1px solid #E0E0E0', padding: '10px', borderRadius: '4px' }">
                    <div>
                        <el-link :underline="false" type="info" :style="{ marginBottom: '5px' }">第四季度</el-link>
                        <el-input-number v-model="form.target_q4" :min="0" :controls="false" size="mini" :style="{ width: '100%' }" disabled></el-input-number>
                    </div>
                    <div :style="{ marginTop: '10px' }">
                        <el-link :underline="false" :style="{ marginBottom: '5px' }">10 月份</el-link>
                        <el-input-number v-model="form.target_m10" :min="0" :controls="false" size="mini" :style="{ width: '100%' }" @blur="UpdateQ4"></el-input-number>
                    </div>
                    <div :style="{ marginTop: '10px' }">
                        <el-link :underline="false" :style="{ marginBottom: '5px' }">11 月份</el-link>
                        <el-input-number v-model="form.target_m11" :min="0" :controls="false" size="mini" :style="{ width: '100%' }" @blur="UpdateQ4"></el-input-number>
                    </div>
                    <div :style="{ marginTop: '10px' }">
                        <el-link :underline="false" :style="{ marginBottom: '5px' }">12 月份</el-link>
                        <el-input-number v-model="form.target_m12" :min="0" :controls="false" size="mini" :style="{ width: '100%' }" @blur="UpdateQ4"></el-input-number>
                    </div>
                </div>
            </div>

        </el-form>

        <span slot="footer">
            <el-button size="mini" @click="visible = false">取 消</el-button>
            <el-button size="mini" type="primary" @click="SubmitForm()" :loading="loading">确 定</el-button>
        </span>
        
    </el-dialog>
</template>

<script>
export default {
    data () {
        return {
            visible: false,

            formError: { },
            form: {
                id: null,
                users_id: null,
                users: {},
                type: null,
                year: null,
                target_sum: 0,
                target_q1: 0,
                target_q2: 0,
                target_q3: 0,
                target_q4: 0,
                target_m1: 0,
                target_m2: 0,
                target_m3: 0,
                target_m4: 0,
                target_m5: 0,
                target_m6: 0,
                target_m7: 0,
                target_m8: 0,
                target_m9: 0,
                target_m10: 0,
                target_m11: 0,
                target_m12: 0
            },
        }
    },

    computed: {
        loading () {
            return !!this.$store.state.httpRequestCount
        }
    },

    methods: {

        // 窗口 - 打开
        Open(id)
        {
            this.$http.post('/9api/hrm/kpi-goals/search-first', { append: ['users'], search: { id: id } }).then(rps => {
                this.form = { ...rps.data, year: rps.data.year.toString() }
                this.visible = true
            })
        },

        // 更新 - 考核目标
        UpdateSum ()
        {
            this.form.target_q1 = (this.form.target_sum - this.form.target_sum % 4) * 0.25
            this.form.target_q2 = (this.form.target_sum - this.form.target_sum % 4) * 0.25
            this.form.target_q3 = (this.form.target_sum - this.form.target_sum % 4) * 0.25
            this.form.target_q4 = (this.form.target_sum - this.form.target_sum % 4) * 0.25 + (this.form.target_sum % 4)
            this.form.target_m1 = (this.form.target_q1 - this.form.target_q1 % 3) / 3
            this.form.target_m2 = (this.form.target_q1 - this.form.target_q1 % 3) / 3
            this.form.target_m3 = (this.form.target_q1 - this.form.target_q1 % 3) / 3 + (this.form.target_q1 % 3)
            this.form.target_m4 = (this.form.target_q2 - this.form.target_q2 % 3) / 3
            this.form.target_m5 = (this.form.target_q2 - this.form.target_q2 % 3) / 3
            this.form.target_m6 = (this.form.target_q2 - this.form.target_q2 % 3) / 3 + (this.form.target_q2 % 3)
            this.form.target_m7 = (this.form.target_q3 - this.form.target_q3 % 3) / 3
            this.form.target_m8 = (this.form.target_q3 - this.form.target_q3 % 3) / 3
            this.form.target_m9 = (this.form.target_q3 - this.form.target_q3 % 3) / 3 + (this.form.target_q3 % 3)
            this.form.target_m10 = (this.form.target_q4 - this.form.target_q4 % 3) / 3
            this.form.target_m11 = (this.form.target_q4 - this.form.target_q4 % 3) / 3
            this.form.target_m12 = (this.form.target_q4 - this.form.target_q4 % 3) / 3 + (this.form.target_q4 % 3)
        },
        
        UpdateQ1 ()
        {
            this.form.target_q1 = this.form.target_m1 + this.form.target_m2 + this.form.target_m3
            this.form.target_sum = this.form.target_q1 + this.form.target_q2 + this.form.target_q3 + this.form.target_q4
        },

        UpdateQ2 ()
        {
            this.form.target_q2 = this.form.target_m4 + this.form.target_m5 + this.form.target_m6
            this.form.target_sum = this.form.target_q1 + this.form.target_q2 + this.form.target_q3 + this.form.target_q4
        },

        UpdateQ3 ()
        {
            this.form.target_q3 = this.form.target_m7 + this.form.target_m8 + this.form.target_m9
            this.form.target_sum = this.form.target_q1 + this.form.target_q2 + this.form.target_q3 + this.form.target_q4
        },

        UpdateQ4 ()
        {
            this.form.target_q4 = this.form.target_m10 + this.form.target_m11 + this.form.target_m12
            this.form.target_sum = this.form.target_q1 + this.form.target_q2 + this.form.target_q3 + this.form.target_q4
        },

        // 表单 - 提交
        async SubmitForm ()
        {
            this.$http.post('/9api/hrm/kpi-goals/update', this.form).then(rps => {
                if (rps.data.state == 'error') {
                    this.formError = rps.data.body
                }
                if (rps.data.state == 'success') {
                    this.$emit('onChange', rps.data.body)
                    this.visible = false
                }
            })
        },
    }
}
</script>