<!---------------------------------------------------------------------------- 
 | 创建合同
 |----------------------------------------------------------------------------
 | 方法：请注意大小写,参数描述见具体方法
 | $refs[ref_name].Open() 手动打开窗口,
 | $refs[ref_name].Close() 手动关闭窗口
 |----------------------------------------------------------------------------
 | 回调
 | @onChange = fn (Object) => {} 成功后返回合同信息
 |
 ---------------------------------------------------------------------------->

<template>
    <el-drawer class="w-el-drawer" :visible.sync="visible" :size="960" :with-header="false" direction="rtl" :before-close="Close" append-to-body destroy-on-close>
        
        <!-- header -->
        <div class="w-el-drawer-header">
            <i class="el-icon-tickets"></i> 新建信息
        </div>

        <!-- body -->
        <div class="w-el-drawer-body"  v-loading="loading">
            <el-form :model="form" label-width="120px" size="mini">
                
                <!-- 标题 -->
                <el-form-item label="标题" :rules="[{ required: true, message: '必填项' }]" :error="formError.name?.toString()" prop="name">
                    <el-input v-model="form.name" placeholder="请输入标题" clearable />
                </el-form-item>

                <!-- 分类 -->
                <el-form-item label="分类" :rules="[{ required: true, message: '必填项' }]" :error="formError.type_id?.toString()" prop="type_id">
                    <news-type-select v-model="form.type_id" placeholder="请输入标题" clearable />
                </el-form-item>

                <!-- 抄送给 -->
                <el-form-item label="抄送给">
                    <users-select v-model="form.users_cc_id" multiple />
                </el-form-item>

                <!-- 概述 -->
                <el-form-item label="概述" :rules="[{ required: true, message: '必填项' }]" :error="formError.overview?.toString()" prop="overview">
                    <el-input type="textarea" :rows="2" placeholder="请输入简单的描述或备注" v-model="form.overview" />
                </el-form-item>

                <!-- 分类 -->
                <el-form-item label="内容">
                    <froala-editor v-model="form.describe" />
                </el-form-item>

                <!-- 附件 -->
                <el-form-item label="附件">
                    <files-upload v-model="form.files_id" model="news" />
                </el-form-item>
            </el-form>
        </div>

        <!-- footer -->
        <div class="w-el-drawer-footer">
            <el-button size="medium" @click="Close()">取 消</el-button>
            <el-button size="medium" type="primary" @click="SubmitForm()" :loading="loading">提 交</el-button>
        </div>
    </el-drawer>
</template>

<script>
export default {
    data () {
        return {
            visible: false,
            loading: false,

            formError: { },
            form: {
                name: null,
                type_id: null,
                users_cc_id: null,
                overview: null,
                describe: null,
                files_id: []
            }
        }
    },

    methods: {

        /**
        |--------------------------------------------------
        | 窗口 - 打开
        |--------------------------------------------------
        |
        */

        Open() {
            this.visible = true
            this.loading = false
        },

        /**
        |--------------------------------------------------
        | 窗口 - 关闭
        |--------------------------------------------------
        |
        */

        Close() {
            this.formError = {}

            this.form.name = null
            this.form.type_id = null
            this.form.users_cc_id = null
            this.form.overview = null
            this.form.describe = null
            this.form.files_id = []

            this.visible = false
            this.loading = false
        },

        /**
        |--------------------------------------------------
        | 表单 - 提交
        |--------------------------------------------------
        |
        */

        SubmitForm() {
            // 清空错误提示
            this.loading = true
            this.formError = {}

            // 提交表单
            this.$http.post('/9api/news/create', this.form).then(rps => {

                this.loading = false

                // 提交失败
                if (rps.data.state == 'error') {
                    this.formError = rps.data.body
                }

                // 提交成功
                if (rps.data.state == 'success') {
                    
                    // 操作通知
                    this.$notify.success({ title: '提示', message: '请求成功', duration: 2000 })
                    this.$emit('onChange', rps.data.body)
                    this.Close()
                }
            })
        },


    }
}
</script>