<template>
    <el-dialog :visible.sync="visible" width="900px" title="产品仓库" append-to-body destroy-on-close :close-on-click-modal="false" :close-on-press-escape="false">

        <!-- 查询 -->
        <div :style="{ display: 'flex', marginBottom: '10px' }">
            <div :style="{ marginRight: '10px' }">
                <el-input prefix-icon="el-icon-search" v-model="search.product.name" @input="$lodash.debounce(TableSearch, 500)" size="mini" placeholder="产品名称检索" clearable></el-input>
            </div>
            <el-button type="primary" size="mini" icon="el-icon-plus" @click="$refs.productCreate.open({ type_id: search.type_id })" >新的产品</el-button>
        </div>

        <!-- 表数据 -->
        <vxe-table border ref="xTable" :data="table" :loading="loading" :checkbox-config="{ reserve: true }" :row-config="{ keyField: 'id' }"  height="500px">

            <!-- 序号 -->
            <vxe-column type="checkbox" width="50" fixed="left"></vxe-column>

            <!-- 产品名称 -->
            <vxe-column min-width="200" field="name" title="产品名称" fixed="left">
                <template #default="{ row }">
                    <el-link icon="el-icon-present" @click="$refs.productDetails.Open(row.id)" type="primary">{{ row.product.name }}</el-link>
                </template>
            </vxe-column>

            <!-- 供应商 -->
            <vxe-column min-width="200" field="supplier.name" title="供应商">
                <template #default="{ row }">
                    <el-link icon="el-icon-office-building" @click="$refs.supplierDetails.Open(row.product.supplier.id)" type="primary">{{ row.product.supplier?.name }}</el-link>
                </template>
            </vxe-column>

            <!-- 产品品牌 -->
            <vxe-column min-width="100" field="num" title="库存数量"></vxe-column>

            <!-- 产品品牌 -->
            <vxe-column min-width="100" field="warehouse.name" title="所在仓库"></vxe-column>

            <!-- 产品品牌 -->
            <vxe-column min-width="100" field="product.brand" title="产品品牌"></vxe-column>

            <!-- 产品类别 -->
            <vxe-column min-width="100" field="product.type.name" title="产品类别"></vxe-column>

            <!-- 计量单位 -->
            <vxe-column min-width="80" field="product.unit" title="计量单位"></vxe-column>

            <!-- 规格参数 -->
            <vxe-column min-width="250" field="parameter" title="规格参数">
                <template #default="{ row }">
                    {{ row.product.parameter?.toString() }}
                </template>
            </vxe-column>

            <!-- 备注说明 -->
            <vxe-column min-width="220" field="describe" title="备注说明"></vxe-column>

        </vxe-table>

        <vxe-pager 
            :layouts="['Total', 'Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump',]"
            :total="page.total"
            :page-size.sync="page.size"
            :current-page.sync="page.current"
            :style="{ marginTop: '10px' }"
            @page-change="TableSearch()">
        </vxe-pager>

        <!-- 表尾 -->
        <div slot="footer">
            <el-button size="small" @click="visible = false">取 消</el-button>
            <el-button size="small" type="primary" @click="FormSubmit()" :loading="loading">确 定</el-button>
        </div>

        <!-- 组件 -->
        <ims-product-create ref="productCreate" @onChange="TableSearch" />
        <ims-product-drawer ref="productDetails" @onChange="TableSearch" />
        <ims-supplier-drawer ref="supplierDetails" @onChange="TableSearch" />
        
    </el-dialog>
</template>

<script>
export default {
    data() {
        return {
            visible: false,
            loading: false,

            table: [],

            page: {
                total: 0,
                size: 50,
                current: 1,
            },

            order: {
                method: 'desc',
                column: 'id'
            },
            
            search: {
                warehouse_id: null,
                product: {
                    name: null
                }
            }
        }
    },

    methods: {

        // 打开窗口
        Open(search) {
            if (search) {
                this.search.warehouse_id = search.warehouse_id
            }
            this.visible = true
            this.TableSearch()
        },

        // 查询数据
        TableSearch() {
            this.loading = true
            this.$http.post(
                '/9api/ims/warehouse-storage/search', 
                {
                    search: this.search,
                    page: this.page,
                    order: this.order,
                    append: ['product', 'warehouse']
                }).then((rps) => {
                    // 更新数据
                    this.page.total = rps.data.total
                    this.table = rps.data.body

                    // 页码溢出
                    if (this.table.length == 0 && this.page.current != 1) {
                        this.page.current = 1
                        this.TableSearch()
                    }

                    this.loading = false
                }
            )
        },

        // 提交确认
        FormSubmit() {
            let selectRecords = [...this.$refs.xTable.getCheckboxRecords(), ...this.$refs.xTable.getCheckboxReserveRecords()]
            this.$emit('onChange', selectRecords)
            this.visible = false
        }
    }
}

</script>6
