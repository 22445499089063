<template>
    <el-dialog :visible.sync="visible" class="w-el-dialog" width="800px" append-to-body title="页面打印" :close-on-click-modal="false" :close-on-press-escape="false">

        <div id="printDom" class="printDom">
            <div style="display: flex; align-items: center; justify-content: space-between;">
                <div>
                    <img width="200px" src="/img/system/default.png">
                </div>
                <div style="width: 100%;">
                    <div style="text-align: right; font-size: 16px; font-weight: bold;">广东网安科技有限公司</div>
                    <div style="text-align: right;">地址:广东省东莞市南城街道黄金路1号6栋4单元1001室</div>
                    <div style="text-align: right;">Tel:13929261394 Fax:0769-22763488</div>
                </div>
            </div>
            <hr>
            <div style="text-align: center; padding: 20px; font-weight: bold; font-size: 26px;">服务记录单</div>
            <table>
                <tr>
                    <th>工单名称</th>
                    <td colspan="5">{{ details.name }}</td>
                </tr>
                <tr>
                    <th>工单单号</th>
                    <td colspan="5">{{ details.number }}</td>
                </tr>
                <tr>
                    <th>工单状态</th>
                    <td>{{ details.state == 0 ? '待确认' : (  details.state == 1 ? '进行中' : (  details.state == 2 ? '已完成' : (  details.state == 3 ? '已关闭' : (  details.state == 4 ? '已暂停' : '已取消' ) ) ) ) }}</td>
                    <th>工单类型</th>
                    <td>{{ details.template?.name }}</td>
                    <th>紧急程度</th>
                    <td>{{ details.p == 1 ? '非常重要' : (  details.p == 2 ? '重要' : (  details.p == 3 ? '次要' : '一般' ) ) }}</td>
                </tr>
                <tr>
                    <th>关联项目</th>
                    <td colspan="5"><span v-if="details.projects">{{ details.projects.number + '-' + details.projects.name }}</span></td>
                </tr>
                <tr>
                    <th>关联任务</th>
                    <td colspan="5"><span v-if="details.projects_task">{{ details.projects_task.name }}</span></td>
                </tr>
                <tr>
                    <th>客户名称</th>
                    <td colspan="5"><span v-if="details.customer">{{ details.customer.name }}</span></td>
                </tr>
                <tr>
                    <th>派单人</th>
                    <td>{{ details.user_sending.name }}</td>
                    <th>收单人</th>
                    <td>{{ details.user_receive.name }}</td>
                    <th>处置时间</th>
                    <td>{{ details.range_at_start }} 至 {{ details.range_at_end }}</td>
                </tr>
                <tr>
                    <td colspan="6">
                        <p><b>工单描述:</b></p>
                        <wo-template-form-disabled v-if="details.template_value" v-model="details.template_value" :id="details.template_id" />
                        <div style="min-height: 100px;">
                            <froala-views v-model="details.describe" />
                        </div>
                    </td>
                </tr>
                <tr>
                    <th>客户满意度</th>
                    <td colspan="5">
                        <el-checkbox label="非常满意"></el-checkbox>
                        <el-checkbox label="满意"></el-checkbox>
                        <el-checkbox label="一般"></el-checkbox>
                        <el-checkbox label="不满意"></el-checkbox>
                    </td>
                </tr>
                <tr>
                    <th>客户签字</th>
                    <td colspan="5"></td>
                </tr>
            </table>
        </div>

        <div slot="footer">
            <el-button size="small" @click="visible = false">取 消</el-button>
            <el-button size="small" type="primary" @click="DetailsPrint()">确 定</el-button>
        </div>
    </el-dialog>
</template>

<script>
import printJS from 'print-js'

export default {
    data () {
        return {
            visible: false,
            loading: false,

            details: {
                id: null,
                customer: {},
                projects: {},
                projects_task: {},
                date_range: [],
                user_admin: {},
                user_receive: {},
                user_sending: {},
                user_assist: [],
                state_users: {
                    affirm_user: {},
                    finish_user: {},
                    close_user: {},
                    cancel_user: {},
                    suspend_user: {},
                },
                template: [],
                files_id: [],
            }

        }
    },

    methods: {

        /**
        |--------------------------------------------------
        | 打开窗口
        |--------------------------------------------------
        |
        */

        Open(id) {
            this.visible = true
            this.DetailsGet(id)
        },

        /**
        |--------------------------------------------------
        | 数据查询
        |--------------------------------------------------
        |
        */

        DetailsGet(id = this.details.id) {
            this.loading = true

            this.$http.post(
                '/9api/wo/search-first',
                {
                    id: id,
                    append: ['user_admin', 'user_receive', 'user_sending', 'user_assist', 'template', 'customer', 'projects', 'projects_task', 'state_users', 'appraise_user'],
                }
            ).then(rps => {
                this.details = rps.data
                this.loading = false
            })
        },

        DetailsPrint() {
            printJS({
                printable: 'printDom',
                type: 'html',
                targetStyles: ['*'],
                font_size: '10pt'
            })
        }
    }
}
</script>

<style lang="less" scoped>
    .printDom {
        table {
            width: 100%;
            border-collapse: collapse;
            border: 1px solid #000000;
            font-size: 12px;

            td, th {
                border: 1px solid #000000;
                padding: 10px 5px;
            }

            th {
                font-weight: normal;
                background-color: #fafafa;
                width: 100px;
                text-align: left;
            }
        }
    }
</style>