<!---------------------------------------------------------------------------- 
 | 合同期限
 |----------------------------------------------------------------------------
 | 方法：请注意大小写,参数描述见具体方法
 | *v-model         合同状态----默认为 null
 | *contract_id     合同ID------默认为 null
 |----------------------------------------------------------------------------
 | 参数说明
 | update => Boolean => 是否可以修改
 |----------------------------------------------------------------------------
 |
 ---------------------------------------------------------------------------->

 <template>
    <el-popover trigger="click" placement="bottom-start" :disabled="!update">
        <el-link slot="reference" type="primary" :underline="update">
            {{ value ? value[0] + ' 至 ' + value[1] : '未配置' }}
            <i v-if="update" class="el-icon-edit el-icon--right"></i>
        </el-link>

        <el-date-picker size="mini" v-model="date" type="daterange" value-format="yyyy-MM-dd" placeholder="选择日期" @change="OnChange"></el-date-picker>
    </el-popover>
</template>

<script>
export default {
    props: {
        value: {
            type: Array,
            default: null
        },

        contract_id: {
            type: Number,
            default: null
        },

        update: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            date: null,
        }
    },

    watch: {
        value: {
            handler(val) {
                this.date = val
            },
            immediate: true
        }
    },

    methods: {
        OnChange(val) {
            if (this.contract_id && this.update) {
                this.$http.post('/9api/contract/update-time-limit', {id: this.contract_id, time_limit: val}).then(rps => {
                    this.$emit('input', val)
                })
            }
        },
    }
}
</script>