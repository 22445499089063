<template>
    <el-drawer :visible.sync="visible" size="80%" direction="rtl" append-to-body destroy-on-close :show-close="false" :before-close="Close">

        <div slot="title">

            <div class="w-margin-b-10">客户联系人</div>
            <div class="w-margin-b-10" style="font-size: 18px; color: #000; font-weight: bold;">{{ contacts.name }} - {{ contacts.customer.name }}</div>

            <!-- 功能按钮 -->
            <div style="display: flex;">
                <el-button size="mini" icon="el-icon-edit" @click="RePage()">刷新</el-button>
                <el-button style="margin-left: 5px;" size="mini" icon="el-icon-edit" @click="$refs.contactsUpdate.Open(contacts.id)">编辑联系人</el-button>
                <el-button style="margin-left: 5px;" size="mini" @click="DataDelete()" type="danger">移除联系人</el-button>
            </div>

            <!-- 基本信息 -->
            <div class="w-margin-t-10" style="background-color: #f4f5f7; border-radius: 3px; padding: 15px; display: flex;">
                <el-statistic title="性别"><template slot="formatter"><span style="font-size: 14px;">{{ contacts.sex }}</span></template></el-statistic>
                <el-statistic title="是否关键决策人"><template slot="formatter"><span style="font-size: 14px;">{{ contacts.policymakers || '未登记' }}</span></template></el-statistic>
                <el-statistic title="所在部门"><template slot="formatter"><span style="font-size: 14px;">{{ contacts.department || '未登记' }}</span></template></el-statistic>
                <el-statistic title="职务"><template slot="formatter"><span style="font-size: 14px;">{{ contacts.appointment || '未登记' }}</span></template></el-statistic>
                <el-statistic title="负责人"><template slot="formatter"><span style="font-size: 14px;">{{ contacts.user_admin?.name || '未登记' }}</span></template></el-statistic>
                <el-statistic title="更新时间"><template slot="formatter"><span style="font-size: 14px;">{{ contacts.updated_at }}</span></template></el-statistic>
            </div>

        </div>

        <div class="w-padding-l-20 w-padding-r-20 w-padding-b-20">
            <el-tabs v-model="tabs">

                <!-- 详细资料 -->
                <el-tab-pane label="详细资料" name="details">
                    
                    <!-- 基本信息 -->
                    <el-descriptions title="基本信息" size="mini" border :column="1" class="w-margin-b-20">
                        <el-descriptions-item label="联系人名称">{{ contacts.name }}</el-descriptions-item>
                        <el-descriptions-item label="性别">{{ contacts.sex }}</el-descriptions-item>
                        <el-descriptions-item label="关联单位">
                            <el-link v-if="contacts.customer_id" type="primary" @click="$refs.customerDrawer.Open(contacts.customer_id)">
                                {{ contacts.customer.number }} - {{ contacts.customer.name }}
                            </el-link>
                        </el-descriptions-item>
                        <el-descriptions-item label="手机">{{ contacts.phone }}</el-descriptions-item>
                        <el-descriptions-item label="电话">{{ contacts.phone_t }}</el-descriptions-item>
                        <el-descriptions-item label="电子邮件">{{ contacts.email }}</el-descriptions-item>
                        <el-descriptions-item label="是否关键决策人">{{ contacts.policymakers }}</el-descriptions-item>
                        <el-descriptions-item label="所在部门">{{ contacts.department }}</el-descriptions-item>
                        <el-descriptions-item label="职务">{{ contacts.appointment }}</el-descriptions-item>
                        <el-descriptions-item label="详细地址">{{ ((contacts.country || '') + (contacts.country_province || '') + (contacts.country_province_city || '') + (contacts.address || '')) || '未登记' }}</el-descriptions-item>
                    </el-descriptions>

                    <!-- 系统信息 -->
                    <el-descriptions title="系统信息" size="mini" border :column="2">
                        <el-descriptions-item label="创建时间">{{ contacts.created_at }}</el-descriptions-item>
                        <el-descriptions-item label="创建人">{{ contacts.created_user.name }}</el-descriptions-item>
                        <el-descriptions-item label="更新时间">{{ contacts.updated_at }}</el-descriptions-item>
                        <el-descriptions-item label="更新人">{{ contacts.updated_user.name }}</el-descriptions-item>
                    </el-descriptions>

                </el-tab-pane>

            </el-tabs>
        </div>

        <!-- 组件 -->
        <crm-contacts-update ref="contactsUpdate" @onChange="RePage" />
        <crm-customer-drawer ref="customerDrawer" @onChange="RePage" />

    </el-drawer>
</template>

<script>
export default {
    data() {
        return {
            visible: false,
            loading: false,
            tabs: 'details',

            contacts: {
                id: null,
                customer: {},
                user_admin: {},
                created_user: {},
                updated_user: {}
            }
        }
    },

    methods: {

        /**
        |--------------------------------------------------
        | 打开窗口
        |--------------------------------------------------
        |
        */

        Open(id) {
            this.visible = true
            this.DataGet(id)
        },

        /**
        |--------------------------------------------------
        | 关闭窗口
        |--------------------------------------------------
        |
        */

        Close(done) {
            this.$emit('onChange')
            done()
        },

        /**
        |--------------------------------------------------
        | 刷新
        |--------------------------------------------------
        |
        */

        RePage() {
            this.DataGet()
        },

        /**
        |--------------------------------------------------
        | 查询
        |--------------------------------------------------
        |
        */

        DataGet(id = this.contacts.id) {
            this.loading = true

            this.$http.post(
                '/9api/crm/contacts/search-first',
                {
                    search: { id: id },
                    append: ['user_admin', 'customer', 'created_user', 'updated_user' ]
                }
            ).then(rps => {
                this.contacts = rps.data
                this.loading = false
            }) 
        },

        /**
        |--------------------------------------------------
        | 删除
        |--------------------------------------------------
        |
        */

        DataDelete() {
            this.$http.post('/9api/crm/contacts/delete', { id: this.contacts.id }).then(rps => {
                this.$emit('onChange')
                this.visible = false
            })
        },

        
    }
}

</script>6
