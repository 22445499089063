import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
import { Notification } from 'element-ui';


axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;

// 请求拦截器
axios.interceptors.request.use(
    config => {

        // 请求数量记录
        store.commit('setHttpRequestCount', 1)
        console.log('request中的进程：', store.state.httpRequestCount)

        // 服务端TOKEN
        config.headers['Authorization'] = 'Bearer ' + sessionStorage.getItem('token')

        return config

    }
)

// 响应拦截器
axios.interceptors.response.use(
    config => {

        // 请求数量记录
        store.commit('setHttpRequestCount', -1)
        console.log('response中的进程：', store.state.httpRequestCount)

        // 解密方式
        // const encrypted = JSON.parse( atob(config.data) )
        // const iv = CryptoJS.enc.Base64.parse(encrypted.iv)
        // const value = encrypted.value;
        // CryptoJS.AES.decrypt(value, CryptoJS.enc.Base64.parse(process.env.VUE_APP_KEY), { iv: iv }).toString(CryptoJS.enc.Utf8)

        return config;

    },
    error => {
        
        // 请求数量记录
        store.commit('setHttpRequestCount', -1)
        console.log('error中的进程', store.state.httpRequestCount)

        // 用户未授权
        if (error.response.status == 401) {
            sessionStorage.setItem('pathTo', router.history.current.fullPath)
            router.push('/auth/login')
        }
        
        // 其它异常提示
        if (error.response.status != 401) {
            Notification.error({ title: '提示', dangerouslyUseHTMLString: true, message: error.response.data, duration: 2000 })
        }

    }
)

Vue.prototype.$http = axios