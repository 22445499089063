<template>
    <div>
        <div :style="{ marginBottom: '20px' }">
            <el-button size="mini" v-if="disabled"  @click="disabled = false">编辑信息</el-button>
            <el-button size="mini" v-if="!disabled" @click="disabled = true">取消</el-button>
            <el-button size="mini" v-if="!disabled" type="primary" @click="SubmitForm()" :loading="loading">保存</el-button>
        </div>

        <!-- 表单 -->
        <el-form :model="form" size="mini" label-position="top" v-loading="loading">

            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="姓名" :rules="[{ required: true, message: '必填项' }]" :error="formError.name?.toString()" prop="name">
                        <el-input v-model="form.name" placeholder="请填写员工姓名" clearable :disabled="disabled" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="手机号码" :rules="[{ required: true, message: '必填项' }]" :error="formError.phone?.toString()" prop="phone">
                        <el-input v-model="form.phone" placeholder="请填写员工手机号码" prefix-icon="el-icon-mobile" clearable :disabled="disabled" />
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="性别" :rules="[{ required: true, message: '必选项' }]" :error="formError.sex?.toString()" prop="sex">
                        <el-select v-model="form.sex" :style="{ width: '100%' }" :disabled="disabled">
                            <el-option :value="0" label="未知"></el-option>
                            <el-option :value="1" label="男"></el-option>
                            <el-option :value="2" label="女"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="邮箱">
                        <el-input v-model="form.email" placeholder="电子邮件地址" prefix-icon="el-icon-message" clearable :disabled="disabled" />
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="管理员" :rules="[{ required: true, message: '必选项' }]" :error="formError.user_admin_id?.toString()" prop="user_admin_id">
                        <users-select v-model="form.user_admin_id" :disabled="disabled" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="凭证密码">
                        <el-input type="password" v-model="form.password" placeholder="密码 A-Z/a-z/0-9/特殊字符" :disabled="disabled" />
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="用户地址">
                        <config-country-cascader v-model="form.country_id" margin="0 0 5px 0" :disabled="disabled" />
                        <el-input type="textarea" :rows="2" v-model="form.address" placeholder="详细地址" :disabled="disabled" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="贡献值">
                        <el-input v-model="form.exp" :disabled="disabled" />
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="座右铭/介绍">
                        <el-input type="textarea" :rows="2" v-model="form.motto" placeholder="详细地址" :disabled="disabled" />
                    </el-form-item>
                </el-col>
            </el-row>

            
            
        </el-form>
    </div>
</template>

<script>
export default {
    props: {
        form: {
            type: [Object],
            default: null
        }
    },

    data () {
        return {
            disabled: true,
            loading: false,

            formError: {}
        }
    },

    methods: {
        SubmitForm() {
            // 开启加载状态
            this.loading = true
            this.formError = {}

            // 提交表单
            this.$http.post('/9api/hrm/employee/update-base', this.form).then(rps => {
                // 关闭加载状态
                this.loading = false

                // 提交失败
                if (rps.data.state == 'error') {
                    this.formError = rps.data.body
                }
                
                // 提交成功
                if (rps.data.state == 'success') {
                    this.$emit('onChange', rps.data.body)
                    this.disabled = true
                }
            })
        }
    }
}
</script>