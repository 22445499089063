<!---------------------------------------------------------------------------- 
 | 合同管理 - 回款记录 - 编辑
 |----------------------------------------------------------------------------
 | 方法：请注意大小写,参数描述见具体方法
 | $refs[ref_name].Open(id) 手动打开窗口,
 | $refs[ref_name].Close() 手动关闭窗口
 |----------------------------------------------------------------------------
 | 回调
 | @success = fn (Object) => {}
 |
 ---------------------------------------------------------------------------->

<template>
    <el-drawer class="w-el-drawer" :visible.sync="visible" :size="960" :with-header="false" direction="rtl" :before-close="Close" append-to-body>
        
        <!-- header -->
        <div class="w-el-drawer-header">
            <i class="el-icon-tickets"></i> 修改回款记录
        </div>

        <!-- body -->
        <div class="w-el-drawer-body"  v-loading="loading">
           
            <!-- 表单 -->
            <el-form :model="form" label-width="120px" size="mini">
                
                <!-- 关联合同 -->
                <el-form-item :rules="[{ required: true, message: '必填项' }]" label="关联合同" :error="formError.contract_id?.toString()" prop="contract_id">
                    <crm-contract-select v-model="form.contract_id" @onChange="ContractOnChange" />
                </el-form-item>

                <!-- 关联合同 -->
                <el-form-item :rules="[{ required: true, message: '必填项' }]" label="关联客户" :error="formError.customer_id?.toString()" prop="customer_id">
                    <crm-customer-select v-model="form.customer_id" clearable />
                </el-form-item>

                <!-- 款项日期 -->
                <el-form-item :rules="[{ required: true, message: '必填项' }]" label="款项日期" :error="formError.pay_date?.toString()" prop="pay_date">
                    <el-date-picker v-model="form.pay_date" value-format="yyyy-MM-dd" type="date" placeholder="请选择款项日期" clearable />
                </el-form-item>

                <!-- 款项负责人 -->
                <el-form-item :rules="[{ required: true, message: '必选项' }]" label="款项负责人" :error="formError.user_id?.toString()" prop="user_id">
                    <users-select v-model="form.user_id" />
                </el-form-item>

                <!-- 款项金额 -->
                <el-form-item :rules="[{ required: true, message: '必填项' }]" label="款项金额" :error="formError.pay_money?.toString()" prop="pay_money">
                    <el-input-number v-model="form.pay_money" :min="0" :precision="2" label="款项金额" style="width: 35%;" @change="form.pay_money_offset = form.pay_money_performance = form.pay_money"></el-input-number>
                </el-form-item>

                <!-- 冲销金额 -->
                <el-form-item :rules="[{ required: true, message: '必填项' }]" label="冲销金额" :error="formError.pay_money_offset?.toString()" prop="pay_money_offset">
                    <el-input-number v-model="form.pay_money_offset" :min="0" :precision="2" label="冲销金额" style="width: 35%;"></el-input-number>
                </el-form-item>

                <!-- 记业绩金额 -->
                <el-form-item :rules="[{ required: true, message: '必填项' }]" label="记业绩金额" :error="formError.pay_money_performance?.toString()" prop="pay_money_performance">
                    <el-input-number v-model="form.pay_money_performance" :min="0" :precision="2" label="记业绩金额" style="width: 35%;"></el-input-number>
                </el-form-item>

                <!-- 开具发票 -->
                <el-form-item label="开具发票">
                    <el-checkbox v-model="form.invoice_state">需要开具发票</el-checkbox>
                </el-form-item>

                <!-- 款项备注 -->
                <el-form-item label="款项备注">
                    <el-input v-model="form.describe" type="textarea" :rows="5" placeholder="款项备注"></el-input>
                </el-form-item>
            </el-form>
        </div>

        <!-- footer -->
        <div class="w-el-drawer-footer">
            <el-button size="medium" @click="Close()">取消 / 返回</el-button>
            <el-button size="medium" type="primary" @click="FormSubmit()" :loading="loading">修改</el-button>
        </div>

    </el-drawer>
</template>
    
<script>
export default {
    data() {
        return {
            visible: false,
            loading: false,

            formError: {},
            form: {
                id: null,
                contract_id: null,
                customer_id: null,
                user_id: null,
                pay_date: null,
                pay_money: 0,
                pay_money_offset: 0,
                pay_money_performance: 0,
                invoice_state: true,
                describe: null,
            }
        }
    },
    
    methods: {
        /**
        |--------------------------------------------------
        | 窗口 - 打开
        |--------------------------------------------------
        |
        */

        Open(id) {
            // 窗口配置
            this.visible = true
            this.loading = true

            this.$http.post('/9api/crm/contract-pay-record/search-first', { id: id }).then(rps => {
                this.form.id = rps.data.id
                this.form.contract_id = rps.data.contract_id
                this.form.customer_id = rps.data.customer_id
                this.form.user_id = rps.data.user_id
                this.form.pay_date = rps.data.pay_date
                this.form.pay_money = rps.data.pay_money
                this.form.pay_money_offset = rps.data.pay_money_offset
                this.form.pay_money_performance = rps.data.pay_money_performance
                this.form.invoice_state = rps.data.invoice_state == 2 ? false : true
                this.form.describe = rps.data.describe
                this.loading = false
            })
        },

        
        /**
        |--------------------------------------------------
        | 窗口 - 关闭
        |--------------------------------------------------
        |
        */

        Close() {
            this.visible = false
            this.loading = false
        },

        /**
        |--------------------------------------------------
        | 合同信息 - 映射表单
        |--------------------------------------------------
        |
        */
        
        ContractOnChange(data) {
            this.form.customer_id = data?.customer_id
        },
        
        /**
        |--------------------------------------------------
        | 表单提交
        |--------------------------------------------------
        |
        */

        FormSubmit() {
            // 清空错误提示
            this.loading = true
            this.formError = {}

            this.$http.post('/9api/crm/contract-pay-record/edit', this.form).then(rps => {
                
                this.loading = false

                if (rps.data.state == 'error') {
                    this.formError = rps.data.body
                }

                if (rps.data.state == 'success') {
                    this.$notify.success({
                        title: '提示',
                        message: '记录修改成功',
                        duration: 2000
                    })
                    this.$emit("success")
                    this.Close()
                }
            })
        }
    }
}
</script>