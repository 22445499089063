<template>
    <!-- 角色检索 -->
    <el-autocomplete
        v-model="name"
        :fetch-suggestions="UserSearch"
        :trigger-on-focus="false"
        @select="UserSelect"
        @clear="UserClear"
        @input="UserInput"
        size="mini"
        value-key="name"
        clearable
        placeholder="成员:例如'许XX''" >
    </el-autocomplete>
</template>

<script>
export default {
    props: {
        value: {
            type: Number,
            default: null
        }
    },

    data () {
        return {
            name: null,
            id: null,
            role: null,
        }
    },

    methods: {
        /**
        |--------------------------------------------------
        | 用户查询
        |--------------------------------------------------
        |
        */

        UserSearch(queryString, callback) {
            if (queryString) {
                this.$http.post('/9api/users/search', { search: { name: queryString } }).then(rps => {
                    callback(rps.data.data)
                })
            }
        },

        /**
        |--------------------------------------------------
        | 用户选择
        |--------------------------------------------------
        |
        */

        UserSelect(item) {
            this.id = item.id
            this.$emit('input', item.id)
            this.$emit('onChange', item)
        },

        /**
        |--------------------------------------------------
        | 用户清空
        |--------------------------------------------------
        |
        */

        UserClear() {
            this.id = null
            this.$emit('input', null)
            this.$emit('onChange', null)
        },

        /**
        |--------------------------------------------------
        | 用户检测
        |--------------------------------------------------
        |
        */

        UserInput() {
            if (this.name.length == 0 && this.id != null) {
                this.id = null
                this.$emit('input', null)
                this.$emit('onChange', null)
            }
        }
    }
}
</script>