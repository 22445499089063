<template>
    <el-dialog :visible.sync="visible" width="900px" append-to-body destroy-on-close @close="Close()" :close-on-click-modal="false" :close-on-press-escape="false">

        <template slot="title">
            <span :style="{ fontSize: '22px', fontWeight: 'bold' }">{{ form.model == 'ims_product_order' ? '付款单' : '回款单' }}</span>
        </template>

        <!-- 表单 -->
        <el-form :model="form" size="mini" label-position="top">

            <!-- 基本信息 -->
            <el-divider content-position="left"><b>基本信息</b></el-divider>
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="支付日期" :rules="[{ required: true, message: '必填项' }]" :error="formError.date?.toString()" prop="date">
                        <el-date-picker v-model="form.date" type="date" value-format="yyyy-MM-dd" :style="{ width: '100%' }"></el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="支付金额">
                        <el-input-number v-model="form.money" :precision="2" :style="{ width: '100%' }"></el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="支付方式" :rules="[{ required: true, message: '必选项' }]" :error="formError.type_id?.toString()" prop="type_id">
                        <config-pay-type-select v-model="form.type_id" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="供应商" :rules="[{ required: true, message: '必选项' }]" :error="formError.supplier_id?.toString()" prop="supplier_id">
                        <el-select v-model="form.supplier_id" :style="{ width: '100%' }" clearable>
                            <el-option v-for="(item, idx) in supplier" :key="idx" :label="item.name" :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="备注">
                        <el-input type="textarea" :rows="3" v-model="form.describe"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>

            <!-- 审核流程 -->
            <el-divider content-position="left"><b>审核流程</b></el-divider>
            <workflow-update ref="workflow" v-model="form.workflow_template" model="ims-pay" init />

        </el-form>

        <div slot="footer">
            <el-button size="small" @click="visible = false">取 消</el-button>
            <el-button size="small" type="primary" @click="FormSubmit()" :loading="loading">确 定</el-button>
        </div>

    </el-dialog>
</template>

<script>
export default {
    data () {
        return {
            visible: false,
            loading: false,

            supplier: [],

            formError: {},
            form: {
                model: null,
                model_id: null,
                date: null,
                money: 0,
                type_id: null,
                supplier_id: null,
                describe: null
            }
        }
    },
    
    methods: {

        // 打开窗口
        Open(config) {

            // 默认选项
            if (config) {
                this.form.model = config.model
                this.form.model_id = config.model_id

                // 供应商查询
                if (config.model == 'ims_product_order') {
                    this.$http.post('/9api/ims/product-order/search-supplier', { id: config.model_id }).then(rps => {
                        this.supplier = rps.data
                    })
                }
                if (config.model == 'ims_product_back') {
                    this.$http.post('/9api/ims/product-back/search-supplier', { id: config.model_id }).then(rps => {
                        this.supplier = rps.data
                    })
                }
            }

            this.visible = true
            this.loading = false
        },

        // 关闭窗口
        Close() {
            this.form.model = null
            this.form.model_id = null
            this.form.date = null
            this.form.money = 0
            this.form.type_id = null
            this.form.supplier_id = null
            this.form.describe = null
        },

        // 提交表单
        FormSubmit() {
            // this.loading = true
            this.$http.post('/9api/ims/pay/create', this.form).then((rps) => {

                // 提交失败
                if (rps.data.state == 'error') {
                    this.formError = rps.data.body
                }

                // 提交成功
                if (rps.data.state == 'success') {
                    this.$emit('onChange', rps.data.body)
                    this.visible = false
                }

                this.loading = false
            })
        },
    }
}
</script>