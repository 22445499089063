<template>
    <div>
        <vxe-table border ref="xTable" :data="table" :loading="loading" :style="{ marginTop: '10px' }">
            <vxe-column min-width="120" title="产品名称">
                <template #default="{ row }">
                    <span :style="{ color: '#0027ff', cursor: 'pointer' }" @click="$refs.productDetails.Open(row.product.id)">{{ row.product.name }}</span>
                </template>
            </vxe-column>
            <vxe-column min-width="120" field="supplier.name" title="供应商">
                <template #default="{ row }">
                    <span :style="{ color: '#0027ff', cursor: 'pointer' }" @click="$refs.supplierDetails.Open(row.product.supplier.id)">{{ row.product.supplier.name }}</span>
                </template>
            </vxe-column>
            <vxe-column min-width="100" field="product.type.name" title="产品类别"></vxe-column>
            <vxe-column min-width="80" field="product.unit" title="计量单位"></vxe-column>
            <vxe-column min-width="100" title="规格参数">
                <template #default="{ row }">
                    {{ row.product.parameter?.toString() }}
                </template>
            </vxe-column>
            <vxe-column min-width="80" field="num" title="退货数量"></vxe-column>
            <vxe-column min-width="90" field="money" title="退货单价"></vxe-column>
            <vxe-column min-width="70" field="discount" title="合计">
                <template #default="{ row }">
                    {{ (row.money * row.num).toFixed(2) }}
                </template>
            </vxe-column>
        </vxe-table>

        <!-- 组件 -->
        <ims-product-drawer ref="productDetails" @onChange="TableSearch" />
        <ims-supplier-drawer ref="supplierDetails" @onChange="TableSearch" />

    </div>
</template>

<script>
export default {
    props: ['product_back_id'],

    data () {
        return {
            loading: false,
            table: [],
            warehouseEnter: []
        }
    },

    watch: {
        product_back_id: {
            handler(val) {
                if (val) {
                    this.TableSearch()
                }
            },
            immediate: true
        }
    },

    methods: {
        TableSearch() {
            this.loading = true
            this.$http.post( '/9api/ims/product-back-log/search-all', { search: { product_back_id: this.product_back_id }, append: ['product'] }).then(rps => {
                this.table = rps.data
                this.loading = false
            })
        },
    }
}
</script>