<template>
    <el-drawer :visible.sync="visible" size="80%" direction="rtl" append-to-body destroy-on-close :show-close="false" :before-close="Close">

        <div slot="title">

            <!-- 合同状态 -->
            <div class="w-margin-b-10">
                <span>库存调拨 - </span>
                <el-tag effect="dark" size="mini" v-if="move.state">已调拨</el-tag>
                <el-tag effect="dark" size="mini" v-if="!move.state" type="danger">已作废</el-tag>
            </div>
            
            <div class="w-margin-b-10" style="font-size: 18px; color: #000; font-weight: bold;">{{ move.number }} - {{ move.name }}</div>

            <!-- 功能按钮 -->
            <div style="display: flex;">
                <el-button size="mini" icon="el-icon-edit" @click="RePage()">更新信息</el-button>
                <el-button style="margin-left: 5px;" size="mini" @click="UpdateDelete()" type="danger">作废调拨</el-button>
            </div>

            <!-- 金额概览 -->
            <div class="w-margin-t-10" style="background-color: #f4f5f7; border-radius: 3px; padding: 15px; display: flex;">
                <el-statistic title="调入仓库"><template slot="formatter"><span style="font-size: 14px;">{{ move.enter_warehouse.name }}</span></template></el-statistic>
                <el-statistic title="调出仓库"><template slot="formatter"><span style="font-size: 14px;">{{ move.back_warehouse.name }}</span></template></el-statistic>
                <el-statistic title="负责人"><template slot="formatter"><span style="font-size: 14px;">{{ move.created_user.name }}</span></template></el-statistic>
                <el-statistic title="调拨时间"><template slot="formatter"><span style="font-size: 14px;">{{ move.date }}</span></template></el-statistic>
            </div>

        </div>

        <div class="w-padding-l-20 w-padding-r-20 w-padding-b-20">
            <el-tabs v-model="tabs">

                <!-- 调拨记录 -->
                <el-tab-pane label="调拨记录" name="log">
                    <ims-warehouse-move-drawer-log v-if="tabs == 'log'" model="ims_warehouse_move" :model_id="move.id" />
                </el-tab-pane>

            </el-tabs>
        </div>

        <!-- 组件 -->
        <ims-product-drawer ref="productDetails" @onChange="RePage" />
        <ims-supplier-drawer ref="supplierDetails" @onChange="RePage" />

    </el-drawer>
</template>

<script>
export default {
    data() {
        return {
            visible: false,
            loading: false,
            tabs: 'log',

            move: {
                id: null,
                created_user: {},
                updated_user: {},
                enter_warehouse: {},
                back_warehouse: {}
            }
        }
    },

    methods: {

        /**
        |--------------------------------------------------
        | 打开窗口
        |--------------------------------------------------
        |
        */

        Open(id) {
            this.visible = true
            this.DataGet(id)
        },

        /**
        |--------------------------------------------------
        | 关闭窗口
        |--------------------------------------------------
        |
        */

        Close(done) {
            this.$emit('onChange')
            done()
        },

        /**
        |--------------------------------------------------
        | 刷新
        |--------------------------------------------------
        |
        */

        RePage() {
            this.DataGet()
        },

        /**
        |--------------------------------------------------
        | 查询
        |--------------------------------------------------
        |
        */

        DataGet(id = this.move.id) {
            this.loading = true

            this.$http.post(
                '/9api/ims/warehouse-move/search-first',
                {
                    search: { id: id },
                    append: ['created_user', 'updated_user', 'back_warehouse', 'enter_warehouse']
                }
            ).then(rps => {
                this.move = rps.data
                this.loading = false
            }) 
        },

        // 作废
        UpdateDelete() {
            this.$confirm('作废后将不可恢复！', '警告', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'error'
            }).then(() => {
                this.$http.post('/9api/ims/warehouse-move/update-delete', { id: this.move.id }).then((rps) => {
                    this.$emit('onChange')
                    this.visible = false
                })
            })
        },
        
    }
}

</script>6
