<template>
    <el-dialog :visible.sync="visible" width="600px" append-to-body title="修改考核计划" :close-on-click-modal="false" :close-on-press-escape="false" :before-close="Close">
            
        <!-- 表单 -->
        <el-form :model="form" label-position="top" v-loading="loading">
            
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="计划名称" :rules="[{ required: true, message: '必填项' }]" :error="formError.name?.toString()" prop="name">
                        <el-input v-model="form.name"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="考核对象" :rules="[{ required: true, message: '必填项' }]" :error="formError.users_id?.toString()" prop="users_id">
                        <users-select v-model="form.users_id" multiple></users-select>
                    </el-form-item>
                </el-col>
            </el-row>

            <!-- 基础信息 -->
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="考核周期" :rules="[{ required: true, message: '必填项' }]" :error="formError.date?.toString()" prop="date">
                        <el-date-picker v-model="form.date" type="monthrange" value-format="yyyy-MM" :style="{ width: '100%' }" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="考核模板" :rules="[{ required: true, message: '必填项' }]" :error="formError.template_id?.toString()" prop="template_id">
                        <hrm-kpi-template-select v-model="form.template_id" />
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="考核说明">
                        <el-input type="textarea" :rows="3" v-model="form.describe" />
                    </el-form-item>
                </el-col>
            </el-row>

        </el-form>

        <span slot="footer">
            <el-button @click="Close()">取 消</el-button>
            <el-button type="primary" @click="SubmitForm()" :loading="loading">确 定</el-button>
        </span>
        
    </el-dialog>
</template>

<script>
export default {
    data () {
        return {
            visible: false,

            formError: { },
            form: {
                id: null,
                name: null,
                users_id: null,
                date: null,
                template_id: null,
                describe: null
            },
        }
    },

    computed: {
        loading () {
            return !!this.$store.state.httpRequestCount
        }
    },

    methods: {

        // 窗口 - 打开
        Open(id)
        {
            this.$http.post(
                '/9api/hrm/kpi-plan/search-first',
                {
                    search: { id: id },
                    append: ['created_users', 'updated_users'],
                    hidden: ['template']
                }
            ).then(rps => {
                this.form = rps.data
                this.visible = true
            })
        },

        // 窗口 - 关闭
        Close()
        {
            this.visible = false
        },

        // 表单 - 提交
        async SubmitForm ()
        {
            this.$http.post('/9api/hrm/kpi-plan/update', this.form).then(rps => {
                if (rps.data.state == 'error') {
                    this.formError = rps.data.body
                }
                if (rps.data.state == 'success') {
                    this.$emit('onChange', rps.data.body)
                    this.Close()
                }
            })
        },
    }
}
</script>