<!---------------------------------------------------------------------------- 
 | 工单模板 - 表单创建
 |----------------------------------------------------------------------------
 | 方法：请注意大小写,参数描述见具体方法
 | *v-model  默认配置
 | *id       模板ID 
 |----------------------------------------------------------------------------
 | 方法
 | Validate() 验证表单
 |
 ---------------------------------------------------------------------------->

<template>
    <div>

        <el-empty description="暂未配置表单" v-if="data_dict.length == 0" style="grid-column-start: span 2;"></el-empty>

        <el-descriptions size="mini" border :column="2">
            <el-descriptions-item v-for="(item, idx) in data_dict" :key="idx" :label="item.name">

                <el-link v-if="['text', 'int', 'float', 'date', 'time', 'datetime', 'select', 'radio'].includes(item.type)" :underline="false">{{ item.form.value }}</el-link>

                <el-link v-if="item.type == 'textarea'" :underline="false" :span="2">{{ item.form.value }}</el-link>

                <el-link v-if="['date-range', 'time-range', 'datetime-range'].includes(item.type)" :underline="false">
                    <span v-if="item.form.value">{{ item.form.value[0] }} 至 {{ item.form.value[1] }}</span>
                </el-link>

                <el-link v-if="['select-multiple', 'checkbox'].includes(item.type)" :underline="false">
                    {{ item.form.value.toString() }}
                </el-link>

            </el-descriptions-item>
        </el-descriptions>
    </div>
</template>

<script>
export default {
    props: {
        value: Array,
        id: Number,
    },

    data () {
        return {
            formError: {},
            data_dict: []
        }
    },

    watch: {
        'id': {
            handler (val) {
                if(val) {
                    // 查询模板配置
                    this.$http.post('/9api/wo-template/search-first', { search: { id: val }, append: ['data_dict'] }).then(rps => {
                        if (rps.data) {
                            this.data_dict = rps.data.data_dict
                            this.Diff()
                        }
                    })
                } else {
                    this.data_dict = []
                }
            },
            immediate: true
        },

        'value': {
            handler () {
                this.Diff()
            },
            immediate: true
        }
    },

    methods: {
        Diff() {
            // 初始化数据字典配置
            this.data_dict.forEach((item) => {
                // 判断用户是否传入默认值
                if (this.value) {
                    let setValue = this.value.find((element) => element.id == item.id)
                    item.form.value = setValue ? setValue.value : item.form.value
                }
            })
        }
    }
}
</script>