<template>
  <el-drawer class="w-el-drawer" :visible.sync="visible" :size="960" :with-header="false" direction="rtl" :before-close="Close" append-to-body destroy-on-close>

      <!-- header -->
      <div class="w-el-drawer-header">
          <i class="el-icon-tickets"></i> 编辑工单模板
      </div>

      <!-- body -->
      <div class="w-el-drawer-body"  v-loading="loading">

          <!-- 表单 -->
          <el-form :model="form" label-width="120px" size="mini">

              <!-- 模板名称 -->
              <el-form-item label="工单模板名称" :rules="[{ required: true, message: '必填项' }]" :error="formError.name?.toString()" prop="name">
                  <el-input v-model="form.name" placeholder="请输入模板名称" clearable />
              </el-form-item>

              <!-- 关联合同 -->
              <el-form-item label="关联合同">
                  <el-input placeholder="*********" clearable disabled />
              </el-form-item>

              <!-- 关联项目 -->
              <el-form-item label="关联项目">
                  <el-input placeholder="*********" clearable disabled />
              </el-form-item>

              <!-- 关联任务 -->
              <el-form-item label="关联任务">
                  <el-input placeholder="*********" clearable disabled />
              </el-form-item>

              <!-- 关联任务 -->
              <el-form-item label="关联客户" :rules="[{ required: true, message: '必填项' }]">
                  <el-input placeholder="*********" clearable disabled />
              </el-form-item>
              
              <!-- 工单名称 -->
              <el-form-item label="工单名称" :rules="[{ required: true, message: '必填项' }]">
                  <el-input placeholder="*********" clearable disabled />
              </el-form-item>

              <!-- 紧急程度 & 工单单号 -->
              <el-row>
                  <el-col :span="12">
                      <el-form-item label="紧急程度" :rules="[{ required: true, message: '必填项' }]">
                          <el-input placeholder="次要/一般/重要/紧急" clearable disabled />
                      </el-form-item>
                  </el-col>
                  <el-col :span="12">
                      <el-form-item label="工单单号" :rules="[{ required: true, message: '必填项' }]">
                          <el-input placeholder="GD*********" clearable disabled />
                      </el-form-item>
                  </el-col>
              </el-row>

                <!-- 收单人 & 协同人 -->
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="收单人" :rules="[{ required: true, message: '必填项' }]">
                            <el-input placeholder="*********" clearable disabled />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="协同人">
                            <el-input placeholder="*********" clearable disabled />
                        </el-form-item>
                    </el-col>
                </el-row>

                <!-- 管理员 & 派单人 -->
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="管理员" :rules="[{ required: true, message: '必填项' }]">
                            <el-input placeholder="*********" clearable disabled />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="派单人"  :rules="[{ required: true, message: '必填项' }]">
                            <el-input placeholder="*********" clearable disabled />
                        </el-form-item>
                    </el-col>
                </el-row>

                <!-- 工单周期 & 预估工时/P -->
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="工单周期" :rules="[{ required: true, message: '必填项' }]">
                            <el-date-picker style="width: 100%;" disabled type="daterange" value-format="yyyy-MM-dd" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="预估工时/P"  :rules="[{ required: true, message: '必填项' }]">
                            <el-input placeholder="0" clearable disabled />
                        </el-form-item>
                    </el-col>
                </el-row>

              <el-divider content-position="left">自定义表单</el-divider>

              <data-dict-checkbox model="wo" v-model="form.template" />

          </el-form>
      </div>

      <!-- footer -->
      <div class="w-el-drawer-footer">
          <el-button size="small" @click="Close()">取消 / 返回</el-button>
          <el-button size="small" type="primary" @click="SubmitForm()" :loading="loading">确认更新</el-button>
      </div>

      
  </el-drawer>
</template>

<script>
export default {
  data() {
      return {
          // 窗口
          visible: false,
          loading: false,

          // 表单
          formError: {},
          form: {
              id: null,
              name: null,
              template: []
          },

          formData: [],
          formDataObj: {},
          name: '',
          index: 0,
          is_active_input: false,
      }
  },
  methods: {

      /**
      |--------------------------------------------------
      | 窗口 - 打开
      |--------------------------------------------------
      |
      */

      Open(id) {
          this.visible = true
          this.loading = true
          this.formError = {}

          this.$http.post('/9api/wo-template/search-first', { search: { id: id } }).then((rps) => {
              this.form.id = rps.data.id
              this.form.name = rps.data.name
              this.form.template = rps.data.template

              this.loading = false
          })
      },

      /**
      |--------------------------------------------------
      | 窗口 - 关闭
      |--------------------------------------------------
      |
      */

      Close() {
          this.visible = false
          this.loading = false
      },

      /**
      |--------------------------------------------------
      | 提交表单
      |--------------------------------------------------
      |
      */

      SubmitForm() {
          this.loading = true

          this.$http.post('/9api/wo-template/update', this.form).then((rps) => {
              // 提交失败
              if (rps.data.state == 'error') {
                  this.formError = rps.data.body
              }
              // 提交成功
              if (rps.data.state == 'success') {
                  this.$emit('onChange', rps.data.body)
                  this.Close()
              }

              this.loading = false
          })
      },
  }
}
</script>