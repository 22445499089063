<!---------------------------------------------------------------------------- 
 | 审批管理
 |----------------------------------------------------------------------------
 | 方法：请注意大小写,参数描述见具体方法
 | $refs[ref_name].Update() 更新数据
 |----------------------------------------------------------------------------
 | 回调
 | @onChange = fn () => {} 操作后回调
 |
 ---------------------------------------------------------------------------->

 <template>
    <!-- 金额概览 -->
    <el-card class="box-card" :style="{ marginTop: '20px' }">
        <div slot="header">
            <div>
                <i class="el-icon-guide" :style="{ marginRight: '5px', color: '#0027ff' }"></i>
                <span>审核流程</span>
                <el-divider direction="vertical"></el-divider>
                <el-radio-group v-model="tabs">
                    <el-radio-button label="flow">流程</el-radio-button>
                    <el-radio-button label="log">记录</el-radio-button>
                </el-radio-group>
            </div>
            <div>
                <div v-if="workflow.progress">
                    <el-button :style="{ marginLeft: '5px' }" type="primary" @click="visibleBack = true">撤销 / 再提交</el-button>
                    <el-button :style="{ marginLeft: '5px' }" type="primary" @click="visibleConsent = true" v-if="workflow.approve_auth">通过</el-button>
                    <el-button :style="{ marginLeft: '5px' }" type="danger"  @click="visibleRefuse = true"  v-if="workflow.approve_auth">拒绝</el-button>
                </div>
            </div>
        </div>

        <el-empty description="未配置审核流程" v-if="tabs == 'flow' && !workflow.progress"></el-empty>
        <el-empty description="暂无审核记录" v-if="tabs == 'log' && !workflow_log.length"></el-empty>

        <!-- 审核流程 -->
        <el-timeline :reverse="true" v-if="tabs == 'flow'">
            <el-timeline-item
                v-for="(item, idx) in workflow.progress"
                :key="idx"
                :type="!item.state ? 'info' : (item.state == 1 ? 'primary' : (item.state == 2 ? 'danger' : (item.state == 3 ? 'success' : 'warning')))">
                
                <!-- 审核状态 -->
                <div>
                    <el-link type="info"    :underline="false" v-if="!item.state">待审核</el-link>
                    <el-link type="primary" :underline="false" v-if="item.state == 1">审核中</el-link>
                    <el-link type="danger"  :underline="false" v-if="item.state == 2">已拒绝</el-link>
                    <el-link type="success" :underline="false" v-if="item.state == 3">已通过</el-link>
                    <el-link type="warning" :underline="false" class="w-margin-l-10">{{ item.multiple ? '会签 - 需要所有人同意' : '或签 - 需要其中一人同意' }}</el-link>
                </div>

                <!-- 已审核 -->
                <div :style="{ marginTop: '5px' }" v-if="item.users_verify.length > 0">
                    <el-link :underline="false" type="info">已审核：</el-link>
                    <el-link :underline="false" type="primary" v-for="e in item.users.filter(x => item.users_verify.indexOf(x) !== -1)" :key="e">
                        {{ workflow.users.find((item) => item.id == e ).name + '，' }}
                    </el-link>
                </div>

                <!-- 待审核 -->
                <div :style="{ marginTop: '5px' }" v-if="item.users_verify.length != item.users.length">
                    <el-link :underline="false" type="info">待审核：</el-link>
                    <el-link :underline="false" type="info" v-for="e in item.users.filter(x => item.users_verify.indexOf(x) === -1)" :key="e">
                        {{ workflow.users.find((item) => item.id == e ).name + '，' }}
                    </el-link>
                </div>
                
                <!-- 抄送人 -->
                <div :style="{ marginTop: '5px' }" v-if="item.cc.length > 0">
                    <el-link :underline="false" type="info">抄送给：</el-link>
                    <el-link :underline="false" type="info" v-for="e in item.cc" :key="e">
                        {{ workflow.users.find((item) => item.id == e ).name + '，' }}
                    </el-link>
                </div>

            </el-timeline-item>
        </el-timeline>

        <!-- 审核记录 -->
        <el-timeline :reverse="true" v-if="tabs == 'log'">
            <el-timeline-item
                v-for="(item, idx) in workflow_log"
                :key="idx"
                :type="!item.event_state ? 'danger' : (item.event_state == 1 ? 'success' : (item.event_state == 2 ? 'primary' : (item.event_state == 3 ? 'warning' : 'info')))">

                <!-- 审核结果 -->
                <div>
                    <el-link class="w-margin-r-5" :underline="false" type="warning" v-if="item.event_state == 3">申请撤销</el-link>
                    <el-link class="w-margin-r-5" :underline="false" type="primary" v-if="item.event_state == 2">提交合同</el-link>
                    <el-link class="w-margin-r-5" :underline="false" type="success" v-if="item.event_state == 1">同意</el-link>
                    <el-link class="w-margin-r-5" :underline="false" type="danger"  v-if="item.event_state == 0">拒绝</el-link>
                    <el-link class="w-margin-r-5" :underline="false" type="info">{{ item.users.name }}</el-link>
                    <el-link class="w-margin-r-5" :underline="false" type="info">{{ item.updated_at }}</el-link>
                </div>
                
                <!-- 审核描述 -->
                <div :style="{ whiteSpace: 'break-spaces', marginTop: '10px' }">
                    {{ item.event_describe }}
                </div>

            </el-timeline-item>
        </el-timeline>

        <!-- 审片意见（通过） -->
        <el-dialog class="w-el-dialog" :visible.sync="visibleConsent" width="600px" append-to-body title="审片意见（通过）">
            <el-input type="textarea" :rows="6" placeholder="审批意见" v-model="event_describe"></el-input>
            <span slot="footer" class="dialog-footer">
                <el-button @click="visibleConsent = false">取 消</el-button>
                <el-button type="success" @click="WorkflowConsent()" :loading="$store.state.loading">同 意</el-button>
            </span>
        </el-dialog>

        <!-- 审片意见（拒绝） -->
        <el-dialog class="w-el-dialog" :visible.sync="visibleRefuse" width="600px" append-to-body title="审片意见（拒绝）">
            <el-input type="textarea" :rows="6" placeholder="审批意见" v-model="event_describe"></el-input>
            <span slot="footer" class="dialog-footer">
                <el-button @click="visibleRefuse = false">取 消</el-button>
                <el-button type="danger" @click="WorkflowRefuse()" :loading="$store.state.loading">拒 绝</el-button>
            </span>
        </el-dialog>

        <!-- 撤销申请/重新提交 -->
        <el-dialog class="w-el-dialog" :visible.sync="visibleBack" width="600px" append-to-body title="撤销申请/重新提交">
            <el-input type="textarea" :rows="6" placeholder="申请描述" v-model="event_describe"></el-input>
            <span slot="footer" class="dialog-footer">
                <el-button @click="visibleBack = false">取 消</el-button>
                <el-button type="danger" @click="WorkflowBack()" :loading="$store.state.loading">提 交</el-button>
            </span>
        </el-dialog>
    </el-card>
</template>

<script>
export default {
    data () {
        return {
            // 窗口状态
            tabs: 'flow',

            visibleConsent: false,
            visibleRefuse: false,
            visibleBack: false,

            workflow: {},
            workflow_log: [],

            event_describe: null,
        }
    },

    props: {
        value: {
            type: Number,
            default: null
        }
    },

    watch: {
        value: {
            handler() {
                this.WorkflowSearch()
            },
            immediate: true
        }
    },

    methods: {

        /**
        |--------------------------------------------------
        | 审批信息查询
        |--------------------------------------------------
        |
        */
        
        WorkflowSearch() {
            // 用户有传递值
            if (this.value) {
                this.$http.post('/9api/workflow/first', { id: this.value, append: ['users', 'approve_auth'] }).then(rps => {
                    this.workflow = rps.data
                })
                this.$http.post('/9api/workflow/log', { workflow_id: this.value, append: ['users'] }).then(rps => {
                    this.workflow_log = rps.data
                })
            }

            // 用户没有传递值
            if (!this.value) {
                this.workflow = {}
                this.workflow_log = []
            }
        },

        /**
        |--------------------------------------------------
        | 审批通过
        |--------------------------------------------------
        |
        */

        WorkflowConsent() {
            this.$http.post('/9api/workflow/approve', {id: this.workflow.id, event_state: 1, event_describe: this.event_describe}).then(rps => {
                this.WorkflowSearch()
                this.$emit('onChange')
                this.visible = false
                this.visibleConsent = false
                this.visibleRefuse = false
            })
        },

        /**
        |--------------------------------------------------
        | 审批拒绝
        |--------------------------------------------------
        |
        */

        WorkflowRefuse() {
            this.$http.post('/9api/workflow/approve', {id: this.workflow.id, event_state: 0, event_describe: this.event_describe}).then(rps => {
                this.WorkflowSearch()
                this.$emit('onChange')
                this.visible = false
                this.visibleConsent = false
                this.visibleRefuse = false
            })
        },

        /**
        |--------------------------------------------------
        | 撤销申请
        |--------------------------------------------------
        |
        */

        WorkflowBack() {
            this.$http.post('/9api/workflow/back', {id: this.workflow.id, event_describe: this.event_describe}).then(rps => {
                this.WorkflowSearch()
                this.$emit('onChange')
                this.visible = false
                this.visibleConsent = false
                this.visibleRefuse = false
            })
        },
        
    }  
}
</script>