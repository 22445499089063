<template>
    <div class="uw-menu-sub">

        <div :class="['uw-menu-sub-title', isActive ? 'is-active' : '']" :style="{ fontWeight: bold ? 'bold' : 'normal' }">
            <slot name="title">
                <i v-if="showIcon && icon" :class="icon"></i>
                <span v-if="showTitle && title">{{ title }}</span>
            </slot>
        </div>
        <div class="uw-menu-sub-content">
            <div class="uw-menu-sub-content-grid" :style="{ gridTemplateColumns: 'repeat(' + gridColumn + ', 1fr)', gap: gridGutter + 'px' }">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        gridColumn: {
            type: Number,
            default: 5
        },
        gridGutter: {
            type: Number,
            default: 30
        },
        title: {
            type: String,
            default: null
        },
        icon: {
            type: String,
            default: null
        },
        bold: {
            type: Boolean,
            default: false
        },
        showIcon: {
            type: [Boolean, Number],
            default: true
        },
        showTitle: {
            type: [Boolean, Number],
            default: true
        }
    },

    data () {
        return {
            isActive: false,
        }
    },

    watch: {
        '$slots.default': {
            handler (newSlots) {
                this.isActive = !!this.$slots.default.find(rps => rps.componentInstance?.getIsActive() == true)
            },
            immediate: true,
            deep: true
        }
    },

    updated()
    {
        this.isActive = !!this.$slots.default.find(rps => rps.componentInstance?.getIsActive() == true)
    }
}
</script>