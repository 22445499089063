<!---------------------------------------------------------------------------- 
 | 表单 - 客户选择
 |----------------------------------------------------------------------------
 | 方法：请注意大小写,参数描述见具体方法
 | *v-model     默认值------默认为 null
 |----------------------------------------------------------------------------
 | 回调
 | @onChange = fn (data) => {} 返回表单内容
 |
 ---------------------------------------------------------------------------->

 <template>
    <div>
        <el-input
            v-if="showInput"
            v-model="selectName"
            @focus="Open"
            @clear="Clear"
            placeholder="选择模板"
            clearable
            :disabled="disabled"
            :style="{ width: width, margin: margin }"/>

        <el-dialog :visible.sync="visible" width="900px" append-to-body title="选择模板" :close-on-click-modal="false" :close-on-press-escape="false">
            
            <!-- toolbar -->
            <uw-grid-search gutter="0 10px" :column="3" :style="{ marginBottom: '10px' }">
                <template slot="full">
                    <el-input @input="TableSearch" v-model="search.name" clearable  placeholder="模板名称"></el-input>
                </template>
            </uw-grid-search>

            <!-- table -->
            <vxe-table :data="table" :loading="loading" @cell-dblclick="TableCellDblclick" :seq-config="{startIndex: page.size * (page.current - 1)}" height="500px">

                <!-- 模板名称 -->
                <vxe-column min-width="180" field="name" title="模板名称">
                    <template #default="{ row }">
                        <span class="el-span-primary" @click="$refs.templateDrawer.Open(row.id)">{{ row.name }}</span>
                    </template>
                </vxe-column>

                <!-- 考核维度 -->
                <vxe-column min-width="80" title="考核维度">
                    <template #default="{ row }">
                        {{ row.class.length }}
                    </template>
                </vxe-column>

                <!-- 考核指标 -->
                <vxe-column min-width="80" title="考核子项">
                    <template #default="{ row }">
                        {{  row.class.flatMap(value => value.children).length  }}
                    </template>
                </vxe-column>

                <!-- 考评流程 -->
                <vxe-column min-width="80" title="考评流程">
                    <template #default="{ row }">
                        {{ row.flow.length }}
                    </template>
                </vxe-column>

                <!-- 系统信息 -->
                <vxe-column min-width="80" field="created_users.name" title="创建人"></vxe-column>
                <vxe-column min-width="80" field="updated_users.name" title="更新人"></vxe-column>
                <vxe-column min-width="160" field="created_at" title="创建时间" ></vxe-column>
                <vxe-column min-width="160" field="updated_at" title="更新时间" ></vxe-column>

            </vxe-table>

            <!-- pager -->
            <vxe-pager :style="{ marginTop: '10px' }" :total="page.total" :page-size.sync="page.size" :current-page.sync="page.current" @page-change="TableSearch()" />

        </el-dialog>

        <!-- 组件 -->
        <hrm-kpi-template-drawer ref="templateDrawer" @onChange="TableSearch()" />

    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: [String, Array, Number],
            default: null
        },

        width: {
            type: String,
            default: '100%'
        },

        margin: {
            type: String,
            default: '0px'
        },

        disabled: {
            type: Boolean,
            default: false
        },

        showInput: {
            type: Boolean,
            default: true
        }
    },

    watch: {
        value: {
            handler(val) {
                if (val) {
                    this.$http.post('/9api/hrm/kpi-template/search-first', { id: val }).then(rps => {
                        this.selectName = rps.data.name
                        this.$emit('onChange', rps.data)
                    })
                } else {
                    this.Clear()
                }
            },
            immediate: true
        }
    },

    data () {
        return {
            visible: false,
            
            table: [],

            // 分页
            page: {
                total: 0,
                size: 50,
                current: 1,
            },

            // 检索
            search: {
                name: null
            },

            // 排序
            order: {
                method: 'desc',
                column: 'id'
            },

            selectObject: {},
            selectName: null
        }
    },

    // 计算熟悉：
    computed: {
        loading ()
        {
            return !!this.$store.state.httpRequestCount
        }
    },

    methods: {

        // 窗口：打开
        Open(search)
        {
            // 初始化检索条件
            if (search)
            {
                this.search = { ...this.search, ...search }
            }

            this.visible = true
            this.TableSearch()
        },

        // 数据：查询
        TableSearch()
        {
            this.$http.post(
                '/9api/hrm/kpi-template/search',
                {
                    page: this.page,
                    search: this.search,
                    order: this.order,
                    append: ['created_users', 'updated_users']
                }
            ).then((rps) => {

                // 更新数据
                this.page.total = rps.data.total
                this.table = rps.data.body

                // 页码溢出
                if (this.table.length == 0 && this.page.current != 1) {
                    this.page.current = 1
                    this.TableSearch()
                }
            })
        },

        // 表格：双击事件
        TableCellDblclick({ row })
        {
            this.selectName = row.name
            this.selectObject = row
            this.$emit('input', row.id)
            this.$emit('onChange', row)
            this.visible = false
        },

        // 选项：清除
        Clear()
        {
            this.selectName = null
            this.selectObject = {}
            this.$emit('input', null)
            this.$emit('onChange', null)
        },
    }
}
</script>