<template>
    <div>
        <el-input
            v-if="showInput"
            v-model="select.inputValue"
            @focus="Open"
            @clear="SelectClear"
            placeholder="合同"
            clearable
            :disabled="disabled"
            :style="{ width: width, margin: margin }"/>

        <el-dialog :visible.sync="dialogVisible" width="1000px" append-to-body title="合同" :close-on-click-modal="false" :close-on-press-escape="false">

            <!-- 提示 -->
            <el-alert title="提示：双击鼠标左键 进行选择" type="info" close-text="知道了" show-icon :style="{ marginBottom: '10px' }" />

            <!-- toolbar -->
            <uw-grid-search gutter="0 10px" :column="4" :style="{ marginBottom: '10px' }">
                <template slot="full">
                    <el-input v-model="post.search.number" @input="$lodash.debounce(TableSearch, 500)" clearable placeholder="合同编号" />
                </template>
                <el-form-item label="合同名称">
                    <el-input @input="$lodash.debounce(TableSearch, 500)" clearable  v-model="post.search.name" placeholder="请输入合同名称"></el-input>
                </el-form-item>
                <el-form-item label="审批状态">
                    <el-select v-model="post.search.workflow_state" @change="TableSearch" placeholder="审批状态" clearable :style="{ width: '100%' }">
                        <el-option label="无需审批" :value=0></el-option>
                        <el-option label="待审批" :value=1></el-option>
                        <el-option label="审批中" :value=2></el-option>
                        <el-option label="已拒绝" :value=3></el-option>
                        <el-option label="已通过" :value=4></el-option>
                        <el-option label="待撤销" :value=5></el-option>
                        <el-option label="撤销中" :value=6></el-option>
                        <el-option label="拒绝撤销" :value=7></el-option>
                        <el-option label="已撤销" :value=8></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="客户名称">
                    <crm-customer-select v-model="post.search.customer_id" @onChange="TableSearch"/>
                </el-form-item>
                <el-form-item label="合同状态">
                    <contract-state-select v-model="post.search.state" @onChange="TableSearch" clearable />
                </el-form-item>
                <el-form-item label="销售">
                    <users-select v-model="post.search.users_sale_ids" @onChange="TableSearch" multiple placeholder="销售查询" />
                </el-form-item>
                <el-form-item label="售前">
                    <users-select v-model="post.search.users_presale_ids" @onChange="TableSearch" multiple placeholder="售前查询" />
                </el-form-item>
                <el-form-item label="商务">
                    <users-select v-model="post.search.users_affair_ids" @onChange="TableSearch" multiple placeholder="商务查询" />
                </el-form-item>
                <el-form-item label="管理员">
                    <users-select v-model="post.search.users_admin_ids" @onChange="TableSearch" multiple placeholder="管理员查询" />
                </el-form-item>
                <el-form-item label="签单日期">
                    <el-date-picker @change="TableSearch" v-model="post.search.sign_date" clearable type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :style="{ width: '100%' }"></el-date-picker>
                </el-form-item>
                <el-form-item label="创建日期">
                    <el-date-picker @change="TableSearch" v-model="post.search.created_at" clearable type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :style="{ width: '100%' }"></el-date-picker>
                </el-form-item>
            </uw-grid-search>

            <!-- table -->
            <vxe-table ref="xTable" :data="table" :loading="$store.state.loading" @sort-change="TableSort" @cell-dblclick="TableCellDblclick" height="500px">

                <!-- 序号 -->
                <vxe-column width="60" type="seq" title="序号"></vxe-column>

                <!-- 合同编号 -->
                <vxe-column min-width="100" field="number" title="合同编号" sortable>
                    <template #default="{ row }">
                        <span class="el-span-primary" @click="$refs.contractDrawer.Open(row.id)">{{ row.number }}</span>
                    </template>
                </vxe-column>

                <!-- 合同名称 -->
                <vxe-column min-width="360" field="name" title="合同名称" sortable>
                    <template #default="{ row }">
                        <!-- 审批判断 -->
                        <el-tag v-if="row.workflow_state == 0" size="mini" effect="dark" type="info">无审核</el-tag>
                        <el-tag v-if="row.workflow_state == 1" size="mini" effect="dark" type="warning">待审核</el-tag>
                        <el-tag v-if="row.workflow_state == 2" size="mini" effect="dark" >审核中</el-tag>
                        <el-tag v-if="row.workflow_state == 3" size="mini" effect="dark" type="danger">已拒绝</el-tag>
                        <el-tag v-if="row.workflow_state == 4" size="mini" effect="dark" type="success">已通过</el-tag>
                        <el-tag v-if="row.workflow_state == 5" size="mini" effect="dark" type="warning">待撤销</el-tag>
                        <el-tag v-if="row.workflow_state == 6" size="mini" effect="dark" type="warning">撤销中</el-tag>
                        <el-tag v-if="row.workflow_state == 7" size="mini" effect="dark" type="danger">拒绝撤销</el-tag>
                        <el-tag v-if="row.workflow_state == 8" size="mini" effect="dark" type="warning">已撤销</el-tag>
                        <!-- 草稿判断 -->
                        <el-tag v-if="row.project_id" size="mini" effect="plain" class="w-margin-l-5">项</el-tag>
                        <el-tag v-if="row.files_id.length > 0" size="mini" effect="plain" type="success" class="w-margin-l-5">附</el-tag>
                        <el-tag v-if="row.draft" size="mini" effect="plain" type="warning" class="w-margin-l-5">稿</el-tag>
                        <!-- 项目名称 -->
                        {{ row.name }}
                    </template>
                </vxe-column>

                <!-- 客户单位 -->
                <vxe-column min-width="250" field="customer.name" title="客户单位"></vxe-column>

                <!-- 预估成本 -->
                <vxe-column width="140" field="estimate_money" title="预估成本/￥" sortable>
                    <template #default="{ row }">
                        <el-link type="warning">{{ row.estimate_money.toLocaleString() }}</el-link>
                    </template>
                </vxe-column>

                <!-- 签单金额 -->
                <vxe-column width="140" field="sign_money" title="签单金额/￥" sortable>
                    <template #default="{ row }">
                        <el-link type="primary">{{ row.sign_money.toLocaleString() }}</el-link>
                    </template>
                </vxe-column>

                <!-- 签单日期 -->
                <vxe-column width="160" field="sign_date" title="签单日期" sortable>
                    <template #default="{ row }">
                        <el-link type="primary" icon="el-icon-time">{{ row.sign_date }}</el-link>
                    </template>
                </vxe-column>

                <!-- 团队 -->
                <vxe-column width="90" title="销售">
                    <template #default="{ row }">
                        <el-link icon="el-icon-user">{{ row.users_sale.name }}</el-link>
                    </template>
                </vxe-column>
                <vxe-column width="90" title="商务">
                    <template #default="{ row }">
                        <el-link icon="el-icon-user">{{ row.users_affair.name }}</el-link>
                    </template>
                </vxe-column>
                <vxe-column width="90" title="售前">
                    <template #default="{ row }">
                        <el-link icon="el-icon-user">{{ row.users_presale.name }}</el-link>
                    </template>
                </vxe-column>
                <vxe-column width="90" title="管理员">
                    <template #default="{ row }">
                        <el-link icon="el-icon-user">{{ row.users_admin.name }}</el-link>
                    </template>
                </vxe-column>

                <!-- 合同状态 -->
                <vxe-column width="160" field="state" title="合同状态" sortable></vxe-column>

                <!-- 更新日期 -->
                <vxe-column width="160" field="updated_at" title="更新日期" sortable>
                    <template #default="{ row }">
                        <el-link icon="el-icon-time">{{ row.updated_at }}</el-link>
                    </template>
                </vxe-column>

                <!-- 创建日期 -->
                <vxe-column width="160" field="created_at" title="创建日期" sortable>
                    <template #default="{ row }">
                        <el-link icon="el-icon-time">{{ row.created_at }}</el-link>
                    </template>
                </vxe-column>

            </vxe-table>

            <!-- pager -->
            <vxe-pager 
                :style="{ marginTop: '10px' }"
                :total="post.page.total"
                :page-size.sync="post.page.size"
                :current-page.sync="post.page.current"
                @page-change="TableSearch()">
            </vxe-pager>

        </el-dialog>

        <!-- 组件 -->
        <crm-contract-drawer ref="contractDrawer" @onChange="TableSearch" />

    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: [String, Array, Number],
            default: null
        },

        width: {
            type: String,
            default: '100%'
        },

        margin: {
            type: String,
            default: '0px'
        },

        disabled: {
            type: Boolean,
            default: false
        },

        showInput: {
            type: Boolean,
            default: true
        }
    },

    data () {
        return {
            dialogVisible: false,

            // 查询结果
            table: [],

            // 查询链接
            url: {
                default: '/9api/crm/contract/search',
                first: '/9api/crm/contract/search-first'
            },

            // 查询条件
            post: {
                page: {
                    total: 0,
                    size: 50,
                    current: 1,
                },
                search: {
                    number: null,
                    name: null,
                    workflow_state: null,
                    customer_id: null,
                    state: null,
                    users_sale_ids: null,
                    users_presale_ids: null,
                    users_affair_ids: null,
                    users_admin_ids: null,
                    sign_date: [],
                    created_at: [],
                    auth_employee: false
                },
                append: ['customer', 'users_sale', 'users_admin', 'users_affair', 'users_presale'],
                hidden: ['describe'],
                order: {
                    method: 'desc',
                    column: 'id'
                },
            },

            // 选择结果
            select: {
                data: {},
                inputValue: null
            }
        }
    },

    watch: {
        value: {
            handler(val) {
                val ? this.SelectSearch() : this.SelectClear()
            },
            immediate: true
        }
    },

    methods: {

        // 窗口：打开
        Open(search)
        {
            // 初始化检索条件
            if (search)
            {
                this.post.search = { ...this.post.search, ...search }
            }

            this.dialogVisible = true
            this.TableSearch()
        },

        // 数据：查询
        TableSearch()
        {
            this.$http.post(this.url.default, this.post).then((rps) => {
                // 更新数据
                this.post.page.total = rps.data.total
                this.table = rps.data.body

                // 页码溢出
                if (this.table.length == 0 && this.post.page.current != 1)
                {
                    this.post.page.current = 1
                    this.TableSearch()
                }
            })
        },

        // 表格：排序
        TableSort(column)
        {
            this.post.order.method = column.order ? column.order : this.post.order.method
            this.post.order.column = column.field
            this.TableSearch()
        },

        // 表格：双击事件
        TableCellDblclick({ row })
        {
            this.select.inputValue = row.name
            this.select.data = row
            this.$emit('input', row.id)
            this.$emit('onChange', row)
            this.dialogVisible = false
        },

        // 选择：查询
        SelectSearch()
        {
            this.$http.post(this.url.first, { id: this.value }).then(rps => {
                this.select.inputValue = rps.data.name
                this.select.data = rps.data
                this.$emit('input', rps.data.id)
                this.$emit('onChange', rps.data)
            })
        },

        // 选择：清除
        SelectClear()
        {
            this.select.inputValue = null
            this.select.data = {}
            this.$emit('input', null)
            this.$emit('onChange', null)
        },
    }
}
</script>