<template>
    <el-drawer :visible.sync="visible" size="80%" direction="rtl" append-to-body destroy-on-close :show-close="false" :before-close="Close">

        <div slot="title">

            <div class="w-margin-b-10">客户管理</div>
            <div class="w-margin-b-10" style="font-size: 18px; color: #000; font-weight: bold;">{{ customer.name }}</div>

            <!-- 功能按钮 -->
            <div style="display: flex;">
                <el-button size="mini" icon="el-icon-edit" @click="RePage()">刷新</el-button>
                <el-button style="margin-left: 5px;" size="mini" icon="el-icon-edit" @click="$refs.dialogUpdate.Open(customer.id)">编辑客户</el-button>
                <el-button style="margin-left: 5px;" size="mini" @click="DataDelete()" type="danger">移除客户</el-button>
            </div>

            <!-- 基本信息 -->
            <div class="w-margin-t-10" style="background-color: #f4f5f7; border-radius: 3px; padding: 15px; display: flex;">
                <el-statistic title="客户编号"><template slot="formatter"><span style="font-size: 14px;">{{ customer.number }}</span></template></el-statistic>
                <el-statistic title="客户种类"><template slot="formatter"><span style="font-size: 14px;">{{ customer.type || '未登记' }}</span></template></el-statistic>
                <el-statistic title="工作电话"><template slot="formatter"><span style="font-size: 14px;">{{ customer.work_phone || '未登记' }}</span></template></el-statistic>
                <el-statistic title="负责人"><template slot="formatter"><span style="font-size: 14px;">{{ customer.user?.name || '未登记' }}</span></template></el-statistic>
                <el-statistic title="更新时间"><template slot="formatter"><span style="font-size: 14px;">{{ customer.updated_at }}</span></template></el-statistic>
            </div>

            <!-- 基本信息 -->
            <div class="w-margin-t-10" style="background-color: #E8EAF6; border-radius: 3px; padding: 15px; display: flex; border: 1px solid #0027ff">
                <el-statistic style="line-height: 2;" group-separator="," :precision="2" :value="customer.pay_sum_a" title="合同总额(元)"></el-statistic>
                <el-statistic style="line-height: 2;" group-separator="," :precision="2" :value="customer.pay_sum_c" title="未回款(元)"></el-statistic>
                <el-statistic style="line-height: 2;" group-separator="," :precision="2" :value="customer.pay_sum_b" title="已回款(元)"></el-statistic>
                <el-statistic style="line-height: 2;" group-separator="," :precision="2" :value="customer.pay_sum_f" title="已开票(元)"></el-statistic>
                <el-statistic style="line-height: 2;" group-separator="," :precision="2" :value="customer.pay_sum_d" title="已退款(元)"></el-statistic>
            </div>

        </div>

        <div class="w-padding-l-20 w-padding-r-20 w-padding-b-20">
            <el-tabs v-model="tabs">

                <!-- 详细资料 -->
                <el-tab-pane label="详细资料" name="details">
                    
                    <!-- 基本信息 -->
                    <el-descriptions title="基本信息" size="mini" border :column="2" class="w-margin-b-20">
                        <el-descriptions-item label="客户编号">{{ customer.number }}</el-descriptions-item>
                        <el-descriptions-item label="客户名称">{{ customer.name }}</el-descriptions-item>
                        <el-descriptions-item label="客户种类">{{ customer.type }}</el-descriptions-item>
                        <el-descriptions-item label="工作电话">{{ customer.work_phone }}</el-descriptions-item>
                        <el-descriptions-item label="负责人">{{ customer.user?.name }}</el-descriptions-item>
                        <el-descriptions-item label="国家或地区">{{ customer.country }}</el-descriptions-item>
                        <el-descriptions-item label="省份">{{ customer.country_province }}</el-descriptions-item>
                        <el-descriptions-item label="城市">{{ customer.country_province_city }}</el-descriptions-item>
                        <el-descriptions-item label="详细地址">{{ customer.address }}</el-descriptions-item>
                        <el-descriptions-item label="开票单位名称">{{ customer.invoice_unit_name }}</el-descriptions-item>
                        <el-descriptions-item label="开票单位税号">{{ customer.invoice_unit_number }}</el-descriptions-item>
                        <el-descriptions-item label="开票单位地址">{{ customer.invoice_unit_address }}</el-descriptions-item>
                        <el-descriptions-item label="开票单位电话">{{ customer.invoice_unit_phone }}</el-descriptions-item>
                        <el-descriptions-item label="开票单位银行名称">{{ customer.invoice_unit_bank_name }}</el-descriptions-item>
                        <el-descriptions-item label="开票单位银行卡号">{{ customer.invoice_unit_bank_number }}</el-descriptions-item>
                    </el-descriptions>

                    <!-- 款项统计 -->
                    <el-descriptions title="款项统计" size="mini" border :column="2" class="w-margin-b-20">
                        <el-descriptions-item group-separator="," :precision="2" :value="customer.pay_sum_a" label="合同总额">{{ customer.pay_sum_a }}</el-descriptions-item>
                        <el-descriptions-item group-separator="," :precision="2" :value="customer.pay_sum_d" label="退款金额">{{ customer.pay_sum_d }}</el-descriptions-item>
                        <el-descriptions-item group-separator="," :precision="2" :value="customer.pay_sum_b" label="已回款金额">{{ customer.pay_sum_b }}</el-descriptions-item>
                        <el-descriptions-item group-separator="," :precision="2" :value="customer.pay_sum_c" label="未回款金额">{{ customer.pay_sum_c }}</el-descriptions-item>
                        <el-descriptions-item group-separator="," :precision="2" :value="customer.pay_sum_f" label="已开票金额">{{ customer.pay_sum_f }}</el-descriptions-item>
                        <el-descriptions-item group-separator="," :precision="2" :value="customer.pay_sum_g" label="未开票金额">{{ customer.pay_sum_g }}</el-descriptions-item>
                        <el-descriptions-item group-separator="," :precision="2" :value="customer.pay_sum_h" label="已开票金额【已回款】">{{ customer.pay_sum_h }}</el-descriptions-item>
                        <el-descriptions-item group-separator="," :precision="2" :value="customer.pay_sum_i" label="已开票金额【未回款】">{{ customer.pay_sum_i }}</el-descriptions-item>
                        <el-descriptions-item group-separator="," :precision="2" :value="customer.pay_sum_l" label="无需开票金额">{{ customer.pay_sum_l }}</el-descriptions-item>
                        <el-descriptions-item group-separator="," :precision="2" :value="customer.pay_sum_e" label="回款冲销金额">{{ customer.pay_sum_e }}</el-descriptions-item>
                    </el-descriptions>

                    <!-- 系统信息 -->
                    <el-descriptions title="系统信息" size="mini" border :column="2">
                        <el-descriptions-item label="创建时间">{{ customer.created_at }}</el-descriptions-item>
                        <el-descriptions-item label="创建人">{{ customer.created_user.name }}</el-descriptions-item>
                        <el-descriptions-item label="更新时间">{{ customer.updated_at }}</el-descriptions-item>
                        <el-descriptions-item label="更新人">{{ customer.updated_user.name }}</el-descriptions-item>
                    </el-descriptions>

                </el-tab-pane>

                <!-- 详细资料 -->
                <el-tab-pane label="客户联系人" name="contacts"><crm-customer-drawer-contacts :customer_id="customer.id" /></el-tab-pane>

            </el-tabs>
        </div>

        <!-- 组件 -->
        <crm-customer-update ref='dialogUpdate' @onChange="RePage()" />

    </el-drawer>
</template>

<script>
export default {
    data() {
        return {
            visible: false,
            loading: false,
            tabs: 'details',

            customer: {
                id: null,
                type: {},
                user: {},
                created_user: {},
                updated_user: {}
            }
        }
    },

    methods: {

        /**
        |--------------------------------------------------
        | 打开窗口
        |--------------------------------------------------
        |
        */

        Open(id) {
            this.visible = true
            this.DataGet(id)
        },

        /**
        |--------------------------------------------------
        | 关闭窗口
        |--------------------------------------------------
        |
        */

        Close(done) {
            this.$emit('onChange')
            done()
        },

        /**
        |--------------------------------------------------
        | 刷新
        |--------------------------------------------------
        |
        */

        RePage() {
            this.DataGet()
        },

        /**
        |--------------------------------------------------
        | 查询
        |--------------------------------------------------
        |
        */

        DataGet(id = this.customer.id) {
            this.loading = true

            this.$http.post(
                '/9api/crm/customer/first',
                {
                    id: id,
                    append: ['type', 'user', 'created_user', 'updated_user']
                }
            ).then(rps => {
                this.customer = rps.data
                this.loading = false
            }) 
        },

        /**
        |--------------------------------------------------
        | 删除
        |--------------------------------------------------
        |
        */

        DataDelete() {
            this.$http.post('/9api/customer/delete', { id: this.customer.id }).then(rps => {
                this.$emit('onChange')
                this.visible = false
            })
        },

        
    }
}

</script>6
