<!---------------------------------------------------------------------------- 
 | CRM - 编辑客户联系人 
 |----------------------------------------------------------------------------
 | 方法：请注意大小写,参数描述见具体方法
 | $refs[ref_name].Open() 手动打开窗口,
 |----------------------------------------------------------------------------
 | 回调
 | @onChange = fn (Object) => {} 成功后返信息
 |
 ---------------------------------------------------------------------------->

 <template>
    <el-dialog :visible.sync="visible" width="500px" append-to-body title="关闭工单" :close-on-click-modal="false" :close-on-press-escape="false" :before-close="Close">

        <!-- 表单 -->
        <el-form :model="form" size="mini" label-position="top" v-loading="loading">
            
            <!-- 用户评分 -->
            <el-form-item label="用户评分" >
                <el-rate
                    v-model="form.appraise_score"
                    :max="10" allow-half show-text
                    :colors="['#9e9e9e', '#ffeb3b', '#e91e63']"
                    :low-threshold="6"
                    :high-threshold="8.5"
                    :texts="['极差', '极差', '失望', '失望', '失望', '一般', '一般', '满意', '惊喜', '惊喜']">
                </el-rate>
            </el-form-item>

            <!-- 用户评价 -->
            <el-form-item label="用户评价" >
                <el-input type="textarea" :rows="5" placeholder="请输入内容" v-model="form.appraise_describe"></el-input>
            </el-form-item>

        </el-form>
        

        <span slot="footer">
            <el-button size="mini" @click="Close()">取 消</el-button>
            <el-button size="mini" type="primary" @click="SubmitForm()" :loading="loading">确 定</el-button>
        </span>
    </el-dialog>
</template>

<script>

export default {
    data () {
        return {
            // 加载状态
            visible: false,
            loading: false,

            formError: {},
            form: {
                id: null,
                appraise_score: 8,
                appraise_describe: null,
            }
        }
    },

    methods: {

        /**
        |--------------------------------------------------
        | 窗口 - 打开
        |--------------------------------------------------
        |
        */
       
        Open (id) {
            this.visible = true
            this.form.id = id
        },


        /**
        |--------------------------------------------------
        | 窗口 - 关闭
        |--------------------------------------------------
        |
        */

        Close() {
            this.loading = false
            this.visible = false
        },

        /**
        |--------------------------------------------------
        | 提交表单
        |--------------------------------------------------
        |
        */

        SubmitForm() {
            // 开启加载状态
            this.loading = true
            this.formError = {}

            // 提交表单
            this.$http.post('/9api/pm/wo/update-close', this.form).then(rps => {
                // 关闭加载状态
                this.loading = false
                this.$emit('onChange')
                this.Close()
            })
        }
    }  
}
</script>