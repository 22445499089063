<!---------------------------------------------------------------------------- 
 | 商机-报名费用
 |----------------------------------------------------------------------------
 | 方法：请注意大小写,参数描述见具体方法
 | *v-model         默认值----默认为 null
 | *business_id     商机ID------默认为 null
 |----------------------------------------------------------------------------
 | 参数说明
 | update => Boolean => 是否可以修改
 |----------------------------------------------------------------------------
 |
 ---------------------------------------------------------------------------->

 <template>
    <el-popover trigger="click" placement="bottom-start" :disabled="!update">
        <el-link slot="reference" type="primary" :underline="update">
            ￥{{ value }}
            <i v-if="update" class="el-icon-edit el-icon--right"></i>
        </el-link>

        <el-input-number size="mini" :precision="2" v-model="date" placeholder="报名费用" @change="OnChange" clearable />
    </el-popover>
</template>

<script>
export default {
    props: {
        value: {
            type: String,
            default: '0'
        },

        business_id: {
            type: Number,
            default: null
        },

        update: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            date: null,
        }
    },

    watch: {
        value: {
            handler(val) {
                this.date = val
            },
            immediate: true
        }
    },

    methods: {
        OnChange(val) {
            if (this.business_id && this.update) {
                this.$http.post('/9api/crm/business/update-tender-money', {id: this.business_id, tender_money: val}).then(rps => {
                    this.$emit('input', val)
                })
            }
        },
    }
}
</script>