<!---------------------------------------------------------------------------- 
 | 表单 - 销售跟单进度
 |----------------------------------------------------------------------------
 | 方法：请注意大小写,参数描述见具体方法
 | *v-model     默认值------默认为 null
 | width        表单宽度----默认为 ‘100%’
 | margin       表单外边距--默认为 ‘0px’
 |
 ---------------------------------------------------------------------------->

 <template>
    <el-select
        @change="SelectChange"
        size="mini"
        v-model="selectValue"
        :style="{ width: width, margin: margin }"
        placeholder="请选择流程"
        clearable>
        <el-option v-for="(item, idx) in select" :key="idx" :label="item.name" :value="item.id"></el-option>
    </el-select>
</template>

<script>
export default {
    props: {
        value: {
            type: [String, Array, Number],
            default: null
        },

        width: {
            type: String,
            default: '100%'
        },

        margin: {
            type: String,
            default: '0px'
        },
    },

    watch: {
        value () {
            this.selectValue = this.value
        }
    },

    data () {
        return {
            select: [],
            selectValue: null,
        }
    },

    mounted () {
        this.select = [{
            id: 1,
            name: '默认流程方案'
        }]
    },

    methods: {
        SelectChange(data) {
            this.$emit('input', data)
        }
    }
}
</script>