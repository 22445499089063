<!---------------------------------------------------------------------------- 
 | 附件上传
 |----------------------------------------------------------------------------
 | 方法：请注意大小写,参数描述见具体方法
 | *contract_id     合同ID------默认为 null
 | size             按钮大小----默认为 null
 |----------------------------------------------------------------------------
 | 回调
 | onChange () => { }
 |
 ---------------------------------------------------------------------------->

 <template>
    <div>
        <el-button slot="reference" :size="size" @click="dialog = true">上传附件</el-button>

        <!-- 附件上传 -->
        <el-dialog :visible.sync="dialog" class="w-el-dialog" width="900px" :close-on-click-modal="false" :close-on-press-escape="false" append-to-body destroy-on-close>
            
            <!-- 标题 -->
            <template slot="title">
                附件上传
            </template>

            <files-upload v-model="files" />

            <template slot="footer">
                <el-button size="mini" :loading="loading" @click="dialog = false">关 闭</el-button>
                <el-button size="mini" :loading="loading" type="primary"  @click="Submit()">提交</el-button>
            </template>

        </el-dialog>
    </div>
</template>

<script>
export default {
    props: {
        contract_id: {
            type: Number,
            default: null
        },

        size: {
            type: String,
            default: null
        },
    },

    data() {
        return {
            dialog: false,
            loading: false,
            
            files: []
        }
    },

    methods: {
        Submit() {
            if (this.contract_id) {
                this.loading = true

                this.$http.post('/9api/contract/update-files', {id: this.contract_id, files_id: this.files}).then(rps => {
                    this.$emit('onChange')
                    this.files = []
                    this.loading = false
                    this.dialog = false
                })
            }
        },
    }
}
</script>