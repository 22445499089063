<!---------------------------------------------------------------------------- 
 | 项目管理 - 任务流程工时修改
 |----------------------------------------------------------------------------
 | 方法：请注意大小写,参数描述见具体方法
 | $refs[ref_name].Open(id, name) 手动打开窗口,
 | $refs[ref_name].Close() 手动关闭窗口
 |----------------------------------------------------------------------------
 | 回调
 | @onSave = fn (Object) => {} 成功后返信息
 |
 ---------------------------------------------------------------------------->

 <template>
    <el-dialog class="w-el-dialog" :visible.sync="visible" append-to-body title="请填输入您要修改的工时" :close-on-click-modal="false" :close-on-press-escape="false" :before-close="Close">
          <el-input-number v-model="consume_days" :min="0" :max="999999" label="消耗工时" class="w-width-auto"></el-input-number>
          <span slot="footer" class="dialog-footer">
              <el-button size="mini" @click="Close()">取 消</el-button>
              <el-button size="mini" type="primary" @click="SubmitForm()" :loading="loading">确 定</el-button>
          </span>
    </el-dialog>
</template>

<script>
export default {
  data () {
      return {
          // 加载状态
          visible: false,
          loading: false,

          id: null,
          consume_days: null
      }
  },

  methods: {

      /**
      |--------------------------------------------------
      | 窗口 - 打开
      |--------------------------------------------------
      |
      */

      Open (id, consume_days) {
          this.id = id
          this.consume_days = consume_days
          this.visible = true
      },


      /**
      |--------------------------------------------------
      | 窗口 - 关闭
      |--------------------------------------------------
      |
      */

      Close() {
          this.loading = false
          this.visible = false
      },


      /**
      |--------------------------------------------------
      | 修改模板信息
      |--------------------------------------------------
      |
      */

      SubmitForm() {
          this.loading = true
          this.$http.post('/9api/projects-flow/edit-task-days', { id: this.id, consume_days: this.consume_days }).then(rps => {
              if (rps.data.state == 'success') {
                  this.$emit('onSave', rps.data.body)
                  this.Close()
              }
              this.loading = false
          })
      }
  }  
}
</script>