<template>
    <el-drawer :visible.sync="visible" size="80%" direction="rtl" append-to-body destroy-on-close :show-close="false" :before-close="Close">

        <div slot="title">

            <div class="w-margin-b-10">供应商</div>
            <div class="w-margin-b-10" style="font-size: 18px; color: #000; font-weight: bold;">{{ supplier.name }}</div>

            <!-- 功能按钮 -->
            <div style="display: flex;">
                <el-button size="mini" icon="el-icon-edit" @click="RePage()">刷新</el-button>
                <el-button style="margin-left: 5px;" size="mini" icon="el-icon-edit" @click="$refs.dialogUpdate.Open(supplier.id)">修改供应商</el-button>
                <el-button style="margin-left: 5px;" size="mini" @click="DataDelete()" type="danger">移除供应商</el-button>
            </div>

            <!-- 金额概览 -->
            <div class="w-margin-t-10" style="background-color: #f4f5f7; border-radius: 3px; padding: 15px; display: flex;">
                <el-statistic title="供应商级别"><template slot="formatter"><span style="font-size: 14px;">{{ supplier.level }}</span></template></el-statistic>
                <el-statistic title="联系人"><template slot="formatter"><span style="font-size: 14px;">{{ supplier.contacts }}</span></template></el-statistic>
                <el-statistic title="联系电话"><template slot="formatter"><span style="font-size: 14px;">{{ supplier.phone }}</span></template></el-statistic>
                <el-statistic title="更新时间"><template slot="formatter"><span style="font-size: 14px;">{{ supplier.updated_at }}</span></template></el-statistic>
            </div>

        </div>

        <div class="w-padding-l-20 w-padding-r-20 w-padding-b-20">
            <el-tabs v-model="tabs">

                <!-- 详细资料 -->
                <el-tab-pane label="详细资料" name="details">

                    <!-- 基本信息 -->
                    <el-descriptions title="基本信息" direction="vertical" size="mini" border :column="2" :style="{ marginBottom: '20px' }">
                        <el-descriptions-item label="供应商名称">{{ supplier.name }}</el-descriptions-item>
                        <el-descriptions-item label="供应商级别">{{ supplier.level }}</el-descriptions-item>
                        <el-descriptions-item label="联系人">{{ supplier.contacts }}</el-descriptions-item>
                        <el-descriptions-item label="联系电话">{{ supplier.phone }}</el-descriptions-item>
                        <el-descriptions-item label="联系地址">{{ supplier.address }}</el-descriptions-item>
                        <el-descriptions-item label="备注">{{ supplier.notes }}</el-descriptions-item>
                    </el-descriptions>

                    <!-- 支付信息 -->
                    <el-descriptions title="支付信息" size="mini" border :column="2" :style="{ marginBottom: '20px' }">
                        <el-descriptions-item label="支付方式">{{ supplier.pay_type?.name }}</el-descriptions-item>
                        <el-descriptions-item label="支付平台">{{ supplier.pay_platform }}</el-descriptions-item>
                        <el-descriptions-item label="付款账户">{{ supplier.pay_account }}</el-descriptions-item>
                        <el-descriptions-item label="账户名称">{{ supplier.pay_name }}</el-descriptions-item>
                        <el-descriptions-item label="发票类型">{{ supplier.invoice_type?.name ?? '未选择' }}</el-descriptions-item>
                        <el-descriptions-item label="发票税率">{{ supplier.invoice_type?.taxation_rate ?? 0 }}%</el-descriptions-item>
                    </el-descriptions>

                    <!-- 系统信息 -->
                    <el-descriptions title="系统信息" direction="vertical" size="mini" border :column="2">
                        <el-descriptions-item label="创建时间">{{ supplier.created_at }}</el-descriptions-item>
                        <el-descriptions-item label="创建人">{{ supplier.created_user.name }}</el-descriptions-item>
                        <el-descriptions-item label="更新时间">{{ supplier.updated_at }}</el-descriptions-item>
                        <el-descriptions-item label="更新人">{{ supplier.updated_user.name }}</el-descriptions-item>
                    </el-descriptions>

                </el-tab-pane>

                <!-- 关联产品 -->
                <el-tab-pane label="关联产品" name="product">
                    <ims-supplier-drawer-product :supplier_id="supplier.id" />
                </el-tab-pane>

            </el-tabs>
        </div>

        <!-- 组件 -->
        <ims-supplier-update ref='dialogUpdate' @onChange="RePage()" />
    </el-drawer>
</template>

<script>
export default {
    data() {
        return {
            visible: false,
            loading: false,
            tabs: 'details',

            supplier: {
                pay_type: {},
                invoice_type: {},
                created_user: {},
                updated_user: {}
            }
        }
    },

    methods: {

        /**
        |--------------------------------------------------
        | 打开窗口
        |--------------------------------------------------
        |
        */

        Open(id) {
            this.visible = true
            this.DataGet(id)
        },

        /**
        |--------------------------------------------------
        | 关闭窗口
        |--------------------------------------------------
        |
        */

        Close(done) {
            this.$emit('onChange')
            done()
        },

        /**
        |--------------------------------------------------
        | 刷新
        |--------------------------------------------------
        |
        */

        RePage() {
            this.DataGet()
        },

        /**
        |--------------------------------------------------
        | 查询
        |--------------------------------------------------
        |
        */

        DataGet(id = this.supplier.id) {
            this.loading = true

            this.$http.post(
                '/9api/ims/supplier/search-first',
                {
                    search: { id: id },
                    append: ['created_user', 'updated_user', 'pay_type', 'invoice_type']
                }
            ).then(rps => {
                this.supplier = rps.data
                this.loading = false
            })
        },

        /**
        |--------------------------------------------------
        | 删除
        |--------------------------------------------------
        |
        */

        DataDelete() {
            this.$http.post('/9api/ims/supplier/delete', {id: this.supplier.id}).then(rps => {
                this.$emit('onChange')
                this.visible = false
            })
        },
    }
}

</script>6
