<!---------------------------------------------------------------------------- 
 | Input - 用户选择
 |----------------------------------------------------------------------------
 | 方法：请注意大小写,参数描述见具体方法
 | *v-model     默认值------默认为 null
 | multiple     是否多选----默认为 false
 |----------------------------------------------------------------------------
 | 回调
 | @onChange = fn (data) => {} 返回表单内容
 |
 ---------------------------------------------------------------------------->

<template>
    <div>
        <!-- 表单模式 -->
        <el-input v-if="type == 'input'" size="mini" v-model="selectlistName"  @focus="dialogVisible = true" :placeholder="placeholder" clearable :disabled="disabled" @clear="TreeResetChecked" />

        <!-- 头像模式 -->
        <div v-if="type == 'avatar'" @click="dialogVisible = true">
            <users-avatar-group>
                <users-avatar name="添加成员" type="info" bg-color="#e0e0e0" />
                <users-avatar v-for="(item, idx) in selectlist" :src="item.avatar" :name="item.name" :key="idx + 'users'" />
            </users-avatar-group>
        </div>

        <el-dialog class="w-el-dialog-users" :visible.sync="dialogVisible" width="600px" @opened="DialogOpend" append-to-body :close-on-click-modal="false" :show-close="false">
            
            <!-- 左侧 -->
            <div class="w-el-dialog-left" v-loading="dialogLoading" element-loading-text="正在拼命加载组织列表">
                
                <!-- 组织树 - 检索 -->
                <div class="w-el-dialog-left-search">
                    <el-input size="mini" v-model="tree.seacrh" prefix-icon="el-icon-search" placeholder="请输入内容" clearable></el-input>
                </div>

                <!-- 组织树 - 数据 -->
                <div class="w-el-dialog-left-tree">
                    <el-tree ref="tree" :data="tree.data" node-key="id" :props="tree.props" @node-click="TreeNodeClick" @check-change="TreeNodeChange" :filter-node-method="TreeFilterNode" :show-checkbox="this.multiple">
                        <span slot-scope="{ data }" class="el-tree-custom-node">

                            <!-- 检索框 -->
                            <div class="el-tree-custom-node-avatar">
                                <el-avatar shape="square" :src="data.avatar" v-if="data.type == 'user'">{{ data.name[0] }}</el-avatar>
                                <span>{{ data.name }}</span>
                            </div>

                            <!-- 树 -->
                            <el-tooltip placement="right" v-if="data.type == 'user'">
                                <el-link icon="el-icon-warning-outline" :underline="false"></el-link>
                                <div slot="content">
                                    ID: {{ data.id }}<br/>
                                    性别: {{ data.sex == 0 ? '未知' : (data.sex == 1 ? '男' : '女') }}<br/>
                                    名称: {{ data.name }}<br/>
                                    手机号: {{ data.phone }}<br/>
                                    邮箱: {{ data.email }}<br/>
                                    企业邮箱: {{ data.wecom_biz_mail }}
                                </div>
                            </el-tooltip>
                        </span>
                    </el-tree>
                </div>

            </div>

            <!-- 右侧 -->
            <div class="w-el-dialog-right" v-loading="dialogLoading" element-loading-text="正在拼命加载用户信息">
                
                <!-- header -->
                <div class="w-el-dialog-right-header">
                    <b>已选择{{ selectlist.length }}个联系人</b>
                    <el-link :underline="false" icon="el-icon-close" @click="dialogVisible = false"></el-link>
                </div>

                <div class="w-el-dialog-right-content">
                    <div class="content-list">
                        <div class="content-list-item" v-for="(item, idx) in selectlist" :key="idx">
                            <div class="content-list-item-avatar">
                                <el-avatar shape="square" :src="item.avatar">{{ item.name[0] }}</el-avatar>
                                {{ item.name }}
                            </div>
                            <el-link :underline="false" icon="el-icon-close" @click="TreeClearChecked(item.id)"></el-link>
                        </div>
                    </div>
                </div>

                <div class="w-el-dialog-right-footer">
                    <el-row :gutter="10">
                        <el-col :span="12"><el-button class="w-width-auto" size="medium" type="primary" @click="TreeSubmit()">确定</el-button></el-col>
                        <el-col :span="12"><el-button class="w-width-auto" size="medium" type="info" @click="dialogVisible = false">取消</el-button></el-col>
                    </el-row>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: [String, Array, Number],
            default: null
        },

        multiple: {
            type: Boolean,
            default: false
        },

        placeholder: {
            type: String,
            default: '请选择用户'
        },

        disabled: {
            type: Boolean,
            default: false
        },

        type: {
            type: String,
            default: 'input'
        },
        
    },

    data () {
        return {
            dialogVisible: false,
            dialogLoading: false,

            tree: {
                seacrh: null,
                data: [],
                props: {
                    children: 'children',
                    label: 'name'
                },
            },

            selectlist: [],
            selectlistName: null
        }
    },

    watch: {
        'tree.seacrh' (val) {
            this.$refs.tree.filter(val);
        },
        'value': {
            immediate: true,
            handler(val) {
                if (val) {
                    this.$http.post('/9api/components-input/input-users/users-search-ids', { ids: this.multiple ? this.value : [this.value] }).then(rps => {
                        this.selectlist = rps.data
                        this.selectlistName = this.multiple ? this.selectlist.map(item => item.name).toString() : rps.data[0].name
                    })
                } else {
                    this.selectlist = []
                    this.selectlistName = null
                }
            }
        }
    },

    mounted () {
        // 开启加载状态
        this.dialogLoading = true

        // 获取组织信息
        this.$http.post('/9api/components-input/input-users/users-organize-tree').then(rps => {
            this.tree.data = rps.data

            // 关闭加载状态
            this.dialogLoading = false
        })
    },

    methods: {

        /**
        |--------------------------------------------------
        | 窗口 - 打开动画结束时
        |--------------------------------------------------
        |
        */

        DialogOpend() {
            if (this.value) {
                this.$refs.tree.setCheckedKeys(this.multiple ? this.value : [this.value])
            } else {
                this.$refs.tree.setCheckedKeys([])
            }
            this.TreeNodeChange()
        },

        /**
        |--------------------------------------------------
        | 点击Node事件（User）
        |--------------------------------------------------
        |
        */

        TreeNodeClick(data, node) {
            if (data.type == 'user') {
                if (this.multiple) {
                    node.checked = !node.checked
                } else {
                    this.$refs.tree.setCheckedKeys([data.id]);
                }
            }
        },


        /**
        |--------------------------------------------------
        | Checked 发生变化
        |--------------------------------------------------
        |
        */

        TreeNodeChange() {
            console.log(this.$refs.tree.getCheckedNodes())
            this.selectlist = this.$refs.tree.getCheckedNodes().filter((item) => item.type == 'user')
        },

        /**
        |--------------------------------------------------
        | 清空选项
        |--------------------------------------------------
        |
        */

        TreeResetChecked() {
            this.selectlist = []
            this.selectlistName = null
            this.$emit('input', null)
            this.$emit('onChange', null)
            if (this.$refs.tree) {
                this.$refs.tree.setCheckedKeys([]);
                this.TreeNodeChange()
            }
        },

        /**
        |--------------------------------------------------
        | 移除某个用户
        |--------------------------------------------------
        |
        */

        TreeClearChecked(id) {
            this.$refs.tree.setChecked(id, false)
            this.TreeNodeChange()
        },

        /**
        |--------------------------------------------------
        | 树结构筛选
        |--------------------------------------------------
        |
        */

        TreeFilterNode(value, data) {
            if (!value) return true;
            return data.name.indexOf(value) !== -1 && data.type == 'user';
        },

        /**
        |--------------------------------------------------
        | 确认选择
        |--------------------------------------------------
        |
        */

        TreeSubmit() {
            if (this.selectlist.length == 0) {
                this.TreeResetChecked()
            } else {
                if (this.multiple) {
                    this.selectlistName = this.selectlist.map(item => item.name).toString()
                    this.$emit('input', this.selectlist.map(item => item.id))
                    this.$emit('onChange', this.selectlist)
                } else {
                    this.selectlistName = this.selectlist[0].name
                    this.$emit('input', this.selectlist[0].id)
                    this.$emit('onChange', this.selectlist[0])
                }
            }

            this.dialogVisible = false
        }
    }
}
</script>