<template>
    <el-dialog :visible.sync="visible" width="900px" title="新建退货订单" append-to-body destroy-on-close @close="Close()" :close-on-click-modal="false" :close-on-press-escape="false">

        <!-- 表单 -->
        <el-form :model="form" size="mini" label-position="top">

            <el-divider content-position="left"><b>退货产品</b></el-divider>

            <!-- 产品列表 -->
            <vxe-table border ref="xTable" :data="form.product" :loading="loading" :style="{ marginTop: '10px' }">

                <!-- 产品名称 -->
                <vxe-column min-width="100" field="product.name" title="产品名称" fixed="left"></vxe-column>

                <!-- 计量单位 -->
                <vxe-column min-width="80" field="product.unit" title="计量单位"></vxe-column>

                <!-- 规格参数 -->
                <vxe-column min-width="100" title="规格参数">
                    <template #default="{ row }">
                        {{ row.product.parameter?.toString() }}
                    </template>
                </vxe-column>

                <!-- 剩余数量 -->
                <vxe-column min-width="80" field="storage_num" title="已入库数量"></vxe-column>

                <!-- 采购数量 -->
                <vxe-column min-width="120" field="num" title="退货数量">
                    <template #default="{ row }">
                        <el-input-number size="mini" v-model="row.num" @change="productUpdate" :min="0" :precision="2" :controls="false" :style="{ width: '100%' }"></el-input-number>
                    </template>
                </vxe-column>

                <!-- 采购单价 -->
                <vxe-column min-width="120" title="退货单价">
                    <template #default="{ row }">
                        <el-input-number size="mini" v-model="row.money" @change="productUpdate" :min="0" :precision="2" :controls="false" :style="{ width: '100%' }"></el-input-number>
                    </template>
                </vxe-column>

                <!-- 合计 -->
                <vxe-column min-width="100" field="money_total" title="合计"></vxe-column>

            </vxe-table>

            <el-divider content-position="left"><b>订单信息</b></el-divider>

            <!-- 退货日期 / 退货原因 -->
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="退货日期" :rules="[{ required: true, message: '必填项' }]" :error="formError.date?.toString()" prop="date">
                        <el-date-picker v-model="form.date" type="date" value-format="yyyy-MM-dd" :style="{ width: '100%' }"></el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="退货原因" :error="formError.reason?.toString()" prop="reason">
                        <el-input v-model="form.reason" clearable></el-input>
                    </el-form-item>
                </el-col>
            </el-row>

            <!-- 退货金额 / 退货备注 -->
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="退货备注">
                        <el-input type="textarea" :rows="3" v-model="form.describe"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="退货金额">
                        <el-input-number v-model="form.money" :precision="2" :style="{ width: '100%' }" disabled></el-input-number>
                    </el-form-item>
                </el-col>
            </el-row>

        </el-form>

        <div slot="footer">
            <el-button size="small" @click="visible = false">取 消</el-button>
            <el-button size="small" type="primary" @click="formSubmit()" :loading="loading">确 定</el-button>
        </div>

    </el-dialog>
</template>

<script>
export default {
    data () {
        return {
            visible: false,
            loading: false,

            formError: {},
            form: {
                product_order_id: null,
                date: null,
                money: 0,
                reason: null,
                describe: null,
                product: [],
            }
        }
    },
    
    methods: {

        // 打开窗口
        Open(search) {
            this.visible = true
            this.loading = false

            this.form.product_order_id = search.product_order_id

            this.$http.post('/9api/ims/product-order-log/search-all', { search: { ...search, state: 1 }, append: ['product'] }).then(rps => {
                this.form.product = rps.data.filter(rps => {
                    rps.storage_num = Number(rps.storage_num) + Number(rps.back_num)
                    rps.num = 0
                    rps.money_total = 0
                    return rps
                })
            })
        },

        // 关闭窗口
        Close() {
            this.form.product_order_id = null
            this.form.date = null
            this.form.money = 0
            this.form.reason = null
            this.form.describe = null
            this.form.product = []
        },

        // 产品列表 - 更新数据
        productUpdate() {
            this.form.product.forEach(element => {
                element.money_total = (element.money * element.num).toFixed(2)
            })
            this.form.money = this.$lodash.array_sum(this.form.product, 0, 'money_total')
        },

        // 提交表单
        formSubmit() {
            // this.loading = true
            this.$http.post('/9api/ims/product-back/create', this.form).then((rps) => {

                // 提交失败
                if (rps.data.state == 'error') {
                    this.formError = rps.data.body
                }

                // 提交成功
                if (rps.data.state == 'success') {
                    this.$emit('onChange', rps.data.body)
                    this.visible = false
                }

                this.loading = false
            })
        },
    }
}
</script>