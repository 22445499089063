<!---------------------------------------------------------------------------- 
 | statistic 统计数值
 |----------------------------------------------------------------------------
 | 参数说明
 | column => Number => 列数
 | gutter => String => 间隔大小
 ---------------------------------------------------------------------------->

<template>
    <div :style="{ display: 'grid', gridTemplateColumns: 'repeat(' + column + ', 1fr)', gap: gutter }">
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        column: {
            type: Number,
            default: 2,
        },

        gutter: {
            type: String,
            default: '10px',
        },
    }
}
</script>