<template>
    <div>
        <div :style="{ marginBottom: '20px' }">
            <el-button size="mini" v-if="disabled"  @click="disabled = false">编辑信息</el-button>
            <el-button size="mini" v-if="!disabled" @click="disabled = true">取消</el-button>
            <el-button size="mini" v-if="!disabled" type="primary" @click="SubmitForm()" :loading="loading">保存</el-button>
        </div>

        <!-- 表单 -->
        <el-form :model="form" size="mini" label-position="top" v-loading="loading">

            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="工号" :rules="[{ required: true, message: '必填项' }]" :error="formError.number?.toString()" prop="number">
                        <el-input v-model="form.number" placeholder="请填写员工工号" :disabled="disabled" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="所属组织" :rules="[{ required: true, message: '必选项' }]" :error="formError.organize_id?.toString()" prop="organize_id">
                        <hrm-organize-cascader v-model="form.organize_id" :disabled="disabled" />
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="在职状态" :rules="[{ required: true, message: '必选项' }]" :error="formError.status?.toString()" prop="status">
                        <el-select v-model="form.status" :style="{ width: '100%' }" :disabled="disabled">
                            <el-option :value="0" label="在职"></el-option>
                            <el-option :value="1" label="离职"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="角色权限" :rules="[{ required: true, message: '必选项' }]" :error="formError.role_id?.toString()" prop="role_id">
                        <config-role-select v-model="form.role_id" :disabled="disabled" />
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="入职时间" :rules="[{ required: true, message: '必填项' }]" :error="formError.created_at?.toString()" prop="created_at">
                        <el-date-picker v-model="form.created_at" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="请选择用户入职时间" :style="{ width: '100%' }" :disabled="disabled"></el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="岗位">
                        <el-input v-model="form.appointment" placeholder="请填写用户岗位" clearable :disabled="disabled" />
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="组织/部门负责人" :rules="[{ required: true, message: '必选项' }]" :error="formError.organize_admin?.toString()" prop="organize_admin">
                        <el-select v-model="form.organize_admin" :style="{ width: '100%' }" :disabled="disabled">
                            <el-option :value="0" label="不是"></el-option>
                            <el-option :value="1" label="是"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="聘用形式" :rules="[{ required: true, message: '必选项' }]" :error="formError.type?.toString()" prop="type">
                        <el-select v-model="form.type" :style="{ width: '100%' }" :disabled="disabled">
                            <el-option value="试用" label="试用"></el-option>
                            <el-option value="全职" label="全职"></el-option>
                            <el-option value="实习" label="实习"></el-option>
                            <el-option value="兼职" label="兼职"></el-option>
                            <el-option value="劳务" label="劳务"></el-option>
                            <el-option value="顾问" label="顾问"></el-option>
                            <el-option value="返聘" label="返聘"></el-option>
                            <el-option value="外包" label="外包"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="管理员" :rules="[{ required: true, message: '必选项' }]" :error="formError.user_admin_id?.toString()" prop="user_admin_id">
                        <users-select v-model="form.user_admin_id" :disabled="disabled" />
                    </el-form-item>
                </el-col>
            </el-row>
            
        </el-form>
    </div>
</template>

<script>
export default {
    props: {
        form: {
            type: [Object],
            default: null
        }
    },

    data () {
        return {
            disabled: true,
            loading: false,

            formError: {}
        }
    },

    methods: {
        SubmitForm() {
            // 开启加载状态
            this.loading = true
            this.formError = {}

            // 提交表单
            this.$http.post('/9api/hrm/employee/update-appointment', this.form).then(rps => {
                // 关闭加载状态
                this.loading = false

                // 提交失败
                if (rps.data.state == 'error') {
                    this.formError = rps.data.body
                }
                
                // 提交成功
                if (rps.data.state == 'success') {
                    this.$emit('onChange', rps.data.body)
                    this.disabled = true
                }
            })
        }
    }
}
</script>