<!---------------------------------------------------------------------------- 
 | 项目成员
 |----------------------------------------------------------------------------
 | 方法：请注意大小写,参数描述见具体方法
 | *v-model     默认值------默认为 null
 | *project_id  默认值------默认为 null
 | placeholder  表单提示----默认为 ‘请选择成员’
 | multiple     是否多选----默认为 false
 | clearable    是否可清空--默认为 false
 | filterable   是否可筛选--默认为 false
 | collapse_tags是否合并tig-默认为 false
 | width        表单宽度----默认为 ‘100%’
 | margin       表单外边距--默认为 ‘0px’
 |----------------------------------------------------------------------------
 | 回调
 | @onChange = fn (data) => {} 表单值发生变化时的回调
 |----------------------------------------------------------------------------
 | 备注：
 | 项目管理 - 任务 - 批量创建 ：存在独立的代码，需要单独修改代码
 |
 ---------------------------------------------------------------------------->

 <template>
    <el-select
        @change="SelectChange"
        v-model="selectValue"
        size="mini"
        :style="{ width: width, margin: margin }"
        :placeholder="placeholder"
        :clearable="clearable"
        :filterable="filterable"
        :collapse-tags="collapse_tags"
        :multiple="multiple" >
        <el-option v-for="(item, idx) in select" :key="idx" :label="item.user.name" :value="item.user.id">
            <span style="float: left;">{{ item.user.name }}</span>
            <span style="float: right; color: #8492a6; font-size: 12px" v-if="item.role == 'admin'">管理员</span>
            <span style="float: right; color: #8492a6; font-size: 12px" v-if="item.role == 'sale'">销售</span>
            <span style="float: right; color: #8492a6; font-size: 12px" v-if="item.role == 'leader'">项目经理</span>
            <span style="float: right; color: #8492a6; font-size: 12px" v-if="item.role == 'helper'">普通组员</span>
            <span style="float: right; color: #8492a6; font-size: 12px" v-if="item.role == 'affair'">商务</span>
            <span style="float: right; color: #8492a6; font-size: 12px" v-if="item.role == 'presale'">售前</span>
        </el-option>
    </el-select>
</template>

<script>
export default {
    props: {
        value: {
            type: [Number, Array, String],
            default: null
        },

        multiple: {
            type: Boolean,
            default: false
        },

        clearable: {
            type: Boolean,
            default: false
        },

        filterable: {
            type: Boolean,
            default: false
        },

        collapse_tags: {
            type: Boolean,
            default: false
        },

        width: {
            type: String,
            default: '100%'
        },

        margin: {
            type: String,
            default: '0px'
        },

        placeholder: {
            type: String,
            default: '请选择任务类型'
        },

        project_id: {
            type: [Number, String],
            default: null
        }
    },

    watch: {
        value: {
            handler() {
                this.selectValue = this.value ? this.value : null
            },
            immediate: true
        },

        project_id: {
            handler() {
                this.TeamSearch()
            },
            immediate: true
        }
    },

    data () {
        return {
            select: [],
            selectValue: null,
        }
    },

    methods: {

        /**
        |--------------------------------------------------
        | 表单值发生变化
        |--------------------------------------------------
        |
        */

        SelectChange(data) {
            this.$emit('input', data)
            this.$emit('onChange', data)
        },


        /**
        |--------------------------------------------------
        | 获取项目组成员信息
        |--------------------------------------------------
        |
        */

        TeamSearch() {
            this.$http.post('/9api/projects-team/search', { project_id: this.project_id, append: 'user' }).then(rps => {
                this.select = rps.data
            })
        }
    }
}
</script>