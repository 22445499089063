<template>
    <el-dialog :visible.sync="visible" width="650px" append-to-body title="完成工单" :close-on-click-modal="false" :close-on-press-escape="false" :before-close="Close">

        <!-- 基本信息 -->
        <div style="background-color: #e8eaf6; border-radius: 3px; padding: 15px; display: flex; border: 1px solid #0027ff; margin-bottom: 20px;">
            <el-statistic title="预计工时"><template slot="formatter"><span style="font-size: 14px;">{{ day_predict }} / P</span></template></el-statistic>
            <el-statistic title="消耗工时"><template slot="formatter"><span style="font-size: 16px;"><b>{{ day_consume }} / P</b></span></template></el-statistic>
            <el-statistic title="剩余可用工时"><template slot="formatter"><span style="font-size: 14px;">{{ day_surplus }} / P</span></template></el-statistic>
        </div>

        <vxe-table ref="xTable" :data="table" :style="{ width: '100%' }">

            <!-- 收单人 -->
            <vxe-column width="120" field="name" title="成员">
                <template #default="{ row }">
                    <div style="display: flex; align-items: center; justify-content: flex-start;">
                        <el-avatar style="margin-right: 5px;" :size="26" :src="row.avatar"> {{ row.name[0] }} </el-avatar>
                        {{  row.name }}
                    </div>
                </template>
            </vxe-column>

            <!-- 消耗工时/P -->
            <vxe-column width="160" field="day_consume" title="消耗工时/P">
                <template #default="{ row }">
                    <el-input-number v-model="row.day_consume" :controls="false" :style="{ width: '100%' }"></el-input-number>
                </template>
            </vxe-column>

            <!-- 完成时间 -->
            <vxe-column title="完成时间">
                <template #default>
                    <el-date-picker v-model="finish_at" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间" :style="{ width: '100%' }"></el-date-picker>
                </template>
            </vxe-column>

        </vxe-table>

        <span slot="footer">
            <el-button @click="Close()">取 消</el-button>
            <el-button type="primary" @click="SubmitForm()" :loading="$store.state.loading">确 定</el-button>
        </span>
    </el-dialog>
</template>

<script>
export default {

    // 存储数据
    data () {
        return {

            // 加载状态
            visible: false,

            // 工单
            wo: {
                user_receive: {},
                user_assist: []
            },

            finish_at: this.$lodash.date_time(),

            table: []

        }
    },

    // 计算属性
    computed: {
        day_predict () {
            return this.wo.day_predict
        },
        day_consume () {
            return this.table.reduce((sum, item) => sum + item.day_consume, 0)
        },
        day_surplus () {
            return this.day_predict - this.day_consume
        }
    },

    // 方法定义
    methods: {

        // 打开窗口
        Open (id) {

            // 获取数据
            this.$http.post('/9api/wo/search-first', { id: id, append: ['user_receive', 'user_assist'] }).then(rps => {
                this.wo = rps.data

                rps.data.user_assist.forEach((item, idx) => {
                    this.table.push({ ...item, day_consume: 0 })
                })

                this.visible = true
            })

        },

        // 关闭窗口
        Close() {
            this.table = []
            this.visible = false
        },

        // 提交表单
        SubmitForm() {
            // 提交表单
            this.$http.post('/9api/pm/wo/update-finish', { id: this.wo.id, finish_at: this.finish_at, day_log: this.table }).then(rps => {
                this.$emit('onChange', rps.data)
                this.Close()
            })
        }
        
    }  
}
</script>