<template>
    <el-dialog
        append-to-body
        destroy-on-close
        fullscreen
        :class="['el-dialog-menu']"
        :visible.sync="visible"
        :close-on-click-modal="false"
        >
        
        <!-- 进阶模式 -->
        <div :class="['el-dialog-menu-layout']" v-loading="loading" :style="{ width: isFullscreen ? '95%' : width, height: isFullscreen ? '95%' : height }">

            <!-- 菜单 -->
            <div class="el-dialog-menu-nav">

                <!-- 菜单 Header -->
                <div class="el-dialog-menu-nav-header">
                    <slot name="title">
                        <div class="el-dialog-menu-nav-header-title">{{ title }}</div>
                        <div class="el-dialog-menu-nav-header-tip">{{ tip }}</div>
                    </slot>
                </div>

                <!-- 菜单 Item -->
                <div class="el-dialog-menu-nav-content">
                    <slot name="menu"></slot>
                </div>

                <!-- 菜单 Item -->
                <div class="el-dialog-menu-nav-footer" v-if="$slots.footer" :style="{ gridTemplateColumns: 'repeat(' + $slots.footer.length + ', 1fr)' }">
                    
                    <slot name="footer"></slot>
                </div>

            </div>

            <!-- 内容 -->
            <div class="el-dialog-menu-content">
                <div class="el-dialog-menu-content-header">
                    <i class="el-icon-warning-outline"></i>
                    <i class="el-icon-full-screen" @click="isFullscreen = !isFullscreen"></i>
                    <i class="el-icon-close" @click="$emit('update:visible', false)"></i>
                </div>

                <div class="el-dialog-menu-content-body">
                    <slot></slot>
                </div>
                
            </div>

        </div>

    </el-dialog>
</template>

<script>
export default {
    data() {
        return {
            isFullscreen: false
        }
    },

    props: {
        visible: {
            type: Boolean,
            default: false
        },
        fullscreen: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        width: {
            type: String,
            default: '60%'
        },
        height: {
            type: String,
            default: '60%'
        },
        title: {
            type: String,
            default: null
        },
        tip: {
            type: String,
            default: null
        }
    },

    watch: {
        fullscreen: {
            handler() {
                this.isFullscreen = this.fullscreen
            },
            immediate: true
        }
    },
}
</script>