<template>
    <div>
        <el-button type="primary" icon="el-icon-box" @click="Export()">打印</el-button>
        <files-preview ref="filesPreview" />
    </div>
</template>

<script>
export default {
    props:{
        model: {
            type: String,
            default: null
        },
        id: {
            type: Number,
            default: null
        }
    },

    data () {
        return {
            downUrl: null
        }
    },

    methods: {

        // 指令回调
        Export ()
        {
            let notify = this.$notify({
                title: '打印表格',
                dangerouslyUseHTMLString: true,
                duration: 0,
                message: '<i class="el-icon-refresh el-animation-rotate"></i> 正在生成中,请耐心等待！'
            })
            
            this.$http.post('/9api/components-files/prints-xls', { model: this.model, id: this.id }).then(rps => {
                this.$refs.filesPreview.Open(rps.data.id, rps.data.path, 'preview')
                notify.close()
            })
        }
    }
}
</script>