<!---------------------------------------------------------------------------- 
 | 项目列表
 |----------------------------------------------------------------------------
 | 方法：请注意大小写,参数描述见具体方法
 | *v-model     默认值------默认为 null
 | width        表单宽度----默认为 ‘100%’
 | margin       表单外边距--默认为 ‘0px’
 |----------------------------------------------------------------------------
 | 回调
 | @onChange = fn () => {} 返回表单内容
 |
 ---------------------------------------------------------------------------->

 <template>
    <div>
        <el-input
            v-model="selectName"
            @focus="DialogOpend"
            @clear="SelectClear"
            size="mini"
            placeholder="筛选项目"
            clearable
            :style="{ width: width, margin: margin }"/>

        <el-dialog :visible.sync="dialogVisible" class="w-el-dialog" width="900px" append-to-body title="选择项目（双击选择）" :close-on-click-modal="false" :close-on-press-escape="false">
            
            <!-- 表容器 -->
            <div class="w-vxe-fullscreen w-padding-none" style="height: 600px;">
                <div class="w-vxe-flex" style="border-radius: 0; box-shadow: none; padding: 0;">

                    <!-- 表格 -->
                    <uw-table>

                        <!-- 检索 -->
                        <template slot="search">
                            <el-input @input="TableSearch" v-model="search.number" clearable  size="mini" placeholder="请输入项目编号"></el-input>
                            <el-input @input="TableSearch" v-model="search.name" clearable  size="mini" placeholder="请输入项目名称"></el-input>
                        </template>

                        <!-- 数据 -->
                        <template slot="data">
                            <vxe-table ref="xTable" :data="table" :loading="dialogLoading" @sort-change="TableSort" @cell-dblclick="TableCellDblclick" height="100%">
                        
                                <!-- ID -->
                                <vxe-column width="60" field="id" title="ID" sortable></vxe-column>

                                <!-- 项目编号 -->
                                <vxe-column width="150" field="number" title="项目编号" sortable>
                                    <template #default="{ row }">
                                        <el-link @click="ProjectsManage(row.id)" icon="el-icon-link" type="primary"><b><span class="w-color-black">{{ row.number }}</span></b></el-link>
                                    </template>
                                </vxe-column>

                                <!-- 项目名称 -->
                                <vxe-column min-width="250" field="name" title="项目名称" sortable>
                                    <template #default="{ row }">
                                        <!-- 状态判断 -->
                                        <el-tag v-if="row.status == 1" size="mini" effect="dark">进行中</el-tag>
                                        <el-tag v-if="row.status == 2" size="mini" effect="dark" type="warning">已暂停</el-tag>
                                        <el-tag v-if="row.status == 4" size="mini" effect="dark" type="info">已关闭</el-tag>
                                        <el-tag v-if="row.status == 5" size="mini" effect="dark" type="success">待验收</el-tag>
                                        <!-- 延期判断 -->
                                        <el-tag style="margin-left: 5px;" v-if="row.is_overdue" size="mini" effect="dark" type="danger">已延期</el-tag>
                                        <!-- 项目名称 -->
                                        {{ row.name }}
                                    </template>
                                </vxe-column>

                                <!-- 项目经理 -->
                                <vxe-column width="120" field="leader_object.user.name" title="项目经理">
                                    <template #default="{ row }">
                                        <div style="display: flex; align-items: center; justify-content: flex-start;" v-if="row.leader_object?.user">
                                            <el-avatar style="margin-right: 5px;" :size="26" v-if="row.leader_object?.user.avatar" :src="row.leader_object?.user.avatar" />
                                            <el-avatar style="margin-right: 5px;" :size="26" v-else> {{ row.leader_object?.user.name[0] }} </el-avatar>
                                            {{  row.leader_object?.user.name }}
                                        </div>
                                    </template>
                                </vxe-column>

                                <!-- 项目销售 -->
                                <vxe-column width="120" field="sale_object.user.name" title="项目销售">
                                    <template #default="{ row }">
                                        <div style="display: flex; align-items: center; justify-content: flex-start;" v-if="row.sale_object?.user">
                                            <el-avatar style="margin-right: 5px;" :size="26" v-if="row.sale_object?.user.avatar" :src="row.sale_object?.user.avatar" />
                                            <el-avatar style="margin-right: 5px;" :size="26" v-else> {{ row.sale_object?.user.name[0] }} </el-avatar>
                                            {{  row.sale_object?.user.name }}
                                        </div>
                                    </template>
                                </vxe-column>

                            </vxe-table>
                        </template>

                        <!-- 分页 -->
                        <template slot="pager">
                            <vxe-pager :total="page.total" :page-size.sync="page.size" :current-page.sync="page.current" @page-change="TableSearch()"></vxe-pager>
                        </template>

                    </uw-table>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: [String, Array, Number],
            default: null
        },

        width: {
            type: String,
            default: '100%'
        },

        margin: {
            type: String,
            default: '0px'
        },
    },

    data () {
        return {
            dialogVisible: false,
            dialogLoading: false,

            table: [],

            // 分页
            page: {
                total: 0,
                size: 50,
                current: 1,
            },

            // 检索
            search: {
                is_overdue: null,
                number: null,
                name: null,
            },

            // 排序
            order: {
                method: 'desc',
                column: 'id'
            },

            selectObject: {},
            selectName: null
        }
    },

    watch: {
        value: {
            handler(val) {
                if (val) {
                    this.$http.post('/9api/projects/search-first', { id: val, hidden: ['describe'] }).then(rps => {
                        this.selectName =  rps.data.name
                        this.selectObject = rps.data
                    })
                } else {
                    this.selectObject = {}
                    this.selectName = null
                }
            },
            immediate: true
        },
    },

    methods: {

        /**
        |--------------------------------------------------
        | 窗口 - 打开动画结束时
        |--------------------------------------------------
        |
        */

        DialogOpend() {
            this.dialogVisible = true
            this.TableSearch()
        },

        /**
        |--------------------------------------------------
        | 表格数据 - 查询
        |--------------------------------------------------
        |
        */

        TableSearch() {
            this.dialogLoading = true
            this.$http.post(
                '/9api/projects/search',
                {
                    page: this.page,
                    search: this.search,
                    order: this.order,
                    append: ['leader_object', 'sale_object'],
                    hidden: ['describe']

                }
            ).then((rps) => {
                // 更新数据
                this.page.total = rps.data.total
                this.table = rps.data.body

                // 页码溢出
                if (this.table.length == 0 && this.page.current != 1) {
                    this.page.current = 1
                    this.TableSearch()
                }

                // 切换加载状态
                this.dialogLoading = false
            })
        },

        /**
        |--------------------------------------------------
        | 数据排序
        |--------------------------------------------------
        |
        */

        TableSort(column) {
            this.order.method = column.order ? column.order : this.order.method
            this.order.column = column.field
            this.TableSearch()
        },

        /**
        |--------------------------------------------------
        | 表格数据 - 行双击事件
        |--------------------------------------------------
        |
        */

        TableCellDblclick({ row }) {
            this.SelectChange(row)
        },

        /**
        |--------------------------------------------------
        | 清空选项
        |--------------------------------------------------
        |
        */

        SelectClear() {
            this.selectName = null
            this.selectObject = {}
            this.$emit('input', null)
            this.$emit('onChange', null)
        },

        /**
        |--------------------------------------------------
        | 选择事件
        |--------------------------------------------------
        |
        */

        SelectChange(item) {
            this.selectName =  item.name
            this.selectObject = item
            this.$emit('input', item.id)
            this.$emit('onChange', item)
            this.dialogVisible = false
        },
    }
}
</script>