<!---------------------------------------------------------------------------- 
 | 合同编辑
 |----------------------------------------------------------------------------
 | 方法：请注意大小写,参数描述见具体方法
 | $refs[ref_name].Open(id) 手动打开窗口,
 | $refs[ref_name].Close() 手动关闭窗口
 |----------------------------------------------------------------------------
 | 回调
 | @onSave = fn (Object) => {} 成功后返回合同信息
 |
 ---------------------------------------------------------------------------->

 <template>
    <el-drawer class="w-el-drawer" :visible.sync="visible" :size="960" :with-header="false" direction="rtl" :before-close="Close" append-to-body destroy-on-close>
        
        <!-- header -->
        <div class="w-el-drawer-header">
            <i class="el-icon-tickets"></i> 新建合同
        </div>

        <!-- body -->
        <div class="w-el-drawer-body"  v-loading="loading">
        
            <!-- 表单 -->
            <el-form :model="form" label-width="120px" size="mini">
                
                <!-- 关联跟单 -->
                <el-form-item label="关联商机">
                    <crm-business-select v-model="form.sale_documentary_id" @onChange="SaleDocumentaryOnChange" />
                </el-form-item>

                <!-- 关联客户 -->
                <el-form-item label="关联客户" :rules="[{ required: true, message: '必选项' }]" :error="formError.customer_id?.toString()" prop="customer_id">
                    <crm-customer-select v-model="form.customer_id" clearable />
                </el-form-item>

                <!-- 合同名称 -->
                <el-form-item label="合同名称" :rules="[{ required: true, message: '必填项' }]" :error="formError.name?.toString()" prop="name">
                    <el-input v-model="form.name" placeholder="X省X市XXxx项目" clearable />
                </el-form-item>

                <!-- 合同编号 -->
                <el-form-item label="合同编号" :rules="[{ required: true, message: '必填项' }]" :error="formError.number?.toString()" prop="number">
                    <el-input v-model="form.number" placeholder="CXXXXXX" clearable />
                </el-form-item>

                <!-- 合同来源 & 合同状态 -->
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="合同来源" :rules="[{ required: true, message: '必选项' }]" :error="formError.source_id?.toString()" prop="source_id">
                            <inputSource v-model="form.source_id"></inputSource>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="合同状态" :rules="[{ required: true, message: '必选项' }]" :error="formError.state?.toString()" prop="state">
                            <contract-state-select v-model="form.state" clearable />
                        </el-form-item>
                    </el-col>
                </el-row>

                <!-- 合同期限 & 签单日期 -->
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="合同期限" :rules="[{ required: true, message: '必选项' }]" :error="formError.time_limit?.toString()" prop="time_limit">
                            <el-date-picker style="width: 100%;" v-model="form.time_limit" value-format="yyyy-MM-dd" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" clearable />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="签单日期" :rules="[{ required: true, message: '必选项' }]" :error="formError.sign_date?.toString()" prop="sign_date">
                            <el-date-picker class="w-width-auto" v-model="form.sign_date" value-format="yyyy-MM-dd hh:mm:ss" type="date" placeholder="请填写签单日期" clearable />
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-divider content-position="left">参与成员</el-divider>

                <!-- 商务 & 销售 -->
                <el-row>
                    <el-col :span="12">
                        <el-form-item :rules="[{ required: true, message: '必选项' }]" label="商务" :error="formError.user_affair_id?.toString()" prop="user_affair_id">
                            <users-select v-model="form.user_affair_id" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item :rules="[{ required: true, message: '必选项' }]" label="销售" :error="formError.user_sale_id?.toString()" prop="user_sale_id">
                            <users-select v-model="form.user_sale_id" />
                        </el-form-item>
                    </el-col>
                </el-row>

                <!-- 售前 & 负责人 -->
                <el-row>
                    <el-col :span="12">
                        <el-form-item :rules="[{ required: true, message: '必选项' }]" label="售前" :error="formError.user_presale_id?.toString()" prop="user_presale_id">
                            <users-select v-model="form.user_presale_id" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item :rules="[{ required: true, message: '必选项' }]" label="管理员" :error="formError.user_admin_id?.toString()" prop="user_admin_id">
                            <users-select v-model="form.user_admin_id" disabled />
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-divider content-position="left">成本预估</el-divider>

                <el-row>
                    <el-col :span="12">
                        <el-form-item :rules="[{ required: true, message: '必填项' }]" label="预估工时" :error="formError.estimate_work_day?.toString()" prop="estimate_work_day">
                            <el-input-number v-model="form.estimate_work_day" :min="0" :precision="2" class="w-width-auto"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item :rules="[{ required: true, message: '必填项' }]" label="预估成本" :error="formError.estimate_money?.toString()" prop="estimate_money">
                            <el-input-number v-model="form.estimate_money" :min="0" :precision="2" class="w-width-auto"></el-input-number>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-divider content-position="left">签单金额</el-divider>
                
                <el-row>
                    <el-col :span="12">
                        <el-form-item :rules="[{ required: true, message: '必填项' }]" label="合同总金额" :error="formError.sign_money?.toString()" prop="sign_money">
                            <el-input-number v-model="form.sign_money" :min="0" :precision="2" disabled class="w-width-auto"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item :rules="[{ required: true, message: '必填项' }]" label="开发金额" :error="formError.sign_money_kf?.toString()" prop="sign_money_kf">
                            <el-input-number v-model="form.sign_money_kf" :min="0" :precision="2" class="w-width-auto"></el-input-number>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="12">
                        <el-form-item :rules="[{ required: true, message: '必填项' }]" label="集成金额" :error="formError.sign_money_jc?.toString()" prop="sign_money_jc">
                            <el-input-number v-model="form.sign_money_jc" :min="0" :precision="2" class="w-width-auto"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item :rules="[{ required: true, message: '必填项' }]" label="运维金额" :error="formError.sign_money_yw?.toString()" prop="sign_money_yw">
                            <el-input-number v-model="form.sign_money_yw" :min="0" :precision="2" class="w-width-auto"></el-input-number>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="12">
                        <el-form-item :rules="[{ required: true, message: '必填项' }]" label="安服金额" :error="formError.sign_money_af?.toString()" prop="sign_money_af">
                            <el-input-number v-model="form.sign_money_af" :min="0" :precision="2" class="w-width-auto"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item :rules="[{ required: true, message: '必填项' }]" label="工程金额" :error="formError.sign_money_gc?.toString()" prop="sign_money_gc">
                            <el-input-number v-model="form.sign_money_gc" :min="0" :precision="2" class="w-width-auto"></el-input-number>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="12">
                        <el-form-item :rules="[{ required: true, message: '必填项' }]" label="设备金额" :error="formError.sign_money_sb?.toString()" prop="sign_money_sb">
                            <el-input-number v-model="form.sign_money_sb" :min="0" :precision="2" class="w-width-auto"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item :rules="[{ required: true, message: '必填项' }]" label="过单金额" :error="formError.sign_money_gd?.toString()" prop="sign_money_gd">
                            <el-input-number v-model="form.sign_money_gd" :min="0" :precision="2" class="w-width-auto"></el-input-number>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="12">
                        <el-form-item :rules="[{ required: true, message: '必填项' }]" label="其它金额" :error="formError.sign_money_qt?.toString()" prop="sign_money_qt">
                            <el-input-number v-model="form.sign_money_qt" :min="0" :precision="2" class="w-width-auto"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item :rules="[{ required: true, message: '必填项' }]" label="集成2金额" :error="formError.sign_money_sw?.toString()" prop="sign_money_sw">
                            <el-input-number v-model="form.sign_money_sw" :min="0" :precision="2" class="w-width-auto"></el-input-number>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-divider content-position="left">其它信息</el-divider>

                <!-- 审批流程 -->
                <el-form-item label="审批流程">
                    <workflow-update ref="workflow" v-model="form.workflow_template" model="contract" />
                </el-form-item>

                <el-form-item label="附件">
                    <files-upload v-model="form.files_id" model="contract" stage="更新合同" />
                </el-form-item>
            </el-form>
        </div>

        <!-- footer -->
        <div class="w-el-drawer-footer">
            <el-button size="medium" @click="Close()">取消 / 返回</el-button>
            <el-button size="medium" type="info" @click="ContractUpdate(false)" v-if="(contract.workflow_state < 4 && contract.draft == 0) || contract.workflow_state == 8" :loading="loading">转为草稿</el-button>
            <el-button size="medium" type="primary" @click="ContractUpdate(true)" v-if="contract.draft == 1" :loading="loading">提交申请</el-button>
            <el-button size="medium" type="primary" @click="ContractUpdate()" v-if="contract.workflow_state < 4 || contract.workflow_state == 8" :loading="loading">更新合同</el-button>
        </div>

    </el-drawer>
</template>
    
<script>
import inputSource from "@/views/config/contractSource/input"

export default {
    components: {
        inputSource
    },

    data() {
        return {
            // 窗口
            visible: false,
            loading: false,

            // 合同信息
            contract: {},

            // 表单
            formError: {},
            form: {
                id: null,
                sale_documentary_id: null,
                customer_id: null,
                name: null,
                number: null,
                source_id: null,
                state: null,
                time_limit: null,
                sign_date: null,
                sign_money: 0,
                sign_money_jc: 0,
                sign_money_yw: 0,
                sign_money_af: 0,
                sign_money_gc: 0,
                sign_money_sb: 0,
                sign_money_kf: 0,
                sign_money_gd: 0,
                sign_money_qt: 0,
                sign_money_sw: 0,
                estimate_money: 0,
                estimate_work_day: 0,
                user_affair_id: null,
                user_sale_id: null,
                user_presale_id: null,
                user_admin_id: null,
                files_id: [],
                workflow_template: null,
                draft: 0,
            },
        }
    },

    watch: {
        'form.sign_money_jc' () { this.form.sign_money = this.form.sign_money_jc + this.form.sign_money_yw + this.form.sign_money_af + this.form.sign_money_gc + this.form.sign_money_sb + this.form.sign_money_kf + this.form.sign_money_gd + this.form.sign_money_qt },
        'form.sign_money_yw' () { this.form.sign_money = this.form.sign_money_jc + this.form.sign_money_yw + this.form.sign_money_af + this.form.sign_money_gc + this.form.sign_money_sb + this.form.sign_money_kf + this.form.sign_money_gd + this.form.sign_money_qt },
        'form.sign_money_af' () { this.form.sign_money = this.form.sign_money_jc + this.form.sign_money_yw + this.form.sign_money_af + this.form.sign_money_gc + this.form.sign_money_sb + this.form.sign_money_kf + this.form.sign_money_gd + this.form.sign_money_qt },
        'form.sign_money_gc' () { this.form.sign_money = this.form.sign_money_jc + this.form.sign_money_yw + this.form.sign_money_af + this.form.sign_money_gc + this.form.sign_money_sb + this.form.sign_money_kf + this.form.sign_money_gd + this.form.sign_money_qt },
        'form.sign_money_sb' () { this.form.sign_money = this.form.sign_money_jc + this.form.sign_money_yw + this.form.sign_money_af + this.form.sign_money_gc + this.form.sign_money_sb + this.form.sign_money_kf + this.form.sign_money_gd + this.form.sign_money_qt },
        'form.sign_money_kf' () { this.form.sign_money = this.form.sign_money_jc + this.form.sign_money_yw + this.form.sign_money_af + this.form.sign_money_gc + this.form.sign_money_sb + this.form.sign_money_kf + this.form.sign_money_gd + this.form.sign_money_qt },
        'form.sign_money_gd' () { this.form.sign_money = this.form.sign_money_jc + this.form.sign_money_yw + this.form.sign_money_af + this.form.sign_money_gc + this.form.sign_money_sb + this.form.sign_money_kf + this.form.sign_money_gd + this.form.sign_money_qt },
        'form.sign_money_qt' () { this.form.sign_money = this.form.sign_money_jc + this.form.sign_money_yw + this.form.sign_money_af + this.form.sign_money_gc + this.form.sign_money_sb + this.form.sign_money_kf + this.form.sign_money_gd + this.form.sign_money_qt },
    },
    
    methods: {

        /**
        |--------------------------------------------------
        | 窗口 - 打开
        |--------------------------------------------------
        |
        */

        open(id) {
            this.visible = true
            this.loading = false

            // 获取合同信息
            this.$http.post('/9api/crm/contract/search-first', { id: id, append: ['workflow_template'] }).then(async rps => {
                this.contract = rps.data
                this.form.id = rps.data.id
                this.form.sale_documentary_id = rps.data.sale_documentary_id
                this.form.customer_id = rps.data.customer_id
                this.form.name = rps.data.name
                this.form.number = rps.data.number
                this.form.source_id = rps.data.source_id
                this.form.state = rps.data.state
                this.form.time_limit = rps.data.time_limit
                this.form.sign_date = rps.data.sign_date
                this.form.sign_money = rps.data.sign_money
                this.form.sign_money_jc = rps.data.sign_money_jc
                this.form.sign_money_yw = rps.data.sign_money_yw
                this.form.sign_money_af = rps.data.sign_money_af
                this.form.sign_money_gc = rps.data.sign_money_gc
                this.form.sign_money_sb = rps.data.sign_money_sb
                this.form.sign_money_kf = rps.data.sign_money_kf
                this.form.sign_money_gd = rps.data.sign_money_gd
                this.form.sign_money_qt = rps.data.sign_money_qt
                this.form.sign_money_sw = rps.data.sign_money_sw
                this.form.estimate_money = rps.data.estimate_money
                this.form.estimate_work_day = rps.data.estimate_work_day
                this.form.user_affair_id = rps.data.user_affair_id
                this.form.user_sale_id = rps.data.user_sale_id
                this.form.user_presale_id = rps.data.user_presale_id
                this.form.user_admin_id = rps.data.user_admin_id
                this.form.files_id = rps.data.files_id
                this.form.draft = rps.data.draft
                this.form.workflow_template = rps.data.workflow_template
            })

            
        },


        /**
        |--------------------------------------------------
        | 窗口 - 关闭
        |--------------------------------------------------
        |
        */

        Close() {
            this.visible = false
            this.loading = false
        },

        /**
        |--------------------------------------------------
        | 合同 - 创建合同
        |--------------------------------------------------
        | @param {Boolean} draft - true = 存为草稿, false = 创建合同
        |
        */

        ContractUpdate(draft) {

            if (draft === false) this.form.draft = 1;
            if (draft === true) this.form.draft = 0;

            // 清空错误提示
            this.loading = true
            this.formError = {}

            // 提交表单
            this.$http.post('/9api/contract/edit', this.form).then(rps => {

                this.loading = false

                // 提交失败
                if (rps.data.status == 'error') {
                    this.formError = rps.data.error
                }

                // 提交成功
                if (rps.data.status == 'success') {
                    
                    // 操作通知
                    this.$notify.success({ title: '提示', message: '合同更新成功', duration: 2000 })
                    this.contract = rps.data.body
                    this.$emit('onSave', this.contract)
                }
            })
        },

        /**
        |--------------------------------------------------
        | 跟单信息 - 映射表单
        |--------------------------------------------------
        |
        */

        SaleDocumentaryOnChange(data) {
            this.form.customer_id = data?.customer_id
            this.form.name = data?.name
            this.form.source_id = data?.source_id
            this.form.sign_money = data?.sign_money
            this.form.sign_money_jc = data?.sign_money_jc
            this.form.sign_money_yw = data?.sign_money_yw
            this.form.sign_money_af = data?.sign_money_af
            this.form.sign_money_gc = data?.sign_money_gc
            this.form.sign_money_sb = data?.sign_money_sb
            this.form.sign_money_kf = data?.sign_money_kf
            this.form.sign_money_gd = data?.sign_money_gd
            this.form.sign_money_qt = data?.sign_money_qt
            this.form.sign_money_sw = data?.sign_money_sw
            this.form.estimate_money = data?.estimate_money
            this.form.estimate_work_day = data?.estimate_work_day
            this.form.user_affair_id = data?.user_affair_id
            this.form.user_sale_id = data?.user_sale_id
            this.form.user_presale_id = data?.user_presale_id
        }
    }
}
</script>