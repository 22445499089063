<template>
    <el-dialog :visible.sync="visible" width="800px" title="新建供应商" append-to-body>

        <!-- 表单 -->
        <el-form :model="form" label-width="120px" size="mini" label-position="top">

            <!-- 基本信息 -->
            <el-divider content-position="left"><b>基本信息</b></el-divider>
            <el-row :gutter="20">
                <el-col :span="8">
                    <el-form-item label="供应商名称" :rules="[{ required: true, message: '必填项' }]" :error="formError.name?.toString()" prop="name">
                        <el-input v-model="form.name"  clearable />
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="供应商级别">
                        <el-select v-model="form.level" placeholder="请选择" filterable clearable :style="{ width: '100%' }">
                            <el-option label="A" value="A"></el-option>
                            <el-option label="B" value="B"></el-option>
                            <el-option label="C" value="C"></el-option>
                            <el-option label="D" value="D"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="联系人姓名" :rules="[{ required: true, message: '必填项' }]" :error="formError.contacts?.toString()" prop="contacts">
                        <el-input v-model="form.contacts" prefix-icon="el-icon-user" clearable />
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="联系人电话" :rules="[{ required: true, message: '必填项' }]" :error="formError.phone?.toString()" prop="phone">
                        <el-input v-model="form.phone" prefix-icon="el-icon-phone-outline" clearable />
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="详细地址">
                        <el-input v-model="form.address" prefix-icon="el-icon-map-location" clearable />
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="备注">
                        <el-input v-model="form.notes" type="textarea" rows="2" />
                    </el-form-item>
                </el-col>
            </el-row>

            <!-- 支付信息 -->
            <el-divider content-position="left"><b>支付信息</b></el-divider>
            <el-row :gutter="20">
                <el-col :span="8">
                    <el-form-item label="支付方式">
                        <config-pay-type-select v-model="form.pay_type_id" />
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="支付平台">
                        <el-input v-model="form.pay_platform" clearable placeholder="微信/XXXXXXXX银行" />
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="账户名称">
                        <el-input v-model="form.pay_name" clearable placeholder="许XXX/XXXX支行" />
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="账户号码">
                        <el-input v-model="form.pay_account" clearable placeholder="微信/XXXXXXXX银行" />
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="票据类型">
                        <config-invoice-type-select v-model="form.invoice_type_id" />
                    </el-form-item>
                </el-col>
            </el-row>

        </el-form>

        <div slot="footer">
            <el-button size="small" @click="visible = false">取 消</el-button>
            <el-button size="small" type="primary" @click="FormSubmit()" :loading="loading">确 定</el-button>
        </div>

    </el-dialog>
</template>

<script>
export default {
    data () {
        return {
            visible: false,
            loading: false,

            formError: {},
            form: {
                name: null,
                level: 'A',
                contacts: null,
                phone: null,
                address: null,
                notes: null,
                pay_type_id: null,
                pay_platform: null,
                pay_name: null,
                pay_account: null,
                invoice_type_id: null
            }
        }
    },

    methods: {

        /**
        |--------------------------------------------------
        | 打开窗口
        |--------------------------------------------------
        |
        */

        Open() {
            this.visible = true
            this.loading = false
        },

        /**
        |--------------------------------------------------
        | 提交表单
        |--------------------------------------------------
        |
        */

        FormSubmit() {

            this.loading = true

            this.$http.post('/9api/ims/supplier/create', this.form).then((rps) => {

                // 提交失败
                if (rps.data.state == 'error') {
                    this.formError = rps.data.body
                }

                // 提交成功
                if (rps.data.state == 'success') {
                    this.$emit('onChange', rps.data.body)
                    this.visible = false
                }

                this.loading = false
            })
        },
    }
}
</script>