<template>
    <el-drawer :visible.sync="visible" size="80%" direction="rtl" append-to-body destroy-on-close :show-close="false" :before-close="Close">

        <div slot="title">

            <!-- 合同状态 -->
            <div class="w-margin-b-10">
                <span>退货订单 - </span>
                <span v-if="back.confied_state == 0" >待审批</span>
                <span v-if="back.confied_state == 1" >已同意</span>
                <span v-if="back.confied_state == 2" >已拒绝</span>
            </div>
            
            <div class="w-margin-b-10" style="font-size: 18px; color: #000; font-weight: bold;">{{ back.number }}</div>

            <!-- 功能按钮 -->
            <div style="display: flex;">
                <el-button style="margin-left: 5px;" size="mini" @click="Confied(back.id, 1)" type="primary" v-if="back.confied_state == 0">同意</el-button>
                <el-button style="margin-left: 5px;" size="mini" @click="Confied(back.id, 2)" type="danger" v-if="back.confied_state == 0">拒绝</el-button>
                <el-button size="mini" @click="DataDelete()" type="danger">删除订单</el-button>
            </div>

            <!-- 金额概览 -->
            <div class="w-margin-t-10" style="background-color: #f4f5f7; border-radius: 3px; padding: 15px; display: flex;">
                <el-statistic title="退货金额"><template slot="formatter"><span style="font-size: 16px;"><b>￥{{ back.money }}</b></span></template></el-statistic>
                <el-statistic title="退货日期"><template slot="formatter"><span style="font-size: 14px;">{{ back.date }}</span></template></el-statistic>
                <el-statistic title="创建人"><template slot="formatter"><span style="font-size: 14px;">{{ back.created_user?.name }}</span></template></el-statistic>
                <el-statistic title="创建时间"><template slot="formatter"><span style="font-size: 14px;">{{ back.created_at }}</span></template></el-statistic>
            </div>

        </div>

        <div class="w-padding-l-20 w-padding-r-20 w-padding-b-20">
            <el-tabs v-model="tabs">

                <!-- 采购记录 -->
                <el-tab-pane label="退货产品" name="product">
                    <ims-product-back-drawer-product :product_back_id="back.id" v-if="tabs == 'product'" />
                </el-tab-pane>

                <!-- 出库记录 -->
                <el-tab-pane label="出库记录" name="warehouseDocumentBack">
                    <ims-product-back-drawer-warehouse-document-back :id="back.id" v-if="tabs == 'warehouseDocumentBack'" />
                </el-tab-pane>

                <!-- 回款 -->
                <el-tab-pane label="回款" name="payEnter">
                    <ims-product-back-drawer-pay-enter :id="back.id" @onChange="RePage" v-if="tabs == 'payEnter'" />
                </el-tab-pane>

                <!-- 附件 -->
                <el-tab-pane label="附件" name="files">
                    <ims-product-back-upload-files :id="back.id" @onChange="RePage" v-if="tabs == 'files'" />
                    <files-table v-model="back.files_id" />
                </el-tab-pane>

            </el-tabs>
        </div>

        <!-- 组件 -->
        <ims-product-drawer ref="productDetails" />
        <ims-supplier-drawer ref="supplierDetails" />

    </el-drawer>
</template>

<script>
export default {
    data() {
        return {
            visible: false,
            loading: false,
            tabs: 'product',

            back: {
                id: null,
                files_id: [],
                created_user: {},
                updated_user: {},
                product_order_log: []
            }
        }
    },

    methods: {

        /**
        |--------------------------------------------------
        | 打开窗口
        |--------------------------------------------------
        |
        */

        Open(id) {
            this.visible = true
            this.DataGet(id)
        },

        /**
        |--------------------------------------------------
        | 关闭窗口
        |--------------------------------------------------
        |
        */

        Close(done) {
            this.$emit('onChange')
            done()
        },

        /**
        |--------------------------------------------------
        | 刷新
        |--------------------------------------------------
        |
        */

        RePage() {
            this.DataGet()
        },

        /**
        |--------------------------------------------------
        | 查询
        |--------------------------------------------------
        |
        */

        DataGet(id = this.back.id) {
            this.loading = true

            this.$http.post(
                '/9api/ims/product-back/search-first',
                {
                    search: { id: id },
                    append: ['created_user', 'updated_user']
                }
            ).then(rps => {
                this.back = rps.data
                this.loading = false
            }) 
        },

        /**
        |--------------------------------------------------
        | 删除
        |--------------------------------------------------
        |
        */

        DataDelete() {
            this.$confirm('一旦删除将无法恢复，移除内容包含以下：(退货订单、产品退货记录、回款记录、关联附件)', '警告', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'error'
            }).then(() => {
                this.$http.post('/9api/ims/product-back/delete', { id: this.back.id }).then((rps) => {
                    this.$emit('onChange')
                    this.visible = false
                })
            })
        },

        // 订单审核
        Confied(id, state) {
            this.$http.post('/9api/ims/product-back/update-confied', { id: id, confied_state: state }).then(rps => {
                this.RePage()
            })
        },
        
    }
}

</script>6
