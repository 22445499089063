<!---------------------------------------------------------------------------- 
 | 国家 - 省份
 |----------------------------------------------------------------------------
 | 方法：请注意大小写,参数描述见具体方法
 | *v-model     默认值------默认为 null
 | width        表单宽度----默认为 ‘100%’
 | margin       表单外边距--默认为 ‘0px’
 |----------------------------------------------------------------------------
 | 回调
 | @onChange = fn (data) => {} 返回表单内容
 |
 ---------------------------------------------------------------------------->

 <template>
    <el-select
        @change="SelectChange"
        v-model="selectValue"
        :style="{ width: width, margin: margin }"
        size="mini"
        placeholder="城市"
        no-data-text="请选择先省份"
        clearable>
        <el-option v-for="(item, idx) in select" :key="idx" :label="item.name" :value="item.name"></el-option>
    </el-select>
</template>

<script>
export default {
    props: {
        value: {
            type: [String, Array],
            default: null
        },

        province: {
            type: String,
            default: null
        },

        width: {
            type: String,
            default: '100%'
        },

        margin: {
            type: String,
            default: '0px'
        },
    },

    watch: {
        value () {
            this.selectValue = this.value
        },
        province (val) {
            if (val) {
                this.$http.post('/9api/country/search-city', { province: this.province }).then(rps => {
                    this.select = rps.data
                })
            } else {
                this.selectValue = null
                this.select = []
                this.$emit('input', null)
                this.$emit('onChange', null)
            }
        }
    },

    data () {
        return {
            select: [],
            selectValue: null,
        }
    },

    methods: {
        SelectChange(data) {
            this.$emit('input', data)
            this.$emit('onChange', data)
        }
    }
}
</script>