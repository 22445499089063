<template>
    <el-dialog :visible.sync="visible" width="600px" title="新的仓库" append-to-body destroy-on-close @close="close()">

        <!-- 表单 -->
        <el-form :model="form" label-width="120px" size="mini" label-position="top">

            <!-- 仓库名称 / 仓库编码 -->
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="仓库名称" :rules="[{ required: true, message: '必填项' }]" :error="formError.name?.toString()" prop="name">
                        <el-input v-model="form.name" clearable />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="仓库编码" :rules="[{ required: true, message: '必填项' }]" :error="formError.number?.toString()" prop="number">
                        <el-input v-model="form.number" clearable />
                    </el-form-item>
                </el-col>
            </el-row>

            <!-- 仓库状态 / 仓库管理员 -->
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="仓库状态" :rules="[{ required: true, message: '必选项' }]" :error="formError.state?.toString()" prop="state">
                        <el-select v-model="form.state" placeholder="请选择" :style="{ width: '100%' }">
                            <el-option label="启用" :value="1"></el-option>
                            <el-option label="停用" :value="0"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="仓库管理员" :rules="[{ required: true, message: '必选项' }]" :error="formError.users_admin_id?.toString()" prop="users_admin_id">
                        <users-select v-model="form.users_admin_id"></users-select>
                    </el-form-item>
                </el-col>
            </el-row>

            <!-- 仓库地址 -->
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="仓库地址">
                        <el-input v-model="form.address" clearable />
                    </el-form-item>
                </el-col>
            </el-row>

        </el-form>

        <div slot="footer">
            <el-button size="small" @click="visible = false">取 消</el-button>
            <el-button size="small" type="primary" @click="formSubmit()" :loading="loading">确 定</el-button>
        </div>

    </el-dialog>
</template>

<script>
export default {
    data () {
        return {
            visible: false,
            loading: false,

            formError: {},
            form: {
                number: null,
                name: null,
                state: 1,
                users_admin_id: null,
                address: null
            }
        }
    },

    methods: {

        // 打开窗口
        open() {
            this.visible = true
            this.loading = false
        },

        // 关闭窗口
        close() {
            this.form.number = null
            this.form.name = null
            this.form.state = 1
            this.form.users_admin_id = null
            this.form.address = null
        },

        // 提交表单
        formSubmit() {

            this.loading = true

            this.$http.post('/9api/ims/warehouse/create', this.form).then((rps) => {

                // 提交失败
                if (rps.data.state == 'error') {
                    this.formError = rps.data.body
                }

                // 提交成功
                if (rps.data.state == 'success') {
                    this.$emit('onChange', rps.data.body)
                    this.visible = false
                }

                this.loading = false
            })
        },
    }
}
</script>