<template>
    <div>
        <el-input
            v-if="showInput"
            v-model="select.inputValue"
            @focus="Open"
            @clear="SelectClear"
            placeholder="商机"
            clearable
            :disabled="disabled"
            :style="{ width: width, margin: margin }"/>

        <el-dialog :visible.sync="dialogVisible" width="900px" append-to-body title="商机" :close-on-click-modal="false" :close-on-press-escape="false">

            <!-- 提示 -->
            <el-alert title="提示：双击鼠标左键 进行选择" type="info" close-text="知道了" show-icon :style="{ marginBottom: '10px' }" />

            <!-- toolbar -->
            <uw-grid-search gutter="0 10px" :column="3" :style="{ marginBottom: '10px' }">
                <template slot="full">
                    <el-input v-model="post.search.name" @input="$lodash.debounce(TableSearch, 500)" clearable placeholder="商机名称" />
                </template>
                <template slot="tools">
                    <el-button type="primary" @click="$refs.businessCreate.Open()">新建商机</el-button>
                </template>
                <el-form-item label="商机状态">
                    <el-select v-model="post.search.state" @change="TableSearch" placeholder="商机状态" clearable :style="{ width: '100%' }">
                        <el-option :value="0" label="放弃"></el-option>
                        <el-option :value="1" label="跟进"></el-option>
                        <el-option :value="2" label="成功"></el-option>
                        <el-option :value="3" label="失败"></el-option>
                        <el-option :value="4" label="搁置"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="销售">
                    <users-select v-model="post.search.users_sale_ids" @onChange="TableSearch" multiple placeholder="销售查询" />
                </el-form-item>
                <el-form-item label="售前">
                    <users-select v-model="post.search.users_presale_ids" @onChange="TableSearch" multiple placeholder="售前查询" />
                </el-form-item>
                <el-form-item label="商务">
                    <users-select v-model="post.search.users_affair_ids" @onChange="TableSearch" multiple placeholder="商务查询" />
                </el-form-item>
                <el-form-item label="管理员">
                    <users-select v-model="post.search.users_admin_ids" @onChange="TableSearch" multiple placeholder="管理员查询" />
                </el-form-item>
                <el-form-item label="跟进日期">
                    <el-date-picker @change="TableSearch" v-model="post.search.updated_at" clearable type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :style="{ width: '100%' }"></el-date-picker>
                </el-form-item>
                <el-form-item label="创建日期">
                    <el-date-picker @change="TableSearch" v-model="post.search.created_at" clearable type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :style="{ width: '100%' }"></el-date-picker>
                </el-form-item>
            </uw-grid-search>

            <!-- table -->
            <vxe-table ref="xTable" :data="table" :loading="$store.state.loading" @sort-change="TableSort" @cell-dblclick="TableCellDblclick" height="500px">

                <!-- 序号 -->
                <vxe-column width="60" type="seq" title="序号"></vxe-column>

                <!-- 跟单状态 -->
                <vxe-column min-width="80" field="state" title="商机状态">
                    <template #default="{ row }">
                        <el-tag effect="dark" v-if="row.state == 0" type="danger">放弃</el-tag>
                        <el-tag effect="dark" v-if="row.state == 1" type="primary">跟进</el-tag>
                        <el-tag effect="dark" v-if="row.state == 2" type="success">成功</el-tag>
                        <el-tag effect="dark" v-if="row.state == 3" type="danger">失败</el-tag>
                        <el-tag effect="dark" v-if="row.state == 4" type="warning">搁置</el-tag>
                    </template>
                </vxe-column>

                <!-- 跟单主题 -->
                <vxe-column min-width="210" field="name" title="商机主题" sortable>
                    <template #default="{ row }">
                        <span class="el-span-primary" @click="$refs.businessDrawer.Open(row.id)">{{ row.name }}</span>
                    </template>
                </vxe-column>

                <!-- 关联客户 -->
                <vxe-column width="210" field="customer_id" title="关联客户" sortable>
                    <template #default="{ row }">
                        <span class="el-span-primary" @click="$refs.customerDrawer.Open(row.customer.id)">{{ row.customer.name }}</span>
                    </template>
                </vxe-column>

                <!-- 预期金额 -->
                <vxe-column width="120" field="sign_money" title="预期金额" sortable>
                    <template #default="{ row }">
                        ￥{{ row.sign_money.toLocaleString() }}
                    </template>
                </vxe-column>

                <!-- 工作进度 -->
                <vxe-column min-width="240" field="progress_id" title="工作进度">
                    <template #default="{ row }">
                        <el-tag class="w-margin-r-5" v-for="(item, idx) in row.progress.filter(v => v.value > 0)" :key="idx">{{ item.title }}</el-tag>
                    </template>
                </vxe-column>

                <!-- 销售 -->
                <vxe-column width="90" field="user_sale_id" title="销售" sortable>
                    <template #default="{ row }">
                        <el-link icon="el-icon-user" :underline="false">{{ row.user_sale.name }}</el-link>
                    </template>
                </vxe-column>

                <!-- 商务 -->
                <vxe-column width="90" field="user_affair_id" title="商务" sortable>
                    <template #default="{ row }">
                        <el-link icon="el-icon-user" :underline="false">{{ row.user_affair.name }}</el-link>
                    </template>
                </vxe-column>

                <!-- 售前 -->
                <vxe-column width="90" field="user_presale_id" title="售前" sortable>
                    <template #default="{ row }">
                        <el-link icon="el-icon-user" :underline="false">{{ row.user_presale.name }}</el-link>
                    </template>
                </vxe-column>

                <!-- 管理员 -->
                <vxe-column width="90" field="user_admin_id" title="管理员">
                    <template #default="{ row }">
                        <el-link icon="el-icon-user" :underline="false">{{ row.user_admin.name }}</el-link>
                    </template>
                </vxe-column>

                <!-- 跟进时间 -->
                <vxe-column width="140" field="updated_at" title="跟进时间" sortable></vxe-column>

            </vxe-table>

            <!-- pager -->
            <vxe-pager 
                :style="{ marginTop: '10px' }"
                :total="post.page.total"
                :page-size.sync="post.page.size"
                :current-page.sync="post.page.current"
                @page-change="TableSearch()">
            </vxe-pager>

        </el-dialog>

        <!-- 组件 -->
        <crm-business-create ref="businessCreate" @success="TableSearch" />
        <crm-business-drawer ref="businessDrawer" @onChange="TableSearch" />
        <crm-customer-drawer ref="customerDrawer" @onChange="TableSearch" />

    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: [String, Array, Number],
            default: null
        },

        width: {
            type: String,
            default: '100%'
        },

        margin: {
            type: String,
            default: '0px'
        },

        disabled: {
            type: Boolean,
            default: false
        },

        showInput: {
            type: Boolean,
            default: true
        }
    },

    data () {
        return {
            dialogVisible: false,

            // 查询结果
            table: [],

            // 查询链接
            url: {
                default: '/9api/crm/business/search',
                first: '/9api/crm/business/search-first'
            },

            // 查询条件
            post: {
                page: {
                    total: 0,
                    size: 50,
                    current: 1,
                },
                search: {
                    name: null,
                    state: null,
                    users_sale_ids: null,
                    users_presale_ids: null,
                    users_affair_ids: null,
                    users_admin_ids: null,
                    updated_at: [],
                    created_at: []
                },
                append: ['customer', 'user_sale', 'user_admin', 'user_affair', 'user_presale'],
                hidden: ['describe'],
                order: {
                    method: 'desc',
                    column: 'id'
                },
            },

            // 选择结果
            select: {
                data: {},
                inputValue: null
            }
        }
    },

    watch: {
        value: {
            handler(val) {
                val ? this.SelectSearch() : this.SelectClear()
            },
            immediate: true
        }
    },

    methods: {

        // 窗口：打开
        Open(search)
        {
            // 初始化检索条件
            if (search)
            {
                this.post.search = { ...this.post.search, ...search }
            }

            this.dialogVisible = true
            this.TableSearch()
        },

        // 数据：查询
        TableSearch()
        {
            this.$http.post(this.url.default, this.post).then((rps) => {
                // 更新数据
                this.post.page.total = rps.data.total
                this.table = rps.data.body

                // 页码溢出
                if (this.table.length == 0 && this.post.page.current != 1)
                {
                    this.post.page.current = 1
                    this.TableSearch()
                }
            })
        },

        // 表格：排序
        TableSort(column)
        {
            this.post.order.method = column.order ? column.order : this.post.order.method
            this.post.order.column = column.field
            this.TableSearch()
        },

        // 表格：双击事件
        TableCellDblclick({ row })
        {
            this.select.inputValue = row.name
            this.select.data = row
            this.$emit('input', row.id)
            this.$emit('onChange', row)
            this.dialogVisible = false
        },

        // 选择：查询
        SelectSearch()
        {
            this.$http.post(this.url.first, { id: this.value }).then(rps => {
                this.select.inputValue = rps.data.name
                this.select.data = rps.data
                this.$emit('input', rps.data.id)
                this.$emit('onChange', rps.data)
            })
        },

        // 选择：清除
        SelectClear()
        {
            this.select.inputValue = null
            this.select.data = {}
            this.$emit('input', null)
            this.$emit('onChange', null)
        },
    }
}
</script>