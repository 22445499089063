<!---------------------------------------------------------------------------- 
 | 附件管理
 |----------------------------------------------------------------------------
 | :model 关联模块名称
 | :stage 上传阶段的标识
 | :span 基础的 24 分栏
 | :preview-type 窗口类型 drawer 或 dialog
 | :preview-direction 打开的方向
 | :preview-modal 是否需要遮罩层
 | :drawer-size Drawer 窗体的大小
 |
 ---------------------------------------------------------------------------->

 <template>
    <div>
        <vxe-table border ref="xTable" :data="files" :loading="loading" :style="{ marginTop: '10px' }">
            <vxe-column min-width="120" field="name" title="附件名称"></vxe-column>
            <vxe-column width="160" field="created_at" title="上传日期"></vxe-column>
            <vxe-column width="100" field="stage" title="上传时机"></vxe-column>
            <vxe-column width="100" field="user.name" title="上传人"></vxe-column>
            <vxe-column width="120" align="center" fixed="right">
                <template #default="{ row }">
                    <el-link type="primary" class="w-margin-r-10" @click.stop="$refs.filesPreview.Open(row.id, row.path, 'preview')">预览</el-link>
                    <el-link type="primary" class="w-margin-r-10" @click.stop="$refs.filesPreview.Open(row.id, row.path, 'down')">下载</el-link>
                    <el-link type="danger"  @click.stop="FilesDelete(row.id)">移除</el-link>
                </template>
            </vxe-column>
        </vxe-table>

        <files-preview ref="filesPreview" :preview-type="previewType" :preview-direction="previewDirection" :preview-modal="previewModal" :drawer-size="drawerSize" />
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Array,
            default: function () {
                return []
            }
        },
        span: {
            type: Number,
            default: 8
        },
        drawerSize: {
            type: [String, Number],
            default: '80%'
        },
        previewType: {
            type: String,
            default: 'dialog'
        },
        previewDirection: {
            type: String,
            default: 'rtl'
        },
        previewModal: {
            type: Boolean,
            default: true
        }
    },

    data () {
        return {
            loading: false,
            files: [],
        }
    },

    watch: {
        value: {
            handler () {
                if (JSON.stringify(this.value) != JSON.stringify(this.files.map(item => item.id))) {
                    this.FilesSearchIds()
                }
            },
            immediate: true,
        }
    },

    methods: {

        /**
        |--------------------------------------------------
        | 查询文件
        |--------------------------------------------------
        |
        */

        FilesSearchIds() {
            this.$http.post('/9api/components-files/search-ids', {ids: this.value}).then(rps => {
                new Promise((resolve) => {
                    this.files = rps.data
                    resolve()
                }).then(rps => {
                    this.$emit('input', this.files.map(item => item.id))
                })
            })
        },

        /**
        |--------------------------------------------------
        | 移除文件
        |--------------------------------------------------
        |
        */

        FilesDelete(id) {
            this.$http.post('/9api/components-files/delete', {id: id}).then(rps => {
                new Promise((resolve) => {
                    this.files = this.files.filter(item => item.id != id)
                    resolve()
                }).then(rps => {
                    this.$emit('input', this.files.map(item => item.id))
                })
            })
        },
    }
}
</script>