<!---------------------------------------------------------------------------- 
 | 项目管理 - 修改人工计价/P
 |----------------------------------------------------------------------------
 | 方法：请注意大小写,参数描述见具体方法
 | $refs[ref_name].Open(id, name) 手动打开窗口,
 | $refs[ref_name].Close() 手动关闭窗口
 |----------------------------------------------------------------------------
 | 回调
 | @onChange = fn () => {} 成功后返信息
 |
 ---------------------------------------------------------------------------->

 <template>
    <el-dialog class="w-el-dialog" :visible.sync="visible" append-to-body title="人工计价/P（￥元）" :close-on-click-modal="false" :close-on-press-escape="false" :before-close="Close">
          <el-input-number v-model="money" :min="0" class="w-width-auto"></el-input-number>
          <span slot="footer" class="dialog-footer">
              <el-button size="mini" @click="Close()">取 消</el-button>
              <el-button size="mini" type="primary" @click="SubmitForm()" :loading="loading">确 定</el-button>
          </span>
    </el-dialog>
</template>

<script>
export default {
  data () {
      return {
          // 加载状态
          visible: false,
          loading: false,

          project_id: null,
          user_id: null,
          money: 0
      }
  },

  methods: {

      /**
      |--------------------------------------------------
      | 窗口 - 打开
      |--------------------------------------------------
      |
      */

      Open (project_id, user_id, money) {
          this.project_id = project_id
          this.user_id = user_id
          this.money = money
          this.visible = true
      },


      /**
      |--------------------------------------------------
      | 窗口 - 关闭
      |--------------------------------------------------
      |
      */

      Close() {
          this.loading = false
          this.visible = false
      },


      /**
      |--------------------------------------------------
      | 修改人工计价/P
      |--------------------------------------------------
      |
      */

      SubmitForm() {
          this.loading = true
          this.$http.post('/9api/projects-team/edit-money', { project_id: this.project_id, user_id: this.user_id, money: this.money }).then(rps => {
              if (rps.data.state == 'success') {
                  this.$emit('onChange')
                  this.Close()
              }
              this.loading = false
          })
      }
  }  
}
</script>