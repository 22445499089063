<!---------------------------------------------------------------------------- 
 | CRM - 编辑客户联系人 
 |----------------------------------------------------------------------------
 | 方法：请注意大小写,参数描述见具体方法
 | $refs[ref_name].Open() 手动打开窗口,
 |----------------------------------------------------------------------------
 | 回调
 | @onChange = fn (Object) => {} 成功后返信息
 |
 ---------------------------------------------------------------------------->

 <template>
    <el-dialog :visible.sync="visible" width="350px" append-to-body title="编辑组织/部门" :close-on-click-modal="false" :close-on-press-escape="false" :before-close="Close">

        <!-- 表单 -->
        <el-form :model="form" size="mini" label-position="top" v-loading="loading">
            
            <!-- 上级组织 -->
            <el-form-item label="上级组织">
                <hrm-organize-cascader v-model="form.parent" placeholder="请选择上级组织" />
            </el-form-item>

            <!-- 组织/部门名称 -->
            <el-form-item label="组织/部门名称" :rules="[{ required: true, message: '必填项' }]" :error="formError.name?.toString()" prop="name">
                <el-input v-model="form.name" placeholder="请填写组织/部门名称" clearable />
            </el-form-item>

        </el-form>
        

        <span slot="footer">
            <el-button size="mini" @click="Close()">取 消</el-button>
            <el-button size="mini" type="primary" @click="SubmitForm()" :loading="loading">确 定</el-button>
        </span>
    </el-dialog>
</template>

<script>

export default {
    data () {
        return {
            // 加载状态
            visible: false,
            loading: false,

            formError: {},
            form: {
                id: null,
                name: null,
                parent: null
            }
        }
    },

    methods: {

        /**
        |--------------------------------------------------
        | 窗口 - 打开
        |--------------------------------------------------
        |
        */
       
        Open (date) {
            this.visible = true
            this.form.id = date.id
            this.form.name = date.name
            this.form.parent = date.parent
        },


        /**
        |--------------------------------------------------
        | 窗口 - 关闭
        |--------------------------------------------------
        |
        */

        Close() {
            this.loading = false
            this.visible = false
        },

        /**
        |--------------------------------------------------
        | 提交表单
        |--------------------------------------------------
        |
        */

        SubmitForm() {
            // 开启加载状态
            this.loading = true
            this.formError = {}

            // 提交表单
            this.$http.post('/9api/hrm/organize/update', this.form).then(rps => {
                // 关闭加载状态
                this.loading = false

                // 提交失败
                if (rps.data.state == 'error') {
                    this.formError = rps.data.body
                }
                
                // 提交成功
                if (rps.data.state == 'success') {
                    this.$emit('onChange', rps.data.body)
                    this.Close()
                }
            })
        }
    }  
}
</script>