<template>
    <el-drawer :visible.sync="visible" size="80%" direction="rtl" append-to-body destroy-on-close :show-close="false" :before-close="Close">

        <div slot="title">

            <div class="w-margin-b-10">回访</div>
            <div class="w-margin-b-10" style="font-size: 18px; color: #000; font-weight: bold;">{{ visit.number }}</div>

            <!-- 功能按钮 -->
            <div style="display: flex;">
                <el-button size="mini" icon="el-icon-refresh" @click="RePage()">刷新</el-button>
                <el-button style="margin-left: 5px;" size="mini" icon="el-icon-edit" @click="$refs.visitUpdate.Open(visit.id)">编辑</el-button>
                <el-button style="margin-left: 5px;" size="mini" @click="DataDelete()" type="danger">移除</el-button>
            </div>

            <!-- 基本信息 -->
            <div class="w-margin-t-10" style="background-color: #f4f5f7; border-radius: 3px; padding: 15px; display: flex;">
                <el-statistic title="客户名称"><template slot="formatter"><span style="font-size: 14px;">{{ visit.customer?.name }}</span></template></el-statistic>
                <el-statistic title="回访时间"><template slot="formatter"><span style="font-size: 14px;">{{ visit.date }}</span></template></el-statistic>
                <el-statistic title="回访人"><template slot="formatter"><span style="font-size: 14px;">{{ visit.user_admin?.name }}</span></template></el-statistic>
                <el-statistic title="回访形式"><template slot="formatter"><span style="font-size: 14px;">{{ visit.type?.name }}</span></template></el-statistic>
            </div>

        </div>

        <div class="w-padding-l-20 w-padding-r-20 w-padding-b-20">
            <el-tabs v-model="tabs">

                <!-- 详细资料 -->
                <el-tab-pane label="详细信息" name="details">
                    
                    <!-- 基本信息 -->
                    <el-descriptions title="基本信息" size="mini" border :column="1" class="w-margin-b-20">
                        <el-descriptions-item label="编号">{{ visit.number }}</el-descriptions-item>
                        <el-descriptions-item label="回访时间">{{ visit.date }}</el-descriptions-item>
                        <el-descriptions-item label="回访人">{{ visit.user_admin?.name }}</el-descriptions-item>
                        <el-descriptions-item label="回访形式">{{ visit.type?.name }}</el-descriptions-item>
                        <el-descriptions-item label="客户名称">{{ visit.customer?.name }}</el-descriptions-item>
                        <el-descriptions-item label="客户联系人">{{ visit.contacts?.name }}</el-descriptions-item>
                        <el-descriptions-item label="关联合同">{{ visit.contract?.name }}</el-descriptions-item>
                        <el-descriptions-item label="客户满意度">{{ visit.satisficing?.name }}</el-descriptions-item>
                        <el-descriptions-item label="客户反馈">{{ visit.remarks }}</el-descriptions-item>
                    </el-descriptions>

                    <!-- 系统信息 -->
                    <el-descriptions title="系统信息" size="mini" border :column="2">
                        <el-descriptions-item label="创建时间">{{ visit.created_at }}</el-descriptions-item>
                        <el-descriptions-item label="创建人">{{ visit.created_user.name }}</el-descriptions-item>
                        <el-descriptions-item label="更新时间">{{ visit.updated_at }}</el-descriptions-item>
                        <el-descriptions-item label="更新人">{{ visit.updated_user.name }}</el-descriptions-item>
                    </el-descriptions>

                </el-tab-pane>
            </el-tabs>
        </div>

        <!-- 组件 -->
        <crm-visit-update ref="visitUpdate" @onChange="RePage" />

    </el-drawer>
</template>

<script>
export default {
    data() {
        return {
            visible: false,
            loading: false,
            tabs: 'details',

            visit: {
                id: null,
                contract: [],
                contacts: {},
                type: {},
                customer: [],
                user_admin: {},
                created_user: {},
                updated_user: {}
            }
        }
    },

    methods: {

        /**
        |--------------------------------------------------
        | 打开窗口
        |--------------------------------------------------
        |
        */

        Open(id) {
            this.visible = true
            this.DataGet(id)
        },

        /**
        |--------------------------------------------------
        | 关闭窗口
        |--------------------------------------------------
        |
        */

        Close(done) {
            this.$emit('onChange')
            done()
        },

        /**
        |--------------------------------------------------
        | 刷新
        |--------------------------------------------------
        |
        */

        RePage() {
            this.DataGet()
        },

        /**
        |--------------------------------------------------
        | 查询
        |--------------------------------------------------
        |
        */

        DataGet(id = this.visit.id) {
            this.loading = true

            this.$http.post(
                '/9api/crm/visit/search-first',
                {
                    search: { id: id },
                    append: ['user_admin', 'type', 'contract', 'customer', 'contacts', 'satisficing', 'created_user', 'updated_user']
                }
            ).then(rps => {
                this.visit = rps.data
                this.loading = false
            }) 
        },

        /**
        |--------------------------------------------------
        | 删除
        |--------------------------------------------------
        |
        */

        DataDelete() {
            this.$http.post('/9api/crm/visit/delete', { id: this.visit.id }).then(rps => {
                this.$emit('onChange')
                this.visible = false
            })
        },
        
    }
}

</script>6
