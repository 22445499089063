<!---------------------------------------------------------------------------- 
 | CRM - 新增客户联系人 
 |----------------------------------------------------------------------------
 | 方法：请注意大小写,参数描述见具体方法
 | $refs[ref_name].Open() 手动打开窗口,
 |----------------------------------------------------------------------------
 | 回调
 | @onChange = fn (Object) => {} 成功后返信息
 |
 ---------------------------------------------------------------------------->

 <template>
    <el-dialog :visible.sync="visible" width="600px" append-to-body title="新建联系人" :close-on-click-modal="false" :close-on-press-escape="false" :before-close="Close">

        <!-- 表单 -->
        <el-form :model="form" size="mini" label-position="top" v-loading="loading">
            
            <!-- 基础信息 -->
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="姓名" :rules="[{ required: true, message: '必填项' }]" :error="formError.name?.toString()" prop="name">
                        <el-input v-model="form.name" placeholder="请填写联系人名称" clearable />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="负责人" :rules="[{ required: true, message: '必填项' }]" :error="formError.user_admin_id?.toString()" prop="user_admin_id">
                        <users-select v-model="form.user_admin_id" />
                    </el-form-item>
                </el-col>
            </el-row>
            
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="客户名称" :rules="[{ required: true, message: '必选项' }]" :error="formError.customer_id?.toString()" prop="customer_id">
                        <crm-customer-select v-model="form.customer_id" clearable @onChange="CustomerOnChange" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="手机">
                        <el-input v-model="form.phone" placeholder="请填写联系人工作电话" prefix-icon="el-icon-mobile" clearable />
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="电话">
                        <el-input v-model="form.phone_t" placeholder="请填写联系人工作电话" prefix-icon="el-icon-phone-outline" clearable />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="邮箱">
                        <el-input v-model="form.email" placeholder="电子邮件地址" prefix-icon="el-icon-message" clearable />
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="性别">
                        <el-select v-model="form.sex" placeholder="请选择" :style="{ width: '100%' }" clearable>
                            <el-option value="女" label="女" />
                            <el-option value="男" label="男" />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="是否关键决策人">
                        <el-select v-model="form.policymakers" placeholder="请选择" :style="{ width: '100%' }" clearable>
                            <el-option value="是" label="是" />
                            <el-option value="否" label="否" />
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="所在部门">
                        <el-input v-model="form.department" placeholder="联系人所在部门" clearable />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="职务">
                        <el-input v-model="form.appointment" placeholder="联系人的职务信息或职务名称" clearable />
                    </el-form-item>
                </el-col>
            </el-row>

            <!-- 位置信息 -->
            <el-divider content-position="left">联系地址</el-divider>
            <el-row :gutter="5">
                <el-col :span="8">
                    <el-form-item>
                        <config-country-select v-model="form.country" clearable />
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item>
                        <config-country-select-province v-model="form.country_province" :country="form.country" clearable />
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item>
                        <config-country-select-city v-model="form.country_province_city" :province="form.country_province" clearable />
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row>
                <el-col :span="24">
                    <el-form-item>
                        <el-input type="textarea" :rows="2" v-model="form.address" placeholder="详细地址" clearable />
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        

        <span slot="footer">
            <el-button size="mini" @click="Close()">取 消</el-button>
            <el-button size="mini" type="primary" @click="SubmitForm()" :loading="loading">确 定</el-button>
        </span>
    </el-dialog>
</template>

<script>

export default {
    data () {
        return {
            // 加载状态
            visible: false,
            loading: false,

            formError: {},
            form: {
                name: null,
                user_admin_id: this.$store.state.user.id,
                customer_id: null,
                phone: null,
                phone_t: null,
                email: null,
                sex: null,
                policymakers: '否',
                department: null,
                appointment: null,
                country: null,
                country_province: null,
                country_province_city: null,
                address: null,
            }
        }
    },

    methods: {

        /**
        |--------------------------------------------------
        | 窗口 - 打开
        |--------------------------------------------------
        |
        */
       
        Open (config) {
            // 默认合同信息配置
            if (config) this.form.customer_id = config.customer_id

            this.visible = true
        },


        /**
        |--------------------------------------------------
        | 窗口 - 关闭
        |--------------------------------------------------
        |
        */

        Close() {
            this.form.name = null
            this.form.user_admin_id = this.$store.state.user.id,
            this.form.customer_id = null
            this.form.phone = null
            this.form.phone_t = null
            this.form.email = null
            this.form.sex = null
            this.form.policymakers = '否'
            this.form.department = null
            this.form.appointment = null
            this.form.country = null
            this.form.country_province = null
            this.form.country_province_city = null
            this.form.address = null

            this.loading = false
            this.visible = false
        },

        /**
        |--------------------------------------------------
        | 客户名称 - 选中事件
        |--------------------------------------------------
        |
        */

        CustomerOnChange(data) {
            if (data) {
                this.form.country = data.country
                this.form.country_province = data.country_province
                this.form.country_province_city = data.country_province_city
                this.form.address = data.address
            }
        },

        /**
        |--------------------------------------------------
        | 提交表单
        |--------------------------------------------------
        |
        */

        SubmitForm() {
            // 开启加载状态
            this.loading = true
            this.formError = {}

            // 提交表单
            this.$http.post('/9api/crm/contacts/create', this.form).then(rps => {
                // 关闭加载状态
                this.loading = false

                // 提交失败
                if (rps.data.state == 'error') {
                    this.formError = rps.data.body
                }
                
                // 提交成功
                if (rps.data.state == 'success') {
                    this.$emit('onChange', rps.data.body)
                    this.Close()
                }
            })
        }
    }  
}
</script>