<template>
    <div class="el-dialog-menu-nav-group">
        <div class="el-dialog-menu-nav-group-title">{{ title }}</div>
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: null
        },
    },
}
</script>