<template>
    <div>

        <!-- 出库记录 -->
        <el-divider content-position="left"><b>出库记录</b></el-divider>
        <vxe-table border ref="xTable" :data="table.filter(rps => rps.action == 0)" :loading="loading" :style="{ marginTop: '10px' }">
            <!-- 动作 -->
            <vxe-column min-width="160" title="动作">
                <template #default="{ row }">
                    库存调拨出库
                    <el-tag size="mini" effect="dark" type="danger" :style="{ marginLeft: '10px' }" v-if="row.state == 0">已作废</el-tag>
                </template>
            </vxe-column>

            <!-- 产品名称 -->
            <vxe-column min-width="140" title="产品名称">
                <template #default="{ row }">
                    <span class="el-span-primary" @click="$refs.productDrawer.Open(row.product.id)">{{ row.product?.name }}</span>
                </template>
            </vxe-column>

            <!-- 供应商 -->
            <vxe-column min-width="140" title="供应商">
                <template #default="{ row }">
                    <span class="el-span-primary" @click="$refs.supplierDrawer.Open(row.product.supplier.id)">{{ row.product.supplier.name }}</span>
                </template>
            </vxe-column>

            <vxe-column min-width="100" field="warehouse.name" title="仓库名称"></vxe-column>
            <vxe-column min-width="80" field="num" title="数量"></vxe-column>
            <vxe-column min-width="90" field="describe" title="备注"></vxe-column>
            <vxe-column min-width="90" field="created_user.name" title="创建人"></vxe-column>
            <vxe-column min-width="140" field="created_at" title="创建时间"></vxe-column>
        </vxe-table>

        <!-- 入库记录 -->
        <el-divider content-position="left"><b>入库记录</b></el-divider>
        <vxe-table border ref="xTable" :data="table.filter(rps => rps.action == 1)" :loading="loading" :style="{ marginTop: '10px' }">
            <!-- 动作 -->
            <vxe-column min-width="160" title="动作">
                <template #default="{ row }">
                    库存调拨出库
                    <el-tag size="mini" effect="dark" type="danger" :style="{ marginLeft: '10px' }" v-if="row.state == 0">已作废</el-tag>
                </template>
            </vxe-column>

            <!-- 产品名称 -->
            <vxe-column min-width="140" title="产品名称">
                <template #default="{ row }">
                    <span class="el-span-primary" @click="$refs.productDrawer.Open(row.product.id)">{{ row.product?.name }}</span>
                </template>
            </vxe-column>

            <!-- 供应商 -->
            <vxe-column min-width="140" title="供应商">
                <template #default="{ row }">
                    <span class="el-span-primary" @click="$refs.supplierDrawer.Open(row.product.supplier.id)">{{ row.product.supplier.name }}</span>
                </template>
            </vxe-column>

            <vxe-column min-width="100" field="warehouse.name" title="仓库名称"></vxe-column>
            <vxe-column min-width="80" field="num" title="数量"></vxe-column>
            <vxe-column min-width="90" field="describe" title="备注"></vxe-column>
            <vxe-column min-width="90" field="created_user.name" title="创建人"></vxe-column>
            <vxe-column min-width="140" field="created_at" title="创建时间"></vxe-column>
        </vxe-table>
        
        <!-- 组件 -->
        <ims-product-drawer ref="productDrawer" />
        <ims-supplier-drawer ref="supplierDrawer" />

    </div>
</template>

<script>
export default {
    props: ['model', 'model_id'],

    data() {
        return {
            loading: false,
            table: [],
        }
    },

    watch: {
        model_id: {
            handler(val) {
                this.TableSearch()
            },
            immediate: true
        }
    },
    
    methods: {

        // 订单记录
        TableSearch() {
            this.loading = true
            this.$http.post(
                '/9api/ims/warehouse-log/search-all',
                {
                    search: { model: this.model , model_id: this.model_id}, 
                    append: ['created_user', 'product', 'warehouse']
                }
            ).then(rps => {
                this.table = rps.data
                this.loading = false
            })
        },
    }
}
</script>